import { Button, ColorPicker } from "antd";
import React, { useContext } from "react";
import { ReactComponent as ColorDrop } from "../assets/images/ralvie/color_drop_icon.svg";
import { ReactComponent as ColorDropDark } from "../assets/images/ralvie/color_drop_icon_dark.svg";
import AppContext from "../config/AppContext";
import { Checkbox } from "@mui/material";

export default function ColorPickerWithPalettes({ selectedColor, setSelectedColor, place, id }) {
  const predefinedColors = ['#FFCE3C', '#B0B7BA', '#E73E29', "#FFBCB2", '#EE7110', '#FFBF84', '#3F9F4A', "#8ED290", "#27938E", "#7AD1CD", "#1E84D0", "#86C8F7", "#9A61C7", "#D4AFF6", "#CC53B4"]

  const { mode } = useContext(AppContext);

  const handlePredefinedColorChange = (color) => {
    if (id) {
      setSelectedColor(id, color);
      return
    }
    setSelectedColor(color);
  };

  const handleCustomColorChange = (color) => {
    if (id) {
      setSelectedColor(id, "#".concat(color.toHex()));
      return
    }
    setSelectedColor("#".concat(color.toHex()));
  };

  return (
    <div style={{ zIndex: 1, }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(8, 34px)',
          gridGap: '10px',
          background: mode === 'light' ? "#FFFFFF" : "#1F1F1F",
          padding: "5px",
        }}
      >
        {predefinedColors.map((color) => (
          <div key={color} style={{ textAlign: 'center' }}>
            <Checkbox
              checked={selectedColor === color}
              onChange={() => handlePredefinedColorChange(color)}
              sx={{
                padding: '0px',
                position: "relative",
                "& .MuiSvgIcon-root": {
                  backgroundColor: selectedColor === color ? "" : color,
                  borderRadius: "4px",
                  width: '34px',
                  height: '34px',
                  padding: '0px',
                },
                "&.Mui-checked .MuiSvgIcon-root": {
                  border: "none",
                  width: "46px",
                  height: "46px",
                  position: "absolute",
                  top: "-19px",
                },
                "&.Mui-checked": {
                  color: color,
                },
                "& .MuiSvgIcon-root path": {
                  fill: "transparent",
                },
                "&.Mui-checked .MuiSvgIcon-root path": {
                  fill: color,
                },
                "&.Mui-selected": {
                  backgroundColor: color, 
                  color: color,
                },
              }}
            />
          </div>
        ))}

        <ColorPicker
          value={selectedColor}
          defaultValue={selectedColor}
          id="btn-color-picker-project"
          name="btn-color-picker-project"
          onChange={handleCustomColorChange}
        >
          <Button
            icon={mode === 'light' ? <ColorDropDark style={{ fontSize: "24px" }} /> : <ColorDrop style={{ fontSize: "24px" }} />}
            size="large"
            style={{
              background: mode === 'light' ? "#f8f8f8" : "#1f1f1f",
              border: "none",
              cursor: "pointer",
              padding: "0px",
              width: '34px',
              height: '34px',
            }}
          />
        </ColorPicker>
      </div>
    </div>
  );
}

