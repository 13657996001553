import { css } from "@emotion/css";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
// import AiIcon from "../../assets/images/ralvie/ai-robot.svg";
import LavoroAiIcon from "../../assets/images/lavoro-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/edit-icon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import { ReactComponent as CheckAIicon } from "../../assets/images/CheckAIicon.svg";
import { ReactComponent as ProjectIcon } from "../../assets/images/ralvie/project-icon-active.svg";
import { ReactComponent as SundialIcon } from "../../assets/images/ralvie/sundial-logo.svg";
import CustomIconButton from "../../components/AICustomIconButton";
import { tagList } from "../../services/TagService";
import { createTimesheet, generateTitle } from "../../services/WorklogService";
import CommonUtil from "../../util/CommonUtils";
import AILogWork from "./AILogWork";
import { TimesheetContext } from "../../util/context";
import { useTranslation } from "react-i18next";
export default function AITimesheet(props) {
  const [duration, setDuration] = useState();
  const { setReload } = useContext(TimesheetContext);
  const [toggleTimesheet, setToggleTimesheet] = useState(null);
  const [title, setTitle] = useState(undefined);
  const { t } = useTranslation();

  const handleSubEventsToogle = (id) => {
    if (toggleTimesheet === id) {
      setToggleTimesheet(null);
    } else {
      setToggleTimesheet(id);
    }
  };
  useEffect(() => {
    setDuration(CommonUtil.getDurationWithRoundoff(props.event?.duration));
    if (props.event?.events) {
      let events = props.event?.events?.map((e) => e.title);
      let platforms = props.event?.events?.map((e) => e.app);

      let payload = {
        event: events,
        platform: platforms,
        company: CommonUtil.getLoggedUser()?.companyName,
        client: "",
        language: localStorage.getItem("text-summary-lang"),
      };
      const concatenatedTitle = [
        ...new Set(
          props.event?.events.map((event) => {
            let title = event.title || ""; // Handle case where title might be undefined
            // Extract the portion of the title before '-' or '|'
            const truncatedTitle = title.split(/[-|]/)[0].trim();
            return truncatedTitle;
          })
        ),
      ].join(" • "); // Remove duplicates and join with a big dot and space
      setTitle(concatenatedTitle);
      setToggleTimesheet(
        props?.event?.id === props?.edit ? props?.event?.id : null
      );
      // generateTitle(payload).then((res) => {
      //   if (res.data?.data && res.data.data?.title) {
      //     title = res.data.data?.title?.replace(/"/g, "");
      //   }

      //   setTitle(title);
      // });
    }
  }, [props.event]);

  const handleSubmit = async () => {
    const res = await tagList({
      pageNumber: 1,
      pageSize: 1000,
    });
    let tags = [];
    if (res.data.code === "RCI0000") {
      tags = res.data.data?.tags?.filter((t) => t.name === props.event?.tagId);
    }

    let payload = {
      title: props?.event?.title,
      projectId: props?.event?.project?.id,
      tags: tags,
      description: "",
      loggedDuration: props?.event?.duration,
      eventDuration: props?.event?.duration,
      events: props?.event?.events?.map((obj) => obj.id),
      hrs: 0,
      mins: 0,
      isBillable: false,
      timesheetDate: props.filter?.selectedDate?.utc().format(),
    };
    console.log(payload);
    createTimesheet(payload).then((res) => {
      if (res.data?.code == "RCI0039") {
        toast.success(res.data?.message);
        props.handleRemoveEvent(props.event);
        setReload(true);
      } else {
        toast.error(res.data?.message);
      }
    });
  };
  return (
    <>
      <Box
        className={css`
          width: 100%;
          background: ${props?.event?.project?.color};
          border-radius: 5px;
          opacity: 1;
          padding: 8px 16px;
        `}
      >
        <Box item display={"flex"} alignItems={"center"} mb={1}>
          <Box flexGrow={1} display={"grid"}>
            <Tooltip id="ai-timesheet-title" title={title}>
              <span id="ai-timesheet-title" className="worklog-card-title">
                {title}
              </span>
            </Tooltip>
          </Box>
          {/* <img src={AiIcon} alt="AiIcon" />  */}
          <img
            id="ai-timesheet-img"
            src={LavoroAiIcon}
            alt="AiIcon"
            width={"20px"}
            height={"20px"}
          />
        </Box>
        <Box item display={"flex"} alignItems={"center"} columnGap={1}>
          <Tooltip id="ai-timesheet-project" title={"Project"}>
            <ProjectIcon id="ai-timesheet-projecticon" className="icon" />
          </Tooltip>
          <Box flexGrow={1}>
            <Typography
              variant="worklog_project_title"
              sx={{ wordBreak: "break-all" }}
            >
              {props?.event?.project?.name}
            </Typography>
          </Box>
        </Box>
        <Box
          item
          className={css`
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
          `}
        >
          {props?.event?.project?.clientName && (
            <Typography variant="worklog_project_details">
              {props?.event?.project?.clientName}
            </Typography>
          )}
        </Box>
        <Box
          item
          display={"flex"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          <Box item flexGrow={1}>
            {!props?.event?.events?.some(
              (event) => event.source === "manual"
            ) && (
              <Tooltip title={"Duration"}>
                <SundialIcon height="14px" style={{ paddingRight: "4px" }} />
              </Tooltip>
            )}
            <Typography variant="worklog_project_details">
              {props?.event?.hrs +
                "h " +
                props?.event?.mins +
                "m " +
                props?.event?.secs +
                "s"}
            </Typography>
          </Box>
          {/* {props?.event?.tags?.length > 0 && (
            <Box item>
              <TagIcon className="icon" />
            </Box>
          )}
          <Box item pl={1}>
            {toggleTimesheet === props?.event?.id ? (
              <ArrowUp
                className="icon"
                onClick={() => handleSubEventsToogle(props?.event?.id)}
              />
            ) : (
              <ArrowDown
                className="icon"
                onClick={() => handleSubEventsToogle(props?.event?.id)}
              />
            )}
          </Box> */}
          <Box item pr={1}>
            <CustomIconButton
              id="ai-timesheet-check-icon"
              title={t("RAL0200")}
              size={"md"}
              darkMode={
                <CheckAIicon
                  id="ai-timesheet-check-icon"
                  // sx={{ color: "white" }}
                />
              }
              lightMode={
                <CheckAIicon
                  id="ai-timesheet-check-icon"
                  // sx={{ color: "white" }}
                />
              }
              variant="square"
              onClick={() => handleSubmit()}
            />
          </Box>
          <Box item>
            <CustomIconButton
              id="ai-timesheet-edit-icon"
              title={t("RAL0624")}
              // bgcolor="white"
              size={"md"}
              darkMode={<EditIconDark id="ai-timesheet-edit-icon"  />}
              lightMode={<EditIconDark id="ai-timesheet-edit-icon"  />}
              variant="square"
              onClick={() => handleSubEventsToogle(props?.event?.id)}
            />
          </Box>
        </Box>
      </Box>
      {toggleTimesheet === props?.event?.id && (
        <AILogWork
          title={title}
          filter={props.filter}
          eventsToLog={props?.event}
          handleRemoveEvent={(event) => props.handleRemoveEvent(event)}
        />
      )}
    </>
  );
}
