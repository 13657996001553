import { Autocomplete, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTextField from "./CustomTextField";
import "./LanguageSelector.scss";

const useStyles = makeStyles({
  flag: {
    marginRight: 8,
    width: 20,
    height: 15,
  },
  option: {
    display: "flex",
    alignItems: "center",
    font: "normal normal normal var(--font-size-14) / 26px Gotham"  },
});

function LanguageSelector(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const languageOptions = [
    { code: "us", label: t("RAL0681"), flag: "https://flagcdn.com/us.svg", altCode: "en" },
    { code: "cn", label: t("RAL0682"), flag: "https://flagcdn.com/cn.svg", altCode: "zh" },
    { code: "es", label: t("RAL0649"), flag: "https://flagcdn.com/es.svg", altCode: "es" },
  ];

  const getInitialLanguage = () => {
    const lang = i18n.language || localStorage.getItem("i18nextLng") || "en";
    return languageOptions.find((option) => option.altCode === lang) || languageOptions[0];
  };

  const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage);

  useEffect(() => {
    setSelectedLanguage(getInitialLanguage());
  }, [i18n.language]);

  useEffect(() => {
    try {
      props?.onChange(selectedLanguage);
    } catch (e) {
      console.error(e);
    }
  }, [selectedLanguage]);

  return (
    <div className="autocomplete-nopadding">
      <Autocomplete
        clearIcon={false}
        value={selectedLanguage}
        onChange={(event, newValue) => {
          if (newValue) {
            setSelectedLanguage(newValue);
            i18n.changeLanguage(newValue.code); 
          }
        }}
        options={languageOptions}
        autoHighlight
        disabled={props.disabled}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box pb={2} px={2} display={"flex"} columnGap={2} sx={{ cursor: "pointer" }}>
            <li {...props} className={classes.option}>
              <img src={option.flag} alt={option.label} className={classes.flag} />
              {option.label}
            </li>
          </Box>
        )}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            label={t("RAL0091")}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box px={2} borderRight={"2px solid #F4F5F8"}>
                  <img src={selectedLanguage.flag} alt={selectedLanguage.label} className={classes.flag} />
                </Box>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

export default LanguageSelector;
