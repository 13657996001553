import React, { useContext, useEffect, useState } from "react";
import { TimesheetContext, sidebarContext } from "../../util/context";
import { predictProjectAndTag } from "../../services/WorklogService";
import CommonUtil from "../../util/CommonUtils";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  alpha,
  createTheme,
} from "@mui/material";
import {
  findProjectsByAssignedMember,
  listProject,
} from "../../services/ProjectService";
import { tagList } from "../../services/TagService";
import AITimesheet from "./AITimesheet";
import { css } from "@emotion/css";
import { projectPredict } from "../../services/AiService";
import { ReactComponent as ClearIcon } from "../../assets/images/ralvie/clearTextIcon.svg";
import { ReactComponent as ClearIconDark } from "../../assets/images/ralvie/clearTextIconDark.svg";
import AppContext from "../../config/AppContext";
import { useTranslation } from "react-i18next";

function collectNestedTitles(events) {
  let titles = [];
  function collectTitles(eventList) {
    eventList.forEach((event) => {
      if (event.events && event.events.length > 0) {
        event.events.forEach((nestedEvent) => {
          let event = {
            logged_duration: String(nestedEvent.duration),
            title: nestedEvent.title,
          };
          titles.push(event);
        });
        collectTitles(event.events); // Recursively collect titles from deeper nested events
      }
    });
  }

  collectTitles(events); // Start collecting from the top-level events

  return titles;
}

export default function AITimesheetPredictions(props) {
  const { mode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const { setEventsToLog } = useContext(TimesheetContext);
  const { enableAi, userProfile } = useContext(sidebarContext);
  const [projects, setProjects] = useState([]);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [tags, setTags] = useState([]);
  const [aiPredictions, setAiPredictions] = useState([]);
  const [title, setTitle] = useState("");
  const { t } = useTranslation();
  const projectMap = projects?.reduce((acc, project) => {
    acc[project?.id] = project;
    return acc;
  }, {});

  const getProjectColor = (color) => {
    const theme = createTheme({
      palette: {
        primary: {
          main: color,
          light: alpha(color, 0.1),
          dark: alpha(color, 0.9),
        },
      },
    });

    // Access the light and dark versions of the primary color
    const lightColor = theme.palette.primary.light;
    const darkColor = theme.palette.primary.dark;
    const contrastText = theme.palette.primary.contrastText;

    return {
      main: color,
      light: lightColor,
      dark: darkColor,
      contrastText: contrastText,
    };
  };

  function mergeEventsWithSameProject(events) {
    const mergedData = {};

    events.forEach((event) => {
      if (event.project) {
        let totalDuration = event?.events?.reduce((total, se) => {
          return total + se.duration;
        }, 0);
        if (!totalDuration) totalDuration = event.duration;
        console.log(event.id + " " + totalDuration);
        const projectCode = event.project?.id;
        if (projectCode && !mergedData[projectCode]) {
          let color = getProjectColor(event.project?.color);
          // If project code does not exist in mergedData, initialize it
          mergedData[projectCode] = {
            ...event,
            duration: totalDuration,
            tags: [event.tag],
            events: event.events,
            light: color.light,
            dark: color.dark,
          };
        } else {
          // If project code exists, update start and end times, sum the duration, and merge tags and events
          const existingData = mergedData[projectCode];
          existingData.start = new Date(
            Math.min(new Date(existingData.start), new Date(event.start))
          ).toISOString();
          existingData.end = new Date(
            Math.max(new Date(existingData.end), new Date(event.end))
          ).toISOString();
          existingData.duration += totalDuration;
          if (!existingData.tags.includes(event.tag)) {
            existingData.tags.push(event.tag);
          }
          if (existingData.events) {
            let subEvents = [...existingData.events, ...event.events];
            // subEvents.push(...event.events)
            existingData.events = subEvents;
          }
        }
      }
    });

    Object.values(mergedData).forEach((data) => {
      const { hr, min, sec } = CommonUtil.getDurationWithoutRoundoff(
        data.duration
      );
      data.hrs = hr;
      data.mins = min;
      data.secs = sec;
    });

    console.log(Object.values(mergedData));
    setIsLoading(false);

    return Object.values(mergedData);
  }

  const setDefaultProject = () => {
    let project = projects[0];
    let projectEvents = [];
    let duration = 0;
    let groupedEventsByProject = [];
    props?.eventsToLog?.forEach((event) => {
      if (event.events && event.events.length > 0) {
        event.events.forEach((nestedEvent) => {
          duration = duration + nestedEvent.duration;
          projectEvents.push(nestedEvent);
        });
      }
    });
    const timeSpent = CommonUtil.getDurationWithoutRoundoff(duration);
    const data = {
      ...projectEvents[0],
      events: projectEvents,
      hrs: timeSpent?.hr,
      mins: timeSpent?.min,
      secs: timeSpent?.sec,
      project: project, // Use projectMap for faster lookup
      tagId: "",
      duraton: duration,
    };
    groupedEventsByProject.push(data);
    setAiPredictions(groupedEventsByProject);
    setIsLoading(false);
  };
  useEffect(() => {
    let events = props.eventsToLog;

    if (
      enableAi &&
      events &&
      events instanceof Array &&
      projects instanceof Array &&
      projects.length > 0
    ) {
      setIsLoading(true);
      let titles = collectNestedTitles(events);
      let groupedEventsByProject = [];

      projectPredict({ member_id: userProfile?.ralvieId, events: titles })
        .then((res) => {
          if (
            res.data?.projects &&
            res.data?.projects instanceof Array &&
            res.data?.projects?.length > 0
          ) {
            let projectPredictions = res.data?.projects;
            projectPredictions?.forEach((projectPrediction) => {
              let project = projectMap[projectPrediction.project_id];
              let projectTitles = projectPrediction.titles;
              let projectEvents = [];
              let duration = 0;
              events.forEach((event) => {
                if (event.events && event.events.length > 0) {
                  event.events.forEach((nestedEvent) => {
                    const isMatch = projectTitles.find((titles) =>
                      titles.includes(nestedEvent?.title)
                    );
                    if (isMatch) {
                      duration = duration + nestedEvent.duration;
                      projectEvents.push(nestedEvent);
                    }
                  });
                }
              });
              const timeSpent = CommonUtil.getDurationWithoutRoundoff(duration);
              const data = {
                ...projectEvents[0],
                events: projectEvents,
                hrs: timeSpent?.hr,
                mins: timeSpent?.min,
                secs: timeSpent?.sec,
                project: project, // Use projectMap for faster lookup
                tagId: "",
                tags: projectPrediction?.tags,
                duraton: duration,
              };
              groupedEventsByProject.push(data);
            });
            setAiPredictions(groupedEventsByProject);
            setIsLoading(false);
          } else {
            setDefaultProject();
          }
        })
        .catch((e) => {
          setDefaultProject();
          setIsLoading(false);
        });

      // Extract titles before '-' or '|', remove duplicates, and concatenate with a big dot (•)
      const concatenatedTitle = [
        ...new Set(
          events.map((event) => {
            let title = event.title || ""; // Handle case where title might be undefined
            // Extract the portion of the title before '-' or '|'
            const truncatedTitle = title.split(/[-|]/)[0].trim();
            return truncatedTitle;
          })
        ),
      ].join(" • "); // Remove duplicates and join with a big dot and space
      setTitle(concatenatedTitle);
    }
  }, [enableAi, props.eventsToLog, projects]);

  useEffect(() => {
    findProjectsByAssignedMember(CommonUtil.getLoggedUser()?.id)
      .then((res) => {
        setProjects(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
    tagList({
      pageNumber: 1,
      pageSize: 1000,
    }).then((res) => {
      if (res.data.code === "RCI0000") {
        setTags(res.data.data?.tags);
      }
    });
  }, []);
  const handleRemoveEvent = (event) => {
    let updatedList = aiPredictions.filter((e) => e.id !== event.id);
    setAiPredictions(updatedList);
    if (updatedList.length < 1) {
      setEventsToLog([]);
    }
  };

  return (
    <div
      className={css`
        height: calc(100% - 40px);
        position: relative;
      `}
    >
      <>
        <Box
          item
          className={`${css`
            padding-bottom: 20px;
            padding-top: 14px;
            align-items: center;
            display: flex;
          `} header-sticky`}
        >
          <Box flexGrow={1}>
            <Typography
              variant="header3"
              sx={{
                color: "var( --text-secondary-80)",
                font: "normal normal bold 18px/20px Gotham",
                letterSpacing: "0px",
              }}
            >
              {t("RAL0623")}
            </Typography>
          </Box>
          <IconButton
            aria-label="clear input"
            onClick={() => setEventsToLog([])}
            sx={{
              borderRadius: "5px",
              background: "transparent",
              "& .clear_svg": {
                transition: "color 0.3s ease",
                color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
              },
              "&:hover": {
                "& .hover-text, & .clear_svg": {
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                },
              },
            }}
          >
            <Tooltip title={"Clear"}>
              {mode === "dark" ? (
                <ClearIconDark className="clear_svg" />
              ) : (
                <ClearIcon className="clear_svg" />
              )}
            </Tooltip>
          </IconButton>
        </Box>
      </>
      <div
        className={css`
          height: 70vh;
          overflow: hidden;
          :hover {
            overflow-y: auto;
          }
        `}
      >
        {aiPredictions?.map((event) => {
          return (
            <Box mb={2}>
              <AITimesheet
                edit={aiPredictions[0]?.id}
                title={title}
                event={event}
                filter={props.filter}
                eventToEdit={eventToEdit}
                setEventToEdit={(id) => setEventToEdit(id)}
                handleRemoveEvent={(event) => handleRemoveEvent(event)}
                setIsLoading={(state) => setIsLoading(state)}
              />
            </Box>
          );
        })}
      </div>
      <Box
        sx={{
          display: isLoading ? "flex" : "none",
        }}
      >
        <CircularProgress
          style={{ top: "50%", left: "85%", position: "fixed" }}
        />
      </Box>
    </div>
  );
}
