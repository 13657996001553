import React, { useState, useEffect, useContext } from "react";
import { useDrop } from "react-dnd";
import { Box, Popover, Typography } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { ReactComponent as EmptyListIcon } from "../../assets/images/ralvie/Group-icon.svg";
import { ReactComponent as EmptyListIconDark } from "../../assets/images/ralvie/Group-icon-dark.svg";
import { ColorPicker } from "antd";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import { removeAppsFromCategory } from "../../services/CategoryService";
import { toast } from "sonner";
import AppIcon from "../../components/AppIcon";
import AppContext from "../../config/AppContext";
import ColorPickerWithPalettes from "../../components/ColorPickerWithPalettes";
import { useRef } from "react";
import "./CategoryItem.scss";
import { useTranslation } from "react-i18next";

const CategoryItem = ({
  category,
  handleColorChange,
  handleDrop,
  refreshCategories,
  setReload,
  index,
  removeAppFromList,
}) => {
  const { mode } = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const colorPickerRef = useRef(false);
  const { t } = useTranslation();

  const [{ isOver }, drop] = useDrop({
    accept: "APPLICATION",
    drop: (item) => {
      handleDrop(category.id, item, removeAppFromList);
      refreshCategories();
      setExpanded(true); // Open the accordion when an application is added
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleClick = (event) => {
    colorPickerRef.current = true;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    colorPickerRef.current = false;
    setAnchorEl(null);
  };

  const handleRemoveApp = (appId) => {
    removeAppsFromCategory(category.id, appId)
      .then((res) => {
        if (res.data.code === "RCI0031") {
          toast.success(t("RAL0707"));
          refreshCategories();
          setReload((prev) => !prev);
        } else {
          toast.error("Failed to delete application");
        }
      })
      .catch(() => {
        toast.error(t("RAL0708"));
      });
  };

  const getApplicationIcon = () => {
    return <AppIcon height={30} width={30} />;
  };

  useEffect(() => {
    setExpanded(false);
  }, [index]);

  return (
    <Accordion
      square={false}
      elevation={0}
      sx={{
        borderRadius: "10px",
        padding: "10px",
        marginBottom: "10px",
        "&.MuiAccordion-root": {
          "::before": {
            content: "none",
          },
          "&.Mui-expanded": {
          },
        },
      }}
      ref={drop}
      expanded={expanded}
      onChange={() =>
        !anchorEl && !colorPickerRef.current && setExpanded(!expanded)
      }
      style={{
        backgroundColor: isOver ? "lightgreen" : "var(--background-color-3)",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ opacity: mode === "dark" ? 0.4 : 0.8 }} />}
        aria-controls={`panel${category.id}-content`}
        id={`panel${category.id}-header`}
        className="accordinHeader"
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box
            sx={{
              width: "36px",
              height: "36px",
              background: "var(--background-color-6) !important",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              marginRight: "10px",
              cursor: "pointer",
            }}
            variant="contained"
            onClick={handleClick}
          >
            <Box
              sx={{
                backgroundColor: category.color,
                width: "20px",
                height: "20px",
                borderRadius: "2px",
              }}
            ></Box>
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            disableRestoreFocus
            sx={{ zIndex: 1000 }}
          >
            <ColorPickerWithPalettes
              onClick={(e) => e.stopPropagation()}
              place="Category"
              id={category.id}
              selectedColor={category.color}
              setSelectedColor={handleColorChange}
            />
          </Popover>

          {/* <ColorPicker
            defaultValue="#00FF00"
            value={category.color}
            style={{
              width: "40px",
              height: "40px",
              fontSize: "30px",
              background: "#5d2525 !important",
              marginRight: "10px",
            }}
            onChange={(color) => handleColorChange(category.id, color)}
          /> */}
          <Typography
            sx={{
              font: "normal normal bold 17px/22px Gotham",
            }}
          >
            {category.name}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {category.applications && category.applications.length > 0 ? (
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              flexWrap: "wrap",
              gap: "31px",
            }}
          >
            {category.applications.map((app) => (
              <Box
                key={app.id}
                sx={{
                  background: "var(--background-color-31)",
                  borderRadius: "5px",
                  maxWidth: "500px",
                  minWidth: "298px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: 36,
                      height: 36,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {getApplicationIcon()}
                  </Box>
                  <Typography className="categoryAppName" variant="body1" sx={{
                      marginLeft: "10px",
                      textTransform: "capitalize",
                      color: "var(--text-color-1)",
                    }}
                  >
                    {app.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    background: "#FE5050 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    opacity: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                   onClick={() => handleRemoveApp(app.id)}
                >
                  <CloseIcon
                    id={`btn-rmvapp-in-ctgrylist-${app.id}`}
                    fill="white"
                  />
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {mode == "dark" ? <EmptyListIconDark /> : <EmptyListIcon />}
            <Typography variant="body1" mt={1.5}>
            {t("RAL0746")}
            </Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CategoryItem;
