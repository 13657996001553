import React, { useEffect, useRef, useState } from "react";
import SignupLayout from "./SignupLayout";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import "./Signup.scss";
import "./SignupUser.scss";
import OnboardTextField from "../../components/OnboardTextField";
import { useTranslation } from "react-i18next";
import PasswordField from "../../components/PasswordField";
import CommonUtil from "../../util/CommonUtils";
import { UserCountryList } from "../../components/UserCountryList";
import CustomCheckBox from "../../components/CustomCheckBox";
import CustomButton from "../../components/CustomButton";
import PrivacyPolicy from "../onboard/PrivacyPolicy";
import { useNavigate, useLocation } from "react-router-dom";
import OnboardService from "../../services/OnboardService";
import { CustomPhone } from "../../components/CustomPhone";
import { toast } from "sonner";
import { useTheme } from '@mui/material/styles';

export default function SignupUser(props) {
  const { t, ready } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const [openDilog, setopenDilog] = React.useState(false);
  const [HeaderContent, setHeaderContent] = React.useState("");
  const [termsCondition, setTermsCondition] = React.useState(false);
  const [init, setInit] = useState({ termsCondition: true });
  const [isFocusedCountry, setIsFocusedCountry] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("");
  const emailFromState = location.state ? location.state.email : null;

  const theme = useTheme();
  const headerBackgroundColor = theme.palette.mode === 'dark' ? '#000' : '#fff';
  if (localStorage.getItem("token")) {
    CommonUtil.resetUserSession();
    navigate("/pages/verify-email");
  }

  if (!emailFromState) {
    navigate("/pages/verify-email");
  }

  const styleDialog = {
    "& .MuiDialog-paper": {
      padding: "33Px",
      position: "absolute",
      top: "18px",
      borderRadius: "8px",
      bottom: "10px",
      minWidth: "60%",
    },
  };

  const handleAgree = () => {
    if (HeaderContent === "Terms and conditions") {
      setInit({ ...init, termsCondition: false });
      setTermsCondition(true);
    }
    setopenDilog(false);
  };

  const nameRef = useRef(null);

  useEffect(() => {
    if (ready && nameRef.current) {
      nameRef.current.focus();
    }
  }, [ready]);

  const [payload, setPayload] = useState({
    firstName: "",
    email: emailFromState || "",
    phoneNumber: "",
    // country: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    // country: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (payload.email !== emailFromState) {
        setError({ ...error, email: t("RAL0073") });
        toast.error(t("RAL0392"));
        return;
      }
      let phone = "";
      phone = phoneNumber.replace(/ /g, "").substring(phoneCountryCode.length);
      let data = {
        name: payload.firstName,
        email: payload.email,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        phoneCountryCode: phoneCountryCode,
        phone: phone,
      };
      OnboardService.createCustomer(data).then((res) => {
        if (res.data.code === "RCI0000") {
          localStorage.setItem(
            "token",
            "Bearer " + res.data?.data?.access_token
          );
          toast.success(t("RAL0145"));
          navigate("/pages/company-industry", {
            state: { email: payload.email },
          });
        } else if (res.data.code === "UASE0020") {
          setError({ ...error, email: res.data?.message });
        } else if (res.data.code === "RCE0068") {
          setError({ ...error, email: res.data?.message });
        } else if (res.data.code === "RCE0073") {
          setError({ ...error, email: res.data?.message });
        } else if (res.data.code === "RCE0096") {
          setError({ ...error, phoneNumber: res.data?.message });
          toast.error(res.data?.message);
        } else if (res.data.code === "RCE0106") {
          setError({ ...error, password: res.data?.message });
        } else {
          toast.error(res.data?.message);
        }
      });
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "phoneNumber") {
      let phoneNumber = value.split(" ");
      if (phoneNumber.length > 0) {
        let phone = value.replace(/ /g, "");
        if (phone.length > 24) {
          value = value.substring(0, 25);
        }
      }
    }

    setPayload({
      ...payload,
      [name]: value,
      phoneNumber: phoneNumber,
      phoneCountryCode: phoneCountryCode,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const validate = () => {
    if (!payload.firstName) {
      setError({
        ...error,
        firstName: t("RAL0001"),
      });
      return false;
    }

    if (!payload.email) {
      setError({
        ...error,
        email: t("RAL0001"),
      });
      return false;
    }

    // if (!payload.country) {
    //   setError({
    //     ...error,
    //     country: t("RAL0001"),
    //   });
    //   return false;
    // }

    if (!payload.password) {
      setError({
        ...error,
        password: t("RAL0001"),
      });
      return false;
    }

    if (!CommonUtil.isAlphaNumericPassword(payload.password)) {
      setError({
        ...error,
        password: t("RAL0010"),
      });
      return false;
    }

    if (!payload.confirmPassword) {
      setError({
        ...error,
        confirmPassword: t("RAL0001"),
      });
      return false;
    }

    if (payload.confirmPassword !== payload.password) {
      setError({
        ...error,
        confirmPassword: t("RAL0011"),
      });
      return false;
    }

    if (!termsCondition) {
      setError({
        ...error,
        termsCondition: t("RAL0079"),
      });
      toast.error(t("RAL0079"));
      return;
    }
    return true;
  };

  const handleClosePrivacyPolicy = () => {
    setopenDilog(false);
  };

  const handleOpenPrivacyPolicy = () => {
    setopenDilog(true);
    setHeaderContent("Terms and conditions");
  };

  useEffect(() => {
    if (openDilog && HeaderContent === "Terms and conditions") {
    }
  }, [openDilog, HeaderContent]);

  const handleCountryFocus = () => {
    setIsFocusedCountry(true);
  };

  const handleCountryBlur = () => {
    setIsFocusedCountry(false);
  };

  const handlePhoneChange = (phoneNumber, phoneCountryCode) => {
    const countryCode = "+" + phoneCountryCode;
    setPhoneCountryCode(countryCode);
    setPhoneNumber(phoneNumber);
  };

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <>
      <SignupLayout>
        <div className="SignupUser">
          <div className="signup_user_001">
          <div className="signup_user_header" style={{ backgroundColor: headerBackgroundColor }}>
            <div>
              <span id="text-onboard-signup-user" className="signup_user_002">
                {t("RAL0367")}
              </span>
            </div>

            <div className="signup_user_003">
              <span id="text-onboard-signup-userinfo">
                {t("RAL0019")}&nbsp;
                <span
                  id="link-onboard-signup-usersignin"
                  className="signup_user_clickSignIn_004"
                  onClick={() => navigate("/")}
                >
                  {t("RAL0099")}
                </span>
              </span>
            </div>
            </div>
            <div className="signup_user_content">
            <Grid container spacing={2} pt={5}>
              <Grid item lg={12} sm={12} className="firstNameError">
                <OnboardTextField
                  id="textfield-firstname-sigup"
                  autoComplete={false}
                  required
                  fullWidth
                  label={t("RAL0139")}
                  value={payload.firstName}
                  name="firstName"
                  onChange={handleChange}
                  error={error.firstName}
                  helperText={error.firstName}
                  inputRef={nameRef}
                  onKeyDown={(e) => handleKeyDown(e)}
                  inputProps={{
                    maxLength: 60,
                  }}
                  validation="alpha-numeric-space"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} pt={3}>
              <Grid item lg={6} sm={6}>
                <OnboardTextField
                  id="textfield-email-sigup"
                  autoComplete={false}
                  required
                  fullWidth
                  disabled
                  label={t("RAL0014")}
                  value={payload.email}
                  name="email"
                  onChange={handleChange}
                  error={error.email}
                  helperText={error.email}
                  inputProps={{
                    maxLength: 36,
                    autoComplete: "off",
                  }}
                  validation="email"
                />
              </Grid>

              <Grid item sm={6}>
                <CustomPhone
                  id="textfield-mobile-sigup"
                  onChange={handlePhoneChange}
                />
              </Grid>

              {/* <Grid item sm={6} pt={3}>
                <Autocomplete
                  autoSelect={false}
                  openOnFocus={false}
                  onFocus={handleCountryFocus}
                  onBlur={handleCountryBlur}
                  open={isFocusedCountry && !payload.country}
                  disablePortal
                  id="dropdown-user-create-country"
                  fullWidth
                  autoComplete={false}
                  size={"small"}
                  getOptionLabel={(option) => option.country}
                  clearText="Clear"
                  value={payload.country ? { country: payload.country } : null}
                  options={UserCountryList}
                  renderInput={(params) => (
                    <OnboardTextField
                      {...params}
                      autoComplete={false}
                      onChange={(e) => console.log()}
                      height={{ xl: "48px", xs: "34px" }}
                      resetError={() => setError({ ...error, country: "" })}
                      placeholder={t("RAL0141")}
                      required
                      helperText={error.country}
                      error={error.country}
                      label={t("RAL0141")}
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setPayload({
                        ...payload,
                        country: newValue.country,
                      });
                      setError({ ...error, country: "" });
                    } else {
                      setPayload({
                        ...payload,
                        country: "",
                      });
                    }
                  }}
                />
              </Grid> */}
            </Grid>

            <Grid container spacing={2} pt={3}>
              <Grid item sm={12} pt={3}>
                <PasswordField
                  className="signup-name changePasswordError-1"
                  id="textfield-singup-view-password"
                  required
                  fullWidth
                  label={t("RAL0017")}
                  value={payload.password}
                  name="password"
                  onChange={handleChange}
                  error={error.password}
                  helperText={error.password}
                  onKeyDown={(e) => handleKeyDown(e)}
                  inputProps={{
                    maxLength: 36,
                  }}
                  customPlaceholder={t("RAL0017")}
                />
              </Grid>
            </Grid>
            
            <Grid container spacing={2} pt={2}>
              <Grid item sm={12} pt={3}>
                <PasswordField
                  className="changePasswordError"
                  id="textfield-singup-view-confirmPassword"
                  required
                  fullWidth
                  label={t("RAL0018")}
                  value={payload.confirmPassword}
                  name="confirmPassword"
                  onChange={handleChange}
                  error={error.confirmPassword}
                  helperText={error.confirmPassword}
                  onKeyDown={(e) => handleKeyDown(e)}
                  inputProps={{
                    maxLength: 36,
                  }}
                  customPlaceholder={t("RAL0018")}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={6}
              minWidth="100%"
              className="signup_user_checkbox_005"
            >
              <Box className="signUpCheckbox">
                <CustomCheckBox
                  id="checkbox-onboard-sigup"
                  value={termsCondition}
                  checked={termsCondition}
                  onChange={(e) => setTermsCondition(e.target.checked)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </Box>
              <span
                id="text-onboard-sigup"
                className="signup_user_termsCondition_006"
              >
                {t("RAL0087")} &nbsp;
                <Link
                  id={"link-singup-view-terms"}
                  className="signup_user_viewprivacy_007"
                  href="https://ralvie.ai/about-2/"
                  target="_blank"
                >
                  {t("RAL0088")}{" "}
                </Link>
                &nbsp;&&nbsp;
                <Link
                  id={"link-singup-view-privacy"}
                  className="signup_user_viewprivacy_007"
                  href="https://ralvie.ai/about-2/"
                  target="_blank"
                >
                  {t("RAL0095")}{" "}
                </Link>
              </span>

              <span className="signup_user_and_text_008"></span>

              {/* <span className="signup_user_termsCondition_006">
                {t("RAL0087")}{" "}
              </span>{" "}
              &nbsp;
              <Link
                id={"link-singup-view-terms"}
                className="signup_user_viewprivacy_007"
                href="https://ralvie.ai/about-2/"
                target="_blank"
              >
                {t("RAL0088")}
              </Link>

              <Link
                id={"link-singup-view-privacy"}
                className="signup_user_viewprivacy_007"
                href="https://ralvie.ai/about-2/"
                target="_blank"
              >
                {t("RAL0095")}
              </Link> */}
              {/* onClick={handleOpenPrivacyPolicy} */}
            </Grid>

            <Grid
              item
              lg={12}
              minWidth="100%"
              className="signup_user_submit_button_009"
            >
              <CustomButton
                id="btn_signup_user_signup_text_010"
                variant="contained"
                size="medium"
                fullWidth="fullWidth"
                onClick={handleSubmit}
              >
                <span
                  id="signup_user_signup_text_010"
                  className="signup_user_signup_text_010"
                >
                  {t("RAL0008")}
                </span>
              </CustomButton>
            </Grid>
          </div>
          </div>
        </div>
      </SignupLayout>

      <Dialog
        sx={styleDialog}
        onClose={() => setopenDilog(!openDilog)}
        open={openDilog}
      >
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          id="signup_user_signup_header_content"
          className="signup_user_privacy_dialogbox_011"
        >
          {HeaderContent}
        </Typography>
        {HeaderContent === "Terms and conditions" ? (
          <>
            <PrivacyPolicy
              onClose={handleClosePrivacyPolicy}
              onClick={() => handleAgree()}
            />
          </>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
}
