import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Tooltip } from "@mui/material";
import { getAllapplications } from "../../services/ApplicationService";
import { alpha } from "@mui/material/styles";
import "./category.scss";
import AppIcon from "../../components/AppIcon";
import { useTranslation } from "react-i18next";
import { ReactComponent as NoActivity } from "../../assets/images/NoActivity.svg";
import { useDrag } from "react-dnd";

const ApplicationList = ({ reload, apps, setApps, removeAppFromList }) => {
  const { t } = useTranslation();
  const fixedColors = [
    "#FF5733",
    "#2C3E50",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#33FFA1",
    "#A133FF",
    "#FF5733",
    "#FFC300",
    "#DAF7A6",
    "#581845",
    "#900C3F",
    "#C70039",
    "#FF5733",
    "#FFBD33",
    "#33FFBD",
    "#57A1FF",
    "#FF33C4",
    "#33C4FF",
    "#C4FF33",
    "#8E44AD",
    "#3498DB",
    "#2ECC71",
    "#E74C3C",
    "#1ABC9C",
    "#F39C12",
    "#D35400",
    "#27AE60",
    "#2980B9",
    "#8E44AD",
  ];

  const getApplicationIcon = (data) => {
    return <AppIcon data={data} height={30} width={30} />;
  };

  useEffect(() => {
    getAllapplications().then((response) => {
      if (response.code === "RCI0000") {
        //setApps(response.data.application);
        let apps = response.data.application.filter(
          (a) => a.name?.toLowerCase() !== "afk"
        );
        setApps(apps);
      }
    });
  }, [reload, setApps]);

  const ApplicationItem = ({ appData, index }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "APPLICATION",
      item: { id: appData.id, name: appData.name },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    const appColor = fixedColors[index % fixedColors.length];
    const lightColor = appColor ? alpha(appColor, 0.09) : "transparent";

    return (
      <Grid
        item
        lg={12}
        mt={index === 0 ? 1.5 : 0}
        key={appData.id}
        mb={2}
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          p={1}
          style={{ backgroundColor: lightColor }}
        >
          <Box
            sx={{
              width: "15%",
              pr: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {getApplicationIcon(appData)}
          </Box>
          <Box
            sx={{ width: "50%" }}
            className="text_overflow"
            style={{ textTransform: "capitalize" }}
          >
            {appData?.name?.length > 14 ? (
              <Tooltip
                id={"app_title_" + appData?.name}
                title={appData?.name}
              >
                <Typography
                  id={"app_title_" + appData?.name}
                  sx={{ textTransform: "capitalize"}}
                >
                  <span id="category-app-name" className="appName">
                    {`${appData?.name.substring(0, 14)}...`}
                  </span>
                </Typography>
              </Tooltip>
            ) : (
            <Typography
              id={"app_title_" + appData?.name}
              sx={{ textTransform: "capitalize" }}
            >
              <span id="category-app-name" className="appName">{appData?.name}</span>
            </Typography>
          )}
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <Box
      className="applicationCard"
      display="flex"
      flexDirection="column"
      height="100%"
      pt={0}
    >
      <span style={{
        position: 'sticky',
        top: 0,
        paddingTop: '30px',
        paddingBottom: '10px',
        background: 'var(--background-color-3)'
      }} className="mostusedapps">{t("RAL0158")}</span>
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        {apps.length === 0 ? (
          <Box className="ErrorMessage" textAlign="center" minWidth="100%">
            <NoActivity id="category-noactivity-icon"/>
            <Typography id="category-noactivity-text">{t("RAL0155")}</Typography>
          </Box>
        ) : (
          <Box>
            {apps.map((appData, index) => (
              <ApplicationItem
                key={appData.id}
                appData={appData}
                index={index}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ApplicationList;
