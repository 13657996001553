import {
  Box,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { bindPopover } from "material-ui-popup-state";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeSheetActiveIcon from "../../assets/images/nav-icons/icon-calendar-active.svg";
import TimeSheetInActiveIcon from "../../assets/images/nav-icons/icon-calendar.svg";
import TimeSheetInActiveIconDark from "../../assets/images/nav-icons/icon-calendar_dark.svg";
import DashboardActiveIcon from "../../assets/images/nav-icons/icon-dashboard-active.svg";
import DashboardInactiveIcon from "../../assets/images/nav-icons/icon-dashboard.svg";
import DashboardInactiveIconDark from "../../assets/images/nav-icons/icon-dashboard_dark.svg";
import IntegrationActiveIcon from "../../assets/images/nav-icons/icon-integration-active.svg";
import IntegrationIcon from "../../assets/images/nav-icons/icon-integration.svg";
import IntegrationIconDark from "../../assets/images/nav-icons/icon-integration_dark.svg";
import MemberActiveIcon from "../../assets/images/nav-icons/icon-member-active.svg";
import MemberInActiveIcon from "../../assets/images/nav-icons/icon-member.svg";
import MemberInActiveIconDark from "../../assets/images/nav-icons/icon-member_dark.svg";
import OrganizationActiveIconImg from "../../assets/images/nav-icons/icon-organization-active.svg";
import { ReactComponent as OrganizationIcon } from "../../assets/images/nav-icons/icon-organization.svg";
import { ReactComponent as OrganizationIconDark } from "../../assets/images/nav-icons/icon-organization_dark.svg";
import ProjectActiveIcon from "../../assets/images/nav-icons/icon-project-active.svg";
import ProjectInActiveIcon from "../../assets/images/nav-icons/icon-project.svg";
import ProjectInActiveIconDark from "../../assets/images/nav-icons/icon-project_dark.svg";
import ReportActiveIcon from "../../assets/images/nav-icons/icon-report-active.svg";
import ReportInActiveIcon from "../../assets/images/nav-icons/icon-report.svg";
import ReportInActiveIconDArk from "../../assets/images/nav-icons/icon-report_dark.svg";
import SettingsActiveIcon from "../../assets/images/nav-icons/icon-setting-active.svg";
import SettingsIcon from "../../assets/images/nav-icons/icon-setting.svg";
import SettingsIconDark from "../../assets/images/nav-icons/icon-setting_dark.svg";
import TagActiveIcon from "../../assets/images/nav-icons/icon-tag-active.svg";
import TagInActiveIcon from "../../assets/images/nav-icons/icon-tag.svg";
import TagInActiveIconDark from "../../assets/images/nav-icons/icon-tag_dark.svg";
// import AiIcon from "../../assets/images/ralvie/ai-robot.svg";
import LavoroAiIcon from "../../assets/images/lavoro-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/ralvie/ArrowRight.svg";
import { ReactComponent as ArrowRightDark } from "../../assets/images/ralvie/ArrowRight_dark.svg";
import { ReactComponent as DarkMode } from "../../assets/images/ralvie/dark_mode.svg";
import { ReactComponent as LightMode } from "../../assets/images/ralvie/light_mode.svg";
import { ReactComponent as NavPanelCloseIcon } from "../../assets/images/ralvie/nav-panel-close.svg";
import { ReactComponent as NavPanelOpenIcon } from "../../assets/images/ralvie/nav-panel-open.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/ralvie/profile-icon.svg";
import { ReactComponent as RalvieLogoIcon } from "../../assets/images/ralvie/ralvie-logo-icon.svg";
import { ReactComponent as RalvieLogo } from "../../assets/images/ralvie/ralvie-logo.svg";
import { ReactComponent as RalvieLogoDark } from "../../assets/images/ralvie/ralvie_logo_dark.svg";

import CustomSwitch from "../../components/CustomSwitch";
import AppContext from "../../config/AppContext";
import AiIntroduction from "../../pages/AI/AiIntroduction";
import RefreshToken from "../../pages/onboard/RefreshToken";
import Organization from "../../pages/organization/Organization";
import UserNavSubMenu from "../../pages/profile/UserNavSubMenu";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext } from "../../util/context";
import CustomIconButton, { CustomIconbtn } from "../CustomIconButton";
import OverFlowText from "../OverFlowText";
import CollapseNavSubMenu from "./CollapseNavSubMenu";
import "./LayoutComponents.scss";
import "./NavMenu.scss";
import NavMenuItem from "./NavMenuItem";
import { updateUserPreferences } from "../../services/DashboardService";
import { useTranslation } from "react-i18next";
import CancelDialog from "../../pages/project/CancelDialog";

export default function NavMenu(props) {
  const {
    expand,
    setExpand,
    enableAi,
    setEnableAi,
    userProfile,
    profilePic,
    companyProfile,
    userPreferences,
    setUserPreferences,
  } = useContext(sidebarContext);

  const theme = useTheme();
  const { t } = useTranslation();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const role = CommonUtil.getRoleCode();
  const [toggleOrgMenu, setToggleOrgMenu] = useState(false);
  const [toggleUserMenu, setToggleUserMenu] = useState(false);
  const [toggleReportMenu, setToggleReportMenu] = useState(false);
  const [toggleSettingsMenu, setToggleSettingsMenu] = useState(false);
  const [toggleAiBtn, setToggleAiBtn] = useState(false);
  const [disableAIBtn, setDisableAIBtn] = useState(false);

  const companyName =
    companyProfile?.name === null || companyProfile?.name === undefined
      ? CommonUtil.getLoggedUser()?.companyName
      : companyProfile?.name;

  const navigate = useNavigate();
  const memberName = userProfile?.memberName
    ? userProfile?.memberName
    : CommonUtil.getLoggedUser()?.firstName;
  const userId = CommonUtil.getLoggedUser()?.userId;

  const profileImageStyle = {
    width: isXlScreen ? "40px" : "30px",
    height: isXlScreen ? "40px" : "30px",
    borderRadius: "50%",
    objectFit: "cover",
  };

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const reportPopupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const settingPopupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const orgPopupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });

  const handleCloseCancelDialog = () => {
    setDisableAIBtn(false);
  };

  const handleConfirmCancel = () => {
    setEnableAi(false);
    updateUserPreferences(userId, {
      aiAssistEnabled: false,
    });
    setDisableAIBtn(false);
  };

  const { colorMode, mode } = useContext(AppContext);
  const toolTipStyle = {
    tooltip: {
      // sx: {
      //   borderRadius: "0px",
      //   backgroundColor: "#242424 !important",
      //   color: "#ffffff !important",
      // },
    },
    // arrow: {
    //   sx: {
    //     color: "#242424 !important",
    //   },
    // },
  };
  const toolTipPopoverPosition = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -5],
        },
      },
    ],
  };
  useEffect(() => {
    setToggleOrgMenu(orgPopupState.isOpen);
  }, [orgPopupState.isOpen]);
  useEffect(() => {
    setToggleReportMenu(reportPopupState.isOpen);
  }, [reportPopupState.isOpen]);

  useEffect(() => {
    setToggleSettingsMenu(settingPopupState.isOpen);
  }, [settingPopupState.isOpen]);

  useEffect(() => {
    setToggleUserMenu(popupState.isOpen);
  }, [popupState.isOpen]);

  const enableAiFeature = () => {
    setEnableAi(true);
    updateUserPreferences(userId, {
      aiAssistEnabled: true,
    }).then((res) => {
      if (
        !userPreferences ||
        userPreferences?.disableAiIntroduction == null ||
        !userPreferences?.disableAiIntroduction
      ) {
        setToggleAiBtn(true);
      }
      if (res.data?.code === "RCI0000" && res.data?.data) {
        setUserPreferences(res.data?.data);
      }
    });
  };

  return (
    <div className={expand ? "sidebar-expand" : "sidebar"}>
      <RefreshToken />
      <Box className="navFirstSection">
        {!expand && (
          <Box className="header-logo-toggled">
            <Box flexGrow={1}>
              <RalvieLogoIcon
                id="ralvie-logo-icon"
                className="header-logo-icon-toggled"
              />
            </Box>
            <Box
              item
              className="navbar-close"
              onClick={() => setExpand(!expand)}
            >
              <NavPanelOpenIcon
                id="ralvie-logo-navpanelopen-icon"
                className="navbar-close-icon"
              />
            </Box>
          </Box>
        )}
        {expand && (
          <Box className="header-logo">
            <Box flexGrow={1}>
              {mode === "dark" ? (
                <RalvieLogoDark
                  id="ralvie-logo-icon"
                  className="header-logo-icon"
                />
              ) : (
                <RalvieLogo
                  id="ralvie-logo-icon"
                  className="header-logo-icon"
                />
              )}
            </Box>
            <Box
              item
              className="navbar-close"
              onClick={() => setExpand(!expand)}
            >
              <NavPanelCloseIcon
                id="ralvie-logo-navpanelopen-icon"
                className="navbar-close-icon"
              />
            </Box>
          </Box>
        )}
        <div className="ForDashSubMenu">
          <NavMenuItem
            className="dashboardMenu"
            isActive={
              window.location.pathname === "/dashboard" ||
              window.location.pathname === "/categoriesApps"
            }
            activeIcon={
              <img
                id="ralvie-logo-navpanelopen-dashboardactiveicon"
                alt={"dashboard"}
                src={DashboardActiveIcon}
                className="nav-menu-svg"
              />
            }
            inActiveIcon={
              <img
                id="ralvie-logo-navpanelopen-dashboardinactiveicon"
                alt={"dashboard"}
                src={
                  mode === "light"
                    ? DashboardInactiveIcon
                    : DashboardInactiveIconDark
                }
                className="nav-menu-svg"
              />
            }
            title={t("RAL0447")}
            link={"/dashboard"}
            id={"nav-menu-dashboard"}
          />
          <NavMenuItem
            isActive={window.location.pathname?.includes("/timesheet")}
            activeIcon={
              <img
                id="ralvie-logo-navpanelopen-dashboardtimesheeticon"
                alt={"timesheet"}
                src={TimeSheetActiveIcon}
                className="nav-menu-svg"
              />
            }
            inActiveIcon={
              <img
                id="ralvie-logo-navpanelopen-dashboardtimesheeticon"
                alt={"timesheet"}
                src={
                  mode === "light"
                    ? TimeSheetInActiveIcon
                    : TimeSheetInActiveIconDark
                }
                className="nav-menu-svg"
              />
            }
            title={t("RAL0448")}
            link={"/timesheet"}
            id={"nav-menu-timesheet"}
          />

          {role !== "EMPLOYEE" && role !== "CLIENT" && (
            <NavMenuItem
              activeIcon={
                <img
                  id="ralvie-logo-navpanelopen-dashboardprojecticon"
                  alt={"project"}
                  src={ProjectActiveIcon}
                  className="nav-menu-svg"
                />
              }
              inActiveIcon={
                <img
                  id="ralvie-logo-navpanelopen-dashboardprojecticon"
                  alt={"project"}
                  src={
                    mode === "light"
                      ? ProjectInActiveIcon
                      : ProjectInActiveIconDark
                  }
                  className="nav-menu-svg"
                />
              }
              isActive={window.location.pathname?.includes("/projects")}
              title={t("RAL0449")}
              link={"/projects"}
              id={"nav-menu-project"}
            />
          )}
          {role !== "EMPLOYEE" && (
            <NavMenuItem
              isActive={window.location.pathname?.includes("/members")}
              activeIcon={
                <img
                  id="ralvie-logo-navpanelopen-dashboardmembersicon"
                  alt={"member"}
                  src={MemberActiveIcon}
                  className="nav-menu-svg"
                />
              }
              inActiveIcon={
                <img
                  id="ralvie-logo-navpanelopen-dashboardmembersicon"
                  alt={"member"}
                  src={
                    mode === "light"
                      ? MemberInActiveIcon
                      : MemberInActiveIconDark
                  }
                  className="nav-menu-svg"
                />
              }
              title={t("RAL0450")}
              link={"/members"}
              id={"nav-menu-member"}
            />
          )}
          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <NavMenuItem
              isActive={window.location.pathname?.includes("/tags")}
              activeIcon={
                <img
                  id="ralvie-logo-navpanelopen-dashboardtagsicon"
                  alt={"tag"}
                  src={TagActiveIcon}
                  className="nav-menu-svg"
                />
              }
              inActiveIcon={
                <img
                  id="ralvie-logo-navpanelopen-dashboardtagsicon"
                  alt={"tag"}
                  src={mode === "light" ? TagInActiveIcon : TagInActiveIconDark}
                  className="nav-menu-svg"
                />
              }
              title={t("RAL0451")}
              link={"/tags"}
              id={"nav-menu-tag"}
            />
          )}
          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <NavMenuItem
              isActive={window.location.pathname?.includes("/integration")}
              activeIcon={
                <img
                  id="ralvie-logo-navpanelopen-dashboardintegrationicon"
                  alt={"integration"}
                  src={IntegrationActiveIcon}
                  className="nav-menu-svg"
                />
              }
              inActiveIcon={
                <img
                  id="ralvie-logo-navpanelopen-dashboardintegrationicon"
                  alt={"integration"}
                  src={mode === "light" ? IntegrationIcon : IntegrationIconDark}
                  className="nav-menu-svg"
                />
              }
              title={t("RAL0452")}
              id={"nav-menu-integration"}
              link={"/integration"}
            />
          )}
          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <Tooltip
              className="tooltipMenu"
              title={expand || toggleReportMenu ? null : t("RAL0453")}
              placement="right"
              arrow
              componentsProps={toolTipStyle}
              PopperProps={toolTipPopoverPosition}
            >
              <Box
                {...bindTrigger(reportPopupState)}
                className={`cursor-pointer nav-menu-expand ${
                  window.location.pathname?.includes("/reports")
                    ? "nav-menu-active"
                    : "nav-menu-inactive"
                }`}
              >
                <Box
                  onClick={() => reportPopupState.close()}
                  className="nav-menu-icon"
                >
                  {toggleReportMenu ||
                  window.location.pathname?.includes("/reports") ? (
                    <img
                      id="ralvie-logo-navpanelopen-reporticon"
                      src={ReportActiveIcon}
                      alt=""
                      className="nav-menu-svg"
                    />
                  ) : mode === "light" ? (
                    <img
                      id="ralvie-logo-navpanelopen-reporticon"
                      src={ReportInActiveIcon}
                      alt=""
                      className="nav-menu-svg"
                    />
                  ) : (
                    <img
                      id="ralvie-logo-navpanelopen-reporticon"
                      src={ReportInActiveIconDArk}
                      alt=""
                      className="nav-menu-svg"
                    />
                  )}
                </Box>

                {expand && (
                  <>
                    <Box flexGrow={1}>
                      <Typography
                        className={`nav-menu-text ${
                          window.location.pathname?.includes("/reports")
                            ? "nav-menu-text-active"
                            : ""
                        }`}
                        style={{
                          textTransform: "capitalize",
                          paddingTop:
                            CommonUtil.getOS() === "MacOS" ? "11px" : "0px",
                        }}
                      >
                        <OverFlowText
                          id="ralvie-ai-assistant-text"
                          text={"Reports"}
                          maxLength="10"
                        >
                          {t("RAL0453")}
                        </OverFlowText>
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      {mode === "dark" ? <ArrowRightDark /> : <ArrowRight />}
                    </Box>
                  </>
                )}
              </Box>
            </Tooltip>
          )}
          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <Tooltip
              title={expand || toggleSettingsMenu ? null : t("RAL0454")}
              placement="right"
              arrow
              componentsProps={toolTipStyle}
              PopperProps={toolTipPopoverPosition}
            >
              <Box
                {...bindTrigger(settingPopupState)}
                className={`cursor-pointer nav-menu-expand ${
                  window.location.pathname?.includes("/settings")
                    ? "nav-menu-active"
                    : "nav-menu-inactive"
                }`}
              >
                <Box
                  onClick={() => settingPopupState.close()}
                  className="nav-menu-icon"
                >
                  {toggleSettingsMenu ||
                  window.location.pathname?.includes("/settings") ? (
                    <img
                      id="ralvie-settings-img"
                      src={SettingsActiveIcon}
                      alt=""
                      className="nav-menu-svg"
                    />
                  ) : mode === "light" ? (
                    <img
                      id="ralvie-settings-img"
                      src={SettingsIcon}
                      alt=""
                      className="nav-menu-svg"
                    />
                  ) : (
                    <img
                      id="ralvie-settings-img"
                      src={SettingsIconDark}
                      alt=""
                      className="nav-menu-svg"
                    />
                  )}
                </Box>
                {expand && (
                  <>
                    <Box flexGrow={1}>
                      <Typography
                        className={`nav-menu-text ${
                          window.location.pathname?.includes("/settings")
                            ? "nav-menu-text-active"
                            : ""
                        }`}
                        style={{
                          textTransform: "capitalize",
                          paddingTop:
                            CommonUtil.getOS() === "MacOS" ? "11px" : "0px",
                        }}
                      >
                        <OverFlowText text={t("RAL0597")} maxLength="10">
                          {t("RAL0597")}
                        </OverFlowText>
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      {mode === "dark" ? (
                        <ArrowRightDark id="ralvie-arrowright-icon" />
                      ) : (
                        <ArrowRight id="ralvie-arrowright-icon" />
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Tooltip>
          )}
        </div>
      </Box>

      <Box className="navSecondSection">
        <div
          className="navSdFlex"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "10px",
            justifyContent: "end",
          }}
        >
          {/* AiIcon */}
          <Box
            className="AiIconStyle"
            onClick={() => {
              if (!expand && !enableAi) {
                enableAiFeature();
                // setToggleAiBtn(true);
              } else {
                setExpand(true);
              }
            }}
            sx={{
              background: enableAi
                ? "var(--AI-assistant-background)"
                : "var(--background-color-42)",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center"}}>
              <img
                id="ai-assistan-img"
                src={LavoroAiIcon}
                alt="AI Icon"
                height={"20px"}
                width={"35px"}
              />
                </Box>
              {expand && (
                <Typography id="ai-assistan-text" variant="body1" sx={{  mr:1.2 }}>
                  {t("RAL0455")}
                </Typography>
              )}
          
            {expand && (
              <Box className="aiTextRadio" sx={{ mt: 2 }}>
                <Box sx={{ mb: 2 , mr:0.7 }}>
                  <CustomSwitch
                    id="ai-assistan-switch"
                    className="aiAssistant"
                    value={enableAi}
                    checked={enableAi}
                    onChange={(e) => {
                      if (e.target.checked) {
                        enableAiFeature();
                        // setToggleAiBtn(e.target.checked);
                      } else {
                        if (enableAi) {
                          setDisableAIBtn(true);
                        }
                      }
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {/* OrganizationIcon */}
          <Tooltip
            className="iconOrganization"
            title={expand || toggleOrgMenu ? null : t("RAL0458")}
            placement="right"
            arrow
            componentsProps={toolTipStyle}
            PopperProps={toolTipPopoverPosition}
          >
            <Box
              {...bindTrigger(orgPopupState)}
              className={`cursor-pointer ${
                toggleOrgMenu ? "nav-menu-expand" : "nav-menu"
              } nav-menu-inactive paddingLeftRight`}
            >
              <Box
                className="OrganizationIcon1"
                onClick={() => orgPopupState.close()}
              >
                {toggleOrgMenu ? (
                  <img
                    src={OrganizationActiveIconImg}
                    alt=""
                    className="nav-menu-svg OrganizationIconStyle"
                  />
                ) : // iconActive
                mode === "light" ? (
                  <OrganizationIcon className="nav-menu-svg OrganizationIconStyle " />
                ) : (
                  <OrganizationIconDark className="nav-menu-svg OrganizationIconStyle" />
                )}
              </Box>
              {expand && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box flexGrow={1} className="navEllipsis">
                    {/* nav-menu-text  */}
                    <Typography
                      className={`${
                        toggleOrgMenu ? "nav-menu-text-active" : ""
                      }`}
                    >
                      <OverFlowText text={companyName} maxLength="13">
                        {companyName}
                      </OverFlowText>
                    </Typography>
                  </Box>
                  <Box className="rightArrow">
                    {mode === "dark" ? <ArrowRightDark /> : <ArrowRight />}
                  </Box>
                </Box>
              )}
            </Box>
          </Tooltip>

          {/* ProfileGroupIcon */}
          <Tooltip
            className="iconGroupIcon"
            title={expand ? null : t("RAL0459")}
            placement="right"
            arrow
            componentsProps={toolTipStyle}
            PopperProps={toolTipPopoverPosition}
          >
            <Box
              {...bindTrigger(popupState)}
              className={`cursor-pointer ${
                toggleUserMenu ? "nav-menu-expand" : "nav-menu"
              } ${
                window.location.pathname?.includes("/userprofile")
                  ? "nav-menu-active"
                  : "nav-menu-inactive"
              } nav-menu-inactive paddingLeftRight`}
            >
              <Box
                onClick={() => popupState.close()}
                sx={{
                  margin: expand ? "0 0.9rem 0 0.9rem" : "",
                }}
              >
                <CustomIconButton
                  title={""}
                  size={isXlScreen ? "md" : "sm"}
                  variant=""
                  lightMode={
                    profilePic ? (
                      <img
                        src={profilePic}
                        alt="Profile"
                        style={profileImageStyle}
                      />
                    ) : (
                      <ProfileIcon className="nav-menu-svg" />
                    )
                  }
                  darkMode={
                    profilePic ? (
                      <img
                        id="ralvie-profile-icon"
                        src={profilePic}
                        alt="Profile"
                        style={profileImageStyle}
                      />
                    ) : (
                      <ProfileIcon
                        id="ralvie-profile-icon"
                        className="nav-menu-svg"
                      />
                    )
                  }
                />
              </Box>

              {expand && (
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box flexGrow={1} className="navEllipsis">
                    <OverFlowText
                      className={`${
                        toggleUserMenu ? "nav-popover-active" : ""
                      }`}
                      text={memberName}
                      maxLength="13"
                    >
                      {memberName}
                    </OverFlowText>
                  </Box>
                  <Box className="rightArrow">
                    {mode === "dark" ? <ArrowRightDark /> : <ArrowRight />}
                  </Box>
                </Box>
              )}
            </Box>
          </Tooltip>

          {/* dark mode */}
          <Tooltip
            className="changeModeColor paddingLeftRight"
            title={
              expand
                ? null
                : t("RAL0421") +
                  (mode == "dark" ? t("RAL0423") : t("RAL0422")) +
                  t("RAL0424")
            }
            placement="right"
            arrow
            componentsProps={toolTipStyle}
            PopperProps={toolTipPopoverPosition}
          >
            <Box
              onClick={() => colorMode.toggleColorMode()}
              className={`cursor-pointer ${
                toggleSettingsMenu ? "nav-menu-expand" : "nav-menu"
              } `}
            >
              <Box
                sx={{
                  margin: expand ? "0 0.9rem 0 0.9rem" : "",
                }}
              >
                <CustomIconbtn
                  id="btn-ralvie-custom"
                  title={""}
                  size={isXlScreen ? "md" : "sm"}
                  variant=""
                  mode={mode}
                  darkMode={
                    <DarkMode
                      id="btn-ralvie-custom-dark"
                      className="nav-menu-svg"
                    />
                  }
                  lightMode={
                    <LightMode
                      id="btn-ralvie-custom-light"
                      className="nav-menu-svg"
                    />
                  }
                />
              </Box>

              {expand && (
                <>
                  <Box flexGrow={1}>
                    <Typography>
                      {mode === "dark" ? t("RAL0456") : t("RAL0457")}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Tooltip>
        </div>
      </Box>
      {/* UserNavSubMenu */}
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: expand ? -4 : 120,
        }}
        sx={{
          left: expand ? 23 : 80,
          top: 44,
        }}
        PaperProps={{
          style: {
            overflow: "visible",
            background: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
          },
        }}
      >
        <UserNavSubMenu popupState={popupState} />
      </Popover>
      {/* reportPopupState */}
      <Popover
        {...bindPopover(reportPopupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: expand ? -2 : 120,
        }}
        sx={{
          left: expand ? 2 : 80,
        }}
      >
        <CollapseNavSubMenu
          popupState={reportPopupState}
          subModules={[
            {
              title: t("RAL0449"),
              link: "/reports/project",
              isActive: window.location.pathname?.includes("/reports/project"),
            },
          ]}
        />
      </Popover>
      {/* settingPopupState */}
      <Popover
        {...bindPopover(settingPopupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: expand ? -2 : 120,
        }}
        sx={{
          left: expand ? 2 : 80,
        }}
      >
        <CollapseNavSubMenu
          popupState={settingPopupState}
          subModules={[
            {
              title: t("RAL0245"),
              link: "/settings/department",
              isActive: window.location.pathname?.includes(
                "/settings/department"
              ),
            },
            {
              title: t("RAL0244"),
              link: "/settings/position",
              isActive:
                window.location.pathname?.includes("/settings/position"),
            },
          ]}
        />
      </Popover>

      {/* Organization */}
      <Popover
        {...bindPopover(orgPopupState)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: expand ? -2 : 120,
        }}
        sx={{
          left: expand ? 25 : 80,
          top: 40,
        }}
        PaperProps={{
          style: {
            overflow: "visible",
            background: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
          },
        }}
      >
        <Organization />
      </Popover>
      {/* enableAi */}
      <AiIntroduction
        open={toggleAiBtn}
        handleClose={() => setToggleAiBtn(false)}
        onSubmit={(enable) => {
          setEnableAi(enable);
          updateUserPreferences(userId, { aiAssistEnabled: enable });
          setToggleAiBtn(false);
        }}
      />
      <CancelDialog
        open={disableAIBtn}
        onClose={handleCloseCancelDialog}
        onConfirm={handleConfirmCancel}
        header={t("RAL0395")}
        message={t("RAL0724")}
      />
    </div>
  );
}
