import { css } from "@emotion/css";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { ReactComponent as OrganizationActiveIcon } from "../../assets/images/ralvie/OrganizationActiveIcon.svg";
import { ReactComponent as OrganizationIcon } from "../../assets/images/ralvie/OrganizationIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/ralvie/SearchIcon.svg";
import { ReactComponent as TickIconWhite } from "../../assets/images/ralvie/TickIconWhite.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/ralvie/close-icon-white.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/ralvie/close_icon.svg";
import CustomButton from "../../components/CustomButton";
import { listUserCompanies, switchCompany } from "../../services/MemberService";
import OnboardService from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext, UserContext } from "../../util/context";
import TagTextField from "../tags/TagTextField";
import "./organization.scss";
import { ReactComponent as EmptyProjectListDark } from "../../assets/images/ralvie/noProjectsDark.svg";
import { ReactComponent as EmptyProjectList } from "../../assets/images/ralvie/noProjects.svg";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/edit-icon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import AppContext from "../../config/AppContext";
import { useTranslation } from "react-i18next";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.mode === "dark" ? "black" : "#F4F5F8",
    borderRadius: "5px",
    opacity: 1,
    font: "normal normal normal 16px/20px Gotham",
    color: theme.palette.mode === "dark" ? "#FFFFFF" : "#242424",
    "& fieldset": {
      borderRadius: "5px",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
    },
    "&.Mui-focused": {
      backgroundColor: theme.palette.mode === "dark" ? "#4E4E4E" : "#F4F2FE",
    },
    "&.Mui-error": {
      backgroundColor: "#FFF1F1",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "5px",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
    },
  },
}));

export default function Organization() {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const { setTokenRefreshTimer } = useContext(UserContext);
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loggedCompany, setLoggedCompany] = useState(
    CommonUtil.getLoggedUser()?.companyId
  );
  const [clearSearch, setClearSearch] = useState(false);
  const [name, setName] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [addCompany, setAddCompany] = useState(false);
  const [updateCompany, setUpdateCompany] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const { mode } = useContext(AppContext);
  const { setCompanyProfile } = useContext(sidebarContext);
  const [error, setError] = useState(false);

  const [roleCode, setRoleCode] = useState(
    CommonUtil.getLoggedUser()?.roleCode
  );

  useEffect(() => {
    setLoading(true);
    listUserCompanies({ pageNumber: 1, pageSize: 1000, name: name }).then(
      (res) => {
        if (
          res.data?.code === "UASI0029" &&
          res.data?.data?.companies instanceof Array
        ) {
          setCompanies(res.data?.data?.companies);
          const currentCompany = res.data?.data?.companies.find(
            (company) => company.id === loggedCompany
          );
          if (currentCompany) {
            setCompanyName(currentCompany.name);
          }
        }
        setLoading(false);
      }
    );
  }, [name]);

  const handleSwitchCompany = (companyId) => {
    setLoading(true);
    switchCompany(companyId).then((res) => {
      if (res?.data?.code === "UASI0011") {
        if (res?.data?.data?.access_token !== null) {
          localStorage.setItem(
            "token",
            "Bearer " + res?.data?.data?.access_token
          );
          CommonUtil.decodeToken();

          localStorage.setItem(
            "refresh_token",
            "Bearer " + res?.data?.data?.refresh_token
          );

          const decoded = jwtDecode(res?.data?.data?.access_token);
          var currentSeconds = Math.floor(Date.now() / 1000);
          console.warn("decoded.exp", decoded.exp);
          console.warn("currentSeconds", currentSeconds);
          var secondsLeft = decoded.exp - currentSeconds;
          console.warn("secondsLeft", secondsLeft);
          var timer = Math.floor(secondsLeft / 60) - 5;
          console.warn("timer", timer);
          console.warn("Next token generation after", timer + " mins");

          timer = timer * 60 * 1000;
          setTokenRefreshTimer(timer);
          setLoading(false);
          CommonUtil.resetCompanySession();
          setLoggedCompany(companyId);
          window.location.reload();
        }
      }
      setLoading(false);
    });
  };

  const handleChange = (e) => {
    let value = e.target.value;

    const regex =
      /^[A-Za-z0-9][A-Za-z0-9\s!@#$%^&*()\-_=+{}\[\]:;"'<>,.?/|\\~`]*$/;
    if (regex.test(value)) {
      setError("");
      setOrganizationName(value);
    } else {
      setOrganizationName("");
      setError(t("RAL0472"));
    }
  };

  const handleEdit = (e) => {
    let value = e.target.value;
    if (value === '') {
      setCompanyName(value);
    }
    const regex =
      /^[A-Za-z0-9][A-Za-z0-9\s!@#$%^&*()\-_=+{}\[\]:;"'<>,.?/|\\~`]*$/;
    if (regex.test(value)) {
      setError("");
      setCompanyName(value);
    } else {
      setError(t("RAL0472"));
    }
  };

  const handleSubmit = () => {
    if (organizationName) {
      setLoading(true);
      OnboardService.createCustomerCompany({ name: organizationName }).then(
        (res) => {
          if (res.data?.code === "UASI0006") {
            toast.success(res.data.message);
            setOrganizationName(null);
            setAddCompany(false);
            listUserCompanies({ pageNumber: 1, pageSize: 1000 }).then((res) => {
              if (
                res.data?.code === "UASI0029" &&
                res.data?.data?.companies instanceof Array
              ) {
                setCompanies(res.data?.data?.companies);
              }
            });
          } else {
            toast.error(res.data.message);
          }
          setLoading(false);
        }
      );
    }
  };

  const handleUpdateCompany = () => {
    if (companyName) {
      setLoading(true);
      const companyId = loggedCompany;
      const payload = {
        name: companyName,
      };
      OnboardService.updateCustomerCompany(companyId, payload).then((res) => {
        if (res.data?.code === "UASI0007") {
          setCompanyProfile(payload);
          toast.success(res.data.message);
          setUpdateCompany(false);
          listUserCompanies({ pageNumber: 1, pageSize: 1000 }).then((res) => {
            if (
              res.data?.code === "UASI0029" &&
              res.data?.data?.companies instanceof Array
            ) {
              setCompanies(res.data?.data?.companies);
            }
          });
        } else {
          toast.error(res.data.message);
        }
        setLoading(false);
      });
    }
  };

  const handleEditOrganization = (name) => {
    setUpdateCompany(true);
    setCompanyName(name);
  };

  const nameCaseTransform = (name) => {
    if (name && name.includes('_')) {
      var parts = name.split('_');
      return CommonUtil.isValidEmail(parts[0]);
    }
    return false;
  }

  return (
    <div
      className={css`
        border-radius: 5px;
        opacity: 1;
        min-height: ${isXlScreen ? "400px" : "300px"};
        min-width: ${isXlScreen ? "500px" : "400px"};
      `}
    >
      <div
        className={css`
          display: grid;
          padding: 20px;
          row-gap: 20px;
        `}
      >
        <Typography
          id="text-organization-list-info"
          className={css`
            font: normal normal normal 20px/24px GothamMedium !important;
            letter-spacing: 0px;
            color: ${mode === "light" ? "#242424" : "#f8f8f8cc"};
          `}
        >
          {t("RAL0468")}
        </Typography>
        {(name || companies.length > 10) && (
          <CssTextField
            id="textfield-organization-list-search"
            placeholder={t("RAL0469")}
            label=""
            fullWidth
            helperText={""}
            autoComplete="new-password"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              if (e.target.value) setClearSearch(true);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!clearSearch && <SearchIcon />}
                  {clearSearch && (
                    <Box
                      onClick={() => {
                        setName("");
                        setClearSearch(false);
                      }}
                      className={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        width: 30px;
                        :hover {
                          background: #e8e6f1 0% 0% no-repeat padding-box;
                          border-radius: 5px;
                          opacity: 1;
                        }
                      `}
                    >
                      <CloseIcon className="icon" />
                    </Box>
                  )}
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 60,
              },
            }}
          />
        )}
      </div>

      {addCompany && (
        <div
          className={css`
            display: flex;
            padding: 20px;
            align-items: center;
            opacity: 1;
            column-gap: 20px;
            cursor: pointer;
          `}
        >
          <div className={"organization-icon"}>
            <OrganizationIcon id="icon-organization-list" />
          </div>
          <Box flexGrow={1}>
            <TagTextField
              id="text-org-name"
              label={t("RAL0033")}
              value={organizationName}
              inputProps={{
                maxLength: 60,
              }}
              onChange={(e) => handleChange(e)}
              validation="company"
              error={error}
              helperText={error}
            />
          </Box>
          <Box item>
            <CustomButton
              id="btn-add-orgnization"
              disabled={CommonUtil.isEmptyString(organizationName)}
              size="small"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              {t("RAL0170")}
            </CustomButton>
          </Box>

          <Box
            item
            sx={{
              height: "20px",
              width: "20px",
              background: "#FE5050 0% 0% no-repeat padding-box",
              borderRadius: "4px",
              opacity: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setOrganizationName(null);
              setAddCompany(false);
            }}
          >
            <CancelIcon id="btn-org-name-cancel" />
          </Box>
        </div>
      )}

      {updateCompany && (
        <div
          className={css`
            display: flex;
            padding: 20px;
            align-items: center;
            opacity: 1;
            column-gap: 20px;
            cursor: pointer;
          `}
        >
          <div className={"organization-icon"}>
            <OrganizationIcon id="organization-list-org-icon" />
          </div>
          <Box flexGrow={1}>
            <TagTextField
              autoFocus
              id="text-org-update-name"
              label={t("RAL0033")}
              value={companyName}
              inputProps={{
                maxLength: 60,
              }}
              onChange={(e) => handleEdit(e)}
            />
          </Box>
          <Box item>
            <CustomButton
              id="btn-update-orgnization"
              disabled={CommonUtil.isEmptyString(companyName)}
              size="small"
              variant="contained"
              onClick={() => handleUpdateCompany()}
            >
              {t("RAL0309")}
            </CustomButton>
          </Box>

          <Box
            item
            sx={{
              height: "20px",
              width: "20px",
              background: "#FE5050 0% 0% no-repeat padding-box",
              borderRadius: "4px",
              opacity: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              // setCompanyName(null);
              setUpdateCompany(false);
            }}
          >
            <CancelIcon id="btn-org-update-name-cancel" />
          </Box>
        </div>
      )}

      <div className="organizationData">
        <Box
          sx={{
            display: isLoading ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "30vh",
            maxHeight: "30vh",
          }}
        >
          <CircularProgress
            id="icon-organization-list-circular"
            style={{ position: "fixed" }}
          />
        </Box>
        {companies?.length < 1 && (
          <Box
            display={"flex"}
            flexDirection="column"
            alignItems="center"
            justifyContent={"center"}
          >
            {mode == "dark" ? <EmptyProjectListDark /> : <EmptyProjectList />}
            <Typography
              id="text-organization-list-msg"
              variant="h6"
              className="noProjectError"
              mt={2}
            >
              {t("RAL0470")}
            </Typography>
          </Box>
        )}
        {companies
          .filter((c) => c.id === loggedCompany)
          .map((c) => {
            return !updateCompany && (
              <div
                className={css`
                  display: flex;
                  padding: 20px;
                  align-items: center;
                  background: ${mode === "light" ? "#FFF9F2" : "#fa9c2b1a"};
                  opacity: 1;
                  column-gap: 20px;
                  cursor: pointer;
                `}
              >
                <div className={"active-organization-icon"}>
                  <OrganizationActiveIcon id="icon-org-name-active" />
                </div>
                <Box flexGrow={1}>
                  <Typography
                    sx={{
                      color:
                        mode === "light"
                          ? "var(--color-45)"
                          : "var(--color-16)",
                      textTransform: nameCaseTransform(c?.name) ? 'none' : ''
                    }}
                  >
                    {c?.name}
                  </Typography>
                </Box>

                {roleCode === "ADMIN" || roleCode === "OWNER" ? (
                  <Tooltip
                    id="tooltip-organization-list-info"
                    title={t("RAL0172")}
                  >
                    <IconButton
                      id="organization-list-view-editicon"
                      sx={{ borderRadius: "5px" }}
                      onClick={() => handleEditOrganization(c.name)}
                    >
                      {mode === "dark" ? (
                        <EditIconDark id="organization-list-edit-icon" />
                      ) : (
                        <EditIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                ) : null}
                {/* <div onClick={() => handleEditOrganization()}>
                  {roleCode === "ADMIN" || roleCode === "OWNER" ? (
                    mode === "dark" ? (
                      <EditIconDark />
                    ) : (
                      <EditIcon />
                    )
                  ) : null}
                </div> */}

                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    background: #fa9c2b 0% 0% no-repeat padding-box;
                    border-radius: 50px;
                    opacity: 1;
                  `}
                >
                  <TickIconWhite id="organization-list-tick-icon" />
                </div>
              </div>
            );
          })}
        {companies.slice().reverse()
          .filter((c) => c.id !== loggedCompany)
          .map((c, index) => {
            return (
              <div
                className={css`
                  display: flex;
                  padding: 20px;
                  align-items: center;
                  opacity: 1;
                  column-gap: 20px;
                  cursor: pointer;
                `}
                onClick={() => handleSwitchCompany(c?.id)}
              >
                <div className={"organization-icon"}>
                  <OrganizationIcon id="organization-list-org-icon" />
                </div>
                <Box flexGrow={1}>
                  <Typography sx={{
                    textTransform: nameCaseTransform(c?.name) ? 'none' : ''
                  }}>{c?.name}</Typography>
                </Box>
              </div>
            );
          })}
      </div>
      {companies.length < 25 && !addCompany && (
        <div
          className={`add-org-btn  ${mode === "dark" ? "add-org-btn_dark" : ""
            }`}
          id="add-org-btn"
          onClick={() => setAddCompany(!addCompany)}
        >
          <Typography
            id="text-org-list-info"
            className={css`
              font: normal normal normal 16px/28px Gotham !important;
              letter-spacing: 0px;
              color: ${mode === "light"
                ? "var(--color-1) !important"
                : "var(--color-30) !important"};
              opacity: 1;
            `}
          >
            {t("RAL0471")}
          </Typography>
        </div>
      )}
    </div>
  );
}
