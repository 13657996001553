import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import utcPlugin from "dayjs/plugin/utc";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import { styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomIconButton from "../../components/CustomIconButton";
import { ReactComponent as LeftArrow } from "../../assets/images/icon_left_arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/icon_right_arrow.svg";
import { ReactComponent as LeftArrowLight } from "../../assets/images/icon_left_arrow_dark.svg";
import { ReactComponent as RightArrowLight } from "../../assets/images/icon_right_arrow_dark.svg";
import { css } from "@emotion/css";
import AppContext from "../../config/AppContext";
import { useTranslation } from "react-i18next";

dayjs.extend(isBetweenPlugin);
dayjs.extend(utcPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})(({ isSelected, isHovered, day, mode }) => ({
  borderRadius: 0,
  padding: '20px !important',
  ...(isSelected && {
    color: mode == "dark" ? "#FFFFFF" : "#242424",
    background: mode == "dark" ? "#362C20" : "#FFF7ED",
    borderColor: "#FA9C2B",
    "&:hover, &:focus": {
      background: mode == "dark" ? "#362C20" : "#FFF7ED",
      opacity: 0.1,
    },
  }),
  ...(isHovered && {
    background: mode == "dark" ? "#362C20" : "#FFF7ED",
    "&:hover, &:focus": {
      background: mode == "dark" ? "#362C20" : "#FFF7ED",
    },
  }),
  ...(day.isSame(dayjs(), "day") && {
    borderRadius: "5px",
    borderWidth: "1px !important",
    borderColor: "#FA9C2B !important",
    color: mode == "dark" ? "#FFFFFF" : "#242424",
    background: mode == "dark" ? "#362C20" : "#FFF7ED",
    className: "today-day",
    paddingBottom: "18px !important",
  }),
  ...(day.day() === 0 &&
    isSelected && {
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: '20px !important',
    background: "linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%)",
    "&:hover, &:focus": {
      background: "linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%)",
    },
  }),
  ...(day.day() === 6 &&
    isSelected && {
    borderRadius: "5px",
    color: "#FFFFFF",
    padding: '20px !important',
    background: "linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%)",
    "&:hover, &:focus": {
      background: "linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%)",
    },
  }),
}));

const StyledDateCalendar = styled(DateCalendar)(({ mode }) => ({
  backgroundColor: mode === "light" ? "#ffffff" : "#1f1f1f",
  color: mode === "light" ? "#242424" : "#f8f8f8",
  // borderRadius: "10px",
  // padding: "1.15rem",
  position: "absolute",
  zIndex: 1000,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "@media (max-width: 600px)": {
    padding: "10px",
  },
}));

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }
  return dayA.isSame(dayB, "week");
};

function Day(props) {
  const { day, selectedDay, hoveredDay, ...other } = props;
  const theme = useTheme();
  const { mode } = useContext(AppContext);
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const dayStyles = {
    px: isXlScreen ? 2.5 : 2.5,
  };

  return (
    <CustomPickersDay
      {...other}
      day={day}
      mode={mode}
      sx={dayStyles}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

const WeekLabel = ({
  selectedDay,
  onClick,
  showIcons,
  handlePrevWeek,
  handleNextWeek,
  disabledNextCalenderBtn,
}) => {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const startOfWeek = dayjs(selectedDay).startOf("week");
  const endOfWeek = dayjs(selectedDay).endOf("week");
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2px;
        text-wrap: nowrap;
      `}
    >
      {showIcons && (
        <>
          <CustomIconButton
            id="btn-leftarrow-icon"
            title={t("RAL0656")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={<LeftArrowLight id="btn-leftarrow-icon" height={isXlScreen ? "18px" : "14px"} className="arrow-svg"/>}
            darkMode={<LeftArrow id="btn-leftarrow-icon" height={isXlScreen ? "18px" : "14px"} className="arrow-svg"/>}
            onClick={handlePrevWeek}
          />

          <Typography
            id="btn-date-format"
            onClick={onClick}
            variant="datepicker"
            sx={{
              cursor: "pointer",
              userSelect: "none",
              paddingLeft: "16px",
              paddingRight: "16px",
              color: `${mode === "light" ? "#474b4f" : "#f8f8f8cc"}`,
            }}
          >
            {`${startOfWeek.format("DD MMM")} - ${endOfWeek.format(
              "DD MMM YYYY"
            )}`}
          </Typography>

          <CustomIconButton
            id="btn-rightarrow-icon"
            title={t("RAL0657")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            disabled={disabledNextCalenderBtn}
            lightMode={
              <RightArrowLight id="btn-rightarrow-icon" height={isXlScreen ? "18px" : "14px"} className="arrow-svg"/>
            }
            darkMode={<RightArrow id="btn-rightarrow-icon" height={isXlScreen ? "18px" : "14px"} className="arrow-svg"/>}
            onClick={handleNextWeek}
          />
        </>
      )}
    </div>
  );
};

const TimesheetWeekPicker = forwardRef(
  (
    { setStartDate, setEndDate, selectedDay, setResetWeekCalendar, reset },
    ref
  ) => {
    const [hoveredDay, setHoveredDay] = useState(null);
    const [value, setValue] = useState(dayjs(selectedDay));
    const [showCalendar, setShowCalendar] = useState(false);
    const [disabledNextCalenderBtn, setDisabledNxtCalenderBtn] = useState(null);
    const calendarRef = useRef(null);
    const { mode } = useContext(AppContext);

    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    useEffect(() => {
      if (reset) {
        setValue(dayjs(selectedDay));
        setResetWeekCalendar(false);
      }
      getInitialDisabledValueOfNextCalenderBtn()
    }, [reset]);

    useEffect(() => {
      if (showCalendar) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [showCalendar]);

    const handlePrevWeek = () => {
      const preWeek = value.subtract(1, "week")
      const today = dayjs();
      setValue((prevValue) => prevValue.subtract(1, "week"));
      setDisabledNxtCalenderBtn(preWeek.isSame(today, "day"))

    };

    const getInitialDisabledValueOfNextCalenderBtn = () => {
      const today = dayjs();
      setDisabledNxtCalenderBtn(dayjs(selectedDay).isSame(today, "day"))
    }

    const handleNextWeek = () => {
      const nextWeek = value.add(1, "week");
      const today = dayjs();
      if (nextWeek.isAfter(today, "day")) {
        return;
      }
      setDisabledNxtCalenderBtn(nextWeek.isSame(today, "day"))
      setValue(nextWeek);
    };

    useEffect(() => {
      // Set startDate as the start of the week minus 1 day
      setStartDate(dayjs(value).startOf("week").subtract(1, "day").toDate());

      // Set endDate as the end of the week in UTC
      setEndDate(dayjs(value).endOf("week").utc().toDate());
    }, [value, setStartDate, setEndDate]);

    // Method to reset state
    useImperativeHandle(ref, () => ({
      resetState() {
        const initialDate = dayjs();
        setValue(initialDate);
      },
    }));

    return (
      <div ref={calendarRef}>
        <WeekLabel
          selectedDay={value}
          onClick={() => setShowCalendar(true)}
          showIcons={true}
          handlePrevWeek={handlePrevWeek}
          handleNextWeek={handleNextWeek}
          disabledNextCalenderBtn={disabledNextCalenderBtn}
        />
        {showCalendar && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDateCalendar
              mode={mode}
              value={value}
              disableFuture={true}
              style={{ marginTop:'20px'}}
              onChange={(newValue) => {
                setValue(newValue);
                setShowCalendar(false);
              }}
              showDaysOutsideCurrentMonth
              displayWeekNumber={false}
              slots={{ day: Day }}
              slotProps={{
                day: (ownerState) => ({
                  selectedDay: value,
                  hoveredDay,
                  onPointerEnter: () => setHoveredDay(ownerState.day),
                  onPointerLeave: () => setHoveredDay(null),
                }),
              }}
            />
          </LocalizationProvider>
        )}
      </div>
    );
  }
);

export default TimesheetWeekPicker;
