import { css } from "@emotion/css";
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ReactComponent as ArrowDownDark } from "../../assets/images/ralvie/arrowdown-dark.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/ralvie/arrowdown-white.svg";
import { ReactComponent as ArrowUpDark } from "../../assets/images/ralvie/arrowup-dark.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/ralvie/arrowup-white.svg";
import { ReactComponent as SundialIcon } from "../../assets/images/ralvie/sundial-logo.svg";
import { ReactComponent as TagIcon } from "../../assets/images/ralvie/tag-outlined-icon.svg";
import { ReactComponent as WorklogCaptionDark } from "../../assets/images/ralvie/worklog-caption-dark.svg";
import { ReactComponent as WorklogCaption } from "../../assets/images/ralvie/worklog-caption.svg";
import AppIcon from "../../components/AppIcon";
import CustomButton from "../../components/CustomButton";
import CustomIconButton from "../../components/CustomIconButton";
import Typewriter from "../../components/Typewriter";
import AppContext from "../../config/AppContext";
import { listTimesheet, textSummaraize } from "../../services/WorklogService";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext, TimesheetContext } from "../../util/context";
import { summarization } from "../AI/TimelineSummary";

function formatTimesheetData(input) {
  const projectName = input["Project Name"] || "";
  const description = input["Description"] || "";
  const tags = input["Tags"] ? input["Tags"].join(", ") : "";
  const event = input["Event"] || {};
  const eventUser = event.user || {};
  const eventStartTime = new Date(event.startTime);
  const eventEndTime = new Date(event.endTime);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // convert 0 to 12
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes}${ampm}`;
  };

  const durationMinutes = Math.floor(event.duration / 60);

  return `Project Name: ${projectName}
Tags: ${tags}
Event:
Project ID: ${event.projectId || ""}
Event Number: ${event.eventNumber || ""}
User ID: ${eventUser.userId || ""}
Email: ${eventUser.email || ""}
Application Name: ${event.applicationName || ""}
Duration: ${durationMinutes} m
Time stamp: ${formatTime(eventStartTime)} - ${formatTime(eventEndTime)}
Description: ${description}`;
}

function processTextSummary(text) {
  const summary = text?.replace(/\"/g, "").trim(); // Clean up the summary text
  const startIndex = summary.indexOf("Projects:");
  const cleanSummary = summary.substring(startIndex).trim();
  const lines = cleanSummary.split("\n"); // Split the summary into lines

  let htmlSummary = '<div class="summary">';
  lines.forEach((line) => {
    if (line.includes(":")) {
      const [label, content] = line.split(":", 2);
      htmlSummary += `<p><strong>${label.trim()}:</strong> ${content.trim()}</p>`;
    } else {
      htmlSummary += `<p>${line.trim()}</p>`;
    }
  });
  htmlSummary += "</div>";
  return htmlSummary;
}

export default function DashboardWorklog(props) {
  const { enableAi } = useContext(sidebarContext);
  const { mode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const worklogsRef = useRef(null);
  const [worklogContainerHeight, setWorklogContainerHeight] = useState(0);
  const [timesheets, setTimesheets] = useState([]);
  const [toggleTimesheet, setToggleTimesheet] = useState(null);
  const [loggedHours, setLoggedHours] = useState(null);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [reset, setReset] = useState(false);
  const {
    reload,
    textSummary,
    setTextSummary,
    setReload,
    setEventByDrag,
    setTimesheetToEdit,
    setTimesheetEvents,
    setEventsToLog,
  } = useContext(TimesheetContext);
  const [textSummaryQuery, setTextSummaryQuery] = useState(undefined);
  const [dataToSummaraize, setDataToSummaraize] = useState([]);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    setWorklogContainerHeight(worklogsRef?.current?.clientHeight);
  }, [worklogsRef, timesheets, toggleTimesheet]);
  useEffect(() => {
    loadTimesheet();
  }, [props.filter?.selectedDate, props.userId]);

  useEffect(() => {
    if (reset) {
      loadTimesheet();
      setReset(false);
    }
  }, [reset]);

  const loadTimesheet = () => {
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(
      props.filter?.selectedDate,
      CommonUtil.getTimeZoneId()
    );
    let startDate = dateTz.startOf("day");
    let endDate = dateTz.endOf("day");
    let textQuery = "";
    let queryData = [];
    let company = CommonUtil.getLoggedUser()?.companyName;
    listTimesheet({
      startDate: startDate.utc().format(),
      endDate: endDate.utc().format(),
      userId: props.userId,
    }).then((res) => {
      let timesheets = res.data?.data;
      if (timesheets instanceof Array && timesheets.length > 0) {
        timesheets.forEach((e) => {
          let query = {
            company: company,
            projectName: e?.project.name,
            description: e?.project.description,
            tags: e?.tags?.map((t) => t.name),
            event: e.events?.map((t) => t.windowTitle),
            duration: `${e.loggedDuration % 60} m`,
            client: e?.project?.clientName,
            contactEmail: e?.project?.clientEmail,
            platform: e.events?.map((t) => t.applicationName),
            timeStamp: `${e.events?.[0]?.startTime} - ${
              e.events?.[e.events?.length - 1]?.startTime
            }`,
          };
          queryData.push(query);
          textQuery = textQuery + "\n" + formatTimesheetData(query);
        });
        console.log("queryData----", queryData);
        setDataToSummaraize(queryData);
        setTimesheets(res.data?.data);
      } else {
        setTimesheets([]);
      }
      if (textQuery) {
        setTextSummaryQuery(textQuery);
      }
    });
  };

  const handleSubEventsToogle = (id) => {
    if (toggleTimesheet === id) {
      setToggleTimesheet(null);
    } else {
      setToggleTimesheet(id);
    }
  };

  useEffect(() => {
    if (timesheets?.length > 0) {
      const totalDuration = timesheets.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue?.loggedDuration || 0);
      }, 0);
      if (totalDuration > 0) {
        setLoggedHours(CommonUtil.getDurationWithRoundoff(totalDuration));
      } else {
        setLoggedHours({ hr: 0, min: 0 });
      }
    } else {
      setLoggedHours({ hr: 0, min: 0 });
    }
  }, [timesheets]);

  useEffect(() => {
    if (loggedHours) {
      props.setLoggedHours(loggedHours);
    }
  }, [loggedHours]);

  const handleSummarizationWithCGPT = async (language) => {
    setIsLoading(true);
    try {
      const r = await summarization(textSummaryQuery, language);
      setTextSummary(r.text.replace(/\n/g, "<br>").replace(/- /g, "• "));
      props.setGenerateSummary(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Summarization failed:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.generateSummary) {
      handleSummarization();
    }
  }, [props.generateSummary]);

  const handleSummarization = async () => {
    if (timesheets.length < 1) {
      toast.warning("No timesheet found for the day");
      props.setGenerateSummary(false);
      return;
    }
    setIsLoading(true);
    if (textSummaryQuery) {
      try {
        const r = await textSummaraize({
          language: localStorage.getItem("text-summary-lang"),
          text: dataToSummaraize,
        });
        setTextSummary(processTextSummary(r.data?.data?.summary));
        props.setGenerateSummary(false);
        setIsLoading(false);
      } catch (error) {
        console.error("Summarization failed:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div
        className={css`
          // min-height: calc(100% - 40px);
          position: relative;
        `}
      >
        {textSummary && (
          <>
            <div
              className={css`
                min-height: calc(100% - 60px);
                position: relative;
                overflow: hidden;
                :hover {
                  overflow-y: auto;
                }
              `}
            >
              <Box>
                {" "}
                <div>
                  <Typewriter text={textSummary} delay={20} />
                </div>
              </Box>
            </div>
            <Box
              pt={2}
              display={"flex"}
              flexDirection={"row-reverse"}
              minWidth={"100%"}
              columnGap={2}
            >
              <CustomButton
                variant="outlined"
                onClick={() => setTextSummary(undefined)}
              >
                Close
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={() => {
                  CommonUtil.copyToClipboard(textSummary);
                  setTextSummary(undefined);
                }}
              >
                Copy
              </CustomButton>
            </Box>
          </>
        )}

        {!textSummary && !showAddEvent && !selectedTimesheet && (
          <div
            ref={worklogsRef}
            className={css`
              max-height: 90%;
              overflow: hidden;
              // margin-bottom: 10px;
              margin-top: 20px !important;
              :hover {
                overflow-y: auto;
              }
            `}
          >
            {timesheets?.map((t) => {
              let duration = CommonUtil.getDurationWithRoundoff(
                t?.loggedDuration
              );
              const title = t?.title ? t.title : t.events[0].windowTitle;
              const projectName = t?.project?.name;
              return (
                <div
                  style={{
                    marginBottom: "8px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    // marginTop: "15px",
                  }}
                >
                  <Box
                    className={css`
                      // width: 93%;
                      background: ${t?.project?.color};
                      border-radius: 5px;
                      opacity: 1;
                      padding: 16px;
                    `}
                  >
                    <Box
                      item
                      display={"flex"}
                      pb={1}
                      className={css`
                        white-space: wrap;
                        text-overflow: ellipsis;
                      `}
                    >
                      {title.length > 50 ? (
                        <Tooltip title={title} placement="top" arrow>
                          <Typography
                            id="timesheet-worklog-copy"
                            variant="worklog_project_title"
                            sx={{ wordBreak: "break-all" }}
                          >
                            <span
                              id="timesheet-worklog-copy"
                              style={{ fontFamily: "GothamMedium" }}
                            >{`${title.substring(0, 50)}...`}</span>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          id="timesheet-worklog-copy"
                          variant="worklog_project_title"
                          sx={{
                            wordBreak: "break-all",
                            textTransform: "capitalize",
                            fontFamily: "GothamMedium",
                          }}
                        >
                          {title}
                        </Typography>
                      )}
                    </Box>

                    <Box item display={"flex"} pb={1}>
                      <Typography
                        id="timesheet-worklog-projectname"
                        variant="worklog_project_title"
                        sx={{ wordBreak: "break-all" }}
                      >
                        {projectName}
                      </Typography>
                    </Box>
                    <Box
                      item
                      className={css`
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      `}
                    >
                      {t?.project?.clientName && (
                        <Typography variant="worklog_project_details">
                          {t?.project?.clientName}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      item
                      display={"flex"}
                      alignItems={"center"}
                      justifyItems={"center"}
                    >
                      <Box item flexGrow={1}>
                        {!t.events?.some(
                          (event) => event.source === "MANUAL"
                        ) && (
                          <SundialIcon
                            height="14px"
                            style={{ paddingRight: "4px" }}
                          />
                        )}
                        <Typography variant="worklog_project_details">
                          {duration?.hr + "h " + duration?.min + "m"}
                        </Typography>
                      </Box>

                      {t.tags?.length > 0 && (
                        <Box item pr={0.5}>
                          <Tooltip
                            title={t.tags.map((tag) => tag.name).join(", ")}
                          >
                            <TagIcon className="icon" />
                          </Tooltip>
                        </Box>
                      )}

                      <Box item pl={1}>
                        {toggleTimesheet === t?.id ? (
                          mode == "dark" ? (
                            <ArrowUp
                              className="icon"
                              onClick={() => handleSubEventsToogle(t?.id)}
                            />
                          ) : (
                            <ArrowUpDark
                              className="icon"
                              onClick={() => handleSubEventsToogle(t?.id)}
                            />
                          )
                        ) : mode == "dark" ? (
                          <ArrowDown
                            className="icon"
                            onClick={() => handleSubEventsToogle(t?.id)}
                          />
                        ) : (
                          <ArrowDownDark
                            className="icon"
                            onClick={() => handleSubEventsToogle(t?.id)}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>

                  {toggleTimesheet === t?.id && t?.events && (
                    <Box
                      item
                      className={css`
                        background: var(--background-color-4);
                        opacity: 1;
                        maxheight: 200px;
                        overflow: hidden;
                        :hover {
                          overflow-y: auto;
                        }
                      `}
                    >
                      {t?.events?.map((e) => {
                        let eventTime = CommonUtil.getDuration(e?.duration);
                        return (
                          <Box
                            m={1}
                            mx={2}
                            className={css`
                              background: var(--background-color-3);
                              border-radius: 5px;
                              opacity: 1;
                            `}
                          >
                            <Box display={"flex"} p={2}>
                              <Box item pr={2}>
                                <AppIcon
                                  data={e?.data}
                                  height={18}
                                  width={18}
                                  type="event"
                                  id={e?.eventNumber}
                                />
                              </Box>
                              <Box
                                item
                                flexDirection={"column"}
                                className={css`
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                `}
                              >
                                <Tooltip title={e.windowTitle}>
                                  <span
                                    className={css`
                                      font: normal normal normal 16px/19px
                                        GothamMedium;
                                      letter-spacing: 0px;
                                      color: var(--text-primary);
                                      opacity: 1;
                                    `}
                                  >
                                    {" "}
                                    {e.windowTitle}
                                  </span>
                                </Tooltip>
                                <Box item py={1}>
                                  <Typography
                                    className={css`
                                      font: normal normal normal 12px/14px
                                        Gotham;
                                      letter-spacing: 0px;
                                      color: var(--text-primary);
                                      opacity: 1;
                                    `}
                                  >
                                    {" "}
                                    {eventTime.hr +
                                      "h " +
                                      eventTime.min +
                                      "m " +
                                      eventTime.sec +
                                      "s"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {!showAddEvent && timesheets.length === 0 && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%", marginTop: "175px" }}
          >
            <Box item>
              {mode === "light" ? <WorklogCaption /> : <WorklogCaptionDark />}
            </Box>
            <Box>
              <p className="worklog-caption">{t("RAL0723")}</p>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: isLoading ? "flex" : "none",
            zIndex: 999,
          }}
        >
          <CircularProgress
            style={{ top: "50%", left: "85%", position: "fixed" }}
          />
        </Box>
      </div>
    </>
  );
}
