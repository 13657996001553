import React, { useState, useContext } from "react";
import { Dialog, Typography, IconButton, Box, Tooltip } from "@mui/material";
import AppContext from "../../config/AppContext";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";
import { ReactComponent as SuccessIcon } from "../../assets/images/ralvie/check-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import CustomTextField from "../../components/CustomTextField";
import { ReactComponent as CopyIcon } from "../../assets/images/ralvie/copy-icon.svg";
import ArrowLogo from "../../assets/images/ralvie/right-arrow-icon.svg";
import CommonUtil from "../../util/CommonUtils";
import CustomButton from "../../components/CustomButton";

export default function BiotimeConfigDialog({ open, onClose, companyId }) {
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    CommonUtil.copyToClipboard(companyId);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const listStyle = css`
    text-align: left;
    padding-left: 8px;
    list-style-type: disc;
    opacity: 0.7;
    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
    li {
      margin-bottom: 5px;
    }
  `;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <div
        className={css`
          background: ${mode === "dark" ? "#101010" : "#ffffff"} 0% 0% no-repeat
            padding-box;
          border-radius: 5px;
          opacity: 1;
          min-height: 30vh;
          min-width: 60vh;
          padding: 24px;
        `}
      >
        <Box
          pt={1}
          pb={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box item flexGrow={1}>
            <Typography
              className={css`
                font: normal normal bold 20px/23px GothamBold !important;
                letter-spacing: 0px;
                color: ${mode === "dark" ? "#f8f8f8cc" : "#242424"} !important;
              `}
            >
              {t("RAL0519")}
            </Typography>
          </Box>
          <IconButton
            onClick={onClose}
            sx={{
              border:
                mode === "light" ? "1px solid #FFFFFF" : "1px solid #313131",
              borderRadius: "5px",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          gap={1}
          mb={2}
          sx={{
            backgroundColor: "rgba(15, 170, 81, 0.1)",
            borderRadius: "5px",
            padding: "16px 16px",
          }}
        >
          <SuccessIcon style={{ height: "30px", width: "30px" }} />
          <span className="successMessage">{t("RAL0402")}</span>
        </Box>

        <Box
          sx={{
            "& .companyInfo": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
            "& .companyCode": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          }}
        >
          <span className="companyInfo">
            {t("RAL0403")}"<span className="companyCode">{t("RAL0404")}</span>"
            {t("RAL0405")}
          </span>
        </Box>

        <Box display="flex" alignItems="center" mt={1}>
          <CustomTextField
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor:
                  mode === "dark"
                    ? "#242424 ! important"
                    : "var(--input-background)",
                "!important": true,
              },
              "& .MuiInputBase-input.MuiOutlinedInput-input": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            }}
            value={companyId}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Tooltip title={copied ? t("RAL0417") : ""} arrow open={copied}>
                <span> 
                  <CustomButton
                        variant="contained"
                        sx={{
                            background: "linear-gradient(131deg, #1D0B77 0%, #6A5FA2 100%) !important",
                            borderRadius: "5px",
                            marginRight: "-10px",
                          }}
                        onClick={handleCopy}
                      >
                         <CopyIcon />
                      </CustomButton>
                </span> 
                </Tooltip>
              ),
            }}
            fullWidth
          />
        </Box>

        <Box
          mt={2}
          sx={{
            "& .companyCode": {
              color: mode === "dark" ? "#f8f8f8cc" : "#242424",
            },
          }}
        >
          <span className="companyCode">{t("RAL0406")}</span>

          <Box display="flex" gap={2} alignItems="center" mt={2}>
            <Box
              className="introductionInfo1"
              sx={{
                background:
                  mode === "light"
                    ? "#F4F5F8 0% 0% no-repeat padding-box"
                    : "#242424 !important",
              }}
            >
              <span
                className="introductionInfos"
                style={{
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                }}
              >
                {t("RAL0407")}
              </span>
            </Box>
            <img src={ArrowLogo} alt={"ArrowLogo"} />
            <Box
              className="introductionInfo2"
              sx={{
                background:
                  mode === "light"
                    ? "#F4F5F8 0% 0% no-repeat padding-box"
                    : "#242424 !important",
              }}
            >
              <span
                className="introductionInfos"
                style={{
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                }}
              >
                {t("RAL0408")}
              </span>
            </Box>
            <img src={ArrowLogo} alt={"ArrowLogo"} />
            <Box
              className="introductionInfo3"
              sx={{
                background:
                  mode === "light"
                    ? "#F4F5F8 0% 0% no-repeat padding-box"
                    : "#242424 !important",
              }}
            >
              <span
                className="introductionInfos"
                style={{
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                }}
              >
                {t("RAL0409")}
              </span>
            </Box>
            <img src={ArrowLogo} alt={"ArrowLogo"} />
            <Box
              className="introductionInfo4"
              sx={{
                background:
                  mode === "light"
                    ? "#F4F5F8 0% 0% no-repeat padding-box"
                    : "#242424 !important",
              }}
            >
              <span
                className="introductionInfos"
                style={{
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                }}
              >
                {t("RAL0410")}
              </span>
            </Box>
          </Box>

          <ul className={listStyle}>
            <ul className={listStyle}>
              <li sx={{ color: mode === "dark" ? "#f8f8f8cc" : "#242424" }}>
                <span>{t("RAL0411")}</span>
              </li>
              <li sx={{ color: mode === "dark" ? "#f8f8f8cc" : "#242424" }}>
                <span>{t("RAL0412")}</span>
              </li>
              <li sx={{ color: mode === "dark" ? "#f8f8f8cc" : "#242424" }}>
                <span>{t("RAL0413")}</span>
              </li>
              <li sx={{ color: mode === "dark" ? "#f8f8f8cc" : "#242424" }}>
                <span>{t("RAL0414")}</span>
              </li>
            </ul>
          </ul>
        </Box>
      </div>
    </Dialog>
  );
}
