import api from "../config/http-common";

export const checkCredentials = async (payload) => {
  return api.unsecuredAxios().get("/credentials");
};

export const ralvieLogin = async (payload) => {
  return api.unsecuredAxios().post("/ralvie/login", payload);
};

export const signup = async (payload) => {
  return api.unsecuredAxios().post("/user", payload);
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("USER");
  window.location.replace("/");
};

const createCompany = (payload) => {
  return api.securedAxios().post("/company/web", payload);
};

const login = (payload) => {
  return api.unsecuredAxiosApi().post("/users/authorize", payload);
};

const loginUser = (payload) => {
  return api.unsecuredAxios().post("/user/authorizes", payload);
};

const sendOtpToResetPassword = (email) => {
  return api.unsecuredAxiosApi().post(`/users/${email}/password`);
};

const validateOtpToResetPassword = (payload) => {
  return api.unsecuredAxiosApi().put(`/users/password`, payload);
};

const getUserCredentials = (id) => {
  return api.securedAxios().get(`/user/${id}/credentials`);
};

const activateEmail = (userId, companyId) => {
  return api.securedAxios().post(`/open/user/activate/${userId}/${companyId}`);
};

const getUserDetails = (userIdOrEmailOrPhone) => {
  return api.securedAxios().get(`/user/${userIdOrEmailOrPhone}`);
};

const signupWithoutCompany = (payload) => {
  return api.unsecuredAxios().post("/user/member", payload);
};

const createCustomer = (payload) => {
  return api.unsecuredAxiosApi().post("/user", payload);
};

const sendOtpToVerifyEmail = (email) => {
  return api.unsecuredAxiosApi().post(`/users/${email}/verify`);
};

const verifyOtp = (payload) => {
  return api.unsecuredAxiosApi().put(`/users/${payload.code}/verify`, payload);
};

const createCustomerCompany = (payload) => {
  return api.securedAxiosApi().post("/company", payload);
};

const inviteBatchUsers = (payload) => {
  return api.securedAxiosApi().post("/users/invite", payload);
};

const verifyEmail = (email) => {
  return api.securedAxios().get(`/user/verify/${email}`);
};

const downloadFileOrImages = (payload) => {
  return api.securedAxios().post("/storage/download", payload);
};

const listSundialVersions = () => {
  return api
    .securedAxios()
    .get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        "/sundial_version_control/latest"
    );
};

const updateCustomerCompany = (companyIdOrCode, payload) => {
  return api
    .securedAxios()
    .put(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        `/company/${companyIdOrCode}`,
      payload
    );
};

export const uploadUserProfile = async (userId, payload) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };
  return api
    .securedAxios()
    .put(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        `/users/${userId}/profile_pic`,
      payload,
      config
    );
};

export const getUserCredentialDetails = async (userId) => {
  return api
    .securedAxios()
    .get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        `/users/${userId}/profile`
    );
};

export const removeUserProfile = async (userId) => {
  return api
    .securedAxios()
    .delete(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        `/users/${userId}/profile_pic`
    );
};

export const revokeToken = async (userId) => {
  return api.securedAxiosApi().delete("/user/token");
};

export const validateUserActviation = (userId, companyId) => {
  return api
    .securedAxios()
    .get(`/open/user/activate?userId=${userId}&companyId=${companyId}`);
};

const OnboardService = {
  login,
  loginUser,
  signup,
  createCompany,
  sendOtpToResetPassword,
  validateOtpToResetPassword,
  getUserCredentials,
  activateEmail,
  getUserDetails,
  signupWithoutCompany,
  createCustomer,
  sendOtpToVerifyEmail,
  verifyOtp,
  createCustomerCompany,
  inviteBatchUsers,
  verifyEmail,
  downloadFileOrImages,
  listSundialVersions,
  updateCustomerCompany,
};

export default OnboardService;

export const userDetails = () => {};
