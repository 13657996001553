import {
  Box,
  Button,
  MenuItem,
  Popover,
  Select,
  Typography,
  FormControl,
  TextField,
  Checkbox,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { ReactComponent as ArrowDown } from "../../../src/assets/images/ralvie/arrow-down.svg";
import AppContext from "../../config/AppContext";
import "./ProjectFilter.scss";
import CommonUtil from "../../util/CommonUtils";

export default function ProjectFilter(props) {
  const { open, anchorEl, onClose, status, onStatusChange, SelectInput } =
    props;
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);

  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [selectedStatus, setSelectedStatus] = useState(status || "");
  const [tempClientFilter, setTempClientFilter] = useState([
    ...props.clientFilter,
  ]);
  const [tempClientSearch, setTempClientSearch] = useState("");
  const [tempMemberFilter, setTempMemberFilter] = useState([
    ...props.memberFilter,
  ]);
  const [tempMemberSearch, setTempMemberSearch] = useState("");
  const role = CommonUtil.getRoleCode();
  const menuItemStyle = {
    fontFamily: "Gotham",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "var(--fw-500)",
    backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF", 
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                        "&:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "&.Mui-selected": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
  };
  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  useEffect(() => {
    setTempClientFilter([...props.clientFilter]);
  }, [props.clientFilter]);

  useEffect(() => {
    setTempMemberFilter([...props.memberFilter]);
  }, [props.memberFilter]);

  const handleClientFilterChange = (clientName) => {
    if (clientName === t("RAL0272")) {
      setTempClientFilter([t("RAL0272")]);
    } else {
      let newClientFilter = [...tempClientFilter];

      if (tempClientFilter.includes(t("RAL0272"))) {
        newClientFilter = newClientFilter.filter(
          (name) => name !== t("RAL0272")
        );
      }
      if (newClientFilter.includes(clientName)) {
        newClientFilter = newClientFilter.filter((name) => name !== clientName);
      } else {
        newClientFilter.push(clientName);
      }
      if (newClientFilter.length === 0) {
        setTempClientFilter([t("RAL0272")]);
      } else {
        setTempClientFilter(newClientFilter);
      }
    }
  };

  const handleMemberFilterChange = (memberId) => {
    if (memberId === t("RAL0273")) {
      setTempMemberFilter([t("RAL0273")]);
    } else {
      let newMemberFilter = [...tempMemberFilter];
      if (tempMemberFilter.includes(t("RAL0273"))) {
        newMemberFilter = newMemberFilter.filter(
          (name) => name !== t("RAL0273")
        );
      }
      if (newMemberFilter.includes(memberId)) {
        newMemberFilter = newMemberFilter.filter((id) => id !== memberId);
      } else {
        newMemberFilter.push(memberId);
      }
      if (newMemberFilter.length === 0) {
        setTempMemberFilter([t("RAL0273")]);
      } else {
        setTempMemberFilter(newMemberFilter);
      }
    }
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setSelectedStatus(value);
  };

  const handleApplyFilters = () => {
    props.setClientFilter(tempClientFilter);
    props.setClientSearch(tempClientSearch);
    props.setMemberFilter(tempMemberFilter);
    props.setClientSearch(tempMemberSearch);
    onStatusChange(selectedStatus);
    handleClose();
  };

  const handleResetApply = () => {
    props.handleClientFilterChange(t("RAL0272"));
    props.handleMemberFilterChange(t("RAL0273"));
    setSelectedStatus("");
    onStatusChange("");
    props.handleReset();
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          overflow: "visible",
          marginLeft:
            role === "TEAM LEAD"
              ? isXlScreen
                ? "-155px"
                : "-135px"
              : isXlScreen
              ? "-205px"
              : "-175px",
          width: "360px",
          marginTop:12,
          background:
            mode === "light" ? "var(--color-37)" : "var(--color-9-00)",
        },
      }}
    >
      <Box
        p={3}
        className="projectFilters"
        minWidth={160}
        minHeight={100}
        sx={{
          backgroundColor:
            mode === "light" ? "var(--color-37)" : "var(--color-9-00)",
          paddingBottom: "0px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            font: "normal normal medium 18px/21px Gotham",
            color: mode === "light" ? "var(--color-43)" : "var(--color-33)",
            marginTop: -10,
          }}
        >
          {t("RAL0224")}
        </Typography>

        <Box mb={2} style={{ marginTop: 20 }}>
          <Select
            className="projectFiltersArrow"
            fullWidth
            id="btn-select-status"
            name="status"
            sx={{
              width: "310px",
              height: "50px",
              borderRadius: "5px",
              background:
                mode === "light" ? "var(--color-42)" : "var(--color-47-50)",
              font: "normal normal normal 16px/20px Gotham",
              "& .MuiSelect-select": {
                display: "block",
                width: "80%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color:
                  mode === "light"
                    ? "var(--color-43) !important"
                    : "var(--color-33-80) !important",
              },
            }}
            value={selectedStatus}
            onChange={handleStatusChange}
            displayEmpty
            input={<SelectInput />}
            // IconComponent={ArrowDown}
          >
            <MenuItem
              value=""
              className="input-placeholder"
              id="select-state-id"
              name="select-state-name"
              sx={menuItemStyle}
            >
              {t("RAL0231")}
            </MenuItem>

            <MenuItem
              value={"YET TO START"}
              className="input-placeholder"
              id="select-state-inactive-id"
              name="select-state-inactive-name"
              sx={menuItemStyle}
            >
              {t("RAL0658")}
            </MenuItem>
            <MenuItem
              value={"IN PROGRESS"}
              className="input-placeholder"
              id="select-state-archived-id"
              name="select-state-archived-name"
              sx={menuItemStyle}
            >
              {t("RAL0659")}
            </MenuItem>

            <MenuItem
              value={"OVERDUE"}
              className="input-placeholder"
              id="select-state-archived-id"
              name="select-state-archived-name"
              sx={menuItemStyle}
            >
              {t("RAL0660")}
            </MenuItem>

            <MenuItem
              value={"COMPLETED"}
              className="input-placeholder"
              id="select-state-archived-id"
              name="select-state-archived-name"
              sx={menuItemStyle}
            >
              {t("RAL0661")}
            </MenuItem>

            <MenuItem
              value={"ARCHIVED"}
              className="input-placeholder"
              id="select-state-archived-id"
              name="select-state-archived-name"
              sx={menuItemStyle}
            >
              {t("RAL0234")}
            </MenuItem>
          </Select>
        </Box>
      </Box>
      {props.role !== "EMPLOYEE" &&
        props.role !== "CLIENT" &&
        props.role !== "TEAM LEAD" && (
          <>
            <Box
              p={3}
              className="projectFilters"
              minWidth={160}
              minHeight={85}
              sx={{
                backgroundColor:
                  mode === "light" ? "var(--color-37)" : "var(--color-9-00)",
                paddingTop: "10px",
                paddingBottom: "5px",
              }}
            >
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                }}
              >
                <Select
                  id="dropdown-clientfilter"
                  value={tempClientFilter}
                  onClose={() => console.log("close")}
                  // renderValue={(selected) => selected}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return t("RAL0271");
                    }
                    return selected.join(", ");
                  }}
                  className="allClientsDropdown"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: "5px",
                        borderRadius: "5px",
                        // boxShadow: "0px 3px 6px #00000026",
                        // maxHeight: 325,
                      },
                    },
                    disableAutoFocusItem: true,
                    autoFocus: false,
                  }}
                  multiple
                  sx={{
                    width: "310px",
                    height: "50px",
                    borderRadius: "5px",
                    background:
                      mode === "light"
                        ? "var(--color-42)"
                        : "var(--color-47-50)",
                    font: "normal normal normal 16px/20px Gotham",
                    "& .MuiSelect-select": {
                      display: "block",
                      width: "80%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color:
                        mode === "light"
                          ? "var(--color-43) !important"
                          : "var(--color-33-80) !important",
                    },
                  }}
                >
                  <MenuItem
                    onKeyDown={(e) => e.stopPropagation()}
                    sx={{
                      fontFamily: "Gotham",
                      fontStyle: "normal",
                      fontVariant: "normal",
                      fontWeight: "var(--fw-500)",
                      backgroundColor:
                        mode === "dark"
                          ? "#1F1F1F!important"
                          : "#FFFFFF!important",
                      color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                      "&:hover": {
                        backgroundColor: "#1F1F1F",
                      },
                      "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor:
                          mode === "dark"
                            ? "#362C20!important"
                            : "#FFF9F2!important",
                        color: "#FA9C2B",
                      },
                    }}
                  >
                    <TextField
                      className="TopFilter-mb"
                      id="select-check-clientfilter"
                      placeholder={t("RAL0271")}
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={tempClientSearch}
                      autoComplete="off"
                      onChange={(e) => setTempClientSearch(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        onClick: (e) => {
                          e.stopPropagation();
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            border: "1px solid var(--border-color-1)",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid var(--border-color-1)",
                          },
                          "& input::placeholder": {
                            color:
                              mode === "light"
                                ? "#24242480 !important"
                                : "#f8f8f880 !important",
                            opacity: 1,
                          },
                          "& input": {
                            color:
                              mode === "light"
                                ? "#24242480 !important"
                                : "#f8f8f880 !important",
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </MenuItem>
                  <Box
                    sx={{
                      maxHeight: 260,
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: "var(--background-color-38) transparent",
                      "&::-webkit-scrollbar": {
                        width: "8px",
                        backgroundColor: "transparent",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem
                      id="select-item-allclientfilter"
                      value={t("RAL0272")}
                      onClick={() => handleClientFilterChange(t("RAL0272"))}
                      selected={tempClientFilter?.includes(t("RAL0272"))}
                      sx={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "var(--fw-500)",
                        backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF", 
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                        "&:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "&.Mui-selected": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "& input::placeholder": {
                          color:
                            mode === "light"
                              ? "#24242480 !important"
                              : "#f8f8f880 !important",
                          opacity: 1,
                        },
                      }}
                    >
                      <Checkbox
                        id="select-item-check-clientfilter"
                        checked={tempClientFilter.includes(t("RAL0272"))}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            border:
                              mode === "dark"
                                ? "1px solid #4D4D4D"
                                : "1px solid #B2B2B2",
                            borderRadius: "4px",
                            width: "20px",
                            height: "20px",
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "none",
                          },
                          "&.Mui-checked": {
                            color: "orange",
                          },
                          "& .MuiSvgIcon-root path": {
                            fill: "transparent",
                          },
                          "&.Mui-checked .MuiSvgIcon-root path": {
                            fill: "orange",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#362C20!important",
                            color: "#FA9C2B",
                            width: "20px",
                            height: "20px",
                          },
                        }}
                      />
                      {t("RAL0272")}
                    </MenuItem>
                    {props.clients &&
                      props.clients
                        .filter((client) =>
                          client.name
                            ?.toLowerCase()
                            .includes(tempClientSearch?.toLowerCase())
                        )
                        .map((client) => (
                          <MenuItem
                            id={`select-item-clientfilter-${client.id}`}
                            key={client.id}
                            value={client.name}
                            onClick={() =>
                              handleClientFilterChange(client.name)
                            }
                            selected={tempClientFilter?.includes(client.name)}
                            sx={{
                              fontFamily: "Gotham",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "var(--fw-500)",
                              backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF", 
                              color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                              "&:hover": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                                color: "#FA9C2B", 
                              },
                              "&.Mui-selected": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                                color: "#FA9C2B", 
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                                color: "#FA9C2B", 
                              },
                            }}
                          >
                            <Checkbox
                              id={`select-item-check-clientfilter-${client.id}`}
                              checked={tempClientFilter.includes(client.name)}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  border:
                                    mode === "dark"
                                      ? "1px solid #4D4D4D"
                                      : "1px solid #B2B2B2",
                                  borderRadius: "4px",
                                  width: "20px",
                                  height: "20px",
                                },
                                "&.Mui-checked .MuiSvgIcon-root": {
                                  border: "none",
                                },
                                "&.Mui-checked": {
                                  color: "orange",
                                },
                                "& .MuiSvgIcon-root path": {
                                  fill: "transparent",
                                },
                                "&.Mui-checked .MuiSvgIcon-root path": {
                                  fill: "orange",
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "#362C20!important",
                                  color: "#FA9C2B",
                                  width: "20px",
                                  height: "20px",
                                },
                              }}
                            />
                            {client.name.length > 22 ? (
                              <Tooltip
                                title={client.name}
                                placement="top"
                                arrow
                              >
                                <span>{`${client.name.substring(
                                  0,
                                  22
                                )}...`}</span>
                              </Tooltip>
                            ) : (
                              client.name
                            )}
                          </MenuItem>
                        ))}
                  </Box>
                </Select>
              </FormControl>
            </Box>
            {/* Members Filter */}
            <Box
              p={3}
              className="projectFilters"
              minWidth={160}
              minHeight={80}
              sx={{
                backgroundColor:
                  mode === "light" ? "var(--color-37)" : "var(--color-9-00)",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                }}
              >
                <Select
                  id="dropdown-memberfilter"
                  value={tempMemberFilter}
                  onClose={() => console.log("close")}
                  renderValue={(selected) => {
                    if (selected.includes(t("RAL0273"))) {
                      return t("RAL0273");
                    }

                    const selectedMembers = props.members.filter((member) =>
                      selected.includes(member.id)
                    );
                    return (
                      selectedMembers.map((member) => member.name).join(", ") ||
                      t("RAL0273")
                    );
                  }}
                  multiple
                  sx={{
                    width: "310px",
                    height: "50px",
                    borderRadius: "5px",
                    background:
                      mode === "light"
                        ? "var(--color-42)"
                        : "var(--color-47-50)",
                    font: "normal normal normal 16px/20px Gotham",
                    "& .MuiSelect-select": {
                      display: "block",
                      width: "80%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color:
                        mode === "light"
                          ? "var(--color-43) !important"
                          : "var(--color-33-80) !important",
                    },
                  }}
                  MenuProps={{
                    disableAutoFocusItem: true,
                    autoFocus: false,
                  }}
                >
                  <MenuItem
                    onKeyDown={(e) => e.stopPropagation()}
                    sx={{
                      fontFamily: "Gotham",
                      fontStyle: "normal",
                      fontVariant: "normal",
                      fontWeight: "var(--fw-500)",
                      backgroundColor:
                        mode === "dark"
                          ? "#1F1F1F!important"
                          : "#FFFFFF!important",
                      color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                      "&:hover": {
                        backgroundColor: "#1F1F1F",
                      },
                      "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor:
                          mode === "dark"
                            ? "#362C20!important"
                            : "#FFF9F2!important",
                        color: "#FA9C2B",
                      },
                    }}
                  >
                    <TextField
                      className="TopFilter-mb"
                      id="select-search-memberfilter"
                      placeholder={t("RAL0274")}
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={tempMemberSearch}
                      autoComplete="off"
                      onChange={(e) => setTempMemberSearch(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        onClick: (e) => {
                          e.stopPropagation();
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            border: "1px solid var(--border-color-1)",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid var(--border-color-1)",
                          },
                          "& input::placeholder": {
                            color:
                              mode === "light"
                                ? "#24242480 !important"
                                : "#f8f8f880 !important",
                            opacity: 1,
                          },
                          "& input": {
                            color:
                              mode === "light"
                                ? "#24242480 !important"
                                : "#f8f8f880 !important",
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </MenuItem>
                  <Box
                    sx={{
                      maxHeight: 260,
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: "var(--background-color-38) transparent",
                      "&::-webkit-scrollbar": {
                        width: "8px",
                        backgroundColor: "transparent",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem
                      value={t("RAL0273")}
                      id="select-item-memberfilter"
                      onClick={() => handleMemberFilterChange(t("RAL0273"))}
                      selected={tempMemberFilter?.includes(t("RAL0273"))}
                      sx={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "var(--fw-500)",
                        backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF", 
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                        "&:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "&.Mui-selected": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                      }}
                    >
                      <Checkbox
                        id="select-check-memberfilter"
                        checked={tempMemberFilter.includes(t("RAL0273"))}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            border:
                              mode === "dark"
                                ? "1px solid #4D4D4D"
                                : "1px solid #B2B2B2",
                            borderRadius: "4px",
                            width: "20px",
                            height: "20px",
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "none",
                          },
                          "&.Mui-checked": {
                            color: "orange",
                          },
                          "& .MuiSvgIcon-root path": {
                            fill: "transparent",
                          },
                          "&.Mui-checked .MuiSvgIcon-root path": {
                            fill: "orange",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#362C20!important",
                            color: "#FA9C2B",
                            width: "20px",
                            height: "20px",
                          },
                        }}
                      />
                      {t("RAL0273")}
                    </MenuItem>
                    {props.members &&
                      props.members
                        .filter((member) =>
                          member.name
                            ?.toLowerCase()
                            .includes(tempMemberSearch?.toLowerCase())
                        )
                        .map((member) => (
                          <MenuItem
                            id={`select-item-memberfilter-${member.id}`}
                            key={member.id}
                            selected={tempMemberFilter?.includes(member.id)}
                            sx={{
                              fontFamily: "Gotham",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "var(--fw-500)",
                              backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF", 
                              color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                              "&:hover": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                                color: "#FA9C2B", 
                              },
                              "&.Mui-selected": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                                color: "#FA9C2B", 
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                                color: "#FA9C2B", 
                              },
                            }}
                            value={member.id}
                            onClick={() => handleMemberFilterChange(member.id)}
                          >
                            <Checkbox
                              id={`select-item-check-memberfilter-${member.id}`}
                              checked={tempMemberFilter.includes(member.id)}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  border:
                                    mode === "dark"
                                      ? "1px solid #4D4D4D"
                                      : "1px solid #B2B2B2",
                                  borderRadius: "4px",
                                  width: "20px",
                                  height: "20px",
                                },
                                "&.Mui-checked .MuiSvgIcon-root": {
                                  border: "none",
                                },
                                "&.Mui-checked": {
                                  color: "orange",
                                },
                                "& .MuiSvgIcon-root path": {
                                  fill: "transparent",
                                },
                                "&.Mui-checked .MuiSvgIcon-root path": {
                                  fill: "orange",
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "#362C20!important",
                                  color: "#FA9C2B",
                                  width: "20px",
                                  height: "20px",
                                },
                              }}
                            />
                            <Typography
                              sx={{
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                              }}
                            >
                              {member.name.length > 24 ? (
                                <Tooltip
                                  title={member.name}
                                  placement="top"
                                  arrow
                                >
                                  <span>{`${member.name
                                    .replace("null", "")
                                    .substring(0, 24)}...`}</span>
                                </Tooltip>
                              ) : (
                                <span> {member.name.replace("null", "")}</span>
                              )}
                            </Typography>
                          </MenuItem>
                        ))}
                  </Box>
                </Select>
              </FormControl>
            </Box>
          </>
        )}

      {/* Filter buttons */}
      <Box
        display="flex"
        justifyContent="center"
        mt={0}
        mb={2}
        gap={2}
        pl={3}
        pr={3}
      >
        <Button
          id="btn-rst-id"
          name="btn-rst-name"
          variant="outlined"
          color="primary"
          onClick={handleResetApply}
          sx={{
            width: "150px",
            height: "50px",
            borderRadius: "5px",
            background:
              mode === "light"
                ? "var(--color-37) 0% 0% no-repeat padding-box"
                : "var(--color-9-00) 0% 0% no-repeat padding-box",
            border:
              mode === "light"
                ? "1px solid var(--color-1)"
                : "1px solid var(--color-30)",
            padding: "10px",
            "&:hover": {
              background:
                mode === "light"
                  ? "var(--color-37) 0% 0% no-repeat padding-box"
                  : "var(--color-9-00) 0% 0% no-repeat padding-box",
              border:
                mode === "light"
                  ? "1px solid var(--color-1)"
                  : "1px solid var(--color-30)",
            },
          }}
        >
          <Typography
            variant="h6"
            style={{
              font: "normal normal normal 16px/28px Gotham",
              color: mode === "light" ? "var(--color-1)" : "var(--color-30)",
            }}
          >
            {t("RAL0077")}
          </Typography>
        </Button>
        <Button
          id="btn-aply-id"
          name="btn-aply-name"
          variant="contained"
          color="primary"
          onClick={handleApplyFilters}
          sx={{
            width: "150px",
            height: "50px",
            borderRadius: "5px",
            padding: "25px",
            background: "linear-gradient(99deg, #1D0B77 0%, #6A5FA2 100%)",
          }}
        >
          <Typography
            variant="h6"
            style={{
              font: "normal normal normal 16px/28px Gotham",
              color: "#FFFFFF",
            }}
          >
            {t("RAL0230")}
          </Typography>
        </Button>
      </Box>
    </Popover>
  );
}
