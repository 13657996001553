import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import profileLight from "../../assets/images/EmptyProfile.svg";
import profileDark from "../../assets/images/EmptyProfile_dark.svg";
import { ReactComponent as OfficeIcon } from "../../assets/images/ralvie/office.svg";
import CustomButton from "../../components/CustomButton";
import CustomTextField from "../../components/CustomTextField";
import TitleBar from "../../components/layout-components/TitleBar";
import AppContext from "../../config/AppContext";
import { timeZoneData } from "../../constants/TimeZone";
import { listUserCompanies, updateUser } from "../../services/MemberService";
import {
  getUserCredentialDetails,
  removeUserProfile,
  uploadUserProfile,
} from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext } from "../../util/context";
import PasswordForm from "./PasswordForm";
import "./Profile.scss";
import CancelDialog from "../project/CancelDialog";

function ProfileSettings() {
  const { mode } = useContext(AppContext);
  const [profile, setProfile] = useState(
    mode === "dark" ? profileDark : profileLight
  );
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(profile.replace(/\\/g, "/"));
  const [profileFile, setProfileFile] = useState(null);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const { setProfilePic, userProfile, setUserProfile } =
    useContext(sidebarContext);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const decoded = CommonUtil.decodeToken();

  const [member, setMember] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState(null);

  const defaultState = {
    firstName: "",
    lastName: "",
    email: "",
    profilePhoto: "",
    timeZone: "",
  };

  const [payload, setPayload] = useState(defaultState);

  const [initialValues, setInitialValues] = useState(defaultState);
  const [isChanged, setIsChanged] = useState(false);

  const [error, setError] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    profilePhoto: "",
    timeZone: "",
  });

  useEffect(() => {
    setProfile(mode === "dark" ? profileDark : profileLight);
  }, [mode]);

  useEffect(() => {
    loadUser(decoded.userId);
    if (id) {
    }
  }, []);

  useEffect(() => {
    const initialPayload = {
      firstName: member?.name || "",
      lastName: member?.lastName || "",
      email: member?.email || "",
      profilePhoto: member?.profilePhoto || "",
      timeZone: member?.timeZone || "",
    };
    setPayload(initialPayload);
    setInitialValues(initialPayload);
    setProfilePhoto(member?.profilePhoto || profile.replace(/\\/g, "/"));
  }, [member]);

  useEffect(() => {
    const hasChanges = Object.keys(initialValues).some(
      (key) => initialValues[key] !== payload[key]
    );
    setIsChanged(hasChanges || profileFile !== null);
  }, [payload, initialValues, profileFile]);

  const loadUser = (id) => {
    getUserCredentialDetails(id).then((res) => {
      if (res?.status === 200 && res?.data?.code === "RCI0000") {
        setMember(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    listUserCompanies({ pageNumber: 1, pageSize: 1000, name: name }).then(
      (res) => {
        if (
          res.data?.code === "UASI0029" &&
          res.data?.data?.companies instanceof Array
        ) {
          setCompanies(res.data?.data?.companies);
        }
      }
    );
  }, [name]);

  const reset = (openNew) => {
    setPayload({
      ...defaultState,
    });
    setProfileFile(null);
    setProfilePhoto(profile.replace(/\\/g, "/"));
  };

  const handleFileError = (message) => {
    setError({
      ...error,
      profilePhoto: message,
    });
  };

  const removeProfilePhoto = () => {
    setProfileFile(null);
    setProfilePhoto(profile.replace(/\\/g, "/"));
    setError({
      ...error,
      profilePhoto: "",
    });
    setPayload({
      ...payload,
      profilePhoto: "",
    });

    removeUserProfile(decoded.userId)
      .then((res) => {
        if (res?.status === 200) {
          toast.success(t("RAL0532"));
          setProfilePic(null);
        } else {
          toast.error(t("RAL0533"));
        }
      })
      .catch((err) => {
        toast.error(t("RAL0534"));
      });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPayload({
      ...payload,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const handleFile = (event) => {
    handleFileError("");

    const file = event.target.files[0];
    if (!file) {
      return;
    }

    if (!/\/(jpeg|jpg|png)$/i.test(file.type)) {
      handleFileError(t("RAL0059"));
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      handleFileError(t("RAL0060"));
      event.target.value = "";
      return;
    }
    try {
      setProfilePhoto(URL.createObjectURL(file));
      setProfileFile(file);
      handleFileError("");
    } catch (error) {
      console.error("Error during file upload", error);
      handleFileError(t("RAL0061"));
    }
    event.target.value = "";
  };

  const validateForm = () => {
    if (!payload.firstName) {
      setError({
        ...error,
        firstName: t("RAL0001"),
      });
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   if (profileFile !== null) {
  //     const formData = new FormData();
  //     formData.append("file", profileFile);
  //     uploadUserProfile(decoded.userId, formData).then((uploadRes) => {
  //       if (uploadRes.data?.code === "RCI0000") {
  //         const uploadedPhotoUrl = uploadRes.data?.data?.profilePhoto;
  //         setProfilePhoto(uploadedPhotoUrl);
  //         setPayload((prevPayload) => ({
  //           ...prevPayload,
  //           profilePhoto: uploadedPhotoUrl,
  //         }));
  //       }
  //     });
  //   }
  // }, [profileFile]);

  //   useEffect(() => {
  //     if (profileFile !== null) {
  //         const formData = new FormData();
  //         formData.append("file", profileFile);
  //         uploadUserProfile(decoded.userId, formData).then((uploadRes) => {
  //             if (uploadRes.data?.code === "RCI0000") {
  //                 const uploadedPhotoUrl = uploadRes.data?.data?.profilePhoto;
  //                 setProfilePhoto(uploadedPhotoUrl);
  //                 setPayload((prevPayload) => ({
  //                     ...prevPayload,
  //                     profilePhoto: uploadedPhotoUrl,
  //                 }));

  //                 // Store the uploaded photo URL in localStorage
  //                 localStorage.setItem('profilePic', uploadedPhotoUrl);
  //             }
  //         });
  //     }
  // }, [profileFile]);
  useEffect(() => {
    if (profileFile !== null) {
      const formData = new FormData();
      formData.append("file", profileFile);
      uploadUserProfile(decoded.userId, formData).then((uploadRes) => {
        if (uploadRes.data?.code === "RCI0000") {
          const uploadedPhotoUrl = uploadRes.data?.data?.profilePhoto;
          setProfilePhoto(uploadedPhotoUrl);
          setPayload((prevPayload) => ({
            ...prevPayload,
            profilePhoto: uploadedPhotoUrl,
          }));

          // Store the uploaded photo URL in localStorage
          localStorage.setItem("profilePic", uploadedPhotoUrl);

          // Update the profilePic context state
          setProfilePic(uploadedPhotoUrl); // <-- Add this line
        }
      });
    }
  }, [profileFile]);

  const handleSubmit = (openNew) => {
    setError({
      ...error,
      profilePhoto: "",
    });

    if (!validateForm()) {
      return false;
    }

    let state = {
      name: payload.firstName,
      profilePhoto: profileFile ? payload.email : null,
      deleteProfilePhoto: payload.profilePhoto ? false : true,
      timeZone: payload.timeZone,
    };

    setIsLoading(true);

    updateUser(decoded.userId, state)
      .then((res) => {
        if (res.data?.code === "RCI0040") {
          if (userProfile?.memberName !== state?.name) {
            let updatedUserProfile = {
              ...userProfile,
              memberName: state?.name,
            };
            setUserProfile(updatedUserProfile);
          }
          toast.success(t("RAL0671"));
          setIsLoading(false);
        } else if (res.data?.code === "UASE0021") {
          setError({
            ...error,
            email: res.data?.message,
          });
          setIsLoading(false);
        } else {
          toast.error(res.data?.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(t("RAL0393"));
        setIsLoading(false);
        console.error(err);
      });

    return false;
  };

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    navigate("/dashboard");
  };

  const OrganizationList = ({ data }) => {
    return (
      <>
        <Grid container item lg={12} md={12} spacing="20px">
          {data.map((company, index) => (
            <Grid item key={index} sm={6} md={4} lg={3} xl={3}>
              <Box className="OrganizationsBox">
                <Grid item display={"flex"} flexDirection={"row"}>
                  <Grid item md={"auto"}>
                    <Avatar
                      id="profile-settings-organization-avatar"
                      sx={{ bgcolor: "#1D0B77", width: "50px", height: "50px" }}
                    >
                      <OfficeIcon />
                    </Avatar>
                  </Grid>

                  <Grid item display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                    <Grid
                      item
                      id="profile-settings-organization-name"
                      ml={1.5}
                      sx={{
                        color: "var(--text-color-3)",
                        fontWeight: "medium",
                        fontFamily: "GothamMedium",
                        wordBreak: "break-word",
                      }}
                    >
                      {company?.name?.length > 14 ? (
                        <Tooltip title={company?.name}>
                          <span>{`${company?.name.substring(0, 14)}...`}</span>
                        </Tooltip>
                      ) : (
                        company?.name
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <>
      <TitleBar
        navBack={false}
        id={"header-profile-settings"}
        title={t("RAL0369")}
      ></TitleBar>
      <Box
        height="calc(100vh - 110px)"
        overflow="auto"
        sx={{
          padding: "24px",
          backgroundColor: "var(--background-color-3)",
          borderRadius: "5px",
        }}
      >
        <Grid item md={12} mb={2}>
          <Typography
            id={"text-profile-settings-details"}
            className="form-title"
          >
            {t("RAL0238")}
          </Typography>
        </Grid>

        <Grid container item spacing={3} mb={3}>
          <Grid item md={"auto"} sm={12} xs={12}>
            <Avatar
              id={"profile-settings-member-avatar"}
              alt="Profile Avatar"
              sx={{
                width: { xl: 160, xs: 160 },
                height: { xl: 160, xs: 160 },
                marginBlock: "auto",
              }}
              src={profilePhoto}
            ></Avatar>
          </Grid>

          <Grid container item md={4} xs={12} sm={12}>
            <Box
              sx={{
                marginBlock: "auto",
              }}
            >
              <Box className="jpgMessage">
                <Typography
                  id={"profile-settings-avatar-info"}
                  className="form-profile-upload-text"
                >
                  {t("RAL0057")}
                </Typography>
                <Typography
                  id={"profile-settings-avatar-info1"}
                  className="form-profile-upload-text"
                >
                  {t("RAL0058")}
                </Typography>
              </Box>
              <Box display={"flex"} gap={"20px"} paddingTop={"20px"}>
                <Box width={"98px"} height={"40px"}>
                  <input
                    id={"input-profile-settings-file"}
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFile}
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                  <CustomButton
                    // disabled={isLoading}
                    id={"button-profile-settings-upload"}
                    variant="contained"
                    size="small"
                    fullWidth="fullWidth"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <span id={"text-profile-settings-upload"}>
                      {t("RAL0064")}
                    </span>
                  </CustomButton>
                </Box>

                <Box width={"98px"} height={"40px"}>
                  <CustomButton
                    id={"button-profile-settings-remove"}
                    place={"ps-remove"}
                    disabled={
                      // isLoading ||
                      profileFile === null &&
                      CommonUtil.isEmptyString(payload?.profilePhoto)
                    }
                    variant="outlined"
                    size="small"
                    fullWidth="fullWidth"
                    onClick={() => removeProfilePhoto()}
                  >
                    <span id={"text-profile-settings-upload"}>
                      {t("RAL0065")}
                    </span>
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Grid>
          {error.profilePhoto && (
            <Grid item md="12">
              <Typography
                id={"text-profile-settings-errormsg"}
                className="error-text"
              >
                {error.profilePhoto}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2.5} mb={5}>
          <Grid item lg={4} sm={4}>
            <CustomTextField
              id={"textfield-profile-settings-firstName"}
              required={true}
              name="firstName"
              label={t("RAL0139")}
              error={!CommonUtil.isEmptyString(error.firstName)}
              helperText={error.firstName}
              value={payload.firstName}
              onChange={(e) => handleChange(e)}
              inputProps={{
                maxLength: 60,
              }}
            ></CustomTextField>
          </Grid>

          <Grid item sm={4}>
            <CustomTextField
              id={"textfield-profile-settings-email"}
              required={true}
              disabled={true}
              name="email"
              label={t("RAL0241")}
              placeholder={t("RAL0461")}
              error={!CommonUtil.isEmptyString(error.email)}
              helperText={error.email}
              value={payload.email}
              onChange={(e) => handleChange(e)}
              validation="email"
              inputProps={{
                maxLength: 60,
              }}
            ></CustomTextField>
          </Grid>
        </Grid>

        {/* <Grid container spacing={2} mb={5}>
          <Grid item lg={8} sm={8} className="firstNameError">
            <Autocomplete
              disabled={true}
              id="dropdown-user-timezone"
              fullWidth
              size={"small"}
              getOptionLabel={(option) => option || ""}
              clearText="Clear"
              disableClearable={true}
              value={CommonUtil.getSystemTimezone()}
              options={[CommonUtil.getSystemTimezone()]}
              renderInput={(params) => (
                <CustomTextField
                  dropdown={true}
                  onChange={(e) => console.log()}
                  placeholder={t("RAL0462")}
                  error={!CommonUtil.isEmptyString(error.timeZone)}
                  helperText={error.timeZone}
                  {...params}
                  label={t("RAL0462")}
                ></CustomTextField>
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setPayload({
                    ...payload,
                    timeZone: newValue.value,
                  });
                } else {
                  setPayload({
                    ...payload,
                    timeZone: null,
                  });
                }
                setError({
                  ...error,
                  timeZone: "",
                });
              }}
            ></Autocomplete>
          </Grid>
        </Grid> */}

        <Grid container item lg={12} md={12} mb={2}>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <PasswordForm />
          </Grid>

          <Grid
            container
            item
            md={4}
            sm={12}
            xs={12}
            spacing={2.5}
            mb={2}
            pl={2}
            className="profileActionBtns"
          >
            <Grid item md={6} lg={6} sm={12} xs={12}>
              <CustomButton
                id={"button-profile-settings-cancel"}
                place={"sp-cancel"}
                disabled={!isChanged || isLoading}
                variant="outlined"
                size="large"
                fullWidth="fullWidth"
                onClick={handleCancelButton}
              >
                <span>{t("RAL0177")}</span>
              </CustomButton>
            </Grid>

            <Grid item md={6} lg={6} sm={12} xs={12}>
              <CustomButton
                // disabled={isLoading}
                id={"button-profile-settings-submit"}
                disabled={!isChanged || isLoading}
                variant="contained"
                size="large"
                fullWidth="fullWidth"
                onClick={() => handleSubmit(false)}
              >
                <span>{t("RAL0309")}</span>
              </CustomButton>
            </Grid>
          </Grid>
          <CancelDialog
            open={isCancelDialogOpen}
            onClose={handleCloseCancelDialog}
            onConfirm={handleConfirmCancel}
            header={t("RAL0382")}
            message={t("RAL0383")}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} mb={1}>
          <Typography
            id={"text-profile-settings-organizations"}
            className="form-title"
          >
            {t("RAL0463")}
          </Typography>
        </Grid>

        <OrganizationList data={companies} />
      </Box>
    </>
  );
}

export default ProfileSettings;
