import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as RalvieIntro } from "../../assets/images/ralvie/ralvie-intro.svg";
import { ReactComponent as RalvieDarkIntro } from "../../assets/images/ralvie/ralvie-dark-intro.svg";
import { ReactComponent as RalvieLogo } from "../../assets/images/ralvie/ralvie-logo.svg";
import { ReactComponent as RalvieDarkLogo } from "../../assets/images/ralvie/ralvieLogoDark.svg";
import { ReactComponent as I18nDark } from "../../assets/images/ralvie/i18n-dark.svg";
import { ReactComponent as I18nLight } from "../../assets/images/ralvie/i18n-light.svg";
import { ReactComponent as DarkMode } from "../../assets/images/ralvie/dark_mode.svg";
import { ReactComponent as LightMode } from "../../assets/images/ralvie/light_mode.svg";
import { ReactComponent as SignupLayoutImg } from "../../assets/images/SignupLayoutImg.svg";
import { ReactComponent as EnglishLogo } from "../../assets/images/ralvie/english-lang-icon.svg";
import { ReactComponent as ChaineseLogo } from "../../assets/images/ralvie/chainese-lang-icon.svg";
import { ReactComponent as SpanishLogo } from "../../assets/images/ralvie/spanish-lang-icon.svg";
import { ReactComponent as LanguageTickIcon } from "../../assets/images/ralvie/language-tick-icon.svg";
import { Box, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import CustomIconButton, {
  CustomIconbtn,
} from "../../components/CustomIconButton";
import AppContext from "../../config/AppContext";
import "./SignupLayout.scss";
import { useTranslation } from "react-i18next";
import i18n from "../../config/i18n";
import { CONSTANT } from "../../constants/SupportedLanguages";

function SignupLayoutRight() {

  const { t } = useTranslation();

  return (
    <>
      <div className="SignupLayout">
        <div className="h-100">
          <p className="headText">
          {t("RAL0694")}<span>{t("RAL0695")}</span> {t("RAL0698")} <br /> {t("RAL0696")} <span>{t("RAL0697")}</span>
          </p>
          <p className="subText">
          {t("RAL0699")}
          </p>
          <SignupLayoutImg className="invisible-img" />
        </div>
      </div>
    </>
  );
}

export default function SignupLayout(props) {
  const { colorMode, mode } = useContext(AppContext);
  const { t } = useTranslation();

  const storedLanguage =
    localStorage.getItem(CONSTANT.LOCAL_STORAGE_LANG_KEY) || "en";
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem(CONSTANT.LOCAL_STORAGE_LANG_KEY, language);
    handleLanguageMenuClose();
  };

  return (
    <Grid container direction="row" height="100vh" width="100vw" p={2} sx={{ minHeight: "100vh" }}>
      <Grid container item height={"100%"}>
        <Grid container item md={6} xs={12} sm={12} direction={"row"}>
          <Grid item sm={12} height={"10%"} pl={5} pr={3}>
            <Box className="signUpLayoutTopAlign">
              <Box item flexGrow={1}>
                {mode === "dark" ? <RalvieDarkLogo /> : <RalvieLogo />}
              </Box>
              <Box item className="IconFlex">
                <CustomIconbtn className="ThemeIconAlign" id="btn-dark-mode"
                  title={
                    t("RAL0421") + (mode == "dark" ? t("RAL0423") : t("RAL0422")) + t("RAL0424")
                  }
                  borderColor=""
                  size=""
                  variant=""
                  mode={mode}
                  darkMode={<DarkMode />}
                  lightMode={<LightMode />}
                  onClick={() => colorMode.toggleColorMode()}
                />
                 <CustomIconButton className="ThemeIconAlign"
                  id="btn-switch-lang"
                  title={t("RAL0650")}
                  borderColor=""
                  size=""
                  variant=""
                  lightMode={mode == 'light' ? <I18nLight /> : <I18nDark />}
                  darkMode={mode == 'light' ? <I18nLight /> : <I18nDark />}
                  onClick={handleLanguageMenuOpen}
                />
                 <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleLanguageMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className="language-menu"
                >
                  <MenuItem
                    onClick={() => changeLanguage("en")}
                    className="language-menu-item"
                    sx={{
                      backgroundColor:
                        selectedLanguage === "en"
                          ? mode === "dark"
                            ? "var(--background-color-28)"
                            : "var(--color-1-20)"
                          : "transparent",
                      color:
                        selectedLanguage === "en"
                          ? mode === "dark"
                            ? "#6A5FA2"
                            : "#1D0B77"
                          : "inherit",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <EnglishLogo style={{ marginRight: "10px" }} />
                        <Typography
                          className={`hover-text`}
                          sx={{
                            marginLeft: "5px",
                            font: "normal normal normal 18px/21px Gotham !important",
                            lineHeight: "20px",
                            fontFamily: "Gotham, sans-serif",
                            color:
                              selectedLanguage === "en"
                                ? "#1D0B77"
                                : mode === "dark"
                                ? "var(--color-33-50)"
                                : "#4F4F4F",
                          }}
                        >
                          {t("RAL0647")}
                        </Typography>
                      </Box>
                      {selectedLanguage === "en" && (
                        <LanguageTickIcon className="language-tick" />
                      )}
                    </Box>
                  </MenuItem>

                  <MenuItem
                    onClick={() => changeLanguage("zh")}
                    className="language-menu-item"
                    sx={{
                      backgroundColor:
                        selectedLanguage === "zh"
                          ? mode === "dark"
                            ? "var(--background-color-28)"
                            : "var(--color-1-20)"
                          : "transparent",
                      color:
                        selectedLanguage === "zh"
                          ? mode === "dark"
                            ? "#6A5FA2"
                            : "#1D0B77"
                          : "inherit",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      width="220px"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <ChaineseLogo style={{ marginRight: "10px" }} />
                        <Typography
                          className={`hover-text`}
                          sx={{
                            marginLeft: "5px",
                            font: "normal normal normal 18px/21px Gotham !important",
                            lineHeight: "20px",
                            fontFamily: "Gotham, sans-serif",
                            color:
                              selectedLanguage === "zh"
                                ? "#1D0B77"
                                : mode === "dark"
                                ? "var(--color-33-50)"
                                : "#4F4F4F",
                          }}
                        >
                          {t("RAL0648")}
                        </Typography>
                      </Box>
                      {selectedLanguage === "zh" && (
                        <LanguageTickIcon className="language-tick" />
                      )}
                    </Box>
                  </MenuItem>

                  <MenuItem
                    onClick={() => changeLanguage("es")}
                    className="language-menu-item"
                    sx={{
                      backgroundColor:
                        selectedLanguage === "es"
                          ? mode === "dark"
                            ? "var(--background-color-28)"
                            : "var(--color-1-20)"
                          : "transparent",
                      color:
                        selectedLanguage === "es"
                          ? mode === "dark"
                            ? "#6A5FA2"
                            : "#1D0B77"
                          : "inherit",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      width="220px"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <SpanishLogo style={{ marginRight: "10px" }} />
                        <Typography
                          className={`hover-text`}
                          sx={{
                            marginLeft: "5px",
                            font: "normal normal normal 18px/21px Gotham !important",
                            lineHeight: "20px",
                            fontFamily: "Gotham, sans-serif",
                            color:
                              selectedLanguage === "es"
                                ? "#1D0B77"
                                : mode === "dark"
                                ? "var(--color-33-50)"
                                : "#4F4F4F",
                          }}
                        >
                          {t("RAL0649")}
                        </Typography>
                      </Box>
                      {selectedLanguage === "es" && (
                        <LanguageTickIcon className="language-tick" />
                      )}
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            height={"80%"}
            // width={"100%"}
            // mx={10}
            px={3}
          // border={"1px solid green"}
          >
            {props.children}
          </Grid>
        </Grid>
        <Grid container item md={6} height="100%" display={{ xs: "none", sm: "none", md: "flex", lg: "flex" }}>
          <Box container height={"100%"} width={"100%"} key={mode}>
            {/* {mode === "dark" ? <RalvieDarkIntro /> : <RalvieIntro />} */}
            <SignupLayoutRight />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
