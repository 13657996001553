import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Popover,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import "./Dashboard.scss";
import "./PieChartRes.scss";
import { listApplication } from "../../services/DashboardService";
import CommonUtil from "../../util/CommonUtils";
import CategoryDoughnut from "./CategoryDoughnut";
import { ReactComponent as FilterIcon } from "../../assets/images/filter-icon.svg";
import CustomIconButton from "../../components/CustomIconButton";
import dayjs from "dayjs";
import { css } from "@emotion/css";
import AppContext from "../../config/AppContext";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyDashboard } from "../../assets/images/ralvie/icon-AppCategory-dashboard.svg";
import { ReactComponent as EmptyDashboardDark } from "../../assets/images/ralvie/icon-AppCategory-dashboardDark.svg";

export default function PieChart(props) {
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    userId: "",
    startDate: "",
    endDate: "",
    departmentFilter: "",
    formattedStartTime: "",
    formattedEndTime: "",
  });
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [categoryData, setCategoryData] = useState([]);
  const [grandTotal, setGrandTotal] = useState({ hours: 0, minutes: 0 });
  const [filterOptions, setFilterOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedState, setCheckedState] = useState({});
  const [loading, setLoading] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let role = CommonUtil.getLoggedUser()?.roleCode;
    if (
      role !== "OWNER" &&
      role !== "ADMIN" &&
      (CommonUtil.isEmptyString(filter.userId) || filter.userId === "All members")
    ) {
      return;
    }
    if (filter.startDate && filter.endDate) {
      setLoading(true);
      const userData = JSON.parse(localStorage.getItem("USER"));
  
      const payload = {
        startTime:  CommonUtil.formatDateToUTC(props.startDate),
        endTime:  CommonUtil.formatDateToUTCDate(props.endDate),
        companyId: userData.companyId,
        departmentIds: "All departments".includes(filter.departmentFilter)
          ? ""
          : filter.departmentFilter,
        userId: filter.userId === "All members" ? "" : filter.userId,
      };
  
      listApplication(payload)
        .then((res) => {
          if (res.data.code === "RCI0000") {
            const data = res.data.data.categoryData;

            // Set all categories for filter options
            const categories = data.map((category) => category.categoryName);
            setFilterOptions(categories);

            // Sort the categories by totalDuration in descending order
            const sortedData = data.sort(
              (a, b) => b.totalDuration - a.totalDuration
            );

            // Initialize checkedState for top 5 categories with duration > 0
            const initialCheckedState = {};
            let checkedCount = 0;
            sortedData.forEach((category, index) => {
              if (category.totalDuration > 0 && checkedCount < 5) {
                initialCheckedState[category.categoryName] = true;
                checkedCount++;
              } else {
                initialCheckedState[category.categoryName] = false;
              }
            });
            setCheckedState(initialCheckedState);

            setCategoryData(data);
            setGrandTotal({
              hours: res.data.data.grandTotalHours,
              minutes: res.data.data.grandTotalMinutes,
            });
          } else {
            setCategoryData([]);
            setGrandTotal({ hours: 0, minutes: 0 });
            setFilterOptions([]);
          }
          setLoading(false); 
        })
        .catch((error) => {
          console.error("Error fetching category data:", error);
          setCategoryData([]);
          setGrandTotal({ hours: 0, minutes: 0 });
          setFilterOptions([]);
          setLoading(false); 
        });
    }
  }, [filter]);

  useEffect(() => {
    if (
      filter.userId !== props.userId ||
      !dayjs(filter.startDate).isSame(dayjs(props.startDate)) ||
      !dayjs(filter.endDate).isSame(dayjs(props.endDate)) ||
      filter.departmentFilter !== props.departmentFilter
    ) {
      setFilter({
        ...filter,
        ...props,
        formattedStartTime: CommonUtil.formatDateToUTC(props.startDate),
        formattedEndTime: CommonUtil.formatDateToUTCDate(props.endDate),
      });
    }
  }, [props.userId, props.startDate, props.endDate, props.departmentFilter]);

  const handleCategorySelect = (event) => {
    const categoryName = event.target.name;
    const isChecked = event.target.checked;

    if (isChecked && Object.values(checkedState).filter(Boolean).length < 5) {
      setCheckedState({
        ...checkedState,
        [categoryName]: true,
      });
    } else if (!isChecked) {
      setCheckedState({
        ...checkedState,
        [categoryName]: false,
      });
    } else {
      toast.error("You can select up to 5 categories only.");
    }
  };

  const allCategoriesUnchecked = Object.values(checkedState).every(
    (checked) => !checked
  );
  return (
    <div
      className={css`
        height: 100%;
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Typography id="category-header-text" className="AppCategoriesHeading">{t("RAL0589")}</Typography>
        <Box
          sx={{
            "& .filter_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .filter_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            id="category-filter-icon"
            title={t("RAL0224")}
            variant="square"
            lightMode={<FilterIcon id="category-filter-icon" className="filter_svg" />}
            darkMode={
              <FilterIcon
                id="category-filter-icon" 
                height={isXlScreen ? "18px" : "14px"}
                className="filter_svg"
              />
            }
            bg='transparent'
            onClick={handleClick}
          />
        </Box>
      </div>
      {loading ? (
        <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width:"100%",
          paddingBottom:10,
        }}
      >
        <CircularProgress id="barchart-circular-icon" />
      </Box>
      ) : (
        <>
          <div
            className={css`
              height: 70%;
            `}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width:'100%', flexDirection: "column", }}>
              {allCategoriesUnchecked ? (
                <div
                  className={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    margin-top:10px;
                  `}
                >
                    {mode === "light" ? <EmptyDashboard id="piechart-empty-icon"  className="EmptyDashboard"/> : <EmptyDashboardDark id="piechart-empty-icon"  className="EmptyDashboard" />}
               
                  <Box>
                    <p id="project-empty-icon"  className="EmptyDashboardicon">{t("RAL0277")}</p>
                  </Box>
                </div>

              ) : (
                
                <CategoryDoughnut
                  mode={mode}
                  data={categoryData}
                  grandTotal={grandTotal}
                  selectedCategories={Object.keys(checkedState).filter(
                    (category) => checkedState[category]
                  )}
                />
              )}
            </div>
          </div>
        </>
      )}

      {filterOptions.length > 0 && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              width: 240,
              boxShadow: "0px 3px 6px #00000026",
              background: mode == "dark" ? "#1F1F1F" : "var(--popover-background)",
            },
          }}
        >
          <Box
            py={1}
            sx={{
              maxHeight: 260,
              overflowY: "auto",
              overflowX: "hidden",
              scrollbarWidth: "thin",
              scrollbarColor: "var(--background-color-38) transparent",
              "&::-webkit-scrollbar": {
                width: "8px",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
              },
            }}
          >
            <FormGroup
              className={css`
                background: ${mode == "dark" ? "#1F1F1F" : "var(--popover-background)"} !important;
              `}
            >
              {filterOptions
                .sort((a, b) => {
                  return checkedState[b] - checkedState[a];
                })
                .map((category, index) => (
                  <FormControlLabel
                    sx={{
                      height: "50px",
                      margin: "0px",
                      background:
                        mode === "dark"
                          ? checkedState[category]
                            ? "#362C20"
                            : "#1F1F1F"
                          : checkedState[category]
                          ? "#FFF9F2"
                          : "#FFFFFF",
                    }}
                    key={index}
                    control={
                      <Checkbox
                        id="category-checkbox-select" 
                        name={category}
                        checked={checkedState[category] || false}
                        onChange={handleCategorySelect}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            border:
                              mode === "dark"
                                ? "1px solid #4D4D4D"
                                : "1px solid #B2B2B2",
                            borderRadius: "4px",
                            width: "20px",
                            height: "20px",
                            boxSizing: "border-box",
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "none",
                          },
                          "&.Mui-checked": {
                            color: "#FA9C2B",
                          },
                          "& .MuiSvgIcon-root path": {
                            fill: "transparent",
                          },
                          "&.Mui-checked .MuiSvgIcon-root path": {
                            fill: "#FA9C2B",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#FA9C2B !important",
                            color: "#FA9C2B",
                            width: "20px!important",
                            height: "20px!important",
                          },
                          "& .MuiSvgIcon-root::before": {
                            display: "none",
                          },
                        }}
                      />
                    }

                    label={
                      <Typography
                        sx={{
                          color:
                            mode === "dark"
                              ? checkedState[category]  
                                ? "#FA9C2B"
                                : "var(--color-33-80)"
                              : checkedState[category]  
                                ? "#FA9C2B"
                                : "var(--color-43-80)",
                          marginRight: "0px",
                          font: "normal normal normal 16px/20px Gotham",
                           opacity: 1 ,
                        }}
                      >
                        {category?.length > 14 ? (
                          <Tooltip title={category}>
                            <span id="category-text-name" style={{ font: "normal normal normal 16px/20px Gotham", opacity: 1 }}>
                              {`${category.substring(0, 14)}...`}</span>
                          </Tooltip>
                        ) : (
                          <span id="category-text-name" style={{ font: "normal normal normal 16px/20px Gotham", opacity: 1 }}>{category}</span>
                        )}
                      </Typography>
                    }
                  />
                ))}
            </FormGroup>
          </Box>
        </Popover>
      )}
    </div>
  );
}
