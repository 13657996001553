import { css } from "@emotion/css";
import {
  Avatar,
  Box,
  Grid,
  Popover,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { alpha } from "@mui/system";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled, useTheme } from "@mui/material/styles";
import React, { useContext, useState } from "react";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import { ReactComponent as UpArrow } from "../../assets/images/ralvie/ArrowUp.svg";
import { ReactComponent as ClockIcon } from "../../assets/images/ralvie/Clock_icon.svg";
import { ReactComponent as DownArrow } from "../../assets/images/ralvie/downarrow.svg";
import { ReactComponent as LayerIcon } from "../../assets/images/ralvie/Layer_icon.svg";
import { ReactComponent as LayerIconDark } from "../../assets/images/ralvie/Layer_icon_dark.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/ralvie/more-icon.svg";
import { ReactComponent as ReportIcon } from "../../assets/images/ralvie/report_icon.svg";
import CommonUtil from "../../util/CommonUtils";
import ProjectReportAccordion from "./ProjectReportAccordion";
import { ReactComponent as ProjectIcon } from "../../assets/images/ralvie/Project.svg";
import { useTranslation } from "react-i18next";
import "./ProjectsReport.scss";
import AppContext from "../../config/AppContext";
import CustomButton from "../../components/CustomButton";
import CustomizedDatePickerReport from "../../components/CustomizedDatePickerReport";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import CustomCheckBox from "../../components/CustomCheckBox";
import TimesheetDatePickerWB from "../timesheet/TimesheetDatePickerWB";
import { exportProjectReport } from "../../services/ReportService";
import dayjs from "dayjs";
import CancelDialog from "../project/CancelDialog";

const BorderLinearProgress = styled(({ bgcolor, barcolor, ...props }) => (
  <LinearProgress {...props} />
))(({ theme, bgcolor, barcolor }) => ({
  height: 8,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: bgcolor || "#2C7865", // default value if no bgcolor is passed
    ...theme.applyStyles("dark", {
      backgroundColor: bgcolor || "#2C7865",
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: barcolor || "#90D26D", // default value if no barcolor is passed
    ...theme.applyStyles("dark", {
      backgroundColor: barcolor || "#2C7865",
    }),
  },
}));

export default function ProjectReportCard({ project }) {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [isIncludeMemberContribution, setIsIncludeMemberContribution] =
    useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const isStartDateValid =
    startDate.isBefore(endDate) || startDate.isSame(endDate);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { mode } = useContext(AppContext);
  const popupMore = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (popupMore.isOpen) {
      popupMore.close();
    }
    setStartDate(dayjs());
    setEndDate(dayjs());
    setOpen(false);
    setIsIncludeMemberContribution(false);
  };

  let cost = 100;
  let hour = 100;
  let duration = CommonUtil.getDuration(project?.totalLoggedDuration);
  let billedDuration =
    project?.totalBilledDuration > 0
      ? CommonUtil.getDuration(project?.totalBilledDuration)
      : undefined;
  let unBilledDuration =
    project?.totalNonBillableDuration > 0
      ? CommonUtil.getDuration(project?.totalNonBillableDuration)
      : undefined;

  if (project?.totalLoggedDuration === 0) {
    duration = { hr: 0, min: 0 };
  }

  if (project.totalLoggedDuration > 0) {
    if (project.totalNonBillableDuration > 0) {
      hour = (project.totalBilledDuration / project.totalLoggedDuration) * 100;
      // cost = (project.totalBilledCost / project.totalBilledCost) * 100;
    }
  }

  if (project.totalBilledCost + project.totalNonBillableCost > 0) {
    if (project.totalNonBillableCost > 0) {
      cost =
        (project.totalBilledCost /
          (project.totalBilledCost + project.totalNonBillableCost)) *
        100;
    }
  }

  const handleSubmit = () => {
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(startDate, CommonUtil.getTimeZoneId());
    let startTime = dateTz.startOf("day");
    dateTz = dayjs.tz(endDate, CommonUtil.getTimeZoneId());
    let endTime = dateTz.endOf("day");
    let start = startTime.isUTC()
      ? startTime.format()
      : startTime.utc().format();
    let end = endTime.isUTC() ? endTime.format() : endTime.utc().format();
    exportProjectReport(
      project?.project?.id,
      null,
      null,
      start,
      end,
      null,
      isIncludeMemberContribution
    ).then(() => {
      handleClose();
    });
  };

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    if (popupMore.isOpen) {
      popupMore.close();
    }
    setIsCancelDialogOpen(false);
    setStartDate(dayjs());
    setEndDate(dayjs());
    setOpen(false);
    setIsIncludeMemberContribution(false);
  };

  return (
    <>
      <Grid container className="projectReportBox">
        <Grid item xs={12} lg={3.6} className="ReportIcon">
          <Avatar
            id="projectreport-project-avatar"
            sx={{
              width: { xl: 70, xs: 65 },
              height: { xl: 70, xs: 65 },
              mt: { xs: 1 },
              backgroundColor: (theme) => alpha(project?.project?.color, 0.15),
            }}
          >
            <ReportIcon id="projectreport-report-icon" fill={project?.project?.color} opacity={0.5} />
          </Avatar>
          <div className="boxes">
            <Typography id="projectreport-project-name" className="projectNameText"
               style={{
                 marginTop: !project?.project?.clientName && !project?.project?.clientEmail ? '15px' : undefined,
               }}>
              {project?.project?.name?.length > 14 ? (
                          <Tooltip id="tooltip-project-name" title={project?.project?.name}>
                            <span>{`${project?.project?.name.substring(0, 14)}...`}</span>
                          </Tooltip>
              ) : (project?.project?.name)}
            </Typography>
            <Typography id="projectreport-project-clientName" className="projectClientName">
              {project?.project?.clientName?.length > 14 ? (
                          <Tooltip id="tooltip-project-clientName" title={project?.project?.clientName}>
                            <span>{`${project?.project?.clientName.substring(0, 14)}...`}</span>
                          </Tooltip>
              ) : (project?.project?.clientName)}
            </Typography>
            <Typography id="projectreport-project-clientEmail" className="projectClienEmail">
              <Tooltip id="tooltip-project-clientEmail" title={project?.project?.clientEmail?.length < 14 ? "" : project?.project?.clientEmail}>
              {project?.project?.clientEmail?.length > 14 ? (
                          <Tooltip id="tooltip-project-clientEmail" title={project?.project?.clientEmail}>
                            <span>{`${project?.project?.clientEmail.substring(0, 14)}...`}</span>
                          </Tooltip>
              ) : (project?.project?.clientEmail)}
            </Tooltip>
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={3.8}
          className={css`
            display: flex;
            column-gap: 16px;
            border: 1px solid var(--border-color-5);
            background: var(--card-background-color-1);
            border-radius: 5px;
            opacity: 1;
            padding: 16px;
          `}
        >
          <ClockIcon
            id="projectreport-clock-icon"
            sx={{
              position: "relative",
              zIndex: 2,
            }}
          />
          <div className="reportName">
            <Typography id="projectreport-project-totalhours" className="report-1" sx={{ marginTop: "-2px" }}>
              {t("RAL0207")}
              <span>
                {" "}
                {project.totalLoggedDuration > 0
                  ? CommonUtil.getFormatedDuration(duration.hr) +
                    "h " +
                    CommonUtil.getFormatedDuration(duration.min) +
                    "m " +
                    CommonUtil.getFormatedDuration(duration.sec) +
                    "s"
                  : "00h 00m"}{" "}
              </span>
            </Typography>

            <Typography id="projectreport-project-billedhours" className="report-1" sx={{ marginTop: "-2px" }}>
              {t("RAL0209")}
              <span>
                {" "}
                {billedDuration
                  ? CommonUtil.getFormatedDuration(billedDuration.hr) +
                    "h " +
                    CommonUtil.getFormatedDuration(billedDuration.min) +
                    "m " +
                    CommonUtil.getFormatedDuration(billedDuration.sec) +
                    "s"
                  : "00h 00m"}{" "}
              </span>
            </Typography>

            <Typography id="projectreport-project-unbilledhours" className="report-1" sx={{ marginTop: "-2px" }}>
              {t("RAL0210")}
              <span>
                {" "}
                {unBilledDuration
                  ? CommonUtil.getFormatedDuration(unBilledDuration.hr) +
                    "h " +
                    CommonUtil.getFormatedDuration(unBilledDuration.min) +
                    "m " +
                    CommonUtil.getFormatedDuration(unBilledDuration.sec) +
                    "s"
                  : "00h 00m"}{" "}
              </span>
            </Typography>

            <Box sx={{ width: "100%" }}>
              <BorderLinearProgress
                variant="determinate"
                value={hour}
                barcolor="#90D26D"
              />
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              height={"100%"}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography id="projectreport-project-billedhours" className="report-1">{t("RAL0209")}</Typography>
              </Box>
              <Box>
                <Typography id="projectreport-project-unbilledhours" className="report-1">{t("RAL0210")}</Typography>
              </Box>
            </Box>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={3.8}
          className={css`
            display: flex;
            column-gap: 16px;
            border: 1px solid var(--border-color-5);
            background: var(--card-background-color-1);
            border-radius: 5px;
            opacity: 1;
            padding: 16px;
          `}
        >
          {mode === "dark" ? (
            <LayerIconDark
              id="projectreport-layer-icon"
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            />
          ) : (
            <LayerIcon
              id="projectreport-layer-icon"
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            />
          )}

          <div className="flexBox">
            <Typography id="projectreport-report-cost" className="report-2">
              {t("RAL0387")}
              <span>
                {" $ "}
                {Math.floor(
                  ((project?.totalBilledCost || 0) +
                    (project?.totalNonBillableCost || 0)) *
                    100
                ) / 100}
              </span>
            </Typography>

            <Typography id="projectreport-report-billedcost" className="report-2" sx={{ marginTop: "-2px" }}>
              {t("RAL0211")}
              <span>
                {" $ "}
                {project?.totalBilledCost
                  ? Math.floor(project?.totalBilledCost * 100) / 100
                  : 0}
              </span>
            </Typography>
            <Typography id="projectreport-report-nonbilledcost" className="report-2" sx={{ marginTop: "-2px" }}>
              {t("RAL0212")}
              <span
                className={css`
                  font: normal normal normal 14px/16px GothamMedium !important;
                  letter-spacing: 0px;
                  color: var(--text-primary);
                  opacity: 1;
                `}
              >
                {" $ "}
                {project?.totalNonBillableCost
                  ? Math.floor(project?.totalNonBillableCost * 100) / 100
                  : 0}
              </span>
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={cost}
              bgcolor="#FFAA00"
              barcolor="#D55D38"
            />

            <Box
              display={"flex"}
              flexDirection={"row"}
              height={"100%"}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography id="projectreport-report-billed-cost" className="report-2">{t("RAL0211")}</Typography>
              </Box>
              <Box>
                <Typography id="projectreport-report-unbilled-cost" className="report-2">{t("RAL0212")}</Typography>
              </Box>
            </Box>
          </div>
        </Grid>
        <Grid item display={"flex"} justifyContent={"center"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              {...bindTrigger(popupMore)}
              sx={{
                "& .more_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .more_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              <IconButton className="projectReport3Dots">
                <MoreIcon id="projectreport-more-icon" className="more_svg" />
              </IconButton>
            </Box>
            <Box
              sx={{
                "& .up_down_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .up_down_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              {(!selectedProject ||
                selectedProject?.id !== project?.project?.id) && (
                <IconButton
                  className="projectReport3Dots"
                  onClick={() => {
                    setSelectedProject(project?.project);
                  }}
                >
                  <DownArrow id="projectreport-down-icon" className="up_down_svg" />
                </IconButton>
              )}
              {selectedProject &&
                selectedProject?.id === project?.project?.id && (
                  <IconButton
                    className="projectReport3Dots"
                    onClick={() => setSelectedProject(undefined)}
                  >
                    <UpArrow id="projectreport-up-icon" className="up_down_svg" />
                  </IconButton>
                )}
            </Box>
            <Popover
              {...bindPopover(popupMore)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                sx: {
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.12)",
                },
              }}
            >
              <Box
                onClick={() => {
                  handleClickOpen();
                }}
                py={1}
                px={2}
                className={css`
                  background: ${mode == "dark" ? "#101010" : "#ffffff"} 0% 0%
                    no-repeat padding-box;
                  color: ${mode == "dark" ? "#f8f8f8cc" : "#242424"};
                  opacity: 1;
                  cursor: pointer;
                `}
                id="btn-report-export"
              >
                {t("RAL0047")}
              </Box>
            </Popover>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullScreen={fullScreen}
              fullWidth={true}
            >
              <Box
                sx={{
                  backgroundColor:
                    mode === "light" ? "#ffffff" : "#101010 !important", // Inner dialog background color
                }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{ color: mode === "light" ? "#242424" : "#f8f8f8" }}
                >
                  {t("RAL0478")}
                </DialogTitle>
                <DialogContent width={"100%"} sx={{ height: "18.5rem" }}>
                  <Box py={2}>
                    <Typography
                      sx={{
                        color: mode === "light" ? "#242424cc" : "#f8f8f8cc",
                      }}
                      className={css`
                        font: normal normal normal 16px/28px Gotham;
                        letter-spacing: 0px;
                      `}
                      id="report-export-info"
                    >
                      {t("RAL0644")} <br/>
                      {t("RAL0645")}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Box>
                      <Typography
                        sx={{
                          color:
                            mode === "light"
                              ? "#242424 !important"
                              : "#F8F8F8 !important",
                              whiteSpace: 'nowrap', 
                              overflow: 'hidden', 
                              textOverflow: 'ellipsis',
                              marginTop:1.5,
                              marginRight:2
                        }}
                        className={css`
                          font: normal normal normal 16px/19px Gotham;
                          letter-spacing: 0px;
                          font-weight: 600;
                          margin-right: 0.6rem;
                        `}
                        id="report-export-daterange"
                      >
                        {t("RAL0480")}
                      </Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box className={`${mode === 'dark' ? "customDatePickerInputBoxBackGround" : ""}`}>
                          <CustomizedDatePickerReport
                            id="btn-start-clndr"
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                            value={startDate}
                          />
                        </Box>
                        <Box mx={1}> - </Box> {/* Added mx for spacing */}
                        <Box className={`${mode === 'dark' ? "customDatePickerInputBoxBackGround" : ""}`}>
                          <CustomizedDatePickerReport
                            id="btn-end-clndr"
                            onChange={(date) => {
                              setEndDate(date);
                            }}
                            value={endDate}
                          />
                        </Box>
                      </Box>
                      {!isStartDateValid && (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          pt={1}
                          pl={2}
                        >
                          <Box pr={1}>{<ErrorIcon />}</Box>
                          <Box>
                            <Typography className="helper-text">
                              {t("RAL0483")}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    py={1}
                    className={css`
                      border-radius: 5px;
                      opacity: 1;
                      display: flex;
                      align-items: center;
                      margin-bottom: 10px;
                    `}
                  >
                    <CustomCheckBox
                    id="projectreport-select-checkbox"
                      onChange={() =>
                        setIsIncludeMemberContribution(
                          !isIncludeMemberContribution
                        )
                      }
                      checked={isIncludeMemberContribution}
                    />

                    <Box display="flex" flexDirection={"column"} rowGap={0.5}>
                      <Typography
                        sx={{
                          color:
                            mode === "light"
                              ? "#242424 !important"
                              : "#F8F8F8 !important",
                        }}
                        className={css`
                          font: normal normal normal 16px/19px GothamMedium;
                          letter-spacing: 0px;
                        `}
                        id="projectreport-members-contribution"
                      >
                        {t("RAL0481")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} rowGap={1}>
                    <Box
                      px={2}
                      py={1}
                      sx={{
                        border: `1px solid ${
                          mode === "light"
                            ? "#eaebef !important"
                            : "#313131 !important"
                        }`,
                      }}
                      className={css`
                        border-radius: 5px;
                        opacity: 1;
                        display: flex;
                      `}
                    >
                      {/* <CustomCheckBox
                      // onChange={() => handleSelection(project?.id)}
                      // checked={checkProjectSelection(project?.id)}
                      /> */}
                      <Avatar
                        id={"project-report-avatar"}
                        className="projectAvatar"
                        sx={{
                          width: "46px",
                          height: "46px",
                          backgroundColor: (theme) =>
                            alpha(project?.project?.color, 0.15),
                        }}
                      >
                        <ProjectIcon
                          id={"project-report-icon"}
                          fill={project?.project?.color}
                          opacity={0.5}
                        />
                      </Avatar>
                      <Box display="flex" flexDirection={"column"} rowGap={0.5}>
                      <Tooltip title={project?.project?.name}>
                        <Typography
                          sx={{
                            color:
                              mode === "light"
                                ? "#242424 !important"
                                : "#F8F8F8 !important",
                                whiteSpace: "nowrap",       
                                overflow: "hidden",        
                                textOverflow: "ellipsis",
                                maxWidth: "400px",
                          }}
                          className={css`
                            font: normal normal normal 16px/19px GothamMedium;
                            letter-spacing: 0px;
                            opacity: 1;
                          `}
                          id={"project-report-project-name"}
                        >
                          {project?.project?.name}
                        </Typography>
                        </Tooltip>
                        <Tooltip title={project?.project?.clientName || ''}>
                        <Typography
                          sx={{
                            color:
                              mode === "light"
                                ? "#242424cc !important"
                                : "#f8f8f8cc !important",
                                whiteSpace: "nowrap",       
                                overflow: "hidden",        
                                textOverflow: "ellipsis",
                                maxWidth: "400px",
                          }}
                          className={css`
                            font: normal normal normal 16px/20px Gotham;
                            letter-spacing: 0px;
                          `}
                          id={"project-report-clientName"}
                        >
                          {project?.project?.clientName}
                        </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{
                    padding: "30px 24px",
                  }}
                >
                  <Box display={"flex"} columnGap={1}>
                    <CustomButton
                      place="sp-cancel"
                      id="btn-cancel-projectreport"
                      name="btn-cancel-projectreport"
                      variant="outlined"
                      size="large"
                      fullWidth="fullWidth"
                      height="60px"
                      minWidth="150px"
                      onClick={handleCancelButton}
                    >
                      <span id="btn-cancel-member">{t("RAL0177")}</span>
                    </CustomButton>
                    <CustomButton
                      id="btn-add-projectreport"
                      name="btn-add-projectreport"
                      variant="contained"
                      size="large"
                      fullWidth="fullWidth"
                      height="60px"
                      minWidth="150px"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <span id="btn-project-export">{t("RAL0047")}</span>
                    </CustomButton>
                  </Box>
                </DialogActions>
              </Box>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
      {selectedProject && selectedProject?.id === project?.project?.id && (
        <Grid maxWidth={"100%"} overflowY={"auto"}>
          <ProjectReportAccordion
            projectId={project.project.id}
          ></ProjectReportAccordion>
        </Grid>
      )}
      <CancelDialog
        open={isCancelDialogOpen}
        onClose={handleCloseCancelDialog}
        onConfirm={handleConfirmCancel}
        header={t("RAL0742")}
        message={t("RAL0743")}
      />
    </>
  );
}
