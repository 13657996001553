import React, { useState, useEffect } from "react";
import {
  Box,
  styled,
  InputBase,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import ImportExportIcon from "@mui/icons-material/ImportExport";
import DesignationService, {
  getDesignationById,
  designationList,
  createDesignation,
  updateDesignationAPI,
  downloadDesignationImportTemplate,
  importDesignations,
  softDeleteDesignation,
} from "../../services/DesignationService";

import ContentLayout from "../../components/layout-components/ContentLayout";
import CustomIconButton from "../../components/CustomIconButton";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as RefreshIconHoverDark } from "../../assets/images/refresh_icon_hover_dark.svg";
import { ReactComponent as RefreshIconHover } from "../../assets/images/refresh_icon_hover.svg";
import { ReactComponent as RefreshIconDark } from "../../assets/images/refresh-icon-dark.svg";
import { ReactComponent as AddIcon } from "../../assets/images/add-icon.svg";
import { ReactComponent as CloseIconImg } from "../../assets/images/close-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit_icon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import CustomButton from "../../components/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as EmptyProjectList } from "../../assets/images/ralvie/noProjects.svg";
import { ReactComponent as EmptyProjectListDark } from "../../assets/images/ralvie/noProjectsDark.svg";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveTwoTone";
import { ReactComponent as ArchiveIcon } from "../../assets/images/ralvie/archive.svg";
import { ReactComponent as ArchiveIconDark } from "../../assets/images/ralvie/archiveDark.svg";

import "./designation.scss";
import TagTextField from "../tags/TagTextField";
import ImportDialog from "../../components/ImportDialog";
import CustomSearchField from "../../components/CustomSearchField";
import AppContext from "../../config/AppContext";
import { useContext } from "react";
import ArchiveDialog from "../project/ArchiveDialog";

function Designation() {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isLargeScreen = useMediaQuery("(min-width: 1500px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1500px)"
  );
  const isSmallScreen = useMediaQuery("(max-width: 1199px)");
  const [isLoading, setIsLoading] = useState(false);
  const [designations, setdesignations] = useState([]);
  const [newDesignationName, setNewDesignationName] = useState("");
  const [add, setAdd] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedDesignationId, setSelectedDesignationId] = useState(null);
  const [editDesignation, setEditDesignation] = useState("");
  const [editDesignationName, setEditDesignationName] = useState("");
  const [editingDesignationId, setEditingDesignationId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [selectedDesigntionId, setSelectedDesigntionId] = useState(null);
  const [dialogActionType, setDialogActionType] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [originalDesignationName, setoriginalDesignationName] = useState("");

  const { mode } = useContext(AppContext);

  const [filter, setFilter] = useState({
    id: "",
    name: "",
    toggleKeyToURL: true,
  });

  useEffect(() => {
    loadDesignations();
  }, [filter]);

  const loadDesignations = () => {
    setIsLoading(true);
    setdesignations([]);
    const payload = filter.name ? filter : { name: "" };
    designationList(payload).then((res) => {
      if (res?.data?.code === "RCI0000") {
        setdesignations(res.data.data.designations);
      }
      setIsLoading(false);
    });
  };

  const [newDesignation, setNewDesignation] = useState({
    id: "",
    name: "",
  });

  const [error, setError] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const payload1 = {
      pageNumber: 1,
      pageSize: 1000,
    };

    designationList(payload1)
      .then((res) => {
        if (res.data.code === "RCI0000") {
          setdesignations(res.data.data.designations);
        }
      })
      .catch((err) => {
        console.error("Error loading designations:", err);
      });
  };

  const resetFilter = () => {
    setFilter({ name: "" });
    setNewDesignation({
      id: "",
      name: "",
    });
    setEditingDesignationId(null);
    setClearSearch(true);
  };

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      setFilter({ name: value });
    } else {
      setFilter({ name: "" });
      setClearSearch(true);
    }
  };

  const handleAddClick = () => {
    if (editingDesignationId) {
      setEditingDesignationId(null); 
      setEditDesignationName("");   
      setError("");
  }
  setAdd(true);
  };
  const handleOpen = (id) => {
    setSelectedDesignationId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDesignationId(null);
  };

  const handleCloseDialog = () => {
    setOpenArchiveDialog(false);
  };

  const handleArchiveOpenDesignation = (id) => {
    setSelectedDesigntionId(id);
    setOpenArchiveDialog(true);
    setDialogActionType("archive");
  };

  const handleArchiveDesignation = async () => {
    try {
      if (selectedDesigntionId) {
        const response = await softDeleteDesignation(selectedDesigntionId);

        if (response.data?.code === "RCI0000") {
          setdesignations((prevDesignations) =>
            prevDesignations.filter(
              (desgn) => desgn.id !== selectedDesigntionId
            )
          );
          toast.success(t("RAL0187"));
          handleCloseDialog();
        } else {
          toast.error(response.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      width: "100%",
      borderRadius: 4,
      position: "relative",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: isXlScreen ? "12px 26px 12px 12px" : "9px 26px 7px 12px",
      backgroundColor: "transparent !important",
      opacity: 1,
      font: "normal normal normal 16px/20px Gotham",
      color: "#242424",
    },
  }));

  const NoDesignations = () => {
    return (
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        marginTop={"150px"}
      >
        <Box item md="12">
          {mode == "dark" ? <EmptyProjectListDark id="designation-empty-list-icon" /> : <EmptyProjectList id="designation-empty-list-icon"/>}
        </Box>

        <Typography id="designation-empty-list-message" variant="h6" className="noProjectError">
          {isSearchMode ? t("RAL0398") : t("RAL0399")}
        </Typography>
      </Box>
    );
  };

  // const handleImportDesignations = (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   setIsLoading(true);
  //   importDesignations(formData).then((res) => {
  //     setIsLoading(false);
  //     if (res.data?.code === "RCI0000") toast.success(t("RAL0376"));
  //     else toast.error(res.data.message);
  //     resetFilter();
  //   });
  // };

  const handleClearSearch = () => {
    setFilter({ name: "" });
    setClearSearch(true);
  };

  const actions = (
    <Box display="flex">
      <Box item pr={2.5}>
        <CustomSearchField
          width= "22vw"
          id="btn-search-designation"
          name="btn-search-tag"
          clearSearch={clearSearch}
          // onChange={() => setClearSearch(false)}
          onChange={(newValue) => handleSearch(newValue)}
          onClear={handleClearSearch}
          label={t("RAL0365")}
          value={filter.name}
          size={isXlScreen ? "large" : "md"}
          onClick={(value) => {
            handleSearch(value);
          }}
        />
      </Box>

      {/* <Box item pr={2}>
        <ImportDialog
          title={"Import designations"}
          downloadTemplate={() => downloadDesignationImportTemplate()}
          onImport={(file) => handleImportDesignations(file)}
        />
      </Box> */}

      <Box
        item
        pr={2.5}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onMouseOver={() => {
          setIsHovered(true);
        }}
      >
        {isHovered ? (
          <CustomIconButton
            id="btn-refresh-designation"
            title={t("RAL0169")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              mode == "light" ? (
                <RefreshIconHover height={isXlScreen ? "18px" : "14px"} />
              ) : (
                <RefreshIconHoverDark height={isXlScreen ? "18px" : "14px"} />
              )
            }
            darkMode={
              mode == "light" ? (
                <RefreshIconHover height={isXlScreen ? "18px" : "14px"} />
              ) : (
                <RefreshIconHoverDark height={isXlScreen ? "18px" : "14px"} />
              )
            }
            loadDesignations={loadDesignations}
            onClick={() => resetFilter()}
          />
        ) : (
          <CustomIconButton
            id="btn-refresh-designation"
            title={t("RAL0169")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              mode == "light" ? (
                <RefreshIcon height={isXlScreen ? "18px" : "14px"} />
              ) : (
                <RefreshIconDark height={isXlScreen ? "18px" : "14px"} />
              )
            }
            darkMode={
              mode == "light" ? (
                <RefreshIcon height={isXlScreen ? "18px" : "14px"} />
              ) : (
                <RefreshIconDark height={isXlScreen ? "18px" : "14px"} />
              )
            }
            onClick={() => resetFilter()}
          />
        )}
      </Box>

      <CustomIconButton
        id="btn-add-designation"
        title={t("RAL0170")}
        value={newDesignationName}
        variant="square"
        size={isXlScreen ? "large" : "md"}
        type="contained"
        border={"none"}
        lightMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
        darkMode={<AddIcon height={isXlScreen ? "18px" : "14px"} />}
        onClick={handleAddClick}
        onChange={(e) => setNewDesignationName(e.target.value)}
      />
    </Box>
  );
  /////////////////////////ADD DESIGNATION FUNCTIONALITIES/////////////////////////////////////////
  const handleAddChange = (e) => {
    const value = e.target.value;
    setNewDesignation({ ...newDesignation, name: value });
    if (/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)) {
      setError(t("RAL0473"));
    } else {
      setError("");
    }
  };

  const resetOnAdd = () => {
    toast.success(t("RAL0185"));
    setAdd(false);
    setError("");
    setNewDesignation({ name: "" });
    loadData();
  };

  const handleAddDesignation = () => {
    if (!newDesignation.name) {
      setError(t("RAL0001"));
      return;
    }
    const payload = {
      name: newDesignation.name,
    };

    createDesignation(payload).then((response) => {
      if (response.data?.code !== "RCI0027") {
        setError(response.data?.message);
      } else {
        resetOnAdd();
      }
    });
  };

  const addDesignation = () => {
    return (
      <>
        <Grid
          item
          xs={isLargeScreen ? 3 : isMediumScreen ? 4 : isSmallScreen ? 6 : 12}
        >
          <Grid
            item
            // md="2.9"
            // sm="3.8"
            // xs="12"
            className= {error ? "error-designation-box" : "designation-box"}
            // mr={2}
            // mb={1}
          >
            <Box
              // mt={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
              // marginLeft={2}
              // width={300}
              width={"100% !important"}
              height="60px"
              spacing={3}
              top="188px"
              left="360px"
              borderRadius="5px"
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                className={"new-designation " + (error ? "error-box" : "")}
                justifyContent={"center"}
                flexDirection={"row-reverse"}
              >
                <Box
                  display={"flex"}
                  flexGrow={1}
                  className="designation-name"
                  alignItems={"center"}
                >
                  <Box item flexGrow={1}>
                    <TagTextField
                      autoFocus
                      id="text-desig-name"
                      label={t("RAL0183")}
                      error={!!error}
                      helperText={error}
                      onChange={handleAddChange}
                      inputProps={{ maxLength: 25 }}
                    />
                  </Box>

                  <Box item pr={2}>
                    <CustomButton
                      disabled={error || !newDesignation.name}
                      id="btn-add-designation"
                      size="small"
                      variant="contained"
                      onClick={handleAddDesignation}
                    >
                      {t("RAL0170")}
                    </CustomButton>
                  </Box>

                  <Box
                    item
                    sx={{
                      height: "20px",
                      width: "20px",
                      background: "#FE5050 0% 0% no-repeat padding-box",
                      borderRadius: "4px",
                      opacity: "1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAdd(false);
                      setError("");
                      setNewDesignation({ name: "" });
                    }}
                  >
                    <Tooltip id="tooltip-designation-close-text" title={t("RAL0177")}>
                      <CloseIconImg id="icon-designation-close" style={{ fill: "white" }} />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
            {error && (
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{ position: "relative", top: "15px" }}
              >
                <Box item pr={1} pt={0.2}>
                  {<ErrorIcon id="designation-error-icon" height={14} width={16} />}
                </Box>
                <Box item>
                  <Typography id="designation-error-message" className="helper-text">{error}</Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  //calling the addDesignation function to render
  {
    add && addDesignation();
  }

  ////////////////////////////EDIT DESIGNATION FUNCTIONALITIES//////////////////////////////////
  const handleEditDesignation = (designation) => {
    setAdd(false); 
    setEditingDesignationId(designation.id);
    setEditDesignationName(designation.name);
    setoriginalDesignationName(designation.name);

  };

  // const handleEditChange = (e) => {
  //   setEditDesignationName(e.target.value);
  // };
  const handleEditChange = (e) => {
    const value = e.target.value;
    setEditDesignationName(value);

    // Validation logic
    if (/^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)) {
      setError(t("RAL0473"));
    } else {
      setError("");
    }
  };

  const resetOnEdit = () => {
    toast.success(t("RAL0186"));
    setEditingDesignationId(null);
    setError("");
    setEditDesignationName("");
    loadData();
  };

  const handleEditSubmit = () => {
    if (!editDesignationName) {
      setError(t("RAL0001"));
      return;
    }

    const payload = {
      name: editDesignationName,
    };

    updateDesignationAPI(editingDesignationId, payload)
      .then((response) => {
        if (response.data?.code !== "RCI0028") {
          setError(response.data?.message);
        } else {
          resetOnEdit();
        }
      })
      .catch((error) => {
        console.error("Error updating designation:", error);
        setError(t("RAL0476"));
      });
  };

  const designation = (data) => {
    const isEditing = editingDesignationId === data.id;

    return (
      <Grid
        item
        // md="2.9"
        // sm="3.8"
        // xs="12"
        className= {error ? "error-designation-box" : "designation-box"}
        // mr={2}
        // mb={1}
      >
        <Box
          // mt={3}
          display="flex"
          alignItems="center"
          className={"designation" + (isEditing && error ? " error-box" : "")}
          justifyContent="center"
          // marginLeft={2}
          // width={300}
          width={"100% !important"}
          height="60px"
          spacing={3}
          top="188px"
          left="360px"
          borderRadius="5px"
          sx={{
            backgroundColor: isEditing
              ? "var(--background-color-19)"
              : "var(--background-color-18)",
          }}
        >
          <Box width="70%" p={"5px"} m={"5px"} flexGrow={1}>
            {isEditing ? (
              <TagTextField
                id="text-desig-name"
                label={t("RAL0477")}
                value={editDesignationName}
                onChange={handleEditChange}
                inputProps={{ maxLength: 25 }}
              />
            ) : (
              <Typography
                id="text-desig-editDesignationName"
                sx={{
                  font: "normal normal 16px/20px Gotham !important",
                  wordBreak: "break-word",
                }}
                variant="body1"
                color="var(--text-secondary)"
                textAlign="left"
              >
                {data?.name}
              </Typography>
            )}
          </Box>

          <Box display={"flex"} flexGrow={1} alignItems={"center"}>
            {isEditing ? (
              <>
                <Box>
                  <CustomButton
                    id="button-designation-submit"
                    disabled={error || !editDesignationName || editDesignationName === originalDesignationName}

                    size="small"
                    variant="contained"
                    onClick={handleEditSubmit}
                  >
                    {t("RAL0309")}
                  </CustomButton>
                </Box>
                {/* <Box
                  onClick={handleEditSubmit}
                  sx={{
                    height: "20px",
                    width: "20px",
                    background: "#0FAA51 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    opacity: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                >
                  <DoneIcon sx={{ p: "2px" }} />
                </Box> */}
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    background: "#FE5050 0% 0% no-repeat padding-box",
                    borderRadius: "4px",
                    opacity: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    // marginTop: "10px",
                    marginInline: "15px",
                  }}
                  onClick={() => {
                    setEditingDesignationId(null);
                    setError("");
                  }}
                >
                  <Tooltip id="tooltip-designation-close" title={t("RAL0177")}>
                    <CloseIconImg id="designation-close-icon-img" style={{ fill: "white" }} />
                  </Tooltip>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ marginLeft: "20px" }}>
                  <Tooltip id="tooltip-designation-edit" title={t("RAL0172")}>
                    <IconButton
                      sx={{ borderRadius: "5px" }}
                      onClick={() => handleEditDesignation(data)}
                    >
                      {mode === "dark" ? (
                        <EditIconDark id="btn-edit-designation" />
                      ) : (
                        <EditIcon id="btn-edit-designation" />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Tooltip id="tooltip-designation-archive" title={t("RAL0173")}>
                    <IconButton sx={{ borderRadius: "5px" }}>
                      {mode == "dark" ? (
                        <ArchiveIconDark
                          id="btn-edit-archive"
                          onClick={() => handleArchiveOpenDesignation(data.id)}
                        />
                      ) : (
                        <ArchiveIcon
                          id="btn-edit-archive"
                          onClick={() => handleArchiveOpenDesignation(data.id)}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )}
          </Box>
        </Box>

        {isEditing && error && (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ position: "relative", top: "15px" }}
          >
            <Box item pr={1} pt={0.2}>
              {<ErrorIcon id="btn-error-icon" height={14} width={16} />}
            </Box>
            <Box item>
              <Typography id="designation-error-icon-message" className="helper-text">{error}</Typography>
            </Box>
          </Box>
        )}
      </Grid>
    );
  };

  return (
    <>
      <ContentLayout
        id="header-text-designation-name"
        title={t("RAL0167")}
        type="form"
        actions={actions}
        navBack={false}
        subtitle={t("RAL0181")}
      >
        {/* Conditional rendering for adding a new Designation */}
        {/* {add && addDesignation()} */}

        <Grid container spacing={3}>
          {isLoading ? (
            <Box
              display={"flex"}
              height={"100%"}
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              marginTop={"150px"}
            >
              <CircularProgress id="designation-circular-icon"/>
            </Box>
          ) : designations.length > 0 || add ? (
            <>
              {/* Conditional rendering for adding a new Designation */}
              {add && addDesignation()}

              {designations.map((t, index) => (
                <Grid
                  item
                  xs={
                    isLargeScreen
                      ? 3
                      : isMediumScreen
                      ? 4
                      : isSmallScreen
                      ? 6
                      : 12
                  }
                  key={index}
                >
                  {/* Render individual designation component */}
                  {designation(t)}
                </Grid>
              ))}
            </>
          ) : (
            <NoDesignations />
          )}
        </Grid>
        <ArchiveDialog
          open={openArchiveDialog}
          onClose={handleCloseDialog}
          onConfirm={
            dialogActionType === "complete" ? "" : handleArchiveDesignation
          }
          name={t("RAL0670")}
          type="Designation"
        />
      </ContentLayout>
    </>
  );
}

export default Designation;
