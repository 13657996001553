import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { css } from "@emotion/css";
import CustomButton from "../../components/CustomButton";
import AppContext from "../../config/AppContext";
import CancelDialog from "../project/CancelDialog";
import { useTranslation } from "react-i18next";
import { sidebarContext } from "../../util/context";

import CloseIcon from "@mui/icons-material/Close";
export default function AiIntroduction(props) {
  const { mode } = useContext(AppContext);
  const { enableAi } = useContext(sidebarContext);
  const { t } = useTranslation();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    props.handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        open={props.open}
        onClose={() => props.handleClose()}
      >
        <Box
          sx={{
            backgroundColor:
              mode === "dark" ? "#1f1f1f !important" : "#ffffff !important",
          }}
        >
          <DialogTitle
            id="ai-activity-text"
            className={css`
              font: normal normal normal 20px/23px GothamBold !important;
              letter-spacing: 0px;
              color: ${mode === "light"
                ? "#242424 !important"
                : "#f8f8f8cc !important"};
              opacity: 1;
            `}
          >
            {t("RAL0625")}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => props.handleClose()}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              height: "65vh",
            }}
            // className={css`
            //   overflow-y: auto;
            //   scrollbar-width: thin;
            //   scrollbar-color: var(--background-color-38) transparent;
            //   &::-webkit-scrollbar {
            //     width: 8px;
            //     background-color: transparent;
            //   }
            //   &::-webkit-scrollbar-thumb {
            //     border-radius: 10px;
            //   }
            // `}
          >
            <iframe
              id="ai-activity-file"
              className={css`
                margin: 16px 0px;
                border-radius: 5px;
              `}
              width="540px"
              height="220px"
              src="https://www.youtube.com/embed/0TUdTZQsHaI"
              title={t("RAL0626")}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            <DialogContentText>
              <Typography
                id="btn-ai-activity-info"
                className={css`
                  font: normal normal normal 16px/28px Gotham !important;
                  letter-spacing: 0px;
                  color: ${mode === "light"
                    ? "#242424 !important"
                    : "#f8f8f8cc !important"};
                  opacity: 1;
                `}
              >
                {t("RAL0627")}
                {t("RAL0628")}
                {t("RAL0629")}
                <br></br>
                <br></br>
                {t("RAL0630")}
                {t("RAL0631")}
                <br></br>
                <br></br>
                {t("RAL0632")}
                {t("RAL0633")}
                {t("RAL0634")}
                {/* {t("RAL0635")} */}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            className={css`
              width: 96%;
              justify-content: flex-end;
              margin: 8px;
              gap: 8px;
            `}
          >
            {/* <CustomButton
              place="sp-cancel"
              id="btn-ai-activity-cancel"
              variant="outlined"
              size="large"
              onClick={handleCancelButton}
            >
              <span id="btn-ai-activity-cancel">{t("RAL0177")}</span>
            </CustomButton>
            <CustomButton
              variant="contained"
              size="large"
              id="btn-ai-activity-submit"
              onClick={() => props.onSubmit(!enableAi)}
            >
              <span id="btn-ai-activity-submit">{t("RAL0636")}</span>
            </CustomButton> */}
          </DialogActions>

          <CancelDialog
            open={isCancelDialogOpen}
            onClose={handleCloseCancelDialog}
            onConfirm={handleConfirmCancel}
            header={t("RAL0395")}
            message={t("RAL0396")}
          />
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
