import { css } from "@emotion/css";
import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MyProfile } from "../../assets/images/ralvie/MyProfile.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/ralvie/logout-icon.svg";
import { ReactComponent as LanguageIcon } from "../../assets/images/ralvie/language-icon.svg";
import { ReactComponent as LanguageArrowIcon } from "../../assets/images/ralvie/language-arrow-icon.svg";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";
import { sidebarContext } from "../../util/context";
import "./Profile.scss";
import "./UserNavSubMenu.scss";
import { revokeToken } from "../../services/OnboardService";
import { useTranslation } from "react-i18next";
import { ReactComponent as EnglishLogo } from "../../assets/images/ralvie/english-lang-icon.svg";
import { ReactComponent as ChaineseLogo } from "../../assets/images/ralvie/chainese-lang-icon.svg";
import { ReactComponent as SpanishLogo } from "../../assets/images/ralvie/spanish-lang-icon.svg";
import { ReactComponent as LanguageTickIcon } from "../../assets/images/ralvie/language-tick-icon.svg";
import i18n from "../../config/i18n";
import { CONSTANT } from "../../constants/SupportedLanguages";

function UserNavSubMenu({ popupState }) {
  const { mode } = useContext(AppContext);
  const navigate = useNavigate();
  const { userProfile, profilePic } = useContext(sidebarContext);
  const memberName = userProfile?.memberName
    ? userProfile?.memberName
    : CommonUtil.getLoggedUser()?.firstName;
  const memberEmail = CommonUtil.getLoggedUser()?.email;
  const { t } = useTranslation();

  const storedLanguage =
    localStorage.getItem(CONSTANT.LOCAL_STORAGE_LANG_KEY) || "en";
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
  const [langClick, setLangClick] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageMenuOpen = (event) => {
    setLangClick(true);
    setAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
    setLangClick(false);
  };

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem(CONSTANT.LOCAL_STORAGE_LANG_KEY, language);
    setLangClick(false); 
    handleLanguageMenuClose();
  };

  return (
    <>
      <div className={`flexDirectionStyle userSettings `}>
        <Box
          sx={{ height: 70, width: 360, flexDirection: "row" }}
          container
          display={"flex"}
          alignItems="center"
          px={3}
          pt={3}
          mb={3}
        >
          <Box item>

            <Avatar
              id={"user-nav-submenu-avatar"}
              sx={{ height: 60, width: 60 }}
              src={
                profilePic ||
                "../../assets/images/ralvie/user_profile_default.svg"
              }
            />
          </Box>
          <Box item sx={{ marginLeft: "20px", width: 250 }}>
            {memberName.length > 14 ? (
              <Tooltip title={memberName} placement="top" arrow>
                <Typography
                  id={"user-nav-submenu-memberName"}
                  className="text-truncate"
                  sx={{
                    font: "normal normal bold 16px/19px Gotham",
                    textTransform: "capitalize",
                    marginBottom: "10px",
                    color: mode === "dark" ? "#f8f8f8cc" : "#4F4F4F",
                  }}
                >
                  <span>{`${memberName.substring(0, 14)}...`}</span>
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                id={"user-nav-submenu01-memberName"}
                className="text-truncate"
                sx={{
                  font: "normal normal bold 16px/19px Gotham",
                  textTransform: "capitalize",
                  marginBottom: "10px",
                  color: mode === "dark" ? "#f8f8f8cc" : "#4F4F4F",
                }}
              >
                {memberName}
              </Typography>
            )}

            {memberEmail.length > 14 ? (
              <Tooltip title={memberEmail} placement="bottom" >
                <Typography
                  id={"user-nav-submenu-memberEmail"}
                  mr={3}
                  className="text-truncate"
                  sx={{
                    textTransform: "lowercase",
                    font: "normal normal normal 14px/19px Gotham",
                    color: mode === "dark" ? "#f8f8f880" : "#4F4F4F",
                  }}
                >
                  <span>{`${memberEmail.substring(0, 14)}...`}</span>
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                mr={3}
                id={"user-nav-submenu01-memberEmail"}
                className="text-truncate"
                sx={{
                  textTransform: "lowercase",
                  font: "normal normal normal 14px/19px Gotham",
                  color: mode === "dark" ? "#f8f8f880" : "#4F4F4F",
                }}
              >
                {memberEmail}
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          id="btn-profile-settings"
          onClick={() => {
            navigate("/userprofile");
            popupState.close();
          }}
          // mt={1}
          py={2}
          px={3}
          sx={{
            cursor: "pointer",
            // backgroundColor: mode === "dark" ? "#1f1f1f80" : "#FFFFFF",
            display: "flex",
            alignItems: "center",
            columnGap: 2,
            transition: "background-color 0.3s ease",
            "& .my-profile-svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#4F4F4F",
            },
            "&:hover": {
              backgroundColor:
                mode === "dark"
                  ? "var(--color-1-20)"
                  : "var(--background-color-28)",
              "& .hover-text, & .my-profile-svg": {
                color: mode === "dark" ? "#6A5FA2" : "#1D0B77",
              },
            },
          }}
        >
          <MyProfile className="my-profile-svg" />

          <Typography
            className={`hover-text`}
            sx={{
              marginLeft: "5px",
              font: "normal normal normal 18px/21px Gotham !important",
              lineHeight: "20px",
              fontFamily: "Gotham, sans-serif",
              color: mode === "dark" ? "var(--color-33-50)" : "#4F4F4F",
            }}
          >
            {t("RAL0369")}
          </Typography>
        </Box>

        <Box
          id="btn-language-settings"
          onClick={handleLanguageMenuOpen}
          // mt={1}
          py={2}
          px={3}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            columnGap: 2,
            transition: "background-color 0.3s ease",
            "& .my-language-svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#4F4F4F",
            },
            backgroundColor:
            langClick
              ? mode === "dark"
                ? "var(--color-1-20)"
                : "var(--background-color-28)"
              : "", 
              "& .hover-text, & .my-language-svg": {
                color:
                langClick
                  ? mode === "dark"
                    ? "#6A5FA2"
                    : "#1D0B77"
                  : "", 
              },
            "&:hover": {
              backgroundColor:
                mode === "dark"
                  ? "var(--color-1-20)"
                  : "var(--background-color-28)",
              "& .hover-text, & .my-language-svg": {
                color: mode === "dark" ? "#6A5FA2" : "#1D0B77",
              },
            },
          }}
        >
          <LanguageIcon className="my-language-svg" />

          <Typography
            className={`hover-text`}
            sx={{
              marginLeft: "2px",
              font: "normal normal normal 18px/21px Gotham !important",
              lineHeight: "20px",
              fontFamily: "Gotham, sans-serif",
              color: mode === "dark" ? "var(--color-33-50)" : "#4F4F4F",
            }}
          >
            {t("RAL0091")}
          </Typography>

          <Box sx={{ ml: "auto" }}>
            <LanguageArrowIcon />
          </Box>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLanguageMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              marginBottom: "60px", 
              transform: "translateY(-10px)",
              marginLeft: "5px",
            }}
          >
            <MenuItem
              onClick={() => changeLanguage("en")}
              className="user-language-menu-item"
              sx={{
                backgroundColor:
                  selectedLanguage === "en"
                    ? mode === "dark"
                      ? "var(--color-1-20)"
                      : "var(--color-1-20)"
                    : "transparent",
                color:
                  selectedLanguage === "en"
                    ? mode === "dark"
                      ? "#6A5FA2"
                      : "#1D0B77"
                    : "inherit",
                    mb: 1,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <EnglishLogo style={{ marginRight: "10px" }} />
                  <Typography
                    className={`hover-text`}
                    sx={{
                      marginLeft: "5px",
                      font: "normal normal normal 18px/21px Gotham !important",
                      lineHeight: "20px",
                      fontFamily: "Gotham, sans-serif",
                      color:
                        selectedLanguage === "en"
                          ? "#1D0B77"
                          : mode === "dark"
                          ? "var(--color-33-50)"
                          : "#4F4F4F",
                    }}
                  >
                    {t("RAL0647")}
                  </Typography>
                </Box>
                {selectedLanguage === "en" && (
                  <LanguageTickIcon className="user-language-tick" />
                )}
              </Box>
            </MenuItem>

            <MenuItem
              onClick={() => changeLanguage("zh")}
              className="user-language-menu-item"
              sx={{
                backgroundColor:
                  selectedLanguage === "zh"
                    ? mode === "dark"
                      ? "var(--color-1-20)"
                      : "var(--color-1-20)"
                    : "transparent",
                color:
                  selectedLanguage === "zh"
                    ? mode === "dark"
                      ? "#6A5FA2"
                      : "#1D0B77"
                    : "inherit",
                    mb: 1,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                width="220px"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <ChaineseLogo style={{ marginRight: "10px" }} />
                  <Typography
                    className={`hover-text`}
                    sx={{
                      marginLeft: "5px",
                      font: "normal normal normal 18px/21px Gotham !important",
                      lineHeight: "20px",
                      fontFamily: "Gotham, sans-serif",
                      color:
                        selectedLanguage === "zh"
                          ? "#1D0B77"
                          : mode === "dark"
                          ? "var(--color-33-50)"
                          : "#4F4F4F",
                    }}
                  >
                    {t("RAL0648")}
                  </Typography>
                </Box>
                {selectedLanguage === "zh" && (
                  <LanguageTickIcon className="user-language-tick" />
                )}
              </Box>
            </MenuItem>

            <MenuItem
              onClick={() => changeLanguage("es")}
              className="user-language-menu-item"
              sx={{
                backgroundColor:
                  selectedLanguage === "es"
                    ? mode === "dark"
                      ? "var(--color-1-20)"
                      : "var(--color-1-20)"
                    : "transparent",
                color:
                  selectedLanguage === "es"
                    ? mode === "dark"
                      ? "#6A5FA2"
                      : "#1D0B77"
                    : "inherit",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                width="220px"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <SpanishLogo style={{ marginRight: "10px" }} />
                  <Typography
                    className={`hover-text`}
                    sx={{
                      marginLeft: "5px",
                      font: "normal normal normal 18px/21px Gotham !important",
                      lineHeight: "20px",
                      fontFamily: "Gotham, sans-serif",
                      color:
                        selectedLanguage === "es"
                          ? "#1D0B77"
                          : mode === "dark"
                          ? "var(--color-33-50)"
                          : "#4F4F4F",
                    }}
                  >
                    {t("RAL0649")}
                  </Typography>
                </Box>
                {selectedLanguage === "es" && (
                  <LanguageTickIcon className="user-language-tick" />
                )}
              </Box>
            </MenuItem>
          </Menu>

        <Box
          id="btn-usr-signout"
          onClick={() => {
            revokeToken().then((res) => {
              CommonUtil.resetUserSession();
              navigate("/");
            });
          }}
          py={2}
          px={3}
          mb={1}
          sx={{
            cursor: "pointer",
            display: "flex",
            "& .logout-svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#4F4F4F",
            },
            "&:hover": {
              backgroundColor:
                mode === "light" ? "#FFEDED" : "var(--color-12-10)",
              "& .hover-text, & .logout-svg": {
                color:
                  mode === "dark" ? "#FE5050 !important" : "#FE5050 !important",
              },
            },
          }}
          columnGap={2}
          alignItems={"center"}
        >
          <LogoutIcon className="logout-svg" />
          <Typography
            id="btn-usr-signout"
            className={`hover-text ${css`
              font: normal normal normal 18px/21px Gotham !important;
              letter-spacing: 0px;
              color: ${mode === "dark"
                ? "var(--color-33-50)"
                : "#4F4F4F"} !important;
            `}`}
            // ml={-0.7}
          >
            {t("RAL0044")}
          </Typography>
        </Box>
      </div>
    </>
  );
}

export default UserNavSubMenu;
