import { css } from "@emotion/css";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Slider,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import CustomTextField from "../../components/CustomTextField";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";
import LanguageSelector from "../../components/LanguageSelector";
import { generateDaySummarize } from "../../services/AiService";
import dayjs from "dayjs";
import CommonUtil from "../../util/CommonUtils";
import { AiContext } from "../../util/context";
import { toast } from "sonner";

export default function GenerateSummary({logdate}) {
  const { t, i18n } = useTranslation();
  const { setGenerateSummary } = useContext(AiContext);

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    language: i18n.language || "en",
    wordCount: 200,
    // style: "",
    focusAreas: [],
    timeGranularity: "",
    style: t("RAL0678"),
    text: "",
    date: null,
  });

  const [errors, setErrors] = useState({ date: "" });

  useEffect(()=>{
    if(logdate){
      const parsedDate = new Date(logdate); 
      setState({
        ...state,
        date: parsedDate
      });
    }
  },[]);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      style: t("RAL0678") 
    }));
  }, [i18n.language]);

  const handleSubmit = () => {
   
    if (!state.date) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        date: t("RAL0637"),
      }));
      return;
    }

    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let dateTz = dayjs.tz(state.date, CommonUtil.getTimeZoneId());
    let startDate = dateTz.startOf("day");
    let endDate = dateTz.endOf("day");
    let payload = {
      ...state,
      startTime: startDate.isUTC()
        ? startDate.format()
        : startDate.utc().format(),
      endTime: endDate.isUTC() ? endDate.format() : endDate.utc().format(),
    };

    setIsLoading(true);
    setGenerateSummary(undefined);
    generateDaySummarize(payload)
      .then((res) => {
        if (res.data?.code === "RCI0000" && res.data?.data?.summary) {
          setGenerateSummary(processTextSummary(res.data?.data?.summary));
        } else if (res.data?.code === "RCE0102") {
          toast.error(t("RAL0638"));
        } else if (res.data?.code === "RCE0215") {
          toast.error(res.data?.message);
        } else {
          toast.error(t("RAL0508"));
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  function processTextSummary(text) {
    const summary = text?.replace(/\"/g, "").trim(); // Clean up the summary text
    const startIndex = summary.indexOf("Projects:");
    const cleanSummary = summary.substring(startIndex).trim();
    const lines = cleanSummary.split("\n"); // Split the summary into lines

    let htmlSummary = '<div class="summary">';
    lines.forEach((line) => {
      if (line.includes(":")) {
        const [label, content] = line.split(":", 2);
        htmlSummary += `<p><strong>${label.trim()}:</strong> ${content.trim()}</p>`;
      } else {
        htmlSummary += `<p>${line.trim()}</p>`;
      }
    });
    htmlSummary += "</div>";
    return htmlSummary;
  }

  const handleDateChange = (date) => {
    if (date === null || isNaN(date.valueOf())) {
      setState((prevState) => ({ ...prevState, date: null }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        date: t("RAL0637"),
      }));
    } else {
      setState((prevState) => ({ ...prevState, date }));
      setErrors((prevErrors) => ({ ...prevErrors, date: "" }));
    }
  };

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          margin-bottom: 20px;
          margin-top: 10px;
        `}
      >
        <Typography
          variant="header3"
          sx={{
            color: "var( --text-secondary-80)",
            font: "normal normal bold 18px/20px Gotham",
            letterSpacing: "0px",
          }}
        >
          {t("RAL0346")}
        </Typography>
        <Typography
          sx={{
            color: "var( --text-secondary-80)",
            font: "normal normal normal 16px/23px Gotham",
            letterSpacing: "0px",
          }}
        >
          {t("RAL0347")}
        </Typography>
      </div>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          margin-bottom: 20px;
          height: 70%;
          overflow-y: auto;
        `}
      >
        <LanguageSelector
          disabled={isLoading}
          onChange={(lang) => setState({ ...state, language: lang?.label })}
        />
        <CustomizedDatePicker
          required={true}
          id="btn-date-aisummary"
          name="btn-date-aisummary"
          label={t("RAL0348")}
          onChange={handleDateChange}
          value={state.date}
          error={Boolean(errors.date.length)}
          helperText={errors.date}
          disabled={isLoading}
        />

        {/* <div className="autocomplete-nopadding">
          <Autocomplete
            clearIcon={false}
            value={state.timeGranularity}
            onChange={(event, newValue) => {
              setState({ ...state, timeGranularity: newValue });
            }}
            options={["Full Day", "Morning", "Afternoon", "Evening"]}
            autoHighlight
            getOptionLabel={(option) => option || ""}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  font: `normal normal normal var(--font-size-14) / 26px Gotham`,
                }}
              >
                {option}
              </li>
            )}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label={t("RAL0349")}
                variant="outlined"
              />
            )}
            disabled={isLoading}
          />
        </div>
        <div className="autocomplete-nopadding">
          <Autocomplete
            multiple={true}
            clearIcon={false}
            value={state.focusAreas}
            onChange={(event, newValue) => {
              setState({ ...state, focusAreas: newValue });
            }}
            options={["Meetings", "Projects"]}
            autoHighlight
            getOptionLabel={(option) => option || ""}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  font: `normal normal normal var(--font-size-14) / 26px Gotham`,
                }}
              >
                {option}
              </li>
            )}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label={t("RAL0350")}
                variant="outlined"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option}
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    "& .MuiChip-label": {
                      font: `normal normal normal var(--font-size-14) / 26px Gotham`,
                    },
                  }}
                />
              ))
            }
            disabled={isLoading}
          />
        </div> */}
        <div className="autocomplete-nopadding">
          <Autocomplete
            clearIcon={false}
            value={state.style}
            onChange={(event, newValue) => {
              setState({ ...state, style: newValue });
            }}
            options={[t("RAL0678"), t("RAL0679"), t("RAL0680")]}
            autoHighlight
            getOptionLabel={(option) => option || ""}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  font: `normal normal normal var(--font-size-14) / 26px Gotham`,
                }}
              >
                {option}
              </li>
            )}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label={t("RAL0351")}
                variant="outlined"
              />
            )}
            disabled={isLoading}
          />
        </div>
        <div
          className={css`
            display: flex;
            flex-direction: column;
            row-gap: 8px;
          `}
        >
          <Typography
            sx={{
              color: "var( --text-secondary-80)",
              font: "normal normal normal 14px/17px Gotham;",
              letterSpacing: "0px",
              opacity: "0.8",
            }}
          >
            {t("RAL0352")}
          </Typography>
          <Slider
            sx={{
              ".MuiSlider-valueLabelOpen": {
                backgroundColor: "#fa9c2b !important",
                font: "normal normal normal 14px/17px Gotham;",
              },
              width: "85%", // Set width to 100% of its container
              margin: "0 auto", // Center it horizontal
            }}
            size="medium"
            defaultValue={state.wordCount}
            aria-label="Small"
            valueLabelDisplay="auto"
            min={50}
            max={300}
            color="secondary"
            onChange={(e, v) => setState({ ...state, wordCount: v })}
            disabled={isLoading}
          />
        </div>
      </div>
      {isLoading && (
        <Box
          sx={{
            display: isLoading ? "flex" : "none",
          }}
        >
          <CircularProgress
            style={{
              top: "50%",
              left: "85%",
              position: "fixed",
              zIndex: 999,
            }}
          />
        </Box>
      )}
      <CustomButton
        variant="contained"
        onClick={() => handleSubmit()}
        disabled={isLoading}
        height="auto"
      >
        {t("RAL0353")}
      </CustomButton>
    </div>
  );
}
