import React, { useEffect, useState } from "react";
import SignupLayout from "./SignupLayout";
import "./Signup.scss";
import "./CompanyIndustry.scss";
import { useTranslation } from "react-i18next";
import CustomSelectButtons from "../../components/CustomSelectButtons";
import { Grid } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";

export default function CompanyIndustry() {
  const { t } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();
  const emailFromState = location.state ? location.state.email : null;

  const [payload, setPayload] = useState({
    industry: "",
    email: emailFromState || "",
  });

  const [error, setError] = useState({
    industry: "",
  });

  const selected = [
    { id: 1, industry: "Agency", type: "Agency", text: t("RAL0426") },
    { id: 2, industry: "Consultancy", type: "Consultancy", text: t("RAL0427") },
    {
      id: 3,
      industry: "Software company",
      type: "Software company",
      text: t("RAL0428"),
    },
    {
      id: 4,
      industry: "Financial services",
      type: "Financial services",
      text: t("RAL0429"),
    },
    {
      id: 5,
      industry: "Construction",
      type: "Construction",
      text: t("RAL0430"),
    },
    { id: 7, industry: "Commerce", type: "Commerce", text: t("RAL0431") },
    { id: 7, industry: "IT", type: "IT", text: t("RAL0432") },
    { id: 8, industry: "Education", type: "Education", text: t("RAL0433") },
    { id: 9, industry: "Student", type: "Student", text: t("RAL0434") },
    { id: 10, industry: "Other", type: "Other", text: t("RAL0435") },
  ];

  const buttonWidths = [
    "width109",
    "width146",
    "width199",
    "width187",
    "width151",
    "width135",
    "width63",
    "width128",
    "width111",
    "width94",
  ];

  const handleSubmit = (e) => {
    navigate("/pages/select-workspace", {
      state: {
        industry: payload.industry,
        email: payload.email,
      },
    });
  };

  const handleSelectClick = (selectedIndustry) => {
    setPayload({
      ...payload,
      industry: selectedIndustry,
    });
  };

  const handleSkip = () => {
    navigate("/pages/select-workspace", {
      state: {
        email: payload.email,
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (payload.industry) {
        handleSubmit();
      } else {
        handleSkip();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [payload]);

  return (
    <>
      <SignupLayout>
        <div className="CompanyIndustry" onKeyDown={handleKeyDown} tabIndex={0}>
          <div className="w-100Email">
            <div>
              <span
                id="text-company-industry-info"
                className="company_industry_002"
              >
                {t("RAL0120")}
              </span>
            </div>

            <Grid sm={12} className="companyIndusBoxes">
              <CustomSelectButtons
                id="buttons-company-industry-select"
                place={"CompanyIndustry"}
                selected={selected}
                onClick={handleSelectClick}
                buttonWidths={buttonWidths}
              />
            </Grid>

            <div className="companyIndusBtns">
              <div className="companyTwoBtns">
                <CustomButton
                  variant="outlined"
                  size="large"
                  fullWidth="fullWidth"
                  id={"link-company-view-skip"}
                  onClick={() => navigate("/pages/select-workspace")}
                >
                  <span>{t("RAL0144")}</span>
                </CustomButton>
                <CustomButton
                  variant="contained"
                  size="large"
                  fullWidth="fullWidth"
                  id={"button-company-industry-submit"}
                  disabled={!payload.industry}
                  onClick={() => handleSubmit()}
                >
                  <span id={"text-company-industry-submit"}>
                    {t("RAL0126")}
                  </span>
                </CustomButton>
              </div>
            </div>
            {/* <Grid container item md="8" spacing={3} mb={1} mt={3} justifyContent={"space-between"}>
              <Grid container spacing={3} mb={1} mt={3}>
                <Grid item md={6} sm={3} xs={3}>
                </Grid>

                <Grid container item md={6} sm={12} xs={12} spacing={3}>
                  <Grid item md={6} sm={12} xs={12}>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </div>
        </div>
      </SignupLayout>
    </>
  );
}
