import axios from "axios";
import api from "../config/http-common";

export const rolesList = async () => {
  return api.securedAxiosApi().get("/roles");
};

export const getUser = async (id) => {
  return api.securedAxios().get("/user/" + id);
};

export const getUserById = async (id) => {
  return api.securedAxiosApi().get("/users/" + id);
};

export const resendActivationMail = async (id) => {
  return api.securedAxiosApi().get("/users/" + id + "/send_activation_mail");
};

export const userList = async (payload, pageNumber = 1, pageSize = 10000) => {
  let url = `/users?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if (payload?.toggleKeyToURL) {
    url += "&toggleKeyToURL=true";
  }
  if (payload?.status) {
    url += `&status=${payload.status}`;
  }
  if (payload?.name) {
    url += `&firstName=${encodeURIComponent(payload.name)}`;
  }
  if (payload?.email) {
    url += `&email=${payload.email}`;
  }
  if (payload?.department) {
    url += "&departments=" + payload.department.join(",");
  }
  if (payload?.designation) {
    url += "&designations=" + payload.designation.join(",");
  }
  if (payload?.includeArchived) {
    url += "&includeArchived=" + payload.includeArchived;
  }
  return api.securedAxiosApi().get(url);
};

export const inviteMember = async (payload) => {
  return api.securedAxios().post("/user/invite", payload);
};

export const updateMember = async (id, payload) => {
  return api.securedAxios().put("/user/" + id, payload);
};
export const updateUser = async (id, payload) => {
  return api.securedAxios().put("/users/" + id + "/details", payload);
};

export const uploadUserProfilePhotoById = async (id, payload) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
    },
  };
  return api.securedAxios().put(`/user/${id}/profile`, payload, config);
};

export const uploadUserProfilePhoto = async (url, payload) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
    },
  };
  return api.securedAxios().put(url, payload, config);
};

export const deleteUserProfilePhotoById = async (id) => {
  return api.securedAxios().delete(`/user/${id}/profile`);
};

export const activateMember = async (payload) => {
  return api.unsecuredAxios().put("/open/user/activate", payload);
};

export const activateMiotUser = async (email) => {
  return api.unsecuredAxios().put(`/open/miot/user/${email}/activate`);
};

export const clientList = async (payload) => {
  return api.securedAxiosApi().get(`/role/${payload.roleId}/users`);
};

export const listUserCompanies = async (payload) => {
  return api.securedAxios().post("/companies/me", payload);
};

export const switchCompany = async (companyId) => {
  return api.securedAxios().put(`/companies/${companyId}/switch`);
};

export const downloadUserImportTemplate = async () => {
  // return api.securedAxiosApi().get("/department/import-template");
  try {
    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        "/users/import-template",
      {
        responseType: "blob", // Important to specify the response type as blob
        headers: {
          Authorization: localStorage.getItem("token"),
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "member import_ralvie cloud.xlsx"); // Set the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export const importMembers = async (payload) => {
  const config = {
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
    },
  };
  return api.securedAxiosApi().post(`/users/import`, payload, config);
};

export const softDeleteMember = async (id) => {
  return api.securedAxiosApi().delete(`/users/${id}`);
};

export const teamLeadsList = async (
  payload,
  pageNumber = 0,
  pageSize = 1000
) => {
  let url = `/users/team_leads?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return api
    .securedAxios()
    .get(process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") + url);
};

export const updateUsersWithTeamLeads = async (payload) => {
  return api
    .securedAxios()
    .put(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +
        `/users/assign_teamleads`,
      payload
    );
};

export const batchMembersArchive = async (payload) => {
  return api
    .securedAxios()
    .delete(
      `${process.env.REACT_APP_SERVER_URL?.replace(
        "/web",
        "/api/v1"
      )}/users/archive`,
      { data: payload }
    );
};

export const batchMembersUnarchive = async (payload) => {
  return api
    .securedAxios()
    .put(
      `${process.env.REACT_APP_SERVER_URL?.replace(
        "/web",
        "/api/v1"
      )}/users/unarchive`,
      payload
    );
};
