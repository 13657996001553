import React, { useContext, useEffect, useRef, useState } from "react";
import SignupLayout from "./SignupLayout";
import { Box, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Signup.scss";
import "./ValidateOtp.scss";
import OnboardTextField from "../../components/OnboardTextField";
import OnboardService from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import CustomButton from "../../components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/edit_icon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/ralvie/edit_icon_dark.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/ralvie/info-icon.svg";
import { ReactComponent as InfoDarkIcon } from "../../assets/images/ralvie/info_dark_icon.svg.svg";
import { toast } from "sonner";
import AppContext from "../../config/AppContext";

export default function ValidateOtp() {
  const { t } = useTranslation();
  const [otp, setOtp] = useState(new Array(5).fill(""));
  const [otpSent, setOtpSent] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const resendOtpRef = useRef(resendOtp);
  let navigate = useNavigate();
  const location = useLocation();
  const emailFromState = location.state ? location.state.email : null;
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(false);
  const { mode } = useContext(AppContext);
  const otpInputRefs = useRef([]);

  const [encodedEmail, setEncodedEmail] = useState("");

  useEffect(() => {
    if (emailFromState) {
      const encoded = btoa(emailFromState);
      setEncodedEmail(encoded);
    }
  }, [emailFromState]);

  const [payload, setPayload] = useState({
    otpType: "VERIFY_EMAIL",
    status: "",
    code: emailFromState || "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    otpType: "",
    status: "",
    code: "",
    otp: "",
    password: "",
    confirmPassword: "",
    errorMessage: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setResendOtp(true);
      resendOtpRef.current = true;
    }, 60000);
    return () => clearTimeout(timer);
  }, [resendOtp]);

  const handleSendOtp = (e) => {
    setResendOtp(false);
    resendOtpRef.current = false;
    if (validate()) {
      const email = payload.code;
      OnboardService.sendOtpToVerifyEmail(email).then((res) => {
        if (res.data.code === "RCI0015") {
          toast.success(t("RAL0151"));
          setOtpSent(true);
          setTimeout(() => {
            setResendOtp(true);
            resendOtpRef.current = true;
          }, 60000);
        } else {
          toast.error(res.data?.message);
        }
      });
    }
  };

  const handleSubmit = (otpValue) => {
    var state = {
      ...payload,
      code: encodedEmail,
      otp: otpValue,
    };
    OnboardService.verifyOtp(state).then((res) => {
      if (res.data.code === "RCI0019") {
        setSuccess(true);
        setErrors(false);
        setError({ ...error, errorMessage: "" });
        setTimeout(() => {
          navigate("/pages/signup", { state: { email: payload.code } });
        }, 1500);
      } else {
        setErrors(true);
        setOtp(new Array(5).fill(""));
        const errorMessage =
          res.data.code === "RCE0101" ? t("RAL0153") : t("RAL0150");
        setError({
          ...error,
          errorMessage: errorMessage,
        });
        if (otpInputRefs.current[0]) {
          otpInputRefs.current[0].focus();
        }
        setTimeout(() => {
          setErrors(false);
        }, 1500);
      }
    });
  };

  const validate = () => {
    if (!payload.code) {
      setError({
        ...error,
        code: t("RAL0001"),
      });
      return false;
    }
    if (!CommonUtil.isValidEmail(payload.code)) {
      setError({
        ...error,
        code: t("RAL0073"),
      });
      return false;
    }
    return true;
  };

  const handleChangeOtp = (index, value) => {
    const newOtp = [...otp];
    if (value.length === 1) {
      newOtp[index] = value;
    } else if (value.length > 1) {
      const pastedValues = value.split("");
      pastedValues.forEach((char, idx) => {
        if (idx < otp.length) {
          newOtp[idx] = char;
        }
      });
    }
    setOtp(newOtp);

    const otpValue = newOtp.join("");
    setPayload({
      ...payload,
      otp: otpValue,
    });

    setError({
      ...error,
      otp: "",
      errorMessage: "",
    });
    setErrors(false);

    if (value.length === 0 && index > 0) {
      const previousIndex = index - 1;
      const previousInput = document.getElementById(
        `outlined-basic-${previousIndex}`
      );
      if (previousInput) {
        previousInput.focus();
      }
    } else if (value.length === 1 && index < 5) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(`outlined-basic-${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    const isOtpEntered = newOtp.every((digit) => digit !== "");
    if (isOtpEntered && otpValue.length === 5) {
      handleSubmit(otpValue);
    }
  };

  return (
    <>
      <SignupLayout>
        <div className="ValidateOtp">
          <div className="w-100Email">
            <div id="text-validate-user-onboard" className="mainHeading">{t("RAL0101")}</div>

            <div className="signup_email_sub_head">
              <span id="text-validate-user-onboardinfo">
                {t("RAL0019")}&nbsp;
                <span
                  id="link-validate-user-signin"
                  className="signup_email_verify_clickSignIn_005"
                  onClick={() => navigate("/")}
                >
                  {t("RAL0099")}
                </span>
              </span>
            </div>

            <div className="signup_email_sub_head">
              <span id="text-validate-user-onboarduserinfo">{t("RAL0102")}</span>&nbsp;
            </div>

            <div className="resEmail text-lowercase">
              <p
                style={{
                  paddingTop: CommonUtil.getOS() === "MacOS" ? "11px" : "0px",
                }}
              >
                {payload.code}&nbsp;&nbsp;
              </p>
              <span
                id="link-validate-user-email"
                className="signup_email_verify_014"
                onClick={() => navigate("/pages/verify-email")}
              >
                <Tooltip id="tooltip-validate-user-info" title={t("RAL0172")}>
                  {mode === "dark" ? <EditIconDark /> : <EditIcon />}
                </Tooltip>
              </span>
            </div>

            <Box className="otpInputs">
              {otp.map((value, index) => (
                <OnboardTextField
                  autoComplete={true}
                  className="otpInputsBoxes"
                  key={index}
                  id={`outlined-basic-${index}`}
                  validation="numeric"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]",
                    maxLength: 1,
                    style: { textAlign: "center" },
                  }}
                  errors={errors}
                  success={success}
                  firstField={index === 0}
                  suceessMessage={t("RAL0149")}
                  errorMessage={error.errorMessage}
                  onChange={(e) => handleChangeOtp(index, e.target.value)}
                  value={value}
                  inputRef={(el) => (otpInputRefs.current[index] = el)}
                  onPaste={(e) => {
                    e.preventDefault();
                    const pastedData = e.clipboardData.getData("text");
                    handleChangeOtp(index, pastedData);
                  }}
                />
              ))}
            </Box>

            {!resendOtp && !success && !errors && (
              <div>
                <div className="signup_email_verify_015">
                  <span id={"icon-validate-user-information"} className="signup_email_verify_017">
                    {mode === "dark" ? <InfoDarkIcon /> : <InfoIcon />}
                  </span>
                  &nbsp;&nbsp;
                  <span id={"text-validate-user-information"} className="signup_email_verify_016">
                    {t("RAL0146")} {t("RAL0147")}
                  </span>
                  &nbsp;
                </div>
              </div>
            )}

            <Grid item sm={12} mt={4}>
              <CustomButton
                id="btn_validate_user_otp"
                variant="contained"
                size="large"
                fullWidth
                disabled={!resendOtp}
                onClick={handleSendOtp}
              >
                <span id="text_validate_user_otpcode" className="signup_email_verify_submitbutton_012">
                  {t("RAL0105")}
                </span>
              </CustomButton>
            </Grid>
          </div>
        </div>
      </SignupLayout>
    </>
  );
}
