import React, { useEffect, useRef, useState } from "react";
import SignupLayout from "./SignupLayout";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Signup.scss";
import "./VerifyEmail.scss";
import OnboardTextField from "../../components/OnboardTextField";
import OnboardService from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function VerifyEmail() {
  const { t, ready } = useTranslation();
  let navigate = useNavigate();
  const [showSignInLink, setShowSignInLink] = useState(false);
  const emailRef = useRef(null);

  useEffect(() => {
    if (ready && emailRef.current) {
      emailRef.current.focus();
    }
  }, [ready]);

  const [payload, setPayload] = useState({
    otpType: "VERIFY_EMAIL",
    status: "",
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    otpType: "",
    status: "",
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = (e) => {
    if (validate()) {
      const email = payload.email;
      OnboardService.sendOtpToVerifyEmail(email).then((res) => {
        if (res.data.code === "RCI0015") {
          navigate("/pages/validate-email", {
            state: { email: payload.email },
          });
          toast.success(t("RAL0151"));
        } else if (res.data.code === "RCI0021") {
          navigate("/pages/signup", { state: { email: payload.email } });
        } else if (res.data.code === "RCE0068") {
          setError({ ...error, email: t("RAL0162") });
          setShowSignInLink(true);
        } else {
          toast.error(res.data?.message);
          setShowSignInLink(false);
        }
      });
    }
  };

  const validate = () => {
    if (!payload.email) {
      setError({
        ...error,
        email: t("RAL0001"),
      });
      return false;
    }
    if (!CommonUtil.isValidEmail(payload.email)) {
      setError({
        ...error,
        email: t("RAL0022"),
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setShowSignInLink(false);
    setPayload({
      ...payload,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <SignupLayout>
        <div className="VerifyEmail">
          <div className="w-100Email">
            <div>
              <span id="text-verify-emial-onboard" className="signup_email_verify_002">{t("RAL0119")}</span>
            </div>

            <div
              className="signup_email_verify_003"
              style={{ marginTop: "20px" }}
            >
              <span id="text-verify-email-info">
                {t("RAL0019")}&nbsp;
                <span
                  id="link-verify-email-signin"
                  className="signup_email_verify_clickSignIn_005"
                  onClick={() => navigate("/")}
                >
                  {t("RAL0099")}
                </span>
              </span>
            </div>

            <div
              className="signup_email_verify_004"
              style={{ marginTop: "30px" }}
            >
              <span id="text-verify-email-onboardinfo">{t("RAL0104")}</span>
            </div>

            <Grid item sm={12} pt={4.5} alignItems="center">
              <OnboardTextField
                id="textfield-verify-email-email"
                autoComplete={false}
                required
                fullWidth
                label={t("RAL0003")}
                value={payload.email}
                name="email"
                onChange={handleChange}
                error={error.email}
                helperText={error.email}
                onKeyDown={(e)=>handleKeyDown(e)}
                inputRef={emailRef}
                inputProps={{
                  maxLength: 36,
                  // placeholder: t("Enter your email"),
                  autoComplete: "off", 
                }}
                validation="email"
                showLinkAfterError={showSignInLink ? (<div className="signup_email_verify_clickSignIn_UserExists">
                  <span id="link-verify-email-login" onClick={() => navigate("/")}>
                    {t("RAL0006")}
                  </span>
                </div>) : null}
              />
            </Grid>

            {/* className="signup_email_verify_submitbutton_006" */}
            <Grid item sm={12} className="sendEmailOTP">
              <CustomButton
                id="btn_verify_email_onboard"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleSubmit}
              >
                <span id="text_verify_email_code" className="signup_email_verify_007">{t("RAL0107")}</span>
              </CustomButton>
            </Grid>
          </div>
        </div>
      </SignupLayout>
    </>
  );
}
