import React, { useCallback, useContext, useEffect, useState } from "react";
import ContentLayout from "../../components/layout-components/ContentLayout";
import CustomIconButton from "../../components/CustomIconButton";
import {
  Box,
  Tooltip,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  Button,
  TextField,
  Checkbox,
  AvatarGroup,
  Avatar,
  InputBase,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import CustomDataTable from "../../components/CustomDataTable";
import CommonUtil from "../../util/CommonUtils";
import {
  batchProjetsArchive,
  batchProjetsComplete,
  batchProjetsUnarchive,
  downloadProjectImportTemplate,
  exportProjects,
  importProjects,
  softDeleteProject,
  viewProjectList,
} from "../../services/ProjectService";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddIcon } from "../../assets/images/ralvie/add-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/EditIcon.svg";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as ExportIcon } from "../../assets/images/ralvie/export-icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/filter-icon.svg";
import CustomSearchField from "../../components/CustomSearchField";
import { toast } from "sonner";
import ImportDialog from "../../components/ImportDialog";
import { clientList, rolesList, userList } from "../../services/MemberService";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { ReactComponent as ArchiveIcon } from "../../assets/images/archive-icon.svg";
import { ReactComponent as ArchiveIconDark } from "../../assets/images/archive-icon-dark.svg";
import { ReactComponent as CompleteIcon } from "../../assets/images/ralvie/complete.svg";
import { ReactComponent as CompleteIconDark } from "../../assets/images/ralvie/complete_dark.svg";
import ArchiveDialog from "./ArchiveDialog";
import { ReactComponent as TableViewListIcon } from "../../assets/images/table-list-view.svg";
import ProjectFilter from "./ProjectFilter";
import UnarchiveDialog from "./UnarchiveDialog";
import AppContext from "../../config/AppContext";

export default function ProjectListView() {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [clientFilter, setClientFilter] = useState([t("RAL0272")]);
  const [members, setMembers] = useState([]);
  const [memberFilter, setMemberFilter] = useState([t("RAL0273")]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [clientSearch, setClientSearch] = useState("");
  const [memberSearch, setMemberSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openMenu, setOpenMenu] = useState(false);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const { mode } = useContext(AppContext);
  const role = CommonUtil.getRoleCode();
  const [clearSearch, setClearSearch] = useState(false);
  const [dialogActionType, setDialogActionType] = useState("");
  const SelectInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      width: "100%",
      borderRadius: 4,
      position: "relative",
      fontSize: 16,
      padding: isXlScreen ? "12px 26px 12px 12px" : "9px 26px 7px 12px",
      backgroundColor: "transparent !important",
      opacity: 1,
      font: "normal normal normal 16px/20px Gotham",
    },
  }));

  const getRowClassName = () => {
    return selectedRows.length > 0 ? "selectedCheckboxVisible" : "";
  };

  const handleSwitch = () => {
    navigate("/projects", {
      state: {
        filter: filter,
        memberFilter: memberFilter,
        clientFilter: clientFilter,
      },
    });
  };

  const handleProjectFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusChange = (status) => {
    setFilter(status);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-Uk", options);
  };

  const handleClientFilterChange = (clientName) => {
    if (clientName === t("RAL0272")) {
      setClientFilter([t("RAL0272")]);
    } else {
      let newClientFilter = [...clientFilter];

      if (clientFilter.includes(t("RAL0272"))) {
        newClientFilter = newClientFilter.filter(
          (name) => name !== t("RAL0272")
        );
      }

      if (newClientFilter.includes(clientName)) {
        newClientFilter = newClientFilter.filter((name) => name !== clientName);
      } else {
        newClientFilter.push(clientName);
      }

      // Ensure at least one client is selected, otherwise default to "All clients"
      if (newClientFilter.length === 0) {
        setClientFilter([t("RAL0272")]);
      } else {
        setClientFilter(newClientFilter);
      }
    }

    setSearch("");
    handleClose();
  };

  const handleMemberFilterChange = (memberId) => {
    if (memberId === t("RAL0273")) {
      setMemberFilter([t("RAL0273")]);
    } else {
      let newMemberFilter = [...memberFilter];

      if (memberFilter.includes(t("RAL0273"))) {
        newMemberFilter = newMemberFilter.filter((id) => id !== t("RAL0273"));
      }

      if (newMemberFilter.includes(memberId)) {
        newMemberFilter = newMemberFilter.filter((id) => id !== memberId);
      } else {
        newMemberFilter.push(memberId);
      }

      // Ensure at least one member is selected, otherwise default to "All members"
      if (newMemberFilter.length === 0) {
        setMemberFilter([t("RAL0273")]);
      } else {
        setMemberFilter(newMemberFilter);
      }
    }

    setSearch("");
    handleClose();
  };

  const location = useLocation();
  const { state } = location || {};

  useEffect(() => {
    if (state?.filter) {
      setFilter(state?.filter);
    }
    if (state?.memberFilter) {
      setMemberFilter(state?.memberFilter);
    }

    if (state?.clientFilter) {
      setClientFilter(state?.clientFilter);
    }
  }, []);

  const handleReset = () => {
    setClientFilter([t("RAL0272")]);
    setMemberFilter([t("RAL0273")]);
    setSearch("");
    setClientSearch("");
    setMemberSearch("");
    setProjects([]);
    setFilter("");
    setClearSearch(true);
    loadProjectData(page, rowsPerPage, "", "");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesResponse = await rolesList();
        if (rolesResponse.data.code === "UASI0000") {
          const clientRole = rolesResponse.data.data.roles.find(
            (role) => role.name === "Client"
          );
          if (clientRole) {
            const clientsResponse = await clientList({ roleId: clientRole.id });
            if (clientsResponse.data.code === "RCI0000") {
              const updatedClients = clientsResponse.data.data.map((user) => ({
                id: user.id,
                name: user.name,
              }));
              setClients(updatedClients);
            }
          }

          // Fetch user list and filter out users with the "Client" role
          const usersResponse = await userList();
          if (usersResponse.data.code === "UASI0000") {
            const updatedMembers = usersResponse.data.data.users
              .filter((user) => user.roleName !== "Client")
              .map((user) => ({
                id: user.id,
                name: user.memberName,
                photo: user.profilePhoto,
              }));
            setMembers(updatedMembers);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log(members);
  }, [members]);

  useEffect(() => {
    const filteredProjects = projects.filter((project) => {
      const computedStatus = computeProjectStatus(project);

      const projectNameIncludes =
        !search || project?.name?.toLowerCase().includes(search.toLowerCase());

      const statusMatches =
        !filter || computedStatus.toLowerCase() === filter.toLowerCase();
      const clientNameIncludes =
        clientFilter.includes(t("RAL0272")) ||
        clientFilter.some((name) =>
          project?.client?.name?.toLowerCase().includes(name.toLowerCase())
        );
      const memberNameIncludes =
        memberFilter.includes(t("RAL0273")) ||
        (project.assignedMembers &&
          project.assignedMembers.some((memberId) =>
            memberFilter.includes(memberId)
          ));
      return (
        projectNameIncludes &&
        statusMatches &&
        clientNameIncludes &&
        memberNameIncludes
      );
    });
    setFilteredProjects(filteredProjects);
  }, [search, clientFilter, filter, memberFilter, projects]);

  const getMemberName = (memberId) => {
    const member = members.find((member) => member.id === memberId);
    return member ? member.name : "Unknown";
  };

  const noResultsMessage = () => {
    if (search) {
      return t("RAL0284");
    } else if (memberFilter !== t("RAL0273") && clientFilter !== t("RAL0272")) {
      return t("RAL0541");
    } else if (memberFilter !== t("RAL0273")) {
      return t("RAL0542");
    } else if (clientFilter !== t("RAL0272")) {
      return t("RAL0285");
    }
    return t("RAL0286");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  useEffect(() => {
    loadProjectData(page, rowsPerPage, filter, search);
  }, [page, rowsPerPage, filter, search]);

  const loadProjectData = (pageNumber, pageSize, status, name) => {
    setIsLoading(true);
    viewProjectList({ pageNumber, pageSize }, status, name)
      .then((data) => {
        if (data?.data?.code === "RCI0000") {
          setRows(data?.data?.data?.projects);
          setProjects(data?.data?.data?.projects);
          setTotalRecords(data?.data?.data?.totalCount);
          setPage(data?.data?.data?.currentPage);
        } else {
          setRows([]);
          setProjects([]);
          setTotalRecords(0);
          setPage(1);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call error:", error);
        setIsLoading(false);
      });
  };

  // const handleImport = (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   setIsLoading(true);
  //   importProjects(formData).then((res) => {
  //     setIsLoading(false);
  //     if (res.data?.code === "RCI0000") toast.success(res.data.message);
  //     else toast.error(res.data.message);
  //     handleReset();
  //   });
  // };

  const handleClearSearch = () => {
    setSearch("");
    setClearSearch(true);
    setClearSearch(true);
    setFilteredProjects(projects);
  };

  const actions = (
    <Box display="flex" alignItems="center">
      {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
        <>
          {selectedRows.length > 0 && projects.length > 0 && (
            <Box pr={2}>
              {projects[0].status === "ARCHIVED" ? (
                <Box
                  sx={{
                    "& .unarchive_svg": {
                      transition: "color 0.3s ease",
                      color:
                        mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                    },
                    "&:hover": {
                      "& .hover-text, & .unarchive_svg": {
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                      },
                    },
                  }}
                >
                  <CustomIconButton
                    id="btn-unarchive-project"
                    title={t("RAL0535")}
                    size={isXlScreen ? "large" : "md"}
                    variant="square"
                    lightMode={
                      <ArchiveIcon
                        id="btn-unarchive-project"
                        className="unarchive_svg"
                        height={isXlScreen ? "18px" : "14px"}
                      />
                    }
                    darkMode={
                      <ArchiveIcon
                        id="btn-unarchive-project"
                        className="unarchive_svg"
                        height={isXlScreen ? "18px" : "14px"}
                      />
                    }
                    onClick={() => setOpenUnarchiveDialog(true)}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    "& .archive_svg": {
                      transition: "color 0.3s ease",
                      color:
                        mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                    },
                    "&:hover": {
                      "& .hover-text, & .archive_svg": {
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                      },
                    },
                  }}
                >
                  <CustomIconButton
                    id="btn-archive-project"
                    title={t("RAL0173")}
                    size={isXlScreen ? "large" : "md"}
                    variant="square"
                    lightMode={
                      <ArchiveIcon
                        id="btn-archive-project"
                        className="archive_svg"
                        height={isXlScreen ? "18px" : "14px"}
                      />
                    }
                    darkMode={
                      <ArchiveIcon
                        id="btn-archive-project"
                        className="archive_svg"
                        height={isXlScreen ? "18px" : "14px"}
                      />
                    }
                    onClick={() => setOpenArchiveDialog(true)}
                  />
                </Box>
              )}
            </Box>
          )}
        </>
      )}

      {!selectedRows.length && (
        <>
          <Box pr={2}>
            <CustomSearchField
              id="btn-search-project"
              name="btn-search-project"
              width="22vw"
              clearSearch={clearSearch}
              // onChange={() => setClearSearch(false)}
              onChange={(newValue) => setSearch(newValue)}
              onClear={handleClearSearch}
              label={t("RAL0270")}
              value={search}
              size={isXlScreen ? "large" : "md"}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setSearch(event.target.value);
                }
              }}
              onClick={(value) => {
                setClearSearch(false);
                setSearch(value);
              }}
            />
          </Box>

          <Box item pr={2}>
            <Box
              sx={{
                "& .filter_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .filter_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              <CustomIconButton
                id="btn-filter-project"
                title={t("RAL0224")}
                size={isXlScreen ? "large" : "md"}
                variant="square"
                lightMode={
                  <FilterIcon
                    id="btn-filter-project"
                    className="filter_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                darkMode={
                  <FilterIcon
                    id="btn-filter-project"
                    className="filter_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                onClick={handleProjectFilter}
              />
            </Box>
          </Box>

          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <>
              {/* <Box item pr={2}>
            <ImportDialog
              title={t("RAL0275")}
              downloadTemplate={() => downloadProjectImportTemplate()}
              onImport={(file) => handleImport(file)}
            />
          </Box> */}

              {/* <Box mr={1.5}>
            <CustomIconButton
              id="btn-custom-add-member"
              name="btn-custom-add-member"
              title={t("RAL0276")}
              size={isXlScreen ? "large" : "md"}
              variant="square"
              type="outlined"
              lightMode={<ExportIcon height={isXlScreen ? "18px" : "14px"} />}
              darkMode={<ExportIcon height={isXlScreen ? "18px" : "14px"} />}
              onClick={() => {
                projects?.length > 0
                  ? exportProjects()
                  : toast.error(t("RAL0277"));
              }}
            />
          </Box> */}
            </>
          )}

          <Box mr={2}>
            <Box
              sx={{
                "& .refresh_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .refresh_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              <CustomIconButton
                id="btn-refresh-project"
                title={t("RAL0169")}
                size={isXlScreen ? "large" : "md"}
                variant="square"
                lightMode={
                  <RefreshIcon
                    id="btn-refresh-project"
                    className="refresh_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                darkMode={
                  <RefreshIcon
                    id="btn-refresh-project"
                    className="refresh_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                onClick={() => handleReset()}
              />
            </Box>
          </Box>

          <Box item pr={2}>
            <Box
              sx={{
                "& .table_view_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .table_view_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              <CustomIconButton
                id="btn-switch-project"
                title={t("RAL0266")}
                size={isXlScreen ? "large" : "md"}
                variant="square"
                lightMode={
                  <TableViewListIcon
                    id="btn-switch-project"
                    className="table_view_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                darkMode={
                  <TableViewListIcon
                    id="btn-switch-project"
                    className="table_view_svg"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                onClick={handleSwitch}
              />
            </Box>
          </Box>

          {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
            <>
              {/* <Button
            id="btn-add-project"
            variant="contained"
            className="addButton"
            onClick={() => navigate("/projects/new")}
          >
            <AddIcon />
          </Button> */}
              <CustomIconButton
                id="btn-custom-add-project"
                name="btn-custom-add-project"
                title={t("RAL0361")}
                size={isXlScreen ? "large" : "md"}
                variant="square"
                type="contained"
                border={"none"}
                lightMode={
                  <AddIcon
                    id="btn-custom-add-project"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                darkMode={
                  <AddIcon
                    id="btn-custom-add-project"
                    height={isXlScreen ? "18px" : "14px"}
                  />
                }
                onClick={(view) =>
                  navigate("/projects/new", { state: { fromView: "list" } })
                }
              />
            </>
          )}
        </>
      )}
    </Box>
  );

  const handleEditProject = useCallback(
    (id, view) => {
      if (id) {
        navigate(`/projects/${id}`, { state: { fromView: view } });
        handleClose();
      }
    },
    [navigate]
  );

  const handleArchiveProject = (id) => {
    setSelectedRows([id]);
    setSelectedProjectId(id);
    setOpenArchiveDialog(true);
    setDialogActionType("archive");
  };

  const handleCompleteProject = (id) => {
    setSelectedRows([id]);
    setSelectedProjectId(id);
    setOpenArchiveDialog(true);
    setDialogActionType("complete");
  };

  const handleCloseDialog = () => {
    setOpenArchiveDialog(false);
  };

  const handleUnarchiveProject = (id) => {
    setSelectedRows([id]);
    setSelectedProjectId(id);
    setOpenUnarchiveDialog(true);
  };

  const handleCloseUnarchiveDialog = () => {
    setOpenUnarchiveDialog(false);
  };

  const handleRowSelection = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleConfirmCompleteProject = async () => {
    try {
      let projectIds =
        selectedRows.length > 0 ? selectedRows : [selectedProjectId];
      if (projectIds.length >= 1) {
        const payload = { ids: projectIds };
        const res = await batchProjetsComplete(payload);
        if (res.data?.code === "RCI0000") {
          loadProjectData(page, rowsPerPage, filter, search);
          toast.success(t("RAL0544"));
          setSelectedRows([]);
          handleCloseDialog();
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error(t("RAL0540"));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("RAL0543"));
    }
  };

  const handleConfirmArchive = async () => {
    try {
      let projectIds =
        selectedRows.length > 0 ? selectedRows : [selectedProjectId];
      if (projectIds.length === 1) {
        const response = await softDeleteProject(projectIds[0]);
        if (response.data?.code === "RCI0000") {
          loadProjectData(page, rowsPerPage);
          toast.success(t("RAL0583"));
          setSelectedRows([]);
          handleCloseDialog();
        } else {
          toast.error(response.data?.message);
        }
      } else if (projectIds.length > 1) {
        const payload = { ids: projectIds };
        const res = await batchProjetsArchive(payload);
        if (res.data?.code === "RCI0000") {
          loadProjectData(page, rowsPerPage);
          toast.success(t("RAL0583"));
          setSelectedRows([]);
          handleCloseDialog();
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error(t("RAL0540"));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("RAL0526"));
    }
  };

  const handleConfirmUnrchive = async () => {
    try {
      let projectIds =
        selectedRows.length > 0 ? selectedRows : [selectedProjectId];
      if (projectIds.length >= 1) {
        const payload = { ids: projectIds };
        const res = await batchProjetsUnarchive(payload);
        if (res.data?.code === "RCI0000") {
          loadProjectData(page, rowsPerPage);
          toast.success(t("RAL0539"));
          setSelectedRows([]);
          handleCloseUnarchiveDialog();
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error(t("RAL0540"));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("RAL0584"));
    }
  };

  const computeProjectStatus = (project) => {
    const currentDate = new Date();
    const { startDate, endDate, status } = project;

    if (status === "ARCHIVED") return "ARCHIVED";
    if (new Date(startDate) > currentDate) {
      return "YET TO START";
    }
    if (new Date(endDate) > currentDate && status !== "COMPLETED") {
      return "IN PROGRESS";
    }
    if (
      new Date(endDate) < currentDate &&
      status !== "COMPLETED" &&
      status !== "ARCHIVED"
    ) {
      return "OVERDUE";
    }
    return "COMPLETED";
  };

  const fixedColors = [
    "#FF5733",
    "#2C3E50",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#33FFA1",
    "#A133FF",
    "#FF5733",
    "#FFC300",
    "#DAF7A6",
    "#581845",
    "#900C3F",
    "#C70039",
    "#FF5733",
    "#FFBD33",
    "#33FFBD",
    "#57A1FF",
    "#FF33C4",
    "#33C4FF",
    "#C4FF33",
    "#8E44AD",
    "#3498DB",
    "#2ECC71",
    "#E74C3C",
    "#1ABC9C",
    "#F39C12",
    "#D35400",
    "#27AE60",
    "#2980B9",
    "#8E44AD",
  ];

  const getColorForMember = (identifier) => {
    const index = identifier.charCodeAt(0) % fixedColors.length;
    return fixedColors[index];
  };

  const columns = React.useMemo(() => [
    {
      field: "name",
      headerName: t("RAL0289"),
      sortable: false,
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={params.row.name}
          placement="bottom-start"
          disableInteractive
        >
          <span style={{ textTransform: "capitalize" }}>{params.row.name}</span>
        </Tooltip>
      ),
    },

    {
      field: "color",
      headerName: t("RAL0291"),
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip
            title={params.row.color}
            placement="bottom-start"
            disableInteractive
          >
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: params.row.color,
                marginRight: "35px",
                marginTop: "18px",
                borderRadius: "5px",
              }}
            />
          </Tooltip>
        </div>
      ),
    },

    {
      field: "client.name",
      headerName: t("RAL0585"),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const name = params.row.client ? params.row.client.name : "";
        const truncatedName = name.slice(0, 14);

        return (
          <Tooltip title={name} placement="bottom-start" disableInteractive>
            {truncatedName}
          </Tooltip>
        );
      },
    },

    {
      field: "startDate",
      headerName: t("RAL0586"),
      sortable: false,
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={formattedDate(params.row.startDate)}
          placement="bottom-start"
          disableInteractive
        >
          {formattedDate(params.row.startDate)}
        </Tooltip>
      ),
    },

    {
      field: "endDate",
      headerName: t("RAL0587"),
      sortable: false,
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={formattedDate(params.row.endDate)}
          placement="bottom-start"
          disableInteractive
        >
          {formattedDate(params.row.endDate)}
        </Tooltip>
      ),
    },

    {
      field: "tags",
      headerName: t("RAL0222"),
      sortable: false,
      headerClassName: "project-data-table datagrid-column-header",
      flex: 1,
      renderCell: (params) => {
        const assignedMembers = params.row.assignedMembers || [];

        const activeMembers = assignedMembers.filter((memberId) => {
          const member = members.find((m) => m.id === memberId);
          return member && member.status !== "ARCHIVED";
        });

        const memberAvatars = activeMembers.slice(0, 3).map((memberId) => {
          const member = members.find((m) => m.id === memberId);
          const memberName = member ? member.name : "Unknown";
          const profilePhoto = member?.photo;
          const backgroundColor = getColorForMember(memberName);

          return (
            <Tooltip title={memberName} key={memberId} placement="top" arrow>
              <Avatar
                id="project-image-avatar"
                src={profilePhoto}
                style={{
                  backgroundColor: !profilePhoto ? backgroundColor : undefined,
                }}
              >
                {!profilePhoto && memberName[0].toUpperCase()}
              </Avatar>
            </Tooltip>
          );
        });

        const additionalCount =
          activeMembers.length > 3 ? (
            <Tooltip
              title={`+${activeMembers.length - 3} ${t("RAL0672")}`}
              placement="top"
              arrow
            >
              <Avatar id="project-image-avatar">
                +{activeMembers.length - 3}
              </Avatar>
            </Tooltip>
          ) : null;

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
              marginLeft: "10px",
            }}
          >
            <AvatarGroup
              id="project-image-avatargroup"
              max={4}
              sx={{
                "& .MuiAvatar-root": {
                  width: 28,
                  height: 28,
                  fontSize: 15,
                  boxShadow: "0px 3px 6px #0000001A",
                },
              }}
            >
              {memberAvatars}
              {additionalCount}
            </AvatarGroup>
          </div>
        );
      },
    },

    {
      field: "costBudget",
      headerName: t("RAL0279"),
      sortable: false,
      flex: 1,
      width: 10,
      renderCell: (params) => (
        <Tooltip
          title={
            params.row.budgetType === "cost"
              ? `$ ${params.row.costBudget.toFixed(2)}`
              : `${params.row.timeBudget.toFixed(2)} hr`
          }
          placement="bottom-start"
          disableInteractive
        >
          <span>
            {params.row.budgetType === "cost"
              ? `$ ${params.row.costBudget.toFixed(2)}`
              : `${params.row.timeBudget.toFixed(2)} hr`}
          </span>
        </Tooltip>
      ),
    },

    {
      field: "status",
      headerName: t("RAL0267"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      width: 10,
      renderCell: (params) => {
        const status = computeProjectStatus(params.row);

        const statusClass =
          status === "COMPLETED"
            ? "project-complete"
            : status === "IN PROGRESS"
            ? "project-present"
            : status === "ARCHIVED"
            ? "project-archived"
            : status === "OVERDUE"
            ? "project-overtime"
            : status === "YET TO START"
            ? "project-future"
            : "";

        const formatStatus = (statusKey) => {
          const translationKeys = {
            COMPLETED: "RAL0700",
            "IN PROGRESS": "RAL0701",
            ARCHIVED: "RAL0702",
            OVERDUE: "RAL0703",
            "YET TO START": "RAL0704",
          };
          const translatedKey = translationKeys[statusKey] || statusKey;
          const translatedText = t(translatedKey);

          return (
            translatedText.charAt(0).toUpperCase() +
            translatedText.slice(1).toLowerCase()
          );
        };

        return (
          <Box
            title={formatStatus(status)}
            placement="bottom-start"
            disableInteractive
          >
            <span className={statusClass} style={{ fontSize: "14px" }}>
              {formatStatus(status)}
            </span>
          </Box>
        );
      },
    },

    role !== "EMPLOYEE" &&
      role !== "CLIENT" &&
      role !== "TEAM LEAD" && {
        headerName: t("RAL0538"),
        type: "actions",
        flex: 1,
        width: 10,
        renderCell: (params) => {
          const computedStatus = computeProjectStatus(params.row);
          const isCompleted = params.row.status === "COMPLETED";

          return (
            <>
              {params.row.status === "ARCHIVED" ? (
                <Tooltip title={t("RAL0535")} arrow>
                  <GridActionsCellItem
                    icon={
                      mode === "dark" ? (
                        <ArchiveIconDark
                          id="project-archive-icon"
                          className="icon"
                        />
                      ) : (
                        <ArchiveIcon
                          id="project-archive-icon"
                          className="icon"
                        />
                      )
                    }
                    label={t("RAL0535")}
                    onClick={() => handleUnarchiveProject(params.row.id)}
                  />
                </Tooltip>
              ) : (
                <>
                  <Tooltip title={t("RAL0172")} arrow>
                    <GridActionsCellItem
                      icon={
                        mode === "dark" ? (
                          <EditIconDark
                            id="project-edit-icon"
                            className="icon"
                          />
                        ) : (
                          <EditIcon id="project-edit-icon" className="icon" />
                        )
                      }
                      label={t("RAL0172")}
                      style={{
                        marginLeft: isCompleted ? "-33px" : "",
                        marginRight: "8px",
                      }}
                      onClick={() => handleEditProject(params.row.id, "list")}
                    />
                  </Tooltip>

                  <Tooltip title={t("RAL0173")} arrow>
                    <GridActionsCellItem
                      icon={
                        mode === "dark" ? (
                          <ArchiveIconDark
                            id="project-archive-icon"
                            className="icon"
                          />
                        ) : (
                          <ArchiveIcon
                            id="project-archive-icon"
                            className="icon"
                          />
                        )
                      }
                      label={t("RAL0173")}
                      style={{
                        marginRight: isCompleted ? "-1px" : "8px",
                      }}
                      onClick={() => handleArchiveProject(params.row.id)}
                    />
                  </Tooltip>

                  {computedStatus !== "COMPLETED" &&
                    params.row.status !== "ARCHIVED" && (
                      <Tooltip title={t("RAL0545")} arrow>
                        <GridActionsCellItem
                          icon={
                            mode === "light" ? (
                              <CompleteIcon
                                id="project-complete-icon"
                                className="icon"
                              />
                            ) : (
                              <CompleteIconDark
                                id="project-complete-icon"
                                className="icon"
                              />
                            )
                          }
                          label={t("RAL0545")}
                          style={{ marginRight: "8px" }}
                          onClick={() => handleCompleteProject(params.row.id)}
                        />
                      </Tooltip>
                    )}
                </>
              )}
            </>
          );
        },
      },
  ]);

  return CommonUtil.isOwnerOrAdminOrTeamlead() ? (
    <div>
      <ContentLayout
        id="header-text-project-name"
        title={t("RAL0269")}
        rowtitle={
          selectedRows.length > 0 ? (
            <strong className="selected-projects">
              {selectedRows.length} {t("RAL0598")}
              {selectedRows.length > 1 ? "s" : ""} {t("RAL0599")}
            </strong>
          ) : null
        }
        actions={actions}
        isLoading={isLoading}
      >
        <CustomDataTable
          page={page - 1}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          columns={columns}
          getRowClassName={getRowClassName}
          // rows={rows}
          rows={filteredProjects}
          checkboxSelection={true}
          onRowSelectionModelChange={handleRowSelection}
          onPageChange={(e) => {
            const CurrentPage = CommonUtil.getPageNumber(e);
            setPage(CurrentPage);
          }}
          onPageSizeChange={(e) => {
            setRowsPerPage(e);
            setPage(1);
          }}
        />

        <ArchiveDialog
          open={openArchiveDialog}
          onClose={handleCloseDialog}
          onConfirm={
            dialogActionType === "complete"
              ? handleConfirmCompleteProject
              : handleConfirmArchive
          }
          name={t("RAL0598")}
          type="Project"
          actionType={dialogActionType}
        />

        <UnarchiveDialog
          open={openUnarchiveDialog}
          onClose={handleCloseUnarchiveDialog}
          onConfirm={handleConfirmUnrchive}
          name={t("RAL0598")}
          type="Project"
        />

        <ProjectFilter
          open={open}
          anchorEl={anchorEl}
          status={filter}
          SelectInput={SelectInput}
          onStatusChange={handleStatusChange}
          onClose={handleCloseFilter}
          role={role}
          isXlScreen={isXlScreen}
          clientFilter={clientFilter}
          clients={clients}
          clientSearch={clientSearch}
          setClientSearch={setClientSearch}
          handleClientFilterChange={handleClientFilterChange}
          memberFilter={memberFilter}
          setClientFilter={setClientFilter}
          setMemberFilter={setMemberFilter}
          members={members}
          memberSearch={memberSearch}
          setMemberSearch={setMemberSearch}
          handleMemberFilterChange={handleMemberFilterChange}
          onClick={() => handleReset()}
        />
      </ContentLayout>
    </div>
  ) : (
    <></>
  );
}
