import React, { useEffect, useState } from "react";
import {
  Box,
  Popover,
  Typography,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputBase,
  Tooltip,
  TextField,
} from "@mui/material";
import { ReactComponent as ArrowDropDown } from "../../assets/images/arrowdropdown.svg";
import { getAllDepartments } from "../../services/DepartmentService";
import { getAllDesignations } from "../../services/DesignationService";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import AppContext from "../../config/AppContext";
import "./MemberFilter.scss";

export default function MemberFilter({
  open,
  anchorEl,
  onClose,
  filter,
  // handleChange,
  BootstrapInput,
  ApplyFilters,
  MemberReset,
  onApplyFilters,
  refresh,
}) {
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const { t } = useTranslation();
  const [departmentSearch, setDepartmentSearch] = useState("");
  const [designationSearch, setDesignationSearch] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState([
    { id: t("RAL0527"), name: t("RAL0527") },
  ]);
  const [designationFilter, setDesignationFilter] = useState([
    { id: t("RAL0528"), name: t("RAL0528") },
  ]);
  const [refresher, setRefresh] = useState(false);
  const { mode } = useContext(AppContext);
  const menuItemStyle = {
    fontFamily: "Gotham",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "var(--fw-500)",
    backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
    color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
    "&:hover": {
      backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
      color: "#FA9C2B",
    },
    "&.Mui-selected": {
      backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
      color: "#FA9C2B",
    },
    "&.Mui-selected:hover": {
      backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
      color: "#FA9C2B",
    },
  };
  const [filters, setFilters] = useState(() => {
    return filter || {
      status: "",
      name: "",
      email: "",
      toggleKeyToURL: true,
      departmentIds: [],
      designationIds: [],
    }
  });

  const handleChange = (event) => {
    setFilters({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    getAllDepartments()
      .then((res) => {
        let department_data = res.data.data.departments;
        const filteredDepartments = department_data.filter((department) => {
          const departmentID = department.id;
          const departmentName = department.name;
          return departmentID && departmentName;
        });

        setDepartments(filteredDepartments);

        if (filter.departmentIds) {
          const selectedDept = filteredDepartments.filter((department) =>
            filter.departmentIds.includes(department.id)
          );
          if (selectedDept && selectedDept.length != 0) {
            setDepartmentFilter(selectedDept);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });

    getAllDesignations()
      .then((res) => {
        let Designation_Data = res.data.data.designations;
        const filteredDesignations = Designation_Data.filter((designation) => {
          const designationId = designation.id;
          const designationName = designation.name;
          return designationId && designationName;
        });
        setDesignations(filteredDesignations);

        if (filter.designationIds) {

          const selectedDes = filteredDesignations.filter((designation) =>
            filter.designationIds.includes(designation.id)
          );
          if (selectedDes && selectedDes.length != 0) {
            setDesignationFilter(selectedDes);
          }

        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  }, []);

  useEffect(() => {
    if (refresh) {
      setDepartmentFilter([{ id: t("RAL0527"), name: t("RAL0527") }]);
      setDesignationFilter([{ id: t("RAL0528"), name: t("RAL0528") }]);
      setFilters({
        status: "",
        name: "",
        email: "",
        toggleKeyToURL: true,
        departmentIds: [t("RAL0527")],
        designationIds: [t("RAL0528")],
      });
      MemberReset();
    }
  }, [refresh]);

  const handleCheckboxClick = (event, id, name, type) => {
    event.stopPropagation();
    if (type === "department") {
      if (id === t("RAL0527")) {
        setDepartmentFilter([
          { id: t("RAL0527"), name: t("RAL0527") },
        ]);
      } else {
        let newDepartmentFilter = [...departmentFilter];

        if (newDepartmentFilter.find((dep) => dep.id === t("RAL0527"))) {
          newDepartmentFilter = newDepartmentFilter.filter(
            (dep) => dep.id !== t("RAL0527")
          );
        }

        const existingDepartmentIndex = newDepartmentFilter.findIndex(
          (dep) => dep.id === id
        );

        if (existingDepartmentIndex !== -1) {
          newDepartmentFilter.splice(existingDepartmentIndex, 1);
        } else {
          newDepartmentFilter.push({ id, name });
        }

        if (newDepartmentFilter.length === 0) {
          setDepartmentFilter([
            { id: t("RAL0527"), name: t("RAL0527") },
          ]);
        } else {
          setDepartmentFilter(newDepartmentFilter);
        }
      }
    } else if (type === "designation") {
      if (id === t("RAL0528")) {
        setDesignationFilter([{ id: t("RAL0528"), name: t("RAL0528") }]);
      } else {
        let newDesignationFilter = [...designationFilter];

        if (newDesignationFilter.find((des) => des.id === t("RAL0528"))) {
          newDesignationFilter = newDesignationFilter.filter(
            (des) => des.id !== t("RAL0528")
          );
        }

        const existingDesignationIndex = newDesignationFilter.findIndex(
          (des) => des.id === id
        );

        if (existingDesignationIndex !== -1) {
          newDesignationFilter.splice(existingDesignationIndex, 1);
        } else {
          newDesignationFilter.push({ id, name });
        }

        if (newDesignationFilter.length === 0) {
          setDesignationFilter([
            { id: t("RAL0528"), name: t("RAL0528") },
          ]);
        } else {
          setDesignationFilter(newDesignationFilter);
        }
      }
    }
  };

  const renderSelectedDepartment = () => {
    if (departmentFilter.some((dep) => dep.id === t("RAL0527"))) {
      return t("RAL0527");
    } else {
      return departmentFilter.map((dep) => dep.name).join(", ");
    }
  };

  const renderSelectedDesignation = () => {
    if (designationFilter.some((des) => des.id === t("RAL0528"))) {
      return t("RAL0528");
    } else {
      return designationFilter.map((des) => des.name).join(", ");
    }
  };


  const handleApplyFilters = () => {
    const selectedDepartmentIds = departmentFilter.map(
      (department) => department.id
    );
    const selectedDesignationIds = designationFilter.map(
      (designation) => designation.id
    );

    const allfilters = {
      status: {
        status: filters.status,
        name: "",
        email: "",
        toggleKeyToURL: true,
      },
      departments: selectedDepartmentIds,
      designations: selectedDesignationIds,
      refresherStatus: refresher,
    };
    onApplyFilters(allfilters);
    onClose();
  };

  const handleResetApply = () => {
    MemberReset();
    setDepartmentFilter([{ id: t("RAL0527"), name: t("RAL0527") }]);
    setDesignationFilter([{ id: t("RAL0528"), name: t("RAL0528") }]);
    setFilters({
      status: "",
      name: "",
      email: "",
      toggleKeyToURL: true,
      departmentIds: [t("RAL0527")],
      designationIds: [t("RAL0528")],
    });
    onClose();
  };

  const handleResetApplyFilter = (shouldRefresh = true) => {
    MemberReset();
    setDepartmentFilter([{ id: t("RAL0527"), name: t("RAL0527") }]);
    setDesignationFilter([{ id: t("RAL0528"), name: t("RAL0528") }]);
    setFilters({
      status: "",
      name: "",
      email: "",
      toggleKeyToURL: true,
      departmentIds: [t("RAL0527")],
      designationIds: [t("RAL0528")],
    });

    onClose();

    if (shouldRefresh) {
      setRefresh(false);
    }
  };

  if (refresher) {
    handleResetApplyFilter();
  }

  return (
    <Popover
      id={open ? "simple-popover" : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          overflowY: "auto",
          width: "360px",
          marginTop: 12,
          // height: '371px',
          backgroundColor:
            mode === "light" ? "var(--color-37)" : "var(--color-9-00)",
        },
      }}
    >
      <Box
        p={3}
        minWidth={265}
        minHeight={265}
        className="memberFilter"
        sx={{
          backgroundColor:
            mode === "light" ? "var(--color-37)" : "var(--color-9-00)",
        }}
      >
        <Typography
          variant="h6"
          style={{
            font: "normal normal medium 18px/21px Gotham",
            color: mode === "light" ? "var(--color-43)" : "var(--color-33)",
            marginTop: -10,
          }}
        >
          {t("RAL0224")}
        </Typography>

        <Box mb={2} style={{ marginTop: 20 }}>
          <Box mb={2}>
            <Select
              fullWidth
              id="btn-select-status"
              name="status"
              sx={{
                width: "310px",
                height: "50px",
                borderRadius: "5px",
                background:
                  mode === "light"
                    ? "var(--color-42) 0% 0% no-repeat padding-box"
                    : "var( --color-47-50) 0% 0% no-repeat padding-box",
                font: "normal normal normal 16px/20px Gotham",
                "& .MuiOutlinedInput-root": {
                  border: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-select": {
                  display: "block",
                  width: "80%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color:
                    mode === "light"
                      ? "var(--color-43) !important"
                      : "var(--color-33-80) !important",
                },
              }}
              value={filters?.status || ""}
              onChange={handleChange}
              displayEmpty
              input={<BootstrapInput />}
            // IconComponent={ArrowDropDown}
            >
              <MenuItem
                value=""
                className="input-placeholder"
                id="select-state-id"
                name="select-state-name"
                sx={menuItemStyle}
              >
                {t("RAL0231")}
              </MenuItem>
              <MenuItem
                value={"ACTIVE"}
                className="input-placeholder"
                id="select-state-active-id"
                name="select-state-active-name"
                sx={menuItemStyle}
              >
                {t("RAL0232")}
              </MenuItem>
              <MenuItem
                value={"INACTIVE"}
                className="input-placeholder"
                id="select-state-inactive-id"
                name="select-state-inactive-name"
                sx={menuItemStyle}
              >
                {t("RAL0233")}
              </MenuItem>
              <MenuItem
                value={"ARCHIVED"}
                className="input-placeholder"
                id="select-state-archived-id"
                name="select-state-archived-name"
                sx={menuItemStyle}
              >
                {t("RAL0234")}
              </MenuItem>
            </Select>
          </Box>
          <Box mb={2}>
            <Box>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "var(--border-color-1) !important",
                      borderWidth: "1px !important",
                    },
                  },
                }}
              >
                <Select
                  fullWidth
                  id="dropdown-department"
                  name="dropdown-department"
                  value={renderSelectedDepartment()}
                  onChange={() => { }}
                  sx={{
                    width: "310px",
                    height: "50px",
                    borderRadius: "5px",
                    background:
                      mode === "light"
                        ? "var(--color-42) 0% 0% no-repeat padding-box"
                        : "var( --color-47-50) 0% 0% no-repeat padding-box",
                    font: "normal normal normal 16px/20px Gotham",
                    "& .MuiSelect-select": {
                      display: "block",
                      width: "80%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color:
                        mode === "light"
                          ? "var(--color-43) !important"
                          : "var(--color-33-80) !important",
                    },
                  }}
                  renderValue={() => renderSelectedDepartment()}
                  input={<BootstrapInput />}
                  // IconComponent={ArrowDropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: "5px",
                        boxShadow: "3px 3px 6px #00000026",
                        backgroundColor:
                          mode === "dark"
                            ? "#1F1F1F!important"
                            : "#FFFFFF!important",
                      },
                    },
                    disableAutoFocusItem: true,
                    autoFocus: false,
                  }}
                >
                  <MenuItem
                    onKeyDown={(e) => e.stopPropagation()}
                    sx={{
                      fontFamily: "Gotham",
                      fontStyle: "normal",
                      fontVariant: "normal",
                      fontWeight: "var(--fw-500)",
                      backgroundColor:
                        mode === "dark"
                          ? "#1F1F1F!important"
                          : "#FFFFFF!important",
                      color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                      "&:hover": {
                        backgroundColor: "#1F1F1F",
                      },
                      "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor:
                          mode === "dark"
                            ? "#362C20!important"
                            : "#FFF9F2!important",
                        color: "#FA9C2B",
                      },
                      position: "sticky",
                      top: "0px",
                      zIndex: "1",
                      paddingTop: "8px",
                    }}
                  >
                    <TextField
                      id="select-search-memberfilter"
                      placeholder={t("RAL0654")}
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={departmentSearch}
                      autoFocus
                      autoComplete="off"
                      onChange={(e) => setDepartmentSearch(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        onClick: (e) => {
                          e.stopPropagation();
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            border: "1px solid var(--border-color-1)",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid var(--border-color-1)",
                          },
                          "& input::placeholder": {
                            color:
                              mode === "light"
                                ? "#24242480 !important"
                                : "#f8f8f880 !important",
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </MenuItem>
                  <Box
                    sx={{
                      maxHeight: 260,
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: "var(--background-color-38) transparent",
                      "&::-webkit-scrollbar": {
                        width: "8px",
                        backgroundColor: "transparent",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem
                      value={t("RAL0527")}
                      id="dropdown-item-alldepartment"
                      name="dropdown-item-alldepartment"
                      onClick={(event) =>
                        handleCheckboxClick(
                          event,
                          t("RAL0527"),
                          t("RAL0527"),
                          "department"
                        )
                      }
                      selected={departmentFilter?.some(
                        (dep) => dep.id === t("RAL0527")
                      )}
                      sx={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "var(--fw-500)",
                        backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                        "&:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                          color: "#FA9C2B",
                        },
                        "&.Mui-selected": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                          color: "#FA9C2B",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                          color: "#FA9C2B",
                        },
                      }}
                    >
                      <Checkbox
                        id="dropdown-item-check-alldepartment"
                        name="dropdown-item-check-alldepartment"
                        checked={departmentFilter.some(
                          (dep) => dep.id === t("RAL0527")
                        )}
                        onClick={(event) =>
                          handleCheckboxClick(
                            event,
                            t("RAL0527"),
                            t("RAL0527"),
                            "department"
                          )
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            border:
                              mode === "dark"
                                ? "1px solid #4D4D4D"
                                : "1px solid #B2B2B2",
                            borderRadius: "4px",
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "none",
                          },
                          "&.Mui-checked": {
                            color: "orange",
                          },
                          "& .MuiSvgIcon-root path": {
                            fill: "transparent",
                          },
                          "&.Mui-checked .MuiSvgIcon-root path": {
                            fill: "orange",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#362C20!important",
                            color: "#FA9C2B",
                            width: "20px",
                            height: "20px",
                          },
                        }}
                      />
                      {t("RAL0235")}
                    </MenuItem>

                    {departments &&
                      departments
                        .filter((dept) =>
                          dept.name
                            .toLowerCase()
                            .includes(departmentSearch.toLowerCase())
                        )
                        .map((dept) => (
                          <MenuItem
                            id={`dropdown-item-${dept.id}`}
                            name={`dropdown-item-${dept.id}`}
                            key={dept.id}
                            value={dept.id}
                            onClick={(event) =>
                              handleCheckboxClick(
                                event,
                                dept.id,
                                dept.name,
                                "department"
                              )
                            }
                            selected={departmentFilter?.some(
                              (dep) => dep.id === dept.id
                            )}
                            sx={{
                              fontFamily: "Gotham",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "var(--fw-500)",
                              backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
                              color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                              "&:hover": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                                color: "#FA9C2B",
                              },
                              "&.Mui-selected": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                                color: "#FA9C2B",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                                color: "#FA9C2B",
                              },
                            }}
                          >
                            <Checkbox
                              id={` dropdown-item-check-${dept.id}`}
                              name={`dropdown-item-check-${dept.id}`}
                              checked={departmentFilter.some(
                                (dep) => dep.id === dept.id
                              )}
                              onClick={(event) =>
                                handleCheckboxClick(
                                  event,
                                  dept.id,
                                  dept.name,
                                  "department"
                                )
                              }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  border:
                                    mode === "dark"
                                      ? "1px solid #4D4D4D"
                                      : "1px solid #B2B2B2",
                                  borderRadius: "4px",
                                  width: "20px",
                                  height: "20px",
                                  boxSizing: "border-box",
                                },
                                "&.Mui-checked .MuiSvgIcon-root": {
                                  border: "none",
                                },
                                "&.Mui-checked": {
                                  color: "#FA9C2B",
                                },
                                "& .MuiSvgIcon-root path": {
                                  fill: "transparent",
                                },
                                "&.Mui-checked .MuiSvgIcon-root path": {
                                  fill: "#FA9C2B",
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "#FA9C2B !important",
                                  color: "#FA9C2B",
                                  width: "20px!important",
                                  height: "20px!important",
                                },
                                "& .MuiSvgIcon-root::before": {
                                  display: "none",
                                },
                              }}
                            />
                            {dept.name.length > 20 ? (
                              <Tooltip title={dept.name} placement="top" arrow>
                                <span>{`${dept.name
                                  .replace("null", "")
                                  .substring(0, 20)}...`}</span>
                              </Tooltip>
                            ) : (
                              dept.name.replace("null", "")
                            )}
                          </MenuItem>
                        ))}
                  </Box>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box mb={2}>
            <Box>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "var(--border-color-1) !important",
                      borderWidth: "1px !important",
                    },
                  },
                }}
              >
                <Select
                  id="dropdown-designation"
                  name="dropdown-designation"
                  fullWidth
                  value={renderSelectedDesignation()}
                  onChange={() => { }}
                  sx={{
                    width: "310px",
                    height: "50px",
                    borderRadius: "5px",
                    background:
                      mode === "light"
                        ? "var(--color-42) 0% 0% no-repeat padding-box"
                        : "var( --color-47-50) 0% 0% no-repeat padding-box",
                    font: "normal normal normal 16px/20px Gotham",
                    "& .MuiSelect-select": {
                      display: "block",
                      width: "80%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color:
                        mode === "light"
                          ? "var(--color-43) !important"
                          : "var(--color-33-80) !important",
                    },
                  }}
                  renderValue={() => renderSelectedDesignation()}
                  input={<BootstrapInput />}
                  // IconComponent={ArrowDropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: "5px",
                        boxShadow: "3px 3px 6px #00000026",
                        backgroundColor:
                          mode === "dark"
                            ? "#1F1F1F!important"
                            : "#FFFFFF!important",
                      },
                    },
                    disableAutoFocusItem: true,
                    autoFocus: false,
                  }}
                >
                  <MenuItem
                    onKeyDown={(e) => e.stopPropagation()}
                    sx={{
                      fontFamily: "Gotham",
                      fontStyle: "normal",
                      fontVariant: "normal",
                      fontWeight: "var(--fw-500)",
                      backgroundColor:
                        mode === "dark"
                          ? "#1F1F1F!important"
                          : "#FFFFFF!important",
                      color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                      "&:hover": {
                        backgroundColor: "#1F1F1F",
                      },
                      "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor:
                          mode === "dark"
                            ? "#362C20!important"
                            : "#FFF9F2!important",
                        color: "#FA9C2B",
                      },
                      position: "sticky",
                      top: "0px",
                      zIndex: "1",
                      paddingTop: "8px",
                    }}
                  >
                    <TextField
                      id="select-search-memberfilter"
                      placeholder={t("RAL0662")}
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={designationSearch}
                      autoFocus
                      autoComplete="off"
                      onChange={(e) => setDesignationSearch(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        onClick: (e) => {
                          e.stopPropagation();
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            border: "1px solid var(--border-color-1)",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid var(--border-color-1)",
                          },
                          "& input::placeholder": {
                            color:
                              mode === "light"
                                ? "#24242480 !important"
                                : "#f8f8f880 !important",
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  </MenuItem>
                  <Box
                    sx={{
                      maxHeight: 260,
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: "var(--background-color-38) transparent",
                      "&::-webkit-scrollbar": {
                        width: "8px",
                        backgroundColor: "transparent",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <MenuItem
                      id="dropdown-item-alldesignation"
                      name="dropdown-item-alldesignation"
                      value={t("RAL0528")}
                      onClick={(event) =>
                        handleCheckboxClick(
                          event,
                          t("RAL0528"),
                          t("RAL0528"),
                          "designation"
                        )
                      }
                      selected={designationFilter?.some(
                        (des) => des.id === t("RAL0528")
                      )}
                      sx={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "var(--fw-500)",
                        backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                        "&:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                          color: "#FA9C2B",
                        },
                        "&.Mui-selected": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                          color: "#FA9C2B",
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                          color: "#FA9C2B",
                        },
                      }}
                    >
                      <Checkbox
                        id="dropdown-item-alldesignation"
                        name="dropdown-item-alldesignation"
                        checked={designationFilter.some(
                          (des) => des.id === t("RAL0528")
                        )}
                        onClick={(event) =>
                          handleCheckboxClick(
                            event,
                            t("RAL0528"),
                            t("RAL0528"),
                            "designation"
                          )
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            border:
                              mode === "dark"
                                ? "1px solid #4D4D4D"
                                : "1px solid #B2B2B2",
                            borderRadius: "4px",
                            width: "20px",
                            height: "20px",
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "none",
                          },
                          "&.Mui-checked": {
                            color: "orange",
                          },
                          "& .MuiSvgIcon-root path": {
                            fill: "transparent",
                          },
                          "&.Mui-checked .MuiSvgIcon-root path": {
                            fill: "orange",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#362C20!important",
                            color: "#FA9C2B",
                            width: "20px",
                            height: "20px",
                          },
                        }}
                      />
                      {t("RAL0236")}
                    </MenuItem>

                    {designations &&
                      designations
                        .filter((desig) =>
                          desig.name
                            .toLowerCase()
                            .includes(designationSearch.toLowerCase())
                        )
                        .map((desig) => (
                          <MenuItem
                            id={`dropdown-item-${desig.id}-desig`}
                            name={`dropdown-item-${desig.id}-desig`}
                            key={desig.id}
                            value={desig.id}
                            selected={designationFilter?.some(
                              (des) => des.id === desig.id
                            )}
                            onClick={(event) =>
                              handleCheckboxClick(
                                event,
                                desig.id,
                                desig.name,
                                "designation"
                              )
                            }
                            sx={{
                              fontFamily: "Gotham",
                              fontStyle: "normal",
                              fontVariant: "normal",
                              fontWeight: "var(--fw-500)",
                              backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF",
                              color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                              "&:hover": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                                color: "#FA9C2B",
                              },
                              "&.Mui-selected": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                                color: "#FA9C2B",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2",
                                color: "#FA9C2B",
                              },
                            }}
                          >
                            <Checkbox
                              id={`dropdown-item-check-${desig.id}-desig`}
                              name={`dropdown-item-check-${desig.id}-desig`}
                              checked={designationFilter.some(
                                (des) => des.id === desig.id
                              )}
                              onClick={(event) =>
                                handleCheckboxClick(
                                  event,
                                  desig.id,
                                  desig.name,
                                  "designation"
                                )
                              }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  border:
                                    mode === "dark"
                                      ? "1px solid #4D4D4D"
                                      : "1px solid #B2B2B2",
                                  borderRadius: "4px",
                                  width: "20px",
                                  height: "20px",
                                  boxSizing: "border-box",
                                },
                                "&.Mui-checked .MuiSvgIcon-root": {
                                  border: "none",
                                },
                                "&.Mui-checked": {
                                  color: "#FA9C2B",
                                },
                                "& .MuiSvgIcon-root path": {
                                  fill: "transparent",
                                },
                                "&.Mui-checked .MuiSvgIcon-root path": {
                                  fill: "#FA9C2B",
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "#FA9C2B!important",
                                  color: "#FA9C2B",
                                  width: "20px!important",
                                  height: "20px!important",
                                },
                                "& .MuiSvgIcon-root::before": {
                                  display: "none",
                                },
                              }}
                            />
                            {/* {desig.name} */}
                            {desig.name.length > 20 ? (
                              <Tooltip title={desig.name} placement="top" arrow>
                                <span>{`${desig.name
                                  .replace("null", "")
                                  .substring(0, 20)}...`}</span>
                              </Tooltip>
                            ) : (
                              desig.name.replace("null", "")
                            )}
                          </MenuItem>
                        ))}
                  </Box>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-start" mt={4} gap={2}>
          <Button
            id="btn-rst-id"
            name="btn-rst-name"
            variant="outlined"
            color="primary"
            onClick={handleResetApply}
            sx={{
              width: "150px",
              height: "50px",
              borderRadius: "5px",
              background:
                mode === "light"
                  ? "var(--color-37) 0% 0% no-repeat padding-box"
                  : "var(--color-9-00) 0% 0% no-repeat padding-box",
              border:
                mode === "light"
                  ? "1px solid var(--color-1)"
                  : "1px solid var(--color-30)",
              padding: "10px",

              "&:hover": {
                background:
                  mode === "light"
                    ? "var(--color-37) 0% 0% no-repeat padding-box"
                    : "var(--color-9-00) 0% 0% no-repeat padding-box",
                border:
                  mode === "light"
                    ? "1px solid var(--color-1)"
                    : "1px solid var(--color-30)",
              },
            }}
          >
            <Typography
              variant="h6"
              style={{
                font: "normal normal normal 16px/28px Gotham",
                color: mode === "light" ? "var(--color-1)" : "var(--color-30)",
              }}
            >
              {t("RAL0077")}
            </Typography>
          </Button>
          <Button
            id="btn-aply-id"
            name="btn-aply-name"
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
            sx={{
              width: "150px",
              height: "50px",
              borderRadius: "5px",
              padding: "20px",
              background: "linear-gradient(99deg, #1D0B77 0%, #6A5FA2 100%)",
            }}
          >
            <Typography
              variant="h6"
              style={{
                font: "normal normal normal 16px/28px Gotham",
                color: "#FFFFFF",
              }}
            >
              {t("RAL0230")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}
