import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Popover,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import "./Dashboard.scss";
import "./PieChartRes.scss";
import { listDashboardProject } from "../../services/DashboardService";
import ProjectGraph from "./ProjectGraph";
import { ReactComponent as FilterIcon } from "../../assets/images/filter-icon.svg";
import { ReactComponent as EmptyProjectList } from "../../assets/images/ralvie/icon-project-dashboard.svg";
import { ReactComponent as EmptyProjectListDark } from "../../assets/images/ralvie/icon-project-dashboardDark.svg";
import CustomIconButton from "../../components/CustomIconButton";
import { css } from "@emotion/css";
import { toast } from "sonner";
import dayjs from "dayjs";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";

export default function ProjectChart(props) {
  const { mode } = useContext(AppContext);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [projectData, setProjectData] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    userId: "",
    startDate: "",
    endDate: "",
    departmentFilter: "",
    formattedStartTime: "",
    formattedEndTime: "",
  });

  // Handle filter update on prop changes
  useEffect(() => {
    if (
      filter.userId !== props.userId ||
      !dayjs(filter.startDate).isSame(dayjs(props.startDate)) ||
      !dayjs(filter.endDate).isSame(dayjs(props.endDate)) ||
      filter.departmentFilter !== props.departmentFilter
    ) {
      setFilter({
        ...filter,
        ...props,
        formattedStartTime: CommonUtil.formatDateToUTC(props.startDate),
        formattedEndTime: CommonUtil.formatDateToUTCDate(props.endDate),
      });
    }
  }, [props.userId, props.startDate, props.endDate, props.departmentFilter]);

  // Fetch project data based on filter criteria
  useEffect(() => {
    if (filter.startDate && filter.endDate) {
      setLoading(true);
      const payload = {
        startTime:  CommonUtil.formatDateToUTC(props.startDate),
        endTime:  CommonUtil.formatDateToUTCDate(props.endDate),
        departmentIds: "All departments".includes(filter.departmentFilter)
          ? ""
          : filter.departmentFilter,
        userId: filter.userId === "All members" ? "" : filter.userId,
      };

      listDashboardProject(payload)
        .then((res) => {
          if (res.data.code === "RCI0000") {
            const projectReports = res.data.data.projectReports;
            const activeProjects = projectReports.filter(
              (report) => report.project.status !== "ARCHIVED"
            );

            const formattedData = activeProjects.map((report) => {
              const hours = Math.floor(report.totalLoggedDuration / 3600);
              const minutes = Math.floor(
                (report.totalLoggedDuration % 3600) / 60
              );
              return {
                name: report.project.name,
                hours,
                minutes,
                totalDuration: report.totalLoggedDuration,
                color: report.project.color,
              };
            });

            const filteredProjects = formattedData.filter(
              (project) => project.totalDuration > 0
            );

            setProjectData(formattedData);
            setTotalProjects(filteredProjects.length);

            const topProjects = filteredProjects
              .sort((a, b) => b.totalDuration - a.totalDuration)
              .slice(0, 5)
              .map((project) => project.name);

            setSelectedProjects(topProjects);
            setDisplayedProjects(topProjects);
          } else if (res.data.code === "RCE0054") {
            setProjectData([]);
            setTotalProjects(0);
            setSelectedProjects([]);
            setDisplayedProjects([]);
          } else {
            console.error("Error fetching project reports");
          }
          setTimeout(() => {
            setLoading(false);
          }, 4000);
        })
        .catch((error) => {
          console.error("Error fetching category data:", error);
          setProjectData([]);
          setTotalProjects(0);
          setSelectedProjects([]);
          setDisplayedProjects([]);
          setTimeout(() => {
            setLoading(false);
          }, 4000);
        });
      return () => {
        setLoading(false);
      };
    }
  }, [filter]);

  const handleCheckboxChange = (event) => {
    const projectName = event.target.name;
    const isChecked = event.target.checked;

    // Find the project by name
    const project = projectData.find((p) => p.name === projectName);
    if (project) {
      // If the checkbox is checked and less than 5 projects are selected
      if (isChecked && selectedProjects.length < 5) {
        setSelectedProjects([...selectedProjects, projectName]);
      }
      // If the checkbox is unchecked, remove the project from selectedProjects
      else if (!isChecked) {
        setSelectedProjects(
          selectedProjects.filter((proj) => proj !== projectName)
        );
      } else {
        toast.error(t("RAL0705"));
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const allDurationsZero = projectData.every(
    (project) => project.totalDuration === 0
  );

  return (
    <div
      className={css`
        position: relative;
        height: 100%;
        width: 100%;
        // padding: 20px 20px;
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
        `}
      >
        <Typography id="project-header-text" className="AppCategoriesHeading">{t("RAL0590")}</Typography>

        <Box
          sx={{
            "& .filter_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .filter_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            id="project-filter-icon"
            title={t("RAL0224")}
            variant="square"
            lightMode={<FilterIcon id="project-filter-icon" className="filter_svg" />}
            darkMode={<FilterIcon id="project-filter-icon" className="filter_svg" />}
            onClick={handleClick}
            bg="transparent"
          />
        </Box>
      </div>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            paddingTop:12,
          }}
        >
          <CircularProgress id="project-circular-icon"/>
        </Box>
      ) : projectData.length === 0 || allDurationsZero || selectedProjects.length === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            padding: "20px",
          paddingBottom:'0px',

          }}
        >
          <Box
            id="btn-toggle-exp-member"
            sx={{
              position: "absolute",
              top: "65%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            {mode === "light" ? <EmptyProjectList id="project-empty-icon" /> : <EmptyProjectListDark id="project-empty-icon" />}
            <Box>
              <p id="project-empty-icon"  className="EmptyProjectListicon">{t("RAL0277")}</p>
            </Box>
          </Box>
        </div>
      ) : (
        <div
          className={css`
            height: fit-content;
            padding: 0 20px;
            max-width: 95%;
            margin-left: 15px;
          `}
        >
          <ProjectGraph
            mode={mode}
            projectData={projectData.filter((project) =>
              selectedProjects.includes(project.name)
            )}
            totalProjects={totalProjects}
          />
        </div>
      )}

      {projectData.length > 0 && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              width: 250,
              boxShadow: "0px 3px 6px #00000026",
              background:
                mode === "dark" ? "#1F1F1F" : "var(--popover-background)",
            },
          }}
        >
          <Box
            py={1}
            sx={{
              maxHeight: 260,
              overflowY: "auto",
              overflowX: "hidden",
              scrollbarWidth: "thin",
              scrollbarColor: "var(--background-color-38) transparent",
              "&::-webkit-scrollbar": {
                width: "8px",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
              },
            }}
          >
            <FormGroup
              className={css`
                background: ${mode === "dark"
                  ? "#1F1F1F"
                  : "var(--popover-background)"} !important;
              `}
            >
              {projectData
                .filter((project) => selectedProjects.includes(project.name))
                .concat(
                  projectData.filter(
                    (project) => !selectedProjects.includes(project.name)
                  )
                )
                .map((project) => (
                  <FormControlLabel
                    key={project.name}
                    sx={{
                      height: "50px",
                      margin: "0px",
                      background:
                        mode === "dark"
                          ? selectedProjects.includes(project.name)
                            ? "#362C20"
                            : "#1F1F1F"
                          : selectedProjects.includes(project.name)
                          ? "#FFF9F2"
                          : "#FFFFFF",
                    }}
                    control={
                      <Checkbox
                        id="project-checkbox-select"
                        checked={selectedProjects.includes(project.name)}
                        onChange={handleCheckboxChange}
                        name={project.name}
                        sx={{
                          marginLeft: "10px",
                          "& .MuiSvgIcon-root": {
                            border:
                              mode === "dark"
                                ? "1px solid #4D4D4D"
                                : "1px solid #B2B2B2",
                            borderRadius: "4px",
                            width: "20px",
                            height: "20px",
                            boxSizing: "border-box",
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "none",
                          },
                          "&.Mui-checked": {
                            color: "#FA9C2B",
                          },
                          "& .MuiSvgIcon-root path": {
                            fill: "transparent",
                          },
                          "&.Mui-checked .MuiSvgIcon-root path": {
                            fill: "#FA9C2B",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#FA9C2B !important",
                            color: "#FA9C2B",
                            width: "20px!important",
                            height: "20px!important",
                          },
                          "& .MuiSvgIcon-root::before": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          color:
                            mode === "dark"
                              ? selectedProjects.includes(project.name)
                                ? "#FA9C2B"
                                : "var(--color-33-80)"
                              : selectedProjects.includes(project.name)
                              ? "#FA9C2B"
                              : "var(--color-43-80)",
                          marginRight: "0px",
                          font: "normal normal normal 16px/20px Gotham", 
                           opacity: 1,
                        }}
                      >
                        {project?.name?.length > 14 ? (
                          <Tooltip title={project?.name}>
                            <span id="project-chart-name" style={{ font: "normal normal normal 16px/20px Gotham", opacity: 1 }}>
                              {`${project?.name.substring(0, 14)}...`}
                            </span>
                          </Tooltip>
                        ) : (
                          <span id="project-chart-name" style={{ font: "normal normal normal 16px/20px Gotham", opacity: 1 }}>
                            {project?.name}
                          </span>
                        )}
                      </Typography>
                    }
                    
                  />
                ))}
            </FormGroup>
          </Box>
        </Popover>
      )}
    </div>
  );
}
