import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AppIcon from "../../components/AppIcon";
import { getSystemSettings } from "../../services/EventService";
import { css } from "@emotion/css";
import AppContext from "../../config/AppContext";

export default function EventDetails(props) {
  const { mode } = useContext(AppContext);
  const [isHovered, setIsHovered] = useState(false);
  const getApplicationIcon = (data) => {
    return <AppIcon data={data?.data} height={14} width={14} id={data?.id} />;
  };

  function formatTime(seconds) {
    if (seconds < 60) {
      let s =
        Math.ceil(seconds) < 10 ? "0" + Math.ceil(seconds) : Math.ceil(seconds);
      return "00:00:" + s;
    } else if (seconds < 3600) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      let m = minutes < 10 ? "0" + minutes : minutes;
      let s = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      return "00:" + m + ":" + s;
    } else {
      let hours = Math.floor(seconds / 3600);
      let remainingMinutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      let h = hours < 10 ? "0" + hours : hours;
      let m = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;
      let s = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      return `${h}:${m}+${s}`;
    }
  }

  const groupEvents = () => {
    const groupedEvents = {};

    props.event.events
      ?.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      )
      .forEach((event) => {
        let title = event.data?.title?.replace("*", "");
        const key = `${event.data?.app}_${title}`;

        if (!groupedEvents[key]) {
          groupedEvents[key] = {
            id: event.id,
            timestamp: event.timestamp,
            duration: event.duration,
            data: {
              ...event.data,
              app: event.data.app,
              title: title,
            },
            start: event.start,
            end: event.end,
            event_id: event.event_id,
            title: title,
          };
        } else {
          groupedEvents[key].end = event.end;
          groupedEvents[key].duration += event.duration;
        }
      });

    return Object.values(groupedEvents);
  };
  function formatTimeDifference(date1, date2) {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const secondsDifference = diffInMilliseconds / 1000;

    if (secondsDifference < 1) {
      return `01sec`;
    } else if (secondsDifference < 60) {
      const seconds = Math.floor(secondsDifference);
      return `${String(seconds).padStart(2, "0")}sec`;
    } else if (secondsDifference < 3600) {
      const minutes = Math.floor(secondsDifference / 60);
      return `${String(minutes).padStart(2, "0")}mins`;
    } else {
      const hours = Math.floor(secondsDifference / 3600);
      const remainingMinutes = Math.floor((secondsDifference % 3600) / 60);
      return `${String(hours).padStart(2, "0")}:${String(
        remainingMinutes
      ).padStart(2, "0")}hrs`;
    }
  }
  return (
    <div
      style={{
        backgroundColor: "transparent",
        py: "8px",
        width: "100%",
        maxHeight: "400px",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {props.event?.events?.map((app, index) => {
        let eventTime =
          app.start.toLocaleTimeString("en-US", {
            hour12: Number(getSystemSettings("timeformat")) === 12,
          }) +
          " - " +
          app.end.toLocaleTimeString("en-US", {
            hour12: Number(getSystemSettings("timeformat")) === 12,
          });
        let appTitle = app?.data?.app?.toLowerCase().includes("lockapp")
          ? "Idle Time"
          : app?.title?.replace("*", "");
        if (!appTitle) appTitle = app?.data?.app?.split(".")[0];
        const eventIsLogged = app?.projectId != null; 
        const eventBackgroundColor = eventIsLogged
          ? mode === "dark"
            ? "#010101"
            : "#D9D9D9"
          : "transparent";
        return (
          <div
            style={{
              background: eventBackgroundColor,
              height: "100%",
              display: "flex",
            }}
            id={app?.event_id}
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)} 
          >
            <Divider />
            <Box display={"flex"} py={0.5} pl={1} sx={{ width: "100%" }}>
              <Box item pt={0.5} sx={{ width: "5%" }}>
                {getApplicationIcon(app)}
              </Box>
              <Box
                item
                flexGrow={1}
                px={1}
                sx={{
                  width: "20%",
                  flexWrap: "nowrap",
                  overflowWrap: "break-word", 
                }}
              >
                <Box display={"block"} alignItems={"flex-start"}>
                  <Box>
                    <Typography
                      textTransform={"capitalize"}
                      variant="text_12_17_1_1_m"
                    >
                      {appTitle}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="text_10_10_1_1_m"
                      className={css`
                        color: var(--text-color-7) !important;
                      `}
                    >
                      {eventTime}
                    </Typography>
                  </Box>

                  {app?.data?.url !== null && app?.data?.url !== undefined && (
                    <Box>
                      <Typography
                        variant="text_12_17_1_1_m"
                        className="text_overflow"
                        sx={{
                          overflowWrap: "break-word", 
                          wordBreak: "break-word", 
                          display:'flex'
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>url: </span>
                        <Tooltip title={app?.data?.url}>
                          {app?.data?.url?.substring(0, 24)}
                        </Tooltip>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box item sx={{ width: "30%" }}>
                <Box
                  display={"flex"}
                  sx={{ minWidth: "100%", justifyContent: "flex-end" }}
                >
                  <Box item>
                    <Typography
                      variant="text_10_10_1_1_m"
                      px={1.5}
                      py={0.5}
                      sx={{
                        color: "var(--text-color-7) !important",
                      }}
                    >
                      {formatTime(app?.duration)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        );
      })}
    </div>
  );
}
