import React, { useEffect, useRef, useState } from "react";
import SignupLayout from "./SignupLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OnboardTextField from "../../components/OnboardTextField";
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import CommonUtil from "../../util/CommonUtils";
import OnboardService from "../../services/OnboardService";
import "./Signup.scss";
import "./InviteMembers.scss";
import { toast } from "sonner";
import { ReactComponent as CloseIcon } from "../../assets/images/ralvie/close_icon.svg";
import { ReactComponent as CloseIconHover } from "../../assets/images/ralvie/close_icon_hover.svg";

export default function InviteMembers() {
  const { t, ready } = useTranslation();
  let navigate = useNavigate();

  const [payload, setPayload] = useState([{ name: "", email: "" }]);
  const [error, setError] = useState({});
  const [isHoveredArray, setIsHoveredArray] = useState(
    new Array(payload.length).fill(false)
  );
  const memberRefs = useRef([]);
  const memberNameRef = useRef(null);

  useEffect(() => {
    if (ready && memberNameRef.current) {
      memberNameRef.current.focus();
    }
  }, [ready]);

  const handleAddNewMember = () => {
    if (payload.length >= 10) {
      toast.error(t("RAL0646"));
      return;
    }
    const newPayload = [...payload, { name: "", email: "" }];
    setPayload(newPayload);
    setError({ ...error, [newPayload.length - 1]: { name: "", email: "" } });
    setIsHoveredArray([...isHoveredArray, false]);
    setTimeout(() => {
      memberRefs.current[newPayload.length - 1]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 0);
  };

  const handleRemoveMember = (index) => {
    const updatedPayload = payload.filter((_, i) => i !== index);
    const updatedError = { ...error };
    delete updatedError[index];
    setPayload(updatedPayload);
    setError(updatedError);
    setIsHoveredArray(isHoveredArray.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    if (validate()) {
      OnboardService.inviteBatchUsers(payload).then((res) => {
        if (res.data.code === "RCW00002") {
          toast.error(res.data?.message);
          return;
        }
        const dataUserList = res.data?.data;
        if (res.data.code === "RCI0000") {
          navigate("/dashboard");
        } else if (
          (dataUserList && dataUserList.length === 0) ||
          res.data.code === "UASW0001"
        ) {
          const updatedError = {};
          payload.forEach((_, index) => {
            updatedError[index] = {
              ...updatedError[index],
              email: t("RAL0161"),
            };
          });
          setError(updatedError);
        } else {
          const updatedError = {};
          payload.forEach((_, index) => {
            updatedError[index] = {
              ...updatedError[index],
              email: res.data?.message,
            };
          });
          setError(updatedError);
        }
      });
    }
  };

  const validate = () => {
    let isValid = true;
    const updatedError = {};
    let firstErrorField = null;  

    for (let i = 0; i < payload.length; i++) {
      const member = payload[i];
      if (!member.name) {
        updatedError[i] = {
          ...updatedError[i],
          name: t("RAL0001"),
        };
        if (!firstErrorField) firstErrorField = i; 
        isValid = false;
        break;
      }
      if (!member.email) {
        updatedError[i] = { ...updatedError[i], email: t("RAL0001") };
        if (!firstErrorField) firstErrorField = i;  
        isValid = false;
      } else if (!CommonUtil.isValidEmail(member.email)) {
        updatedError[i] = { ...updatedError[i], email: t("RAL0022") };
        if (!firstErrorField) firstErrorField = i;  
        isValid = false;
      }
    }
    setError(updatedError);
    if (firstErrorField !== null) {
      setTimeout(() => {
        memberRefs.current[firstErrorField]?.scrollIntoView({
          behavior: "smooth",
          block: "center", 
        });
      }, 0);
    }
    return isValid;
  };

  const handleChange = (index, name, value) => {
    const updatedPayload = [...payload];
    updatedPayload[index] = { ...updatedPayload[index], [name]: value };
    setPayload(updatedPayload);
    setError({ ...error, [index]: { ...error[index], [name]: "" } });
  };

  const handleMouseEnter = (index) => {
    const updatedHoveredArray = [...isHoveredArray];
    updatedHoveredArray[index] = true;
    setIsHoveredArray(updatedHoveredArray);
  };

  const handleMouseLeave = (index) => {
    const updatedHoveredArray = [...isHoveredArray];
    updatedHoveredArray[index] = false;
    setIsHoveredArray(updatedHoveredArray);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const canAddMember = payload.every((member) => member.name && member.email && CommonUtil.isValidEmail(member.email));
  return (
    <>
    <SignupLayout>
      <div className="InviteMembers">
        <div className="invite_members_001">
          <div>
            <div>
              <span className="invite_members_002">{t("RAL0127")}</span>
            </div>
            <div className="invite_members_scrollable_area">
              {payload.map((member, index) => (
                <Box
                  item
                  display="flex"
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems="center"
                  key={index}
                  pt={5}
                  className="invite_members_box_002"
                  ref={(el) => memberRefs.current[index] = el} 
                >
                  <div className="InviteMemInput">
                    <OnboardTextField
                       // required
                      fullWidth
                      label={t("RAL0139")}
                      value={member.name}
                      error={error[index]?.name}
                      helperText={error[index]?.name}
                      inputRef={memberNameRef}
                      onKeyDown={(e) => handleKeyDown(e)}
                      onChange={(e) =>
                        handleChange(index, "name", e.target.value)
                      }
                      inputProps={{
                        maxLength: 60,
                        placeholder: t("RAL0445"),
                      }}
                    />
                  </div>
                  <div className="InviteMemInput">
                    <OnboardTextField
                      // required
                      fullWidth
                      label={t("RAL0014")}
                      value={member.email}
                      error={error[index]?.email}
                      helperText={error[index]?.email}
                      onKeyDown={(e) => handleKeyDown(e)}
                      onChange={(e) =>
                        handleChange(index, "email", e.target.value)
                      }
                       // onBlur={() => verifyEmailExist(member.email, index)}
                      inputProps={{
                        maxLength: 36,
                        placeholder: t("RAL0446"),
                        autoComplete: "off",
                      }}
                      validation="email"
                    />
                  </div>
                  {payload.length > 1 && (
                    <div className="invite_members_close_icon_styles_005">
                      <ButtonGroup>
                        <Button
                          className="corsBtnIcon"
                          id="btn_invite_user_close"
                          onClick={() => handleRemoveMember(index)}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                             // sx={{
                          //   background: isHoveredArray[index]
                          //     ? "#FE5050 ! important"
                          //     : "#010101",
                          //   border: "none !important",
                          //   borderRadius: "5px",
                          //   width: "36px",
                          //   height: "36px",
                          //   paddingLeft: !isHoveredArray[index] ? "5px" : "5px",
                          //   paddingRight: "5px",
                          // }}
                        >
                          {isHoveredArray[index] ? (
                            <CloseIconHover />
                          ) : (
                            <CloseIcon />
                          )}
                        </Button>
                      </ButtonGroup>
                    </div>
                  )}
                </Box>
              ))}
            </div>

            <div className="invite_members_004">
              <div style={{ flexGrow: 1 }}></div>
              <Link
                id={"link-invite-new-user"}
                className="invite_members_003"
                onClick={handleAddNewMember}
                style={{ pointerEvents: canAddMember ? 'auto' : 'none', opacity: canAddMember ? 1 : 0.5 }}
              >
                {t("RAL0129")}
              </Link>
            </div>

            <div className="invite_members_submitbutton_006">
              <CustomButton
                id="btn_login_user_invite"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleSubmit}
              >
                <span className="invite_members_submitbutton_007">
                  {t("RAL0128")}
                </span>
              </CustomButton>
            </div>

            <div>
              <span
                className="invite_members_close_icon_009"
                onClick={() => navigate("/dashboard")}
              >
                {t("RAL0140")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </SignupLayout>
    </>
  );
}
