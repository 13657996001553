export const CONSTANT = {
  LOCAL_STORAGE_LANG_KEY: "i18nextLng",
  LANGUAGES: [
    { key: "en", name: "english" },
    { key: "zh", name: "chinese" },
    { key: "es", name: "spanish" },
    { key: "ja", name: "japanese" },
    { key: "th", name: "thai" },
  ],
  SUUPORTED_LANGUAGES: ["en", "zh", "es", "th", "ja"],
};
export const DEBUG = true;
