import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import "./Dashboard.scss";
import "./BarChartRes.scss";
import { BarGraph } from "../../components/BarGraph";
import { TotalDurationWeek } from "../../services/DashboardService";
import CommonUtil from "../../util/CommonUtils";
import dayjs from "dayjs";
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyBarchart } from "../../assets/images/ralvie/Empty-barchart.svg";
import { ReactComponent as EmptyBarchartDark } from "../../assets/images/ralvie/Empty-barchartDark.svg";
import AppContext from "../../config/AppContext";

function secondsToHms(d) {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = d % 60;

  if (s >= 30) {
    m = m + 1;
  }

  if (m === 60) {
    h += 1;
    m = 0;
  }

  let mDisplay = m + "m";

  const hDisplay = h + "h";
  return hDisplay + " " + mDisplay;
}

export default function BarChart(props) {
  const theme = useTheme();
  const [filter, setFilter] = useState({
    userId: "",
    startDate: "",
    endDate: "",
    departmentFilter: "",
    formattedStartTime: "",
    formattedEndTime: "",
  });
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [activeTime, setActiveTime] = useState([]);
  const [idleTime, setIdleTime] = useState([]);
  const [totalIdleTime, setTotalIdleTime] = useState("");
  const [totalActiveTime, setTotalActiveTime] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);

  useEffect(() => {
    if (
      filter.userId !== props.userId ||
      !dayjs(filter.startDate).isSame(dayjs(props.startDate)) ||
      !dayjs(filter.endDate).isSame(dayjs(props.endDate)) ||
      filter.departmentFilter !== props.departmentFilter
    ) {
      setFilter({
        ...filter,
        ...props,

        formattedStartTime: CommonUtil.formatDateToUTC(props.startDate),
        formattedEndTime: CommonUtil.formatDateToUTCDate(props.endDate),
      });
    }
  }, [props.userId, props.startDate, props.endDate, props.departmentFilter]);

  useEffect(() => {
    let role = CommonUtil.getLoggedUser()?.roleCode;
    if (
      role !== "OWNER" &&
      role !== "ADMIN" &&
      (CommonUtil.isEmptyString(filter.userId) ||
        filter.userId === "All members")
    ) {
      return;
    }

    if (filter.startDate && filter.endDate) {
      const userData = JSON.parse(localStorage.getItem("USER"));

      const payload = {
        startTime: CommonUtil.formatDateToUTC(props.startDate),
        endTime: CommonUtil.formatDateToUTCDate(props.endDate),
        companyId: userData.companyId,
        departmentId: "All departments".includes(filter.departmentFilter)
          ? ""
          : filter.departmentFilter,
        userId: filter.userId === "All members" ? "" : filter.userId,
      };

      setLoading(true);
      TotalDurationWeek(payload).then((res) => {
        const data = res.data.data;
        if (res.data.code === "RCI0000") {
          const activetime_duration = data.activeTime_duration;
          const idletime_duration = data.idleTime_duration;
          const activeTime = [
            "SUNDAY",
            "MONDAY",
            "TUESDAY",
            "WEDNESDAY",
            "THURSDAY",
            "FRIDAY",
            "SATURDAY",
          ].map((day) => {
            const duration = activetime_duration[day] || 0;
            return duration < 60 ? 0 : duration;
          });

          const idleTime = [
            "SUNDAY",
            "MONDAY",
            "TUESDAY",
            "WEDNESDAY",
            "THURSDAY",
            "FRIDAY",
            "SATURDAY",
          ].map((day) => {
            const duration = idletime_duration[day] || 0;
            return duration < 60 ? 0 : duration;
          });

          setTotalActiveTime(
            secondsToHms(
              Object.values(activeTime).reduce((acc, val) => acc + val, 0)
            )
          );
          setTotalIdleTime(
            secondsToHms(
              Object.values(idleTime).reduce((acc, val) => acc + val, 0)
            )
          );
          setActiveTime(activeTime);
          setIdleTime(idleTime);
        } else {
          setTotalActiveTime("");
          setTotalIdleTime("");
          setActiveTime([]);
          setIdleTime([]);
        }
        setLoading(false);
      });
    }
  }, [filter]);
  const isEmptyData = (data) => data.every((value) => value === 0);
  return (
    <>
      <div
        className={css`
          min-height: 100%;
          max-height: 100%;
          width: 50%;
          background: var(--card-background-color) 0% 0% no-repeat padding-box;
          border-radius: 5px;
          opacity: 1;
          padding: 20px;
          flex: 0 0 calc(50% - 12px);
          overflow: hidden;
        `}
      >
        <div
          className={css`
            height: 100%;
          `}
        >
          <div
            className={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 32px;
            `}
          >
            <Typography id="barchart-header-text" className="chartPresentWeek">
              {t("RAL0591")}
            </Typography>
            <Box className="TotalDuration">
              <Typography
                sx={{
                  textWrap: "nowrap",
                  fontSize: "14px",
                  color: "var(--text-light-color-blue)",
                }}
                variant="h6"
              >
                {totalActiveTime}
              </Typography>
            </Box>
          </div>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                paddingBottom:10,
              }}
            >
              <CircularProgress id="barchart-circular-icon" />
            </Box>
          ) : isEmptyData(activeTime) ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {mode === "light" ? (
                <EmptyBarchart id="Barchart-empty-icon" />
              ) : (
                <EmptyBarchartDark id="Barchart-empty-icon" />
              )}
              <p className="EmptyBarcharticon"> {t("RAL0277")}</p>
            </Box>
          ) : (
            <>
              <div
                className={css`
                  height: fit-content;
                `}
              >
                <BarGraph
                  barBackgroundColor="rgba(29, 11, 119, 0.1)"
                  barColor="rgb(29, 11, 119)"
                  gradientStartColor="#1D0B77"
                  gradientStartColorDark="#1d0b77cc"
                  gradientEndColor="#978BD5"
                  gradientEndColorDark="#978bd5cc"
                  weekData={activeTime}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={css`
          min-height: 100%;
          max-height: 100%;
          width: 50%;
          background: var(--card-background-color) 0% 0% no-repeat padding-box;
          border-radius: 5px;
          opacity: 1;
          padding: 20px;
          flex: 0 0 calc(50% - 12px);
          overflow: hidden;
        `}
      >
        <div
          className={css`
            height: 100%;
          `}
        >
          <div
            className={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 32px;
            `}
          >
            <Typography id="barchart-header-text" className="chartPresentWeek">
              {t("RAL0592")}
            </Typography>
            <Box className="TotalDuration">
              <Typography
                style={{
                  textWrap: "nowrap",
                  fontSize: "14px",
                  color: "var(--text-light-color-blue)",
                }}
                variant="h6"
              >
                {totalIdleTime}
              </Typography>
            </Box>
          </div>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                paddingBottom:10,
              }}
            >
              <CircularProgress id="barchart-circular-icon" />
            </Box>
          ) : isEmptyData(idleTime) ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {mode === "light" ? (
                <EmptyBarchart id="Barchart-empty-icon" />
              ) : (
                <EmptyBarchartDark id="Barchart-empty-icon" />
              )}
              <p className="EmptyBarcharticon">{t("RAL0277")}</p>
            </Box>
          ) : (
            <>
              <div
                className={css`
                  height: fit-content;
                `}
              >
                <BarGraph
                  barBackgroundColor="rgba(255, 162, 58, 0.1)"
                  barColor="rgba(255, 162, 58, 1)"
                  gradientStartColor="#FFA23A"
                  gradientEndColor="#FFD4A4"
                  gradientStartColorDark="#FFA23A"
                  gradientEndColorDark="#FFD4A4"
                  weekData={idleTime}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
