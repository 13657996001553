import React, { useEffect, useState, useCallback } from 'react';
import { addApplicationsToCategory, getAllCategories, updateCategory } from "../../services/CategoryService";
import { debounce } from 'lodash';
import CategoryItem from './CategoryItem';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';

export default function CategoryList({ reload, setReload, removeAppFromList }) {
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();

  const refreshCategories = () => {
    getAllCategories().then((response) => {
      setCategories(response.data.data.category);
    });
  };

  useEffect(() => {
    refreshCategories();
  }, [reload]);

  const handleColorChange = useCallback(debounce((id, color) => {
    const payload = { color: color };
    updateCategory(id, payload).then((response) => {
      if (response.status === 200) {
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.id === id ? { ...category, color: payload.color } : category
          )
        );
      }
    });
  }, 300), []);

  const handleDrop = (categoryId, item) => {
    const payload = {
      appIds: [item.id]
    };

    addApplicationsToCategory(categoryId, payload).then((response) => {
      if (response.status === 200) {
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.id === categoryId
              ? { ...category, applications: [...(category.applications || []), item] }
              : category
          )
        );
        removeAppFromList(item.id);
        setReload(prev => !prev); // Trigger reload to refresh ApplicationList
        toast.success(t("RAL0706"));
        console.log(`Application ${item.name} successfully added to category ${categoryId}`);
      } else {
        console.error(`Failed to update category ${categoryId} with application ${item.name}`);
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.id === categoryId
              ? { ...category, applications: category.applications.filter(app => app.id !== item.id) }
              : category
          )
        );
      }
    });
  };

  return (
    <>
      {categories.map((category, index) => (
        <CategoryItem
          key={category.id}
          category={category}
          handleColorChange={handleColorChange}
          handleDrop={handleDrop}
          refreshCategories={refreshCategories}
          index={index}
          setReload={setReload}
        />
      ))}
    </>
  );
}
