import { css } from "@emotion/css";
import { Box, Popover, Typography } from "@mui/material";
import { bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { ReactComponent as MoreIcon } from "../../assets/images/ralvie/more-icon.svg";
import { ReactComponent as SundialLogoDark } from "../../assets/images/ralvie/sundial-logo-small-dark.svg";
import { ReactComponent as SundialLogo } from "../../assets/images/ralvie/sundial-logo-small.svg";
import AppIcon from "../../components/AppIcon";
import AppContext from "../../config/AppContext";
import {
  getSystemSettings,
  updateEventHideStatus,
} from "../../services/EventService";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext } from "../../util/context";
import EventDetails from "./EventDetails";
import "./timesheet.scss";
import { useTranslation } from "react-i18next";
import CancelDialog from "../project/CancelDialog";
export default function Activity(props) {
  const { mode } = useContext(AppContext);
  const {
    setReload,
    eventsToLog,
    setEventsToLog,
    eventByDrag,
    setEventByDrag,
    timesheetEvents,
    setTimesheetEvents,
  } = useContext(TimesheetContext);
  const [showMore, setShowMore] = useState("none");
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [loggedEvents, setLoggedEvents] = useState(0);
  const [hiddenEvents, setHiddenEvents] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const { t } = useTranslation();
  const [eventCancelDialogOpen, setEventCancelDialogOpen] = useState(false);
  useEffect(() => {
    if (eventsToLog && eventsToLog.length < 1 && isSelected) {
      setIsSelected(false);
    } else {
      let eventToSelect = eventsToLog?.find((e) => e.id === props?.event?.id);
      if (eventToSelect) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    }
  }, [eventsToLog]);

  useEffect(() => {
    if (timesheetEvents && timesheetEvents.length < 1 && isLogged) {
      setIsLogged(false);
    } else {
      let eventIds = [props.event?.id];
      if (props.event?.events) {
        let childEventIds = props.event?.events?.map((ce) => ce.id);
        eventIds.push(childEventIds);
      }
      let eventToSelect = timesheetEvents?.find(
        (e) => eventIds.find((id) => id === e.id) !== undefined
      );
      if (eventToSelect) {
        setIsLogged(true);
      } else {
        setIsLogged(false);
      }
    }
  }, [timesheetEvents]);

  useEffect(() => {
    setLoggedEvents(getLoggedEventCount(props.event));
    setHiddenEvents(hiddenEventsCount(props.event));
  }, [props.event]);

  const handleCloseCancelDialog = () => {
    setTimeout(() => {
      setEventCancelDialogOpen(false);
    }, [5]);
  };

  const handleConfirmCancel = () => {
    setTimeout(() => {
      setEventCancelDialogOpen(false);
      setTimesheetEvents([]);
    }, [5]);
  };

  const handleLoggedEvents = (event) => {
    if (
      event?.events &&
      event?.events instanceof Array &&
      event?.events?.length > 0
    ) {
      if (event.projectId && timesheetEvents?.length > 0) {
        if (timesheetEvents[0].projectId !== event.projectId) {
          return false;
        }
      }
      if (isLogged) {
        const newEvents = timesheetEvents?.filter(
          (timesheetEvent) =>
            !event.events.some((e) => e.id === timesheetEvent.id)
        );
        if (newEvents?.length < 1) {
          setEventCancelDialogOpen(true);
        } else {
          setTimesheetEvents(newEvents);
          setIsLogged(false);
        }
      } else {
        const newEventsToLog = event?.events || [];
        const updatedEvents = [...timesheetEvents, ...newEventsToLog];
        setTimesheetEvents(updatedEvents);
        setIsLogged(true);
      }
    }
  };

  const logEvent = (event) => {
    if (timesheetEvents?.length > 0) {
      return handleLoggedEvents(event);
    }
    let unLoggedEvents = event?.events.filter((e) =>
      CommonUtil.isEmptyString(e.projectId)
    );
    if (
      unLoggedEvents?.length > 0 ||
      CommonUtil.isEmptyString(event?.projectId)
    ) {
      setIsSelected(!isSelected);
      let events = eventsToLog?.filter((e) => e.event_id === event.event_id);
      if (events?.length > 0) {
        events = eventsToLog?.filter((e) => e.event_id !== event.event_id);
        if (events?.length > 0) {
          setEventsToLog(events);
        } else {
          setEventsToLog([]);
        }
      } else {
        let updatedEvent = {
          ...event,
          events: unLoggedEvents,
        };
        const newArray = [...eventsToLog, updatedEvent];
        setEventsToLog(newArray);
      }
    }
  };
  const logEventByDrag = (event) => {
    let unLoggedEvents = event?.events.filter((e) =>
      CommonUtil.isEmptyString(e.projectId)
    );

    if (
      unLoggedEvents?.length > 0 ||
      CommonUtil.isEmptyString(event?.projectId)
    ) {
      setIsSelected((prev) => !prev);

      let events = eventsToLog?.filter((e) => e.event_id === event.event_id);

      if (events?.length > 0) {
        const filteredEvents = eventsToLog.filter(
          (e) => e.event_id !== event.event_id
        );
        if (filteredEvents.length !== eventsToLog.length) {
          setEventsToLog(filteredEvents.length > 0 ? filteredEvents : []);
        }
      } else {
        let updatedEvent = {
          ...event,
          events: unLoggedEvents,
        };
        const newArray = [...eventsToLog, updatedEvent];
        setEventsToLog(newArray);
      }
    }
    setEventByDrag(undefined);
  };

  useEffect(() => {
    if (eventByDrag && eventByDrag?.id === props?.event?.id) {
      logEventByDrag(eventByDrag);
    }
  }, [eventByDrag]);
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const popupMore = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });

  const handleHideEvents = (hiddenStatus) => {
    let eventIds = props.event?.events?.map((e) => e.id);
    let payload = {
      eventIds: eventIds,
      hiddenStatus: hiddenStatus,
    };
    updateEventHideStatus(payload).then((res) => {
      if (res.data?.code === "RCI0000") {
        toast.success(
          hiddenStatus
            ? "Events hidden successfully"
            : "Events unhidden successfully"
        );
        setReload(true);
      }
    });
  };

  function timeDifferenceInMins(date1, date2) {
    const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const minutesDifference = diffInMilliseconds / (1000 * 60);
    return minutesDifference;
  }
  let time =
    props?.event.start.toLocaleTimeString("en-US", {
      timeStyle: "short",
      hour12: Number(getSystemSettings("timeformat")) === 12,
    }) +
    " - " +
    props?.event.end.toLocaleTimeString("en-US", {
      timeStyle: "short",
      hour12: Number(getSystemSettings("timeformat")) === 12,
    });
  let eventTime =
    props?.event.start.toLocaleTimeString("en-US", {
      hour12: Number(getSystemSettings("timeformat")) === 12,
    }) +
    " - " +
    props?.event.end.toLocaleTimeString("en-US", {
      hour12: Number(getSystemSettings("timeformat")) === 12,
    });
  let description =
    props?.event?.data?.title?.replace("*", "") +
    " - " +
    props?.event?.data?.app?.split(".")[0] +
    " - " +
    time;
  let title = props?.event?.data?.app?.split(".")[0];

  if (title.toLowerCase() === "afk") {
    title = "Idle time";
  }
  let showDetails =
    timeDifferenceInMins(
      new Date(props?.event.start),
      new Date(props?.event.end)
    ) >= (props.stepRef.current > 10 ? 6 : 3);
  if (props.stepRef.current === 1) {
    showDetails = true;
  }

  const getApplicationIcon = (data) => {
    return (
      <AppIcon
        data={data?.data}
        height={14}
        width={14}
        type="event"
        id={data?.event_id}
      />
    );
  };
  const getLoggedEventCount = (data) => {
    let loggedEvents = data?.events?.filter(
      (e) => !CommonUtil.isEmptyString(e.projectId)
    );
    return loggedEvents?.length;
  };
  const hiddenEventsCount = (data) => {
    let loggedEvents = data?.events?.filter((e) => e.hidden);
    return loggedEvents?.length;
  };
  return (
    <div
      style={{
        height: showMore === "inline" ? "auto" : "100%",
        overflowY: "auto",
        background: "transparent",
      }}
      className={`selectedCardLeftBorder ${css`
        border-left: 4px solid
          ${isLogged
            ? "#EA4335"
            : loggedEvents > 0
            ? "#919191"
            : isSelected
            ? "#919191"
            : props.event.dark} !important;
      `}`}
      onClick={() => logEvent(props.event)}
      onMouseOut={() => {
        setShowMore("none");
      }}
      onMouseOver={() => {
        if (!props?.isSelecting) {
          setShowMore("inline");
        }
      }}
    >
      <div
        id="activity-calendar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: isLogged
            ? "#fff3e0"
            : loggedEvents > 0
            ? mode === "dark"
              ? "#010101"
              : "#D9D9D9"
            : isSelected
            ? "#919191"
            : props.event.light,
          height: showMore === "inline" ? "fit-content" : "100%",
        }}
        onMouseOver={() => {
          if (isLogged) {
            document.getElementById("event-text").style.color = "#010101";
          }
        }}
        onMouseOut={() => {
          if (isLogged) {
            document.getElementById("event-text").style.color = "";
          }
        }}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <Box display={"flex"}>
            <Box item px={1} pt={0.5} sx={{ width: "5%", flexWrap: "nowrap" }}>
              {props.event?.application?.icon ? (
                <img
                  src={props.event?.application?.icon}
                  alt=""
                  height={14}
                  width={14}
                />
              ) : (
                getApplicationIcon(props.event)
              )}
            </Box>
            <Box
              item
              flexGrow={1}
              pl={0.5}
              sx={{ width: "80%", flexWrap: "nowrap" }}
            >
              <Box display={"block"} alignItems={"flex-start"}>
                <Box className="text_overflow">
                  <Typography
                    id="event-text"
                    maxLength="20"
                    variant="activity_header"
                    text={description}
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      textWrap: "wrap",
                      color: isLogged
                        ? "#474B4F"
                        : loggedEvents > 0
                        ? mode === "dark"
                          ? "#f8f8f8cc"
                          : "#474B4F"
                        : "",
                    }}
                  >
                    {props?.event?.application?.name}
                  </Typography>
                </Box>
                {showDetails && (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="activity_duration"
                      style={{
                        fontSize: "0.7rem",
                        color: isLogged
                          ? "#474B4F"
                          : loggedEvents > 0
                          ? mode === "dark"
                            ? "#f8f8f880"
                            : "#474B4F"
                          : "",
                      }}
                    >
                      {eventTime}
                    </Typography>
                    <Box display="flex" alignItems="center" height="20px">
                      <Box item>
                        {isLogged ? (
                          <SundialLogo height={"10px"} />
                        ) : mode === "light" ? (
                          <SundialLogo height={"10px"} />
                        ) : (
                          <SundialLogoDark height={"10px"} />
                        )}
                      </Box>
                      <Box display="flex" alignItems="center" pl={1}>
                        <Typography
                          variant="activity_log_count"
                          style={{
                            fontSize: "0.6rem",
                            color: isLogged
                              ? "#474B4F"
                              : loggedEvents > 0
                              ? mode === "dark"
                                ? "#f8f8f880"
                                : "#242424cc"
                              : "",
                          }}
                        >
                          {loggedEvents + "/" + props.event?.events?.length}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </div>
        <div style={{ minWidth: "10%" }}>
          <Box display={"flex"}>
            <Box
              item
              px={1}
              pt={0.5}
              sx={{
                width: "10%",
                flexWrap: "nowrap",
                zIndex: 1000,
                "& .more_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .more_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
              display={showMore}
              {...bindTrigger(popupMore)}
            >
              <MoreIcon className="icon more_svg" />
            </Box>
          </Box>
        </div>
      </div>
      <HoverPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <EventDetails event={props.event} loggedEvents={loggedEvents} />
      </HoverPopover>
      <Popover
        {...bindPopover(popupMore)}
        disableAutoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          onClick={() => handleHideEvents(hiddenEvents > 0 ? false : true)}
          py={1}
          px={2}
          className={css`
            background: ${mode === "dark" ? "#101010" : "#ffffff"} 0% 0%
              no-repeat padding-box;
            color: ${mode === "dark" ? "#f8f8f8cc" : "#242424"};
            box-shadow: 0px 3px 6px #00000026;
            opacity: 1;
            cursor: pointer;
          `}
        >
          {hiddenEvents > 0 ? t("RAL0564") : t("RAL0563")}
        </Box>
      </Popover>
      {showMore === "inline" && (
        <EventDetails event={props.event} loggedEvents={loggedEvents} />
      )}
      <CancelDialog
        open={eventCancelDialogOpen}
        onClose={handleCloseCancelDialog}
        onConfirm={handleConfirmCancel}
        header={t("RAL0740")}
        message={t("RAL0741")}
      />
    </div>
  );
}
