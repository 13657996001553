import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  MenuItem,
  Pagination,
  Select,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AppContext from "../config/AppContext";

export default function CustomDataTable({
  rows = [],
  columns = [],
  page,
  rowsPerPage,
  totalRecords,
  checkboxSelection,
  toolbar,
  hidePagination,
  onSelection,
  onPageChange,
  onPageSizeChange,
  getRowClassName,
  ...props
}) {
  const { t } = useTranslation();
  const { mode } = React.useContext(AppContext);

  const getPageDetails = () => {
    if (totalRecords === 0) return "0 - 0 of 0";
    const firstRecordIndex = page * rowsPerPage + 1;
    const lastRecordIndex = Math.min((page + 1) * rowsPerPage, totalRecords);
    return `${firstRecordIndex}-${lastRecordIndex} of ${totalRecords}`;
  };

  const customDataGridStyle = {
    "& .MuiDataGrid-columnHeaders": {
      font: "normal normal normal 16px/20px Gotham !important",
      color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
      letterSpacing: "0px",
      opacity: "1",
    },
    "& .MuiDataGrid-cell": {
      font: "normal normal normal 16px/56px Gotham !important",
      color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
      letterSpacing: "0px",
      opacity: "0.8",
      border: "none",
    },

    ".MuiDataGrid-columnHeader:focus-within": { outline: "none" },
    ".MuiDataGrid-cell:focus-within": {
      outline: "none!important",
      color: "none!important",
    },
    ".MuiDataGrid-columnSeparator": { display: "none !important" },

    // Hide checkboxes by default
    "& .MuiDataGrid-cellCheckbox": {
      visibility: "hidden",
    },

    // Show checkboxes on hover or if the row is selected
    "& .MuiDataGrid-row:hover .MuiDataGrid-cellCheckbox": {
      visibility: "visible",
    },
    "& .MuiDataGrid-row.Mui-selected .MuiDataGrid-cellCheckbox": {
      visibility: "visible",
    },

    ".MuiTablePagination-displayedRows": {
      position: "absolute",
      right: "90px",
    },
    ".MuiDataGrid-virtualScroller": {
      overflowY: "auto !important",
    },
    ".MuiTablePagination-select": {
      backgroundColor: "#F0F1F4",
      borderRadius: "10px !important",
    },
    ".MuiTablePagination-toolbar": { paddingLeft: "0px" },
    ".MuiIconButton-root.Mui-disabled": {
      backgroundColor: "#F0F1F4",
      borderRadius: "0px",
    },
    ".MuiIconButton-root": { borderRadius: "0px" },
    ".Mui-checked": { color: "#FFB353!important" },
    "& .MuiCheckbox-root": {
      color: "#707173 !important",
      "&.Mui-checked": {
        color: "#FFB353 !important",
      },
    },

    "& .MuiDataGrid-columnHeaderCheckbox": {
      backgroundColor: mode === "dark" ? "#f8f8f8cc" : "#EBECF0",
    },

    // Default row background color
    "& .MuiDataGrid-row": {
      // backgroundColor: "#FFFFFF",
      backgroundColor: mode === "dark" ? "#1c1c1c !important" : "#FFFFFF !important",
      marginBottom: "1px",
    },

    // Hover background color
    "& .MuiDataGrid-row:hover": {
      backgroundColor: mode === "dark" ? "#000 !important" : "#EBECF0 !important",
      color: mode === "dark" ? "#f8f8f8cc" : "#F4F5F8",
    },

    // Selected background color
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: mode === "dark" ? "#000 !important" : "#FFF9F2 !important",
    },

    // Adjusting the border for selected rows
    "& .MuiDataGrid-row.Mui-selected:hover": {
      backgroundColor: mode === "dark" ? "#4f4d4dcc" : "#FFF9F2",
    },

    "& .MuiDataGrid-row.selectedCheckboxVisible .MuiDataGrid-cellCheckbox": {
      visibility: "visible",
    },

    "& .MuiDataGrid-virtualScrollerContent": {
      backgroundColor: mode === "dark" ? "#000" : "#ffffff",
    },

    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
      backgroundColor: mode === "dark" ? "#1f1d1d" : "#ebecf0",
      marginBottom: "1px",
    },

    "& .project-data-table .MuiDataGrid-columnHeaderTitle": {
      paddingLeft: "20px",
    }
    
  };

  const paginationStyles = {
    button: {
      backgroundColor: mode === "dark" ? "#000" : "#FFF",
      color: mode === "dark" ? "#f8f8f8cc" : "rgba(71, 75, 79, 0.8)",
      fontSize: { xl: "16px", lg: "14px", md: "14px", sm: "14px", xs: "14px" },
      width: { xl: "50px", lg: "40px", md: "40px", sm: "40px", xs: "40px" },
      height: { xl: "50px", lg: "40px", md: "40px", sm: "40px", xs: "40px" },
    },
    ul: {
      "& .Mui-selected": {
        backgroundColor: mode === "dark" ? "#212020" : "#EBECF0",
        color: mode === "dark" ? "#fff" : "#1D0B77",
      },
      "&:hover .Mui-selected": {
        backgroundColor: mode === "dark" ? "#f8f8f8cc" : "#EBECF0",
      },
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 130px)",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        overflow: "hidden",
        // borderTop: `0.5px solid ${mode === "dark" ? "#ebecf066" : "#ebecf066"}`

      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
        }}
      >
        <DataGrid
          sx={{
            ...customDataGridStyle,
            border: "none",
          }}
          rows={rows}
          columns={columns}
          rowHeight={60}
          hideFooter
          paginationMode="server"
          page={page}
          rowCount={totalRecords}
          checkboxSelection={checkboxSelection}
          hideFooterSelectedRowCount
          pageSize={rowsPerPage}
          getRowClassName={getRowClassName}
          disableSelectionOnClick={false}
          onRowSelectionModelChange={onSelection}
          components={{ Toolbar: toolbar }}
          disableColumnMenu 
          localeText={{
            noRowsLabel: t("RAL0722"),
            columnHeaderSortIconLabel: "", 
          }}
          {...props}
        />
      </Box><br/>
      {totalRecords > 0 && !hidePagination && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
           marginBottom:"70px"
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography className="dataTableLable">{t("RAL0166")}</Typography>
            <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
              <Select
                sx={{
                  fontSize: "16px",
                  color: mode === "dark" ? "#fff" : "rgba(71, 75, 79, 0.8)",
                  width: {
                    xl: "99px",
                    lg: "70px",
                    md: "70px",
                    sm: "70px",
                    xs: "70px",
                  },
                  height: {
                    xl: "50px",
                    lg: "40px",
                    md: "40px",
                    sm: "40px",
                    xs: "40px",
                  },
                  backgroundColor: mode === "dark" ? "#000" : "#ffffff",
                }}
                value={rowsPerPage}
                onChange={(e) => onPageSizeChange(e.target.value)}
              >
                <MenuItem
                  value={5}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#3EBECF0",
                      color: "#1D0B77",
                    },
                  }}
                >
                  5
                </MenuItem>
                <MenuItem
                  value={10}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#3EBECF0",
                      color: "#1D0B77",
                    },
                  }}
                >
                  10
                </MenuItem>
                <MenuItem
                  value={20}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#3EBECF0",
                      color: "#1D0B77",
                    },
                  }}
                >
                  20
                </MenuItem>
              </Select>

              <Box ml={2}>
                <Typography className="dataTableLable">
                  {getPageDetails()}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: { lg: "block", sm: "none", xs: "none" } }}>
            <Pagination
              count={Math.ceil(totalRecords / rowsPerPage)}
              page={page + 1}
              sx={paginationStyles}
              shape="rounded"
              onChange={(e, v) => onPageChange(v - 1)}
            />
          </Box>
          <Box sx={{ display: { lg: "none", sm: "block", xs: "block" } }}>
            <TablePagination
              component="div"
              count={Math.ceil(totalRecords / rowsPerPage)}
              page={page}
              onPageChange={(e, v) => onPageChange(v)}
              labelDisplayedRows={() => ""}
              rowsPerPageOptions={[]}
              nextIconButtonProps={{
                disabled: page >= Math.ceil(totalRecords / rowsPerPage) - 1,
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
