import { css } from "@emotion/css";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { ReactComponent as CancelIcon } from "../../assets/images/ralvie/Close.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/ralvie/error-icon.svg";
import { ReactComponent as TagInactiveIcon } from "../../assets/images/ralvie/tag-icon-inactive.svg";
import CustomButton from "../../components/CustomButton";
import CustomSwitch from "../../components/CustomSwitch";
import CustomTextField from "../../components/DescriptionBox";
import AppContext from "../../config/AppContext";
import { updateEvent } from "../../services/EventService";
import { findProjectsByAssignedMember } from "../../services/ProjectService";
import { checkGrammer, createTimesheet } from "../../services/WorklogService";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext } from "../../util/context";
import CancelDialog from "../project/CancelDialog";
import EventList from "./EventList";

function groupEventsByApp(events) {
  let groupedEvents = [];

  events.forEach((event) => {
    const existingGroup = groupedEvents.find(
      (group) =>
        group.application?.name === event.application.name &&
        group.data.app !== "afk"
    );

    if (existingGroup) {
      const start = existingGroup.start
        ? new Date(Math.min(existingGroup.start, event.start))
        : event.start;
      const end = existingGroup.end
        ? new Date(Math.max(existingGroup.end, event.end))
        : event.end;
      if (existingGroup.duration < event.duration) {
        existingGroup.id = event.id;
        existingGroup.timestamp = event.timestamp;
        existingGroup.duration = event.duration;
        existingGroup.data = event.data;
        existingGroup.event_id = event.event_id;
        existingGroup.title = event.title;
        existingGroup.start = start;
        existingGroup.end = end;
        existingGroup.projectId = event.projectId;
      }
      existingGroup.events.push({
        ...event,
        id: event.id,
        timestamp: event.timestamp,
        duration: event.duration,
        data: event.data,
        start: event.start,
        end: event.end,
        event_id: event.event_id,
        title: event.title,
      });
    } else {
      groupedEvents.push({
        ...event,
        events: [event],
        start: event.start,
        end: event.end,
      });
    }
  });
  groupedEvents = groupedEvents.filter((event) => event.duration >= 30);
  return groupedEvents;
}
export default function AILogWork(props) {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const { mode } = useContext(AppContext);
  const [totalEventsDuration, setTotalEventsDuration] = useState(null);
  const [totalEventsDurationInSec, setTotalEventsDurationInSec] = useState(0);
  const [toggleSubEventsDuration, setToggleSubEventsDuration] = useState(0);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const selectedEventsRef = useRef(selectedEvents);
  const [totalEvents, setTotalEvents] = useState([]);

  const { setEventsToLog, setReload, setLogSubmited } =
    useContext(TimesheetContext);
  const [tags, setTags] = useState([]);
  const [unSelectedEvents, setUnSelectedEvents] = useState([]);
  const [eventCancelDialogOpen, setEventCancelDialogOpen] = useState(false);
  const unSelectedEventsRef = useRef(unSelectedEvents);

  useEffect(() => {
    if (unSelectedEvents?.length > 0) {
      let totalEvents = 0;
      selectedEventsRef?.current?.forEach((event) => {
        totalEvents = totalEvents + event?.events?.length;
      });
      let updatedTotalEvents = totalEvents - unSelectedEvents?.length;
      setTotalEvents(updatedTotalEvents);
    } else {
      let totalEvents = 0;
      selectedEventsRef?.current?.forEach((event) => {
        totalEvents = totalEvents + event?.events?.length;
      });
      setTotalEvents(totalEvents);
    }
  }, [unSelectedEvents]);

  useEffect(() => {
    let events = props.eventsToLog;
    if (events) {
      const concatenatedTitle = [
        ...new Set(
          props.eventsToLog?.events.map((event) => {
            let title = event.title || ""; // Handle case where title might be undefined
            // Extract the portion of the title before '-' or '|'
            const truncatedTitle = title.split(/[-|]/)[0].trim();
            return truncatedTitle;
          })
        ),
      ].join(" • ");
      let groupedEvents = groupEventsByApp(props.eventsToLog?.events);
      selectedEventsRef.current = groupedEvents;
      setSelectedEvents(groupedEvents);
      if (props.eventsToLog?.project) {
        let tags = props.eventsToLog?.project?.tags;
        const filteredObjects = tags?.filter((obj) =>
          props.eventsToLog?.tags?.includes(obj.id)
        );
        setState({
          ...state,
          title: concatenatedTitle,
          projectId: props.eventsToLog?.project?.id,
          tags: filteredObjects,
        });
      }
    } else {
      setTotalEventsDuration(null);
      setTotalEventsDurationInSec(0);
      selectedEventsRef.current = [];
      setSelectedEvents([]);
    }
  }, [props.eventsToLog]);

  useEffect(() => {
    let events = selectedEvents;
    let totalDuration = 0;
    if (events && events instanceof Array) {
      let totalEvents = [];
      events.forEach((e) => {
        totalEvents.push(...e?.events);
        let duration = e?.events?.reduce((accumulator, currentValue) => {
          return accumulator + currentValue?.duration;
        }, 0);
        if (!duration) duration = e.duration;
        totalDuration += duration;
      });
      const result = totalEvents
        ?.map((e) => e.id)
        .filter((element) => !unSelectedEventsRef?.current?.includes(element));

      setTotalEvents(result?.length);
      setTotalEventsDuration(CommonUtil.getDurationWithRoundoff(totalDuration));
      setTotalEventsDurationInSec(totalDuration);
    } else {
      setTotalEventsDuration(null);
      setTotalEventsDurationInSec(0);
    }
  }, [selectedEvents]);

  useEffect(() => {
    if (totalEventsDurationInSec > 0) {
      setTotalEventsDuration(
        CommonUtil.getDurationWithRoundoff(totalEventsDurationInSec)
      );
    } else {
      setTotalEventsDuration(null);
    }
  }, [totalEventsDurationInSec]);
  const [state, setState] = useState({
    projectId: "",
    tags: [],
    description: "",
    loggedDuration: 0,
    eventDuration: 0,
    events: [],
    hrs: 0,
    mins: 0,
    billable: true,
    title: "",
  });
  const [error, setError] = useState({
    projectId: "",
    tags: "",
    description: "",
    loggedDuration: "",
    eventDuration: "",
    events: "",
    title: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tagError, setTagError] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    findProjectsByAssignedMember(CommonUtil.getLoggedUser()?.id)
      .then((res) => {
        if (res.data && Array.isArray(res.data.data)) {
          const filteredProjects = res.data.data.filter(
            (project) => project && project.status !== "ARCHIVED"
          );
          setProjects(filteredProjects);
        } else {
          setProjects([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  }, []);
  const tagSelected = (event, value) => {
    if (value?.length > 20) {
      setError({
        ...error,
        tags: t("RAL0579"),
      });
      setTimeout(() => {
        setError({
          ...error,
          tags: "",
        });
      }, 2000);
      return;
    }
    setSelectedTags(value);
    setError({ ...error, tags: "" });
  };

  useEffect(() => {
    if (state.projectId) {
      let project = projects?.filter((p) => p?.id === state.projectId);
      if (project.length > 0) {
        setSelectedProject(project[0]);
        if (project[0]?.tags) {
          setTags(project[0]?.tags);
        }
      }
    }
  }, [state.projectId, projects]);

  useEffect(() => {
    if (state.tags) {
      setSelectedTags(state.tags);
    }
  }, [state.tags]);

  useEffect(() => {
    isValidForm();
  }, [state, selectedTags]);

  useEffect(() => {
    if (totalEventsDuration) {
      let min = 0;
      if (totalEventsDuration?.hr === 0 && totalEventsDuration?.min === 0) {
        min = 1;
      } else {
        min = totalEventsDuration?.min;
      }
      setState({
        ...state,
        hrs: totalEventsDuration?.hr,
        mins: min,
      });
    }
  }, [totalEventsDuration]);

  const handleEventUpdate = (parent) => {
    if (!eventToEdit?.title) {
      toast.error(t("RAL0580"));
      return;
    }
    updateEvent(eventToEdit?.id, { windowTitle: eventToEdit.title }).then(
      (res) => {
        if (res.status === 200) {
          let events = selectedEvents.filter((e) => e.id !== parent?.id);
          if (parent?.events?.length > 0) {
            let subEvents = parent?.events?.filter(
              (e) => e.id !== eventToEdit?.id
            );
            subEvents.push(eventToEdit);
            if (events.length === 0 || parent.id === eventToEdit?.id) {
              let updatedParent = { ...parent, title: eventToEdit.title };

              let updatedEvent = { ...updatedParent, events: subEvents };
              events.push(updatedEvent);
            } else {
              let updatedEvent = { ...parent, events: subEvents };
              events.push(updatedEvent);
            }
          } else {
            events.push(eventToEdit);
          }
          selectedEventsRef.current = [events];
          setSelectedEvents([events]);
          setEventToEdit(null);
        }
      }
    );
  };

  const handleUnselectEvent = (event, parent) => {
    let id = event?.id;
    let isIdExist = unSelectedEvents.find((e) => e === id);
    if (isIdExist) {
      let events = unSelectedEvents.filter((e) => e != id);
      setUnSelectedEvents(events);
      let duration = totalEventsDurationInSec + Number(event?.duration);
      setTotalEventsDurationInSec(duration);
      setToggleSubEventsDuration(
        toggleSubEventsDuration + Number(event?.duration)
      );
    } else {
      let events = [...unSelectedEvents, id];
      let parentEvents = parent.events.map((e) => e.id);

      if (parentEvents.every((element) => events.includes(element))) {
        let updatedEventsToLog = selectedEvents.filter(
          (e) => e.id !== parent.id
        );

        if (updatedEventsToLog.length < 1) {
          setEventCancelDialogOpen(true);
        } else setEventsToLog(updatedEventsToLog);

        const result = unSelectedEvents.filter(
          (element) => !parentEvents.includes(element)
        );
        unSelectedEventsRef.current = result;
        setUnSelectedEvents(result);
        return;
      }
      setUnSelectedEvents(events);
      let duration = totalEventsDurationInSec - Number(event?.duration);
      setTotalEventsDurationInSec(duration);
      setToggleSubEventsDuration(
        toggleSubEventsDuration - Number(event?.duration)
      );
    }
  };

  const isEventUnselected = (id) => {
    if (unSelectedEvents.find((e) => e === id)) {
      return false;
    } else {
      return true;
    }
  };

  const isValidForm = () => {
    if (!state.projectId || !state.title) {
      setIsSubmit(false);
      return false;
    }

    let totalDuration = 0;
    if (Number(state.hrs) > 0) {
      totalDuration = Number(state.hrs) * 60 * 60;
    }
    if (Number(state.mins) > 0) {
      totalDuration += Number(state.mins) * 60;
    }
    if (Number(state.secs) > 0) {
      totalDuration += Number(state.secs);
    }
    if (totalDuration < 60) {
      setIsSubmit(false);
      return false;
    }
    setIsSubmit(true);
  };

  const handleSubmit = () => {
    if (!state.projectId) {
      setError({
        ...error,
        projectId: t("RAL0001"),
      });
      return false;
    }

    let totalDuration = 0;
    if (Number(state.hrs) > 0) {
      totalDuration = Number(state.hrs) * 60 * 60;
    }
    if (Number(state.mins) > 0) {
      totalDuration += Number(state.mins) * 60;
    }
    if (Number(state.secs) > 0) {
      totalDuration += Number(state.secs);
    }
    if (totalDuration < 60) {
      setError({
        ...error,
        loggedDuration: t("RAL0581"),
      });
      return false;
    }

    let events = [];
    selectedEvents?.forEach((e) => {
      if (e?.events?.length > 0) {
        let eventList = e?.events?.filter(
          (obj) => !unSelectedEvents.includes(obj.id)
        );
        let eventIds = eventList?.map((obj) => obj.id);
        if (eventIds) events.push(...eventIds);
      } else {
        events.push(e?.id);
      }
    });

    let eventsToLog = selectedEvents.filter(
      (item) => !unSelectedEvents.includes(item.id)
    );

    let eventTotalDuration = 0;
    eventsToLog.forEach((e) => {
      let duration = e?.events?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue?.duration;
      }, 0);
      if (!duration) duration = e.duration;
      eventTotalDuration += duration;
    });

    let payload = {
      ...state,
      tags: selectedTags.length > 0 ? selectedTags : [],
      loggedDuration: totalEventsDurationInSec,
      eventDuration: totalEventsDurationInSec,
      events: events,
      timesheetDate: props.filter?.selectedDate?.utc().format(),
    };
    setIsSubmit(false);
    setIsLoading(true);
    createTimesheet(payload).then((res) => {
      setIsLoading(false);
      if (res.data?.code == "RCI0039") {
        toast.success(res.data?.message);
        props.handleRemoveEvent(props.eventsToLog);
        setReload(true);
        setLogSubmited(true);
      } else {
        toast.error(res.data?.message);
      }
    });
  };

  const handleRemoveEvent = (id) => {
    let events = selectedEvents.filter((e) => e.id !== id);
    selectedEventsRef.current = [events];
    setSelectedEvents([events]);
    if (events.length < 1) {
      setEventsToLog([]);
    }
  };
  const doGrammarCheck = () => {
    let payload = {
      sentence: state.description,
      language: localStorage.getItem("text-summary-lang"),
    };
    checkGrammer(payload).then((res) => {
      if (res.data?.data && res.data?.data?.corrected_sentence) {
        setState({
          ...state,
          description: res.data?.data?.corrected_sentence,
        });
      }
    });
  };

  const textAreaRef = useRef(null);

  const handleDescriptionChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
    setError((prevError) => ({
      ...prevError,
      description: "",
    }));
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      const { scrollHeight } = textAreaRef.current;
      const height = Math.max(40, Math.min(scrollHeight, 200));
      textAreaRef.current.style.height = `${height}px`;
    }
  }, [state.description]);

  const handleCancelButton = () => {
    setIsCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setIsCancelDialogOpen(false);
    setEventCancelDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsCancelDialogOpen(false);
    setEventCancelDialogOpen(false);
    props.handleRemoveEvent(props.eventsToLog);
  };

  return (
    <div
      className={css`
        min-height: calc(100% - 20px);
        position: relative;
        // margin: 8px;
      `}
    >
      <Box
        className={css`
          height: calc(89% - 20px);
          overflow: hidden;
          padding-right: 0px;
          :hover {
            overflow-y: auto;
            padding-right: 0px;
          }
        `}
      >
        <Box display={"flex"} flexDirection={"column"} mb={0} rowGap={2}>
          <Box item width={"100%"}>
            <CustomTextField
              id="textfield-ai-timesheet-title"
              hideLabel={true}
              onChange={(e) => {
                setState({ ...state, title: e.target.value });
                setError({
                  ...error,
                  title: "",
                });
              }}
              placeholder={t("RAL0338")}
              required
              helperText={error?.title}
              error={error?.title}
              label={t("RAL0338")}
              value={state.title}
              // inputProps={{
              //   maxLength: 50,
              // }}
            />
          </Box>
          <Box item width={"100%"}>
            <CustomTextField
              id="textfield-ai-timesheet-description"
              hideLabel={true}
              multiline
              rows={1}
              inputRef={textAreaRef}
              onChange={handleDescriptionChange}
              placeholder={t("RAL0292")}
              required
              helperText={error?.description}
              error={Boolean(error?.description)}
              label={t("RAL0292")}
              value={state.description}
              sx={{
                ".MuiInputBase-input.MuiOutlinedInput-input": {
                  padding: "0px !important",
                },
                "& .MuiOutlinedInput-root": {
                  overflow: "hidden",
                },
              }}
              inputProps={{
                maxLength: 2000,
              }}
              // InputProps={{
              //   endAdornment: (
              //     <IconButton
              //       title="Grammar check"
              //       variant="text"
              //       onClick={() => doGrammarCheck()}
              //       disabled={CommonUtil.isEmptyString(state.description)}
              //     >
              //       <ReviewsIcon color="var(--text-primary)" />
              //     </IconButton>
              //   ),
              // }}
            />
            <Box display={"flex"} flexDirection={"row-reverse"}>
              <Typography
                id="textfield-ai-timesheet-description"
                variant="activity_log_count"
                sx={{
                  color: "var(--text-secondary-80-80)",
                }}
              >
                {state?.description?.length}/2000
              </Typography>
            </Box>
          </Box>
          <Box item width={"100%"} mt={-1.5}>
            <Autocomplete
              error={!CommonUtil.isEmptyString(error.projectId)}
              helperText={error.projectId}
              id="dropdown-member-create-project"
              fullWidth
              size={"small"}
              getOptionLabel={(option) => option?.name || ""}
              clearText="Clear"
              value={selectedProject}
              options={projects ? projects : []}
              noOptionsText={t("RAL0732")}
              renderInput={(params) => (
                <CustomTextField
                  id="dropdown-member-create-project"
                  hideLabel={true}
                  dropdown={true}
                  onChange={(e) => console.log()}
                  placeholder={t("RAL0288")}
                  required
                  helperText={error?.projectId}
                  error={error?.projectId}
                  {...params}
                  label={t("RAL0573")}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setState({
                    ...state,
                    projectId: newValue.id,
                    tags: null,
                  });
                } else {
                  setState({
                    ...state,
                    projectId: null,
                    tags: null,
                  });
                  setSelectedProject(null);
                }
                setSelectedTags([]);
                setError({
                  ...error,
                  projectId: "",
                });
              }}
              sx={{
                ".MuiInputBase-input.MuiOutlinedInput-input": {
                  paddingX: "10px !important",
                },
              }}
            />
          </Box>
          <Box item width={"100%"}>
            <Autocomplete
              disabled={!selectedProject}
              disableCloseOnSelect={true}
              multiple
              id="tags-outlined"
              options={tags ? tags : []}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              noOptionsText={t("RAL0720")}
              ChipProps={{
                sx: {
                  backgroundColor: "var(--popover-background) !important",
                  color: "var(--text-secondary-80)",
                  borderRadius: "4px",

                  "& .MuiChip-deleteIcon": {
                    color: "var(--text-secondary-50)",
                  },
                },
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    id="tags-outlined"
                    key={option.id}
                    label={option.name}
                    icon={
                      option.iconUrl ? (
                        <img
                          id="tags-outlined"
                          src={option.iconUrl}
                          alt={option.name}
                          height={18}
                          width={18}
                        />
                      ) : (
                        <TagInactiveIcon
                          id="tags-inactive-icon"
                          height={17.99}
                          width={17.99}
                        />
                      )
                    } // Dynamically set the icon
                    {...getTagProps({ index })}
                    deleteIcon={<CancelIcon />}
                  />
                ))
              }
              value={selectedTags}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={tagSelected}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > span": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={option.id}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "auto",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      display: "inline",
                    }}
                  >
                    <Box display={"flex"} columnGap={1} alignItems={"center"}>
                      {option?.iconUrl ? (
                        <img
                          id="tags-outlined"
                          src={option?.iconUrl}
                          alt={option.name}
                          height={17.99}
                          width={17.99}
                        />
                      ) : (
                        <TagInactiveIcon height={17.99} width={17.99} />
                      )}

                      <Box>{option.name}</Box>
                    </Box>
                  </Box>
                </Box>
              )}
              sx={{
                "& .MuiOutlinedInput-root": {
                  background: tagError
                    ? "#FFF1F1 0% 0% no-repeat padding-box"
                    : "#F4F5F8 0% 0% no-repeat padding-box",
                  "& fieldset": {
                    borderRadius: "none",
                    border: "none",
                  },
                  ".MuiInputBase-input.MuiOutlinedInput-input": {
                    paddingY: "4px !important",
                    paddingX: "5px !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "none",
                  },
                  "&:hover fieldset": {
                    borderColor: "none",
                  },
                  "& .MuiInputLabel-root ": {
                    display: "none",
                    border: "none",
                  },
                },
                "& .MuiChip-root": {
                  borderRadius: "4px",
                },
              }}
              renderInput={(params) => (
                <CustomTextField
                  dropdown={true}
                  hideLabel={true}
                  onChange={(e) => console.log()}
                  required
                  helperText={error?.tags}
                  error={error?.tags}
                  {...params}
                  label={t("RAL0191")}
                />
              )}
            />
          </Box>
          {/* <Box
            display={"flex"}
            pl={2}
            py={1}
            bgcolor={"var(--background-color-12)"}
            alignItems={"center"}
          >
            <Box item flexGrow={1}>
              <Typography
                id="ai-timesheet-totalloggedtime"
                className="worklog-time"
              >
                {t("RAL0574")}
              </Typography>
            </Box>
            <Box item>
              <CustomTextField
                disabled={true}
                id="ai-timesheet-hours"
                label=""
                value={state?.hrs}
                onChange={(e) => {
                  if (Number(e.target.value <= 24)) {
                    let min =
                      Number(e.target.value) === 24 ? Number(0) : state.mins;
                    setState({
                      ...state,
                      hrs: Number(e.target.value),
                      mins: min,
                    });
                    setError({ ...error, loggedDuration: "" });
                  }
                }}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root ": {
                    backgroundColor: "var(--background-color-3) !important",
                    height: "40px",
                    width: "40px",
                  },
                  ".MuiInputBase-input.MuiOutlinedInput-input": {
                    textAlign: "center",
                    padding: "0px !important",
                  },
                }}
              />
            </Box>
            <Box item px={1} mt={1}>
              <Typography id="ai-timesheet-hours" className="worklog-time">
                h
              </Typography>
            </Box>
            <Box item>
              <CustomTextField
                disabled={true}
                id="ai-timesheet-mins"
                label=""
                value={state?.mins}
                onChange={(e) => {
                  if (Number(e.target.value <= 60) && state.hrs !== 24) {
                    setState({ ...state, mins: Number(e.target.value) });
                    setError({ ...error, loggedDuration: "" });
                  }
                }}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root ": {
                    backgroundColor: "var(--background-color-3) !important",
                    height: "40px",
                    width: "40px",
                  },
                  ".MuiInputBase-input.MuiOutlinedInput-input": {
                    textAlign: "center",
                    padding: "0px !important",
                  },
                }}
              />
            </Box>
            <Box item px={1} mt={1}>
              <Typography id="ai-timesheet-mins" className="worklog-time">
                m
              </Typography>
            </Box>
          </Box> */}

          {error?.loggedDuration && (
            <Box display={"flex"} alignItems={"center"}>
              <Box item pr={1}>
                {<ErrorIcon id="ai-timesheet-erroricon" />}
              </Box>
              <Box item>
                <Typography id="ai-timesheet-duration" className="helper-text">
                  {error?.loggedDuration}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            className={css`
              background: var(--background-color-13) 0% 0% no-repeat padding-box;
              border-radius: 5px;
              opacity: 1;
              display: flex;
              padding: 12px;
              align-items: center;
            `}
          >
            <Box item flexGrow={1}>
              <Typography
                id="ai-timesheet-billable"
                className={css`
                  font: normal normal normal 16px/20px Gotham;
                  letter-spacing: 0px;
                  color: var(--text-secondary-50);
                  opacity: 1;
                `}
              >
                {t("RAL0341")}
              </Typography>
            </Box>
            <Box item>
              <CustomSwitch
                id="ai-timesheet-switch"
                checked={state.billable}
                onChange={(e) =>
                  setState({ ...state, billable: e.target.checked })
                }
              />
            </Box>
          </Box>
          <Box
            item
            className={css`
              alignitems: center;
              display: flex;
            `}
          >
            <Box flexGrow={1}>
              <Typography
                variant="header3"
                id="ai-timesheet-events"
                sx={{
                  color: "var( --text-secondary-80)",
                  font: "normal normal bold 18px/20px Gotham",
                  letterSpacing: "0px",
                }}
              >
                {t("RAL0048")}
              </Typography>
              <Box
                sx={{
                  marginLeft: "8px",
                  display: "inline-block",
                  width: "30px",
                  height: "20px",
                  background: "var(--background-color-33)",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="header3"
                  id="ai-timesheet-length"
                  sx={{
                    verticalAlign: "text-top",
                    color: "var(--text-color-4)",
                    font: "normal normal medium 12px/15px Gotham",
                  }}
                >
                  {totalEvents}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="header3"
                sx={{
                  color: "var( --text-secondary-80)",
                  font: "normal normal medium 16px/19px Gotham",
                  letterSpacing: "0px",
                }}
              >
                {totalEventsDuration?.hr +
                  "h " +
                  totalEventsDuration?.min +
                  "m " +
                  totalEventsDuration?.sec +
                  "s"}
              </Typography>
            </Box>
          </Box>
          <Box
            className="fadeLeft"
            rowGap={1}
            display={"flex"}
            flexDirection={"column"}
          >
            {selectedEvents?.map((e) => {
              let durationInSec = null;
              if (e.events) {
                durationInSec = e?.events?.reduce(
                  (accumulator, currentValue) => {
                    return accumulator + currentValue?.duration;
                  },
                  0
                );
              } else {
                durationInSec = e?.duration;
              }
              let duration =
                CommonUtil.getDurationWithoutRoundoff(durationInSec);
              return (
                <EventList
                  editable={true}
                  event={e}
                  duration={duration}
                  onRemove={() => handleRemoveEvent(e.id)}
                  isEventUnselected={(id) => isEventUnselected(id)}
                  onRemoveSubevent={(se) => handleUnselectEvent(se, e)}
                  setEventToEdit={(e) => setEventToEdit(e)}
                  onTitleUpdate={() => handleEventUpdate(e)}
                  selectedEvents={selectedEvents}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row-reverse"}
        pt={2}
        className="btnFixedScroll"
      >
        <Box item pl={1} width={"50%"}>
          <CustomButton
            id="btn-ai-timesheet-add"
            disabled={!isSubmit}
            variant="contained"
            size="large"
            fullWidth="fullWidth"
            onClick={() => handleSubmit()}
          >
            <span id="btn-ai-timesheet-add">{t("RAL0342")}</span>
          </CustomButton>
        </Box>
        <Box item width={"50%"} pr={1}>
          <CustomButton
            variant="outlined"
            id="btn-ai-timesheet-cancel"
            size="large"
            fullWidth="fullWidth"
            onClick={handleCancelButton}
          >
            <span id="btn-ai-timesheet-cancel">{t("RAL0177")}</span>
          </CustomButton>
        </Box>
      </Box>

      <CancelDialog
        open={isCancelDialogOpen}
        onClose={handleCloseCancelDialog}
        onConfirm={handleConfirmCancel}
        header={t("RAL0393")}
        message={t("RAL0394")}
      />

      <CancelDialog
        open={eventCancelDialogOpen}
        onClose={handleCloseCancelDialog}
        onConfirm={handleConfirmCancel}
        header={t("RAL0740")}
        message={t("RAL0741")}
      />
      <Box
        sx={{
          display: isLoading ? "flex" : "none",
          zIndex: 999,
        }}
      >
        <CircularProgress
          style={{ top: "50%", left: "85%", position: "fixed" }}
        />
      </Box>
    </div>
  );
}
