import React, { useEffect, useState } from "react";
import SignupLayout from "./SignupLayout";
import "./Signup.scss";
import "./SelectWorkspace.scss";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSelectButtons from "../../components/CustomSelectButtons";
import { Grid } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as SelectTeamIcon } from "../../assets/images/ralvie/workspace_team_icon.svg";
import { ReactComponent as SelectSelfIcon } from "../../assets/images/ralvie/workspace_self_icon.svg";
import OnboardService from "../../services/OnboardService";
import { toast } from "sonner";

export default function SelectWorkspace() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const industryFromState = location.state ? location.state.industry : null;
  const emailFromState = location.state ? location.state.email : null;

  const [payload, setPayload] = useState({
    name: emailFromState,
    type: "",
    industry: industryFromState || "",
    email: emailFromState || "",
  });

  const [error, setError] = useState({
    name: "",
    type: "",
  });

  const selected = [
    {
      id: 1,
      type: "With my team",
      text: t("RAL0436"),
      icon: <SelectTeamIcon />,
      subText:  t("RAL0438"),
    },
    {
      id: 2,
      type: "By myself",
      text: t("RAL0437"),
      icon: <SelectSelfIcon />,
      subText:  t("RAL0439"),
    },
  ];

  const handleSubmit = (e) => {
    if (validate()) {
      if (payload.type === "With my team") {
        navigate("/pages/activity-track", {
          state: {
            industry: payload.industry,
            type: payload.type,
            email: payload.email,
          },
        });
      } else if (payload.type === "By myself") {
        payload.isIndividualUser = true;
        OnboardService.createCustomerCompany(payload).then((res) => {
          if (res.data.code === "UASI0011") {
            localStorage.setItem(
              "token",
              "Bearer " + res.data?.data?.access_token
            );
            navigate("/pages/activity-track", {
              state: {
                type: payload.type,
              },
            });
          } else if (res.data.code === "UASE0060") {
            navigate("/pages/activity-track", {
              state: {
                type: payload.type,
              },
            });
          } else {
            toast.error(res.data?.message);
          }
        });
      }
    }
  };

  const validate = () => {
    if (!payload.type) {
      toast.error(t("RAL0440"));
      return false;
    } else {
      return true;
    }
  };

  const buttonWidths = ["width292", "width293"];

  const handleSelectClick = (selectedType) => {
    setPayload({
      ...payload,
      type: selectedType,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [payload]);

  return (
    <>
      <SignupLayout>
        <div className="SelectWorkspace" onKeyDown={handleKeyDown}>
          <div className="w-100Email">
            <div>
              <span id="text-select-workspace-info" className="select_workspace_002">{t("RAL0130")}</span>
            </div>

            <div className="select_workspace_003">
              <span id="text-select-workspace-info_text1">{t("RAL0131")}</span>
            </div>

            <Grid className="selectWorkspaceBoxes">
              <CustomSelectButtons
                id="buttons-select-workspace-select"
                place="SelectWorkspace"
                selected={selected}
                onClick={handleSelectClick}
                buttonWidths={buttonWidths}
              />
            </Grid>

            <Grid item sm={12}>
              <CustomButton
                id="btn_select-workspace_submit"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleSubmit}
              >
                <span id="text_select-workspace_submit" className="select_workspace_submitbutton_006">
                  {t("RAL0137")}
                </span>
              </CustomButton>
            </Grid>

            <div className="select_workspace_004">
              <span id="text_select-workspace_userinfo">
                {t("RAL0019")}&nbsp;
                <span
                  id="link_select-workspace_signin"
                  className="select_workspace_click_signin_007"
                  onClick={() => navigate("/")}
                >
                  {t("RAL0099")}
                </span>
              </span>
            </div>
          </div>
        </div>
      </SignupLayout>
    </>
  );
}
