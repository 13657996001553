import React, { useState, useEffect } from "react";
import { timeZoneList } from "../constants/TimeZone";
import { t } from "i18next";

import { jwtDecode } from "jwt-decode";
import { getSystemSettings } from "../services/EventService";
import dayjs from "dayjs";
const CommonUtil = {
  isValidEmail(email) {
    if (email === undefined || email === "") return true;
    // const regex =
    //   /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const regex1 =
      /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,4}$/;
    const regex2 = /^[a-zA-Z]+(\.[a-zA-Z]+)?@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
    const regex3 = /^[a-zA-Z]\w*@[\w]+\.[a-zA-Z]{2,4}$/;
    const regex4 = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/;
    const regex5 =
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,4}\.[a-zA-Z]{2,4}$/;

    if (
      regex1.test(email.toLowerCase()) ||
      regex2.test(email.toLowerCase()) ||
      regex3.test(email.toLowerCase()) ||
      regex4.test(email.toLowerCase()) ||
      regex5.test(email.toLowerCase())
    ) {
      return true;
    }
    return false;
  },
  isValidMobile(mobile) {
    if (mobile === undefined || mobile === "") return true;
    const re = /^(\+\d{1,3}[- ]?)?\d{8,15}$/;
    return re.test(String(mobile));
  },

  isExceptNumericName(name) {
    if (name === undefined || name === "") return true;
    let finalString = name.replace(/[^\d.-]/g, "");
    return finalString;
  },

  isValidName(name) {
    if (name === undefined || name === "") return true;
    let regex = /^[a-zA-Z0-9]+[\'\s]?[a-zA-Z0-9 ]+$/;
    return regex.test(name);
  },
  isAlphaNumeric(data) {
    if (data === undefined || data === "") return true;
    let regex = /^[a-zA-Z0-9]+$/i;
    return regex.test(data);
  },

  isValidNumericName(name) {
    if (name === undefined || name === "") return true;
    let regex = /^[A-Za-z0-9 ]+$/;
    return regex.test(name);
  },

  isValidNumeric(name) {
    if (name === undefined || name === "") return true;
    let regex = /^[0-9\. ]+$/;
    return regex.test(name);
  },
  isValidPasitiveNumeric(name) {
    if (name === undefined || name === "") return true;
    let regex = /^[0-9]+$/;
    return regex.test(name);
  },
  isValidDoubleValue(value) {
    if (value === undefined || value === "") return true;
    let regex = /^[+-]?([1-9][0-9]*|0)(\.[0-9]+)?((e|E)[+-]?[0-9]+)?$/;
    return regex.test(value);
  },

  isValidCode(code) {
    if (code === undefined || code === "") return true;
    let regex = /^[a-zA-Z0-9_-]*$/;
    return regex.test(code);
  },
  isValidZipcode(areaCode) {
    if (
      areaCode.length < 5 ||
      areaCode.length > 6 ||
      !areaCode.match(/^[0-9 ]+$/)
    )
      return true;
  },
  isAlphaNumericSlash(code) {
    if (code === undefined || code === "") return true;
    let regex = /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _]*)$/;
    return regex.test(code);
  },

  isEmptyString(val) {
    if (val === null || val === undefined || val === "") return true;
    else return false;
  },
  isAlphaNumericSpace(data) {
    if (data == undefined || data === "") return true;
    let regex = /^[a-zA-Z0-9_ ]+$/i;
    return regex.test(data);
  },
  isValidCompany(data) {
    if (data == undefined || data === "") return true;
    let regex =
      /^[A-Za-z0-9][A-Za-z0-9\s!@#$%^&*()\-_=+{}\[\]:;"'<>,.?/|\\~`]*$/;
    return regex.test(data);
  },
  isNonWhitespace(data) {
    if (data == undefined || data === "") return true;
    let regex = /^[\S]+$/;
    return regex.test(data);
  },

  isAlphaNumericSpecialChar(data) {
    if (data == undefined || data === "") return true;
    let regex = /^[ A-Za-z0-9_@./#&+-]*$/;
    return regex.test(data);
  },
  isAlphaNumericUnderscore(data) {
    if (data == undefined || data === "") return true;
    let regex = /^[A-Za-z0-9_]*$/;
    return regex.test(data);
  },
  isAlphaNumericPassword(str) {
    if (str == undefined || str === "") return true;
    let regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
    return regex.test(str);
  },

  isValidUrl(url) {
    if (url === undefined || url === "") return true;
    let regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return regex.test(String(url));
  },

  formatDayAndMonth(numValue) {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  },

  isEmpty(obj) {
    if (obj === null || obj === undefined) return true;
    return Object.keys(obj).length === 0;
  },
  getCompany() {
    return localStorage ? localStorage.getItem("companyId") : null;
  },

  getLocalDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let newDate = new Date(date);
    let formated =
      newDate.getDate() +
      "-" +
      monthNames[newDate.getMonth()].substring(0, 3) +
      "-" +
      newDate.getFullYear() +
      ", " +
      (newDate.getHours() < 10
        ? "0" + newDate.getHours()
        : newDate.getHours()) +
      ":" +
      (newDate.getMinutes() < 10
        ? "0" + newDate.getMinutes()
        : newDate.getMinutes()) +
      ":" +
      (newDate.getSeconds() < 10
        ? "0" + newDate.getSeconds()
        : newDate.getSeconds());
    return formated;
  },

  getFormatedDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let newDate = new Date(date);
    let formated =
      newDate.getDate() +
      ", " +
      monthNames[newDate.getMonth()] +
      " " +
      newDate.getFullYear();
    return formated;
  },

  handleTextOverflow(text, length) {
    let maxLength = 20;
    if (length) {
      maxLength = length;
    }
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  },

  handleTextOverflowEvents(text, length) {
    let maxLength = 100;
    if (length) {
      maxLength = length;
    }
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  },

  isSpaceExist(text) {
    var inValid = /\s/;
    return inValid.test(text);
  },

  formatWithSuffix(date) {
    const day = dayjs(date);
    if (!day.isValid()) {
      return "Invalid Date";
    }
    const dayOfMonth = day.date();
    let suffix = "th";
    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
      suffix = "st";
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
      suffix = "nd";
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
      suffix = "rd";
    }
    return `${dayOfMonth}${suffix}`;
  },

  getLocalDate1(date) {
    let newDate = new Date(date);
    return newDate
      .toISOString()
      .replace("T", " ")
      .substr(0, newDate.toISOString().lastIndexOf(":"));
  },

  getLocalTime(time) {
    let newTime = new Date(parseInt(time));
    return newTime.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    });
  },

  sentenceCase(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  getDefaultCountryCode() {
    let countryCode = "";
    if (localStorage.getItem("locationInfo")) {
      countryCode = JSON.parse(
        localStorage.getItem("locationInfo")
      ).country_code.toLowerCase();
    }
    return countryCode;
  },
  getDefaultCallingCountryCode() {
    let countryCallingCode = "";
    if (localStorage.getItem("locationInfo")) {
      countryCallingCode = JSON.parse(
        localStorage.getItem("locationInfo")
      ).country_calling_code.toLowerCase();
    }
    return countryCallingCode;
  },
  isValidPassword(password) {
    if (password === undefined || password === "") return true;
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/;
    return re.test(String(password));
  },

  formatLongText(text) {
    if (text && text.length > 20) {
      return text.substr(0, 19) + "...";
    } else {
      return text;
    }
  },
  getLocationDetails(query) {
    let res = "";
    if (localStorage.getItem("locationInfo")) {
      let location = JSON.parse(localStorage.getItem("locationInfo"));
      switch (query) {
        case "ip": {
          res = location.ip;
          break;
        }
        case "latitude": {
          res = location.latitude;
          break;
        }
        case "longitude": {
          res = location.longitude;
          break;
        }
        case "country_code": {
          res = location.country_code;
          break;
        }
        case "country_name": {
          res = location.country_name;
          break;
        }
        case "country_calling_code": {
          res = location.country_calling_code;
          break;
        }
      }
    }
    return res;
  },
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    let domain = window?.location?.host?.replace("level", "");
    if (window?.location?.host === domain)
      document.cookie = cname + "=" + cvalue + ";" + expires + `;path=/`;
    else
      document.cookie =
        cname + "=" + cvalue + ";" + expires + `;domain=${domain};path=/`;
  },
  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  getFormatedDateString(date) {
    let today = new Date(date);
    let fdate =
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    return fdate;
  },

  resetUserSession() {
    const language = localStorage.getItem("i18nextLng");
    localStorage.clear();
    if (language) {
      localStorage.setItem("i18nextLng", language);
    }
  },

  resetCompanySession() {
    localStorage.removeItem("selectedProjects");
    localStorage.removeItem("checkedState");
    localStorage.removeItem("profilePic");
  },

  getTimeZoneNameByValue(timeZone) {
    return timeZoneList.find((t) => t.value === timeZone)?.name;
  },
  getTimeZoneByValue(timeZone) {
    return timeZoneList.find((t) => t.value === timeZone);
  },
  GetWindowDimenssion() {
    const [size, setSize] = useState({
      width: window.innerWidth + "px",
      height: window.innerHeight + "px",
    });
    const updateSize = () =>
      setSize({
        width: window.innerWidth + "px",
        height: window.innerHeight + "px",
      });
    useEffect(() => (window.onresize = updateSize), [size]);
    return size;
  },
  capitalizeFirst(str) {
    return typeof str === "string"
      ? str.charAt(0).toUpperCase() + str.slice(1)
      : str;
  },

  decodeToken() {
    const jwtToken = localStorage.getItem("token");
    let decoded_jwtToken = {};
    if (jwtToken) {
      try {
        decoded_jwtToken = jwtDecode(jwtToken);
      } catch {}
      localStorage.removeItem("USER");
      localStorage.setItem("USER", JSON.stringify(decoded_jwtToken));
    }
    return decoded_jwtToken;
  },

  getLoginType() {
    let loginType = null;
    let user = CommonUtil.decodeToken();
    if (user) {
      if (user?.companyType === "CUSTOMER") {
        loginType = "RETAILCLIENT";
      } else if (
        user?.companyType === "PARTNER" &&
        user?.partnerType !== "PRINCIPAL"
      ) {
        loginType = "HQ";
      } else {
        loginType = "PRINCIPAL";
      }
    }
    return loginType;
  },

  getRoleCode() {
    let roleCode = null;
    let user = CommonUtil.decodeToken();
    if (user) {
      roleCode = user?.roleCode;
    }
    return roleCode;
  },
  getPageNumber(int) {
    if (int == 0) {
      return 1;
    } else {
      return int + 1;
    }
  },
  getTimeZoneId() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
    // if (getSystemSettings("time_zone")) {
    //   let timeZone = timeZoneList.find(
    //     (e) => e.value === getSystemSettings("time_zone")
    //   );
    //   return timeZone ? timeZone?.zoneId : undefined;
    // }
  },
  getDurationWithoutRoundoff(seconds) {
    let duration = {
      sec: 0,
      hr: 0,
      min: 0,
    };
    if (seconds <= 59) {
      let s =
        Math.ceil(seconds) < 10 ? "0" + Math.ceil(seconds) : Math.ceil(seconds);
      duration.sec = s;

      // return "00:00:" + s;
    } else if (seconds < 3600) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      let m = minutes < 10 ? "0" + minutes : minutes;
      let s = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      if (s === 60) {
        duration.min = Number(m) + 1;
      } else {
        duration.sec = s;
        duration.min = m;
      }
      // return "00:" + m + ":" + s;
    } else {
      let hours = Math.floor(seconds / 3600);
      let remainingMinutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      let h = hours < 10 ? "0" + hours : hours;
      let m = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;
      let s = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      duration.sec = s;
      duration.min = m;
      duration.hr = h;
      // return `${h}:${m}+${s}`;
    }
    return duration;
  },
  getDurationWithRoundoff(seconds) {
    let duration = {
      sec: 0,
      hr: 0,
      min: 0,
    };
    if (seconds < 60) {
      let s =
        Math.ceil(seconds) < 10 ? "0" + Math.ceil(seconds) : Math.ceil(seconds);
      duration.min = 1;
      // return "00:00:" + s;
    } else if (seconds < 3600) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      let m = minutes < 10 ? "0" + minutes : minutes;
      let s = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      if (s === 60) {
        duration.min = Number(m) + 1;
      } else {
        duration.sec = s;
        duration.min = m;
      }
      // return "00:" + m + ":" + s;
    } else {
      let hours = Math.floor(seconds / 3600);
      let remainingMinutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      let h = hours < 10 ? "0" + hours : hours;
      let m = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;
      let s = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      duration.sec = s;
      duration.min = m;
      duration.hr = h;
      // return `${h}:${m}+${s}`;
    }
    return duration;
  },
  getLoggedUser() {
    let user = localStorage.getItem("USER");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  },

  isOwnerOrAdmin() {
    let user = localStorage.getItem("USER");
    if (user) {
      user = JSON.parse(user);
      if (user.roleCode === "ADMIN" || user.roleCode === "OWNER") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  isOwnerOrAdminOrTeamlead() {
    let user = localStorage.getItem("USER");
    if (user) {
      user = JSON.parse(user);
      if (
        user.roleCode === "ADMIN" ||
        user.roleCode === "OWNER" ||
        user.roleCode === "TEAM LEAD"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  isOwnerOrAdminOrTeamLead() {
    let user = localStorage.getItem("USER");
    if (user) {
      user = JSON.parse(user);
      if (
        user.roleCode === "ADMIN" ||
        user.roleCode === "OWNER" ||
        user.roleCode === "TEAM LEAD"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  pad(number) {
    return number < 10 ? "0" + number : number;
  },

  formatDate(dateString) {
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  },

  formatDateToUTC(dateString) {
    const date = new Date(dateString);

    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth();
    const utcDay = date.getUTCDate();
    const utcHours = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();
    const utcSeconds = date.getUTCSeconds();

    return `${utcYear}-${CommonUtil.pad(utcMonth + 1)}-${CommonUtil.pad(
      utcDay + 1
    )}T${CommonUtil.pad(utcHours)}:${CommonUtil.pad(
      utcMinutes
    )}:${CommonUtil.pad(utcSeconds)}Z`;
  },

  formatDateToUTCByOne(dateString) {
    const date = new Date(dateString);

    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth();
    const utcDay = date.getUTCDate();
    const utcHours = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();
    const utcSeconds = date.getUTCSeconds();

    return `${utcYear}-${CommonUtil.pad(utcMonth + 1)}-${CommonUtil.pad(
      utcDay
    )}T${CommonUtil.pad(utcHours)}:${CommonUtil.pad(
      utcMinutes
    )}:${CommonUtil.pad(utcSeconds)}Z`;
  },

  formatDateToUTCDate(dateString) {
    const date = new Date(dateString);

    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth();
    const utcDay = date.getUTCDate();
    const utcHours = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();
    const utcSeconds = date.getUTCSeconds();

    return `${utcYear}-${CommonUtil.pad(utcMonth + 1)}-${CommonUtil.pad(
      utcDay
    )}T${CommonUtil.pad(utcHours)}:${CommonUtil.pad(
      utcMinutes
    )}:${CommonUtil.pad(utcSeconds)}Z`;
  },

  formatDateToUTCTime(dateString) {
    const date = new Date(dateString);

    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth();
    const utcDay = date.getUTCDate();
    const utcHours = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();
    const utcSeconds = date.getUTCSeconds();

    return `${utcYear}-${CommonUtil.pad(utcMonth + 1)}-${CommonUtil.pad(
      utcDay
    )}T${CommonUtil.pad(utcHours)}:${CommonUtil.pad(
      utcMinutes
    )}:${CommonUtil.pad(utcSeconds)}Z`;
  },

  calculateDurationInSeconds(start, end) {
    // Convert start and end times to timestamps
    const startTimeStamp = start.getTime();
    const endTimeStamp = end.getTime();

    // Calculate duration in milliseconds
    const durationInMilliseconds = endTimeStamp - startTimeStamp;

    // Convert duration to seconds
    const durationInSeconds = durationInMilliseconds / 1000;

    return durationInSeconds;
  },
  getDuration(seconds) {
    let duration = {
      sec: 0,
      hr: 0,
      min: 0,
    };
    if (seconds < 60) {
      let s =
        Math.ceil(seconds) < 10 ? "0" + Math.ceil(seconds) : Math.ceil(seconds);
      duration.min = 1;
      // return "00:00:" + s;
    } else if (seconds < 3600) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      let m = minutes < 10 ? "0" + minutes : minutes;
      let s = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      duration.sec = s;
      duration.min = m;
      // return "00:" + m + ":" + s;
    } else {
      let hours = Math.floor(seconds / 3600);
      let remainingMinutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = Math.ceil(seconds % 60);
      let h = hours < 10 ? "0" + hours : hours;
      let m = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;
      let s = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
      duration.sec = s;
      duration.min = m;
      duration.hr = h;
      // return `${h}:${m}+${s}`;
    }
    return duration;
  },
  getFormatedDuration(time) {
    return time < 1 ? "00" : time;
  },
  copyToClipboard(text) {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      console.log("Fallback: Text copied to clipboard using execCommand");
    }
  },
  processTextSummary(text) {
    const summary = text?.replace(/\"/g, "").trim(); // Clean up the summary text
    const startIndex = summary.indexOf("Projects:");
    const cleanSummary = summary.substring(startIndex).trim();
    const lines = cleanSummary.split("\n"); // Split the summary into lines

    let htmlSummary = '<div class="summary">';
    lines.forEach((line) => {
      if (line.includes(":")) {
        const [label, content] = line.split(":", 2);
        htmlSummary += `<p><strong>${label.trim()}:</strong> ${content.trim()}</p>`;
      } else {
        htmlSummary += `<p>${line.trim()}</p>`;
      }
    });
    htmlSummary += "</div>";
    return htmlSummary;
  },
  getOS() {
    let OSName = "Windows";
    if (window.navigator.platform.indexOf("Win") !== -1) OSName = "Windows";
    if (window.navigator.platform.indexOf("Mac") !== -1) OSName = "MacOS";

    return OSName;
  },
  getSystemTimezone() {
    const date = new Date();
    const timezoneOffsetMinutes = date.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
    const offsetMinutes = Math.abs(timezoneOffsetMinutes) % 60;
    const sign = timezoneOffsetMinutes <= 0 ? "+" : "-";
    const formattedOffset = `(UTC${sign}${String(offsetHours).padStart(
      2,
      "0"
    )}:${String(offsetMinutes).padStart(2, "0")})`;

    const formatter = new Intl.DateTimeFormat("en", { timeZoneName: "long" });
    const timeZoneName = formatter.format(date).split(", ")[1];

    return `${formattedOffset} ${timeZoneName}`;
  },
};

export default CommonUtil;
