import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ReactComponent as DownloadIcon } from "../../assets/images/ralvie/downloadFile.svg";
import { ReactComponent as AppleIcon } from "../../assets/images/ralvie/icon_apple.svg";
import { ReactComponent as AppleIconDark } from "../../assets/images/ralvie/icon_apple_dark.svg";
import { ReactComponent as WindowsIcon } from "../../assets/images/ralvie/icon_windows.svg";
import CustomButton from "../../components/CustomButton";
import CustomIconButton from "../../components/CustomIconButton";
import AppContext from "../../config/AppContext";
import OnboardService from "../../services/OnboardService";
import "./ActivityTrack.scss";
import "./Signup.scss";
import SignupLayout from "./SignupLayout";

export default function ActivityTrack() {
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);
  let navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const industryFromState = location.state ? location.state.industry : null;
  const typeFromState = location.state ? location.state.type : null;
  const emailFromState = location.state ? location.state.email : null;
  const [packages, setPackages] = useState([
    {
      type: "exe",
      downloadLink:
        "https://minervaiot-storage-service-dev-global.s3.ap-southeast-1.amazonaws.com/public/ralvie/sundial/alpha/windows/Sundial_2.0.0.msi",
    },
    {
      type: "dmg",
      downloadLink:
        "https://minervaiot-storage-service-dev-global.s3.ap-southeast-1.amazonaws.com/public/ralvie/sundial/alpha/mac/Sundial_2.0.0.dmg",
    },
  ]);
  const [selected, setSelected] = useState(null);
  const getOS = () => {
    if (window.navigator.platform.indexOf("Mac") !== -1) {
      return "MacOS";
    } else if (window.navigator.platform.indexOf("Win") !== -1) {
      return "Windows";
    }
    return "Unknown"; 
  };


  useEffect(() => {
    // loadSundialPackages();
  }, []);

  const [payload, setPayload] = useState({
    sundial: "",
    industry: industryFromState || "",
    type: typeFromState || "",
    email: emailFromState || "",
  });

  const buttonWidths = ["width634"];

  const handleNextPage = () => {
    if (payload.type === "With my team") {
      navigate("/pages/create-company", {
        state: {
          industry: payload.industry,
          type: payload.type,
          email: payload.email,
        },
      });
    } else {
      navigate("/dashboard");
    }
  };

  const loadSundialPackages = () => {
    OnboardService.listSundialVersions()
      .then((res) => {
        if (res.data.code === "RCI0000") {
          const sundialPackages = res.data.data;
          if (sundialPackages instanceof Array && sundialPackages.length > 0) {
            setPackages(sundialPackages);
            return;
          } else {
            handleNextPage();
          }
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching sundial versions.");
        handleNextPage();
      });
  };

  const handleSubmit = (type) => {
    if (type && packages?.length > 0) {
      const link = document.createElement("a");
      let pkgs = packages.find((pkg) => pkg?.type?.toLowerCase() === type);
      if (pkgs) {
        link.href = pkgs?.downloadLink;
        link.download = "sundial_package";
        link.setAttribute("download", "sundial_package");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        handleNextPage();
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const validate = () => {
    if (!payload.sundial) {
      toast.error("Please select sundial.");
      return false;
    } else {
      return true;
    }
  };

  const handleSelectClick = (selectedSundial) => {
    setPayload({
      ...payload,
      sundial: selectedSundial,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (payload.sundial) {
        handleSubmit(payload.sundial);
      } else {
        handleNextPage();
      }
    }
  };

  useEffect(() => {
    const handleKeyDownEvent = (event) => handleKeyDown(event);
    document.addEventListener("keydown", handleKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyDownEvent);
    };
  }, [payload]);

  const navigationPath =
    payload.type === "With my team" ? "/pages/create-company" : "/dashboard";

  return (
    <>
      <SignupLayout>
        <div className="ActivityTrack">
          <div className="w-100Email">
            <div>
              <span className="activity_track_002">{t("RAL0132")}</span>
            </div>

            <div className="activityTrackDetails">
              <div>
                <p>{t("RAL0133")}</p>
                <p>{t("RAL0134")}</p>
                <p className="heading">{t("RAL0135")}</p>
                <p>{t("RAL0136")}</p>
              </div>
            </div>

            <Box display={"flex"} flexDirection={"column"} rowGap={2}>
              {getOS() === "MacOS" && packages?.find((pkg) => pkg?.type?.toLowerCase() === "dmg") && (
                <Box
                  onMouseEnter={() => setSelected("apple")}
                  onMouseLeave={() => setSelected(undefined)}
                  onClick={() => handleSubmit("dmg")}
                  className={mode === "dark" ? "activity_track_package_dark" : "activity_track_package"}
                >
                  {mode === "dark" ? <AppleIconDark /> : <AppleIcon />}
                  <Box display={"flex"} flexDirection={"column"} px={"16px"} flexGrow={1}>
                    <Typography className="header">{t("RAL0728")}</Typography>
                    <Typography className={"sub-title"}>{t("RAL0729")}</Typography>
                  </Box>

                  <CustomIconButton
                    size={isXlScreen ? "large" : "md"}
                    variant="square"
                    type="contained"
                    lightMode={<DownloadIcon height={isXlScreen ? "18px" : "14px"} />}
                    darkMode={<DownloadIcon height={isXlScreen ? "18px" : "14px"} />}
                  />
                </Box>
              )}

              {getOS() === "Windows" && packages?.find((pkg) => pkg?.type?.toLowerCase() === "exe" && pkg?.downloadLink) && (
                <Box
                  className={mode === "dark" ? "activity_track_package_dark" : "activity_track_package"}
                  onMouseEnter={() => setSelected("windows")}
                  onMouseLeave={() => setSelected(undefined)}
                  onClick={() => handleSubmit("exe")}
                >
                  <WindowsIcon />
                  <Box display={"flex"} flexDirection={"column"} px={"16px"} flexGrow={1}>
                    <Typography className="header">{t("RAL0730")}</Typography>
                    <Typography className={"sub-title"}>{t("RAL0731")}</Typography>
                  </Box>

                  <CustomIconButton
                    title={t("RAL0226")}
                    size={isXlScreen ? "large" : "md"}
                    variant="square"
                    type="contained"
                    lightMode={<DownloadIcon height={isXlScreen ? "18px" : "14px"} />}
                    darkMode={<DownloadIcon height={isXlScreen ? "18px" : "14px"} />}
                  />
                </Box>
              )}
            </Box>

            <Grid
              item
              md={12}
              xs={12}
              pt={2}
              className="activity_track_submitbutton_009"
            >
              <div className="activity_track_back_007">
                <Link
                  id={"link-activity-view-back"}
                  className="activity_track_back_text_007"
                  to="/pages/select-workspace"
                >
                  {t("RAL0125")}
                </Link>
              </div>

              {/* <div className="activity_track_back_007">
                <span
                  id={"link-activity-view-skip"}
                  className="activity_track_back_text_skip"
                  onClick={() => handleNextPage()}
                >
                  {t("RAL0144")}
                </span>
              </div> */}

              <div className="activity_track_submitbutton_008"></div>

              <div className="activity_track_submitbutton_008">
                <CustomButton
                  id="button-signup-sundial-download"
                  class="activity_track_submitbutton_005"
                  size="large"
                  onClick={handleNextPage}
                  variant="outlined"
                >
                  {t("RAL0144")}
                </CustomButton>
                {/* <CustomButton
                  id="button-signup-sundial-download"
                  class="activity_track_submitbutton_005"
                  size="large"
                  onClick={handleNextPage}
                  variant="contained"
                >
                  {t("RAL0126")}
                </CustomButton> */}
              </div>
            </Grid>
          </div>
        </div>
      </SignupLayout>
    </>
  );
}
