import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import CustomButton from "../../components/CustomButton";
import OnboardTextField from "../../components/OnboardTextField";
import OnboardService from "../../services/OnboardService";
import CommonUtil from "../../util/CommonUtils";
import "./Signup.scss";
import SignupLayout from "./SignupLayout";
import "./VerifyUser.scss";

export default function VerifyUser() {
  const { t, ready } = useTranslation();
  let navigate = useNavigate();
  const emailRef = useRef(null);
  useEffect(() => {
    if (ready && emailRef.current) {
      emailRef.current.focus();
    }
  }, [ready]);

  const [payload, setPayload] = useState({
    otpType: "RESET_PASSWORD",
    status: "",
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    otpType: "",
    status: "",
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = (e) => {
    if (validate()) {
      const email = payload.email;
      OnboardService.sendOtpToResetPassword(email).then((res) => {
        if (res.data.code === "RCI0015") {
          navigate("/pages/forgot-password", {
            state: { email: payload.email },
          });
          toast.success(t("RAL0151"));
        } else {
          setError({ ...error, email: res.data?.message });
          setTimeout(() => {
            setError({ ...error, email: "" });
          }, 60000);
          // toast.error(res.data?.message);
        }
      });
    }
  };

  const validate = () => {
    if (!payload.email) {
      setError({
        ...error,
        email: t("RAL0001"),
      });
      return false;
    }
    if (!CommonUtil.isValidEmail(payload.email)) {
      setError({
        ...error,
        email: t("RAL0022"),
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setPayload({
      ...payload,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <SignupLayout>
        <div className="VerifyUser">
          <div className="w-100Email">
            <div>
              <span id="text-verify-user-forgotpassword" className="forgot_password_002">{t("RAL0070")}</span>
            </div>

            {/* <div
              className="signup_email_verify_004"
              style={{ marginTop: "30px" }}
            >
              <span>{t("RAL0104")}</span>
            </div> */}

            <div className="forgot_password_003" style={{ marginTop: "30px" }}>
              <span id="text-verify-user-information">{t("RAL0106")}</span>
            </div>

            <Grid item sm={12} pt={4.5}>
              <OnboardTextField
                id="textfield-verify-user-email"
                autoComplete={false}
                required
                fullWidth
                label={t("RAL0003")}
                value={payload.email}
                name="email"
                onChange={handleChange}
                error={error.email}
                helperText={error.email}
                onKeyDown={(e) => handleKeyDown(e)}
                inputRef={emailRef}
                inputProps={{
                  maxLength: 36,
                  placeholder: t("RAL0651"),
                  autoComplete: "off",
                }}
                validation="email"
              />
            </Grid>

            <Grid item sm={12} className="buttonPadding">
              <CustomButton
                id="btn_verify_user_code"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleSubmit}
              >
                <span className="signup_email_verify_007">{t("RAL0107")}</span>
              </CustomButton>
            </Grid>

            <Box className="forgot_password_009">
              <span id="text-verify-user-back">{t("RAL0072")}</span> &nbsp;
              <span
                id={"link-veriry-user-forgotpassword"}
                className="forgot_password_010"
                onClick={() => navigate("/")}
              >
                {t("RAL0006")}
              </span>
            </Box>
          </div>
        </div>
      </SignupLayout>
    </>
  );
}
