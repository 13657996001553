import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as LeftArrow } from "../../assets/images/icon_left_arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/icon_right_arrow.svg";
import { ReactComponent as LeftArrowLight } from "../../assets/images/icon_left_arrow_dark.svg";
import { ReactComponent as RightArrowLight } from "../../assets/images/icon_right_arrow_dark.svg";
import CustomIconButton from "../../components/CustomIconButton";
import dayjs from "dayjs";
import { css } from "@emotion/css";
import AppContext from "../../config/AppContext";
function ButtonField(props) {
  const { t } = useTranslation();
  const { mode } = React.useContext(AppContext);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const {
    value,
    setOpen,
    setValue,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  const handlePrevDay = () => {
    if (value) {
      const newValue = value.subtract(1, "day");
      setValue(newValue);
    }
  };

  const handleNextDay = () => {
    if (!value.isSame(dayjs(), "day") && value) {
      const newValue = value.add(1, "day");
      setValue(newValue);
    }
  };

  const isToday = (date) => {
    return date?.isSame(dayjs(), "day");
  };

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <span>
        <CustomIconButton
          id="btn-prevweek-clndr-timesheet"
          title={t("RAL0331")}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          height={"30px"}
          width={"30px"}
          lightMode={<LeftArrowLight height={isXlScreen ? "14px" : "10px"} />}
          darkMode={<LeftArrow height={isXlScreen ? "14px" : "10px"} />}
          onClick={handlePrevDay}
        />
      </span>

      <span
        className={css`
          padding: 0px 8px;
        `}
      >
        <Typography
          id={id}
          name="btn-datepicker"
          disabled={disabled}
          aria-label={ariaLabel}
          onClick={() => setOpen?.((prev) => !prev)}
          variant="datepicker"
          sx={{
            color: "var(--text-secondary-80)",
            font: "normal normal medium 16px/19px Gotham",
            letterSpacing: "0px",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          <span className="dateLabel">
            {isToday(value) ? t("RAL0725") : value?.format("DD MMMM YYYY")}
          </span>
        </Typography>
      </span>

      <span>
        <CustomIconButton
          id="btn-nxtweek-clndr-timesheet"
          disabled={value.isSame(dayjs(), "day")}
          title={t("RAL0332")}
          size={isXlScreen ? "large" : "md"}
          variant="square"
          height={"30px"}
          width={"30px"}
          lightMode={<RightArrowLight height={isXlScreen ? "14px" : "10px"} />}
          darkMode={<RightArrow height={isXlScreen ? "14px" : "10px"} />}
          onClick={handleNextDay}
        />
      </span>
    </div>
  );
}

function ButtonDatePicker(props) {
  const { mode } = React.useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.selectedDate || dayjs());

  React.useEffect(() => {
    setValue(props.selectedDate || dayjs());
  }, [props.selectedDate]);

  React.useEffect(() => {
    props.onChange(value);
  }, [value]);

  return (
    <DatePicker
      disableFuture={true}
      id="btn-data-pickr-timesheet"
      slots={{ ...props.slots, field: ButtonField }}
      slotProps={{
        ...props.slotProps,
        field: { value, setOpen, setValue },
        day: {
          sx: {
            "&.MuiPickersDay-root.Mui-selected": {
              color: "#fff",
              borderRadius: "5px",
              background:
                "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
              borderColor: "#FA9C2B",
            },
            "&.MuiPickersDay-root.MuiPickersDay-today": {
              borderRadius: "5px",
              border: "1px solid #fa9c2b !important",
              color: mode == "dark" ? "#FFFFFF" : "#242424",
              background: mode == "dark" ? "#362C20" : "#FFF7ED",
              "&:hover": {
                color: "#fff",
                borderRadius: "5px",
                background:
                  "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
                borderColor: "#FA9C2B",
              },
            },
            ":hover": {
              color: "#fff",
              borderRadius: "5px",
              background:
                "transparent linear-gradient(315deg, #FA9C2B 0%, #C1700E 100%) 0% 0% no-repeat padding-box",
              borderColor: "#FA9C2B",
            },
          },
        },
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      label={value == null ? null : value.format("DD MMMM YYYY")}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    />
  );
}

export default function DashboardDatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker {...props} />
    </LocalizationProvider>
  );
}
