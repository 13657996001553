import { Box, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { ReactComponent as NoActivity } from "../../assets/images/NoActivity.svg";
import AppIcon from "../../components/AppIcon";
import { mostUsedApps } from "../../services/EventService";
import CommonUtil from "../../util/CommonUtils";
import { TimesheetContext } from "../../util/context";
import AppContext from "../../config/AppContext";
import dayjs from "dayjs";
import { css } from "@emotion/css";
import "./PieChartRes.scss";
import "./MostUsedApps.scss";
import { ReactComponent as DefaultAppIcon } from "../../assets/images/icon-app-default.svg";
import { ReactComponent as WorklogCaption } from "../../assets/images/ralvie/noProjects.svg";
import { ReactComponent as WorklogCaptionDark } from "../../assets/images/ralvie/noProjectsDark.svg";

const fixedColors = [
  "#FF5733",
  "#2C3E50",
  "#33FF57",
  "#3357FF",
  "#FF33A1",
  "#33FFA1",
  "#A133FF",
  "#FF5733",
  "#FFC300",
  "#DAF7A6",
  "#581845",
  "#900C3F",
  "#C70039",
  "#FF5733",
  "#FFBD33",
  "#33FFBD",
  "#57A1FF",
  "#FF33C4",
  "#33C4FF",
  "#C4FF33",
  "#8E44AD",
  "#3498DB",
  "#2ECC71",
  "#E74C3C",
  "#1ABC9C",
  "#F39C12",
  "#D35400",
  "#27AE60",
  "#2980B9",
  "#8E44AD",
];

const MostUsedApps = forwardRef((props) => {
  const [filter, setFilter] = useState({
    userId: "",
    startDate: "",
    endDate: "",
    departmentFilter: "",
    formattedStartTime: "",
    formattedEndTime: "",
  });
  const { setReload, reload, applicationRules } = useContext(TimesheetContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mostUsedAppsData, setMostUsedAppsData] = useState([]);
  const [appColorMap, setAppColorMap] = useState({});
  const { mode } = useContext(AppContext);

  useEffect(() => {
    console.log(applicationRules);
  }, [applicationRules]);

  function findIconByKeyword(keyword) {
    const lowerCaseKeyword = keyword.toLowerCase();

    for (const app of applicationRules) {
      if (app?.keywords?.some((k) => k.toLowerCase() === lowerCaseKeyword)) {
        return app.icon;
      }
    }

    return undefined;
  }

  useEffect(() => {
    if (
      filter.userId !== props.userId ||
      !dayjs(filter.startDate).isSame(dayjs(props.startDate)) ||
      !dayjs(filter.endDate).isSame(dayjs(props.endDate)) ||
      filter.departmentFilter !== props.departmentFilter
    ) {
      setFilter({
        ...filter,
        ...props,

        formattedStartTime: CommonUtil.formatDateToUTC(props.startDate),
        formattedEndTime: CommonUtil.formatDateToUTCDate(props.endDate),
      });
    }
  }, [props.userId, props.startDate, props.endDate, props.departmentFilter]);

  useEffect(() => {
    if (filter.startDate && filter.endDate) {
      const userData = JSON.parse(localStorage.getItem("USER"));

      let payload = {
        startTime:  CommonUtil.formatDateToUTC(props.startDate),
        endTime:  CommonUtil.formatDateToUTCDate(props.endDate),
        companyId: userData.companyId,
        userId: filter.userId === "All members" ? "" : filter.userId,
        departmentIds: "All departments".includes(filter.departmentFilter)
          ? ""
          : filter.departmentFilter,
      };

      fetchMostUsedApps(payload);
    }
  }, [filter]);

  const sortMostUsedAppsData = (data) => {
    return data?.sort((a, b) => {
      const durationA = a.totalHours * 60 + a.totalMinutes;
      const durationB = b.totalHours * 60 + b.totalMinutes;
      if (durationA === durationB) {
        return a.app.localeCompare(b.app);
      }
      return durationB - durationA;
    });
  };

  const fetchMostUsedApps = (payload) => {
    setIsLoading(true);
    mostUsedApps(payload)
      .then((res) => {
        if (res.data?.code === "RCI0000") {
          const filteredData = res.data.data.mostusedapps?.filter(
            (app) => app.totalDuration >= 60
          );
          const sortedData = sortMostUsedAppsData(filteredData);
          setMostUsedAppsData(sortedData);
        } else {
          setMostUsedAppsData([]);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while processing your request.");
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
        setReload(true);
      });
  };

  useEffect(() => {
    const newAppColorMap = {};
    let previousColor = null;

    mostUsedAppsData?.forEach((appData, index) => {
      let currentColor = fixedColors[index % fixedColors.length];
      if (currentColor === previousColor) {
        currentColor = fixedColors[(index + 1) % fixedColors.length];
      }
      newAppColorMap[appData.app] = currentColor;
      previousColor = currentColor;
    });

    setAppColorMap(newAppColorMap);
  }, [mostUsedAppsData]);

  useImperativeHandle(props.ref, () => ({
    resetState() {
      setMostUsedAppsData([]);
      setAppColorMap({});
    },
  }));

  const getApplicationIcon = (data) => {
    let iconUrl = findIconByKeyword(data?.app);

    return iconUrl ? (
      <img
        src={iconUrl}
        alt={""}
        height={30}
        width={30}
        key={data?.app}
        id={data?.app}
      />
    ) : (
      <DefaultAppIcon height={30} width={30} key={data?.app} id={data?.app} />
    );

    return <AppIcon data={data} height={36} width={36} />;
  };

  return (
    <div
      className={css`
        position: absolute;
        height: 100%;
        width: 100%;
        padding: 24px 24px;
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
        `}
      >
        <Typography id="user-most-apps-text" className="AppCategoriesHeading">{t("RAL0049")}</Typography>
      </div>
      <div
        className={css`
          height: 95%;
          overflow: auto;
        `}
      >
        <div
          className={css`
            height: 100%;
          `}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height:"100%",
                paddingTop:"40px"
              }}
            >
              <CircularProgress id="most-apps-circular-icon"/>
            </Box>
          ) : mostUsedAppsData?.length === 0 ? (
            <div
              className={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
              `}
            >
              <Box>
                {mode === "light" ? <WorklogCaption id="most-apps-WorklogCaption-icon"  className="imagesize"/> : <WorklogCaptionDark id="most-apps-WorklogCaption-icon"  className="imagesize"/>}
              </Box>
              <Box>
                <p id="most-apps-WorklogCaption-text" className="worklog-caption">{t("RAL0277")}</p>
              </Box>
            </div>
          ) : (
            <div className="boxFormatting">
              {mostUsedAppsData?.map((appData, index) => {
                const appColor = appColorMap[appData.app];
                const lightColor = appColor
                  ? alpha(appColor, 0.09)
                  : "transparent";

                return (
                  <Grid
                    item
                    lg={12}
                    mt={index === 0 ? 1.5 : 0}
                    key={appData.app}
                    sx={{ marginBottom: "10px" }}
                  >
                    <Box
                      className="mostUsedApp_card_001"
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent="space-between"
                      spacing={2}
                      p={2}
                      style={{ backgroundColor: lightColor, flexWrap: "nowrap" }}
                    >
                      <Box
                        className="appIcon"
                        item
                        pr={1}
                        sx={{ width: "15%" }}
                      >
                        <Box
                          display="flex"
                          sx={{ width: "100%", flexWrap: "nowrap" }}
                          justifyContent={"center"}
                        >
                          <Box className="appIconAlign" item>
                            {getApplicationIcon(appData)}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        item
                        sx={{ width: "50%", flexWrap: "nowrap" }}
                        className="text_overflow"
                        style={{ textTransform: "capitalize" }}
                      >
                    {appData?.app?.length > 14 ? (
                      <Tooltip id={"app_title_" + appData?.app} title={appData?.app}>
                        <Typography
                          id={"app_title_" + appData?.app}
                          sx={{ textTransform: "capitalize" }}
                        >
                        <span className="appName">{`${appData?.app.substring(0, 14)}...`}</span>
                       </Typography>
                      </Tooltip>
                    ) : (
                        <Typography
                          id={"app_title_" + appData?.app}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <span className="appName">{appData?.app}</span>
                        </Typography>
                      )}
                      </Box>
                      <Box item sx={{ width: "35%", paddingRight: "8px", minWidth: "120px" }}>
                        <Box
                          display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{ width: "100%" }}
                        >
                        <Typography id="most-apps-appData" variant="body2" className="time-text"
                         style={{ marginRight: '-5px', whiteSpace: 'nowrap' }}
                        >
                      {filter.userId && filter.userId !== "All members"
                         ? `${appData.totalHours} h ${appData.totalMinutes} m ${appData.totalSeconds} s`
                      : `${appData.totalHours} h ${appData.totalMinutes} m`}
                     </Typography>
                       </Box>
                    </Box>
                 </Box>
               </Grid>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default MostUsedApps;
