import {
  Avatar,
  avatarClasses,
  AvatarGroup,
  avatarGroupClasses,
  Box,
  Button,
  Card,
  Grid,
  InputBase,
  LinearProgress,
  linearProgressClasses,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { styled } from "@mui/system";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ReactComponent as EditIconDark } from "../../assets/images/edit_icon_dark.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/filter-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/ralvie/add-icon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/images/ralvie/archive.svg";
import { ReactComponent as ArchiveIconDark } from "../../assets/images/ralvie/archiveDark.svg";
import { ReactComponent as CompleteIcon } from "../../assets/images/ralvie/complete.svg";
import { ReactComponent as CompleteIconDark } from "../../assets/images/ralvie/complete_dark.svg";
import { ReactComponent as EditIcon } from "../../assets/images/ralvie/EditIcon.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/ralvie/more-icon.svg";
import { ReactComponent as EmptyProjectList } from "../../assets/images/ralvie/noProjects.svg";
import { ReactComponent as EmptyProjectListDark } from "../../assets/images/ralvie/noProjectsDark.svg";
import { ReactComponent as ProjectIcon } from "../../assets/images/ralvie/Project.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh_icon.svg";
import { ReactComponent as TableViewIcon } from "../../assets/images/table-view.svg";
import CustomIconButton from "../../components/CustomIconButton";
import CustomSearchField from "../../components/CustomSearchField";
import ContentLayout from "../../components/layout-components/ContentLayout";
import AppContext from "../../config/AppContext";
import { clientList, rolesList, userList } from "../../services/MemberService";
import {
  batchProjetsComplete,
  batchProjetsUnarchive,
  listProject,
  softDeleteProject,
} from "../../services/ProjectService";
import CommonUtil from "../../util/CommonUtils";
import UnarchiveDialog from "../project/UnarchiveDialog";
import ArchiveDialog from "./ArchiveDialog";
import ProjectFilter from "./ProjectFilter";
import "./Projects.scss";

const BorderLinearProgress = styled(({ bgcolor, barcolor, ...props }) => (
  <LinearProgress {...props} />
))(({ theme, bgcolor, barcolor }) => ({
  height: 8,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: bgcolor || "#2C7865", // default value if no bgcolor is passed
    ...theme.applyStyles("dark", {
      backgroundColor: bgcolor || "#2C7865",
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: barcolor || "#90D26D", // default value if no barcolor is passed
    ...theme.applyStyles("dark", {
      backgroundColor: barcolor || "#2C7865",
    }),
  },
}));

const S_AvatarGroup = styled(AvatarGroup, {
  $$avatarSize: "20vmin",

  [`&.${avatarGroupClasses.root} .${avatarClasses.root}`]: {
    border: "none",
    marginLeft: "calc($$avatarSize * -0.38)",
    height: "$$avatarSize",
    width: "$$avatarSize",
  },
});

const TagsDisplay = ({ tags }) => {
  const maxLengthPerTag = 10; // Maximum characters per tag before truncating
  const maxTotalLength = 20; // Total maximum characters allowed before applying truncation

  // Function to determine if truncation is necessary for each tag
  const shouldTruncate = (index) => {
    const totalLength = tags.reduce((acc, cur) => acc + cur.name.length, 0);
    if (tags.length === 1 || totalLength <= maxTotalLength) {
      return false;
    }
    return tags[index].name.length > maxLengthPerTag;
  };

  return (
    <div className="tags-container">
      {tags.slice(0, 2).map((tag, index) => (
        <div key={tag.id} className="tags">
          <Box>{tag.iconUrl && <img src={tag.iconUrl} alt={tag.name} />}</Box>
          <Typography
            className="tag-names"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap", // Ensure no line breaks
            }}
          >
            {shouldTruncate(index) ? (
              <Tooltip title={tag.name} placement="top" arrow>
                <span>{tag.name.substring(0, maxLengthPerTag) + "..."}</span>
              </Tooltip>
            ) : (
              <span>{tag.name}</span>
            )}
          </Typography>
        </div>
      ))}
      {tags.length > 2 && <div className="extra-tag">+{tags.length - 2}</div>}
    </div>
  );
};

function Projects() {
  const { mode } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [clientSearch, setClientSearch] = useState("");
  const [memberSearch, setMemberSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const role = CommonUtil.getRoleCode();
  const [projectEnchorEl, setProjectEnchorEl] = useState(null);
  const openFilter = Boolean(projectEnchorEl);
  const [clients, setClients] = useState([]);
  const { t } = useTranslation();
  const [clientFilter, setClientFilter] = useState([t("RAL0272")]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [members, setMembers] = useState([]);
  const [memberFilter, setMemberFilter] = useState([t("RAL0273")]);
  const [filterMessage, setFilterMessage] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [dialogActionType, setDialogActionType] = useState("");
  const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false);

  const SelectInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      width: "100%",
      borderRadius: 4,
      position: "relative",
      fontSize: 16,
      padding: isXlScreen ? "12px 26px 12px 12px" : "9px 26px 7px 12px",
      backgroundColor: "transparent !important",
      opacity: 1,
      font: "normal normal normal 16px/20px Gotham",
    },
  }));

  const handleClick = useCallback((event, projectId) => {
    setAnchorEl(event.currentTarget);
    setCurrentProjectId(projectId);
    setOpenMenu(true);
  }, []);

  const handleClientFilterChange = (clientName) => {
    if (clientName === t("RAL0272")) {
      setClientFilter([t("RAL0272")]);
    } else {
      let newClientFilter = [...clientFilter];

      if (clientFilter.includes(t("RAL0272"))) {
        newClientFilter = newClientFilter.filter(
          (name) => name !== t("RAL0272")
        );
      }

      if (newClientFilter.includes(clientName)) {
        newClientFilter = newClientFilter.filter((name) => name !== clientName);
      } else {
        newClientFilter.push(clientName);
      }

      // Ensure at least one client is selected, otherwise default to "All clients"
      if (newClientFilter.length === 0) {
        setClientFilter([t("RAL0272")]);
      } else {
        setClientFilter(newClientFilter);
      }
    }

    setSearch("");
    handleClose();
  };

  const handleMemberFilterChange = (memberId) => {
    if (memberId === t("RAL0273")) {
      setMemberFilter([t("RAL0273")]);
    } else {
      let newMemberFilter = [...memberFilter];

      if (memberFilter.includes(t("RAL0273"))) {
        newMemberFilter = newMemberFilter.filter((id) => id !== t("RAL0273"));
      }

      if (newMemberFilter.includes(memberId)) {
        newMemberFilter = newMemberFilter.filter((id) => id !== memberId);
      } else {
        newMemberFilter.push(memberId);
      }

      // Ensure at least one member is selected, otherwise default to "All members"
      if (newMemberFilter.length === 0) {
        setMemberFilter([t("RAL0273")]);
      } else {
        setMemberFilter(newMemberFilter);
      }
    }

    setSearch("");
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleCompleteClick = (id) => {
    setSelectedProjectId(id);
    setOpenArchiveDialog(true);
    setDialogActionType("complete");
  };

  const handleArchiveOpen = (id) => {
    setSelectedProjectId(id);
    setOpenArchiveDialog(true);
    setDialogActionType("archive");
  };

  const handleUnarchiveClick = (id) => {
    setSelectedProjectId(id);
    setOpenUnarchiveDialog(true);
  };

  const handleCloseUnarchiveDialog = () => {
    setOpenUnarchiveDialog(false);
  };

  const handleConfirmUnrchive = async () => {
    try {
      let projectIds = [selectedProjectId];
      if (projectIds.length >= 1) {
        const payload = { ids: projectIds };
        const res = await batchProjetsUnarchive(payload);
        if (res.data?.code === "RCI0000") {
          fetchProjects(filter, search);
          toast.success(t("RAL0539"));
          if (projectIds.length === 1) {
            setProjects([]);
          }
          handleCloseUnarchiveDialog();
          handleClose();
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error(t("RAL0540"));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("RAL0584"));
    }
  };

  const handleArchiveProject = async () => {
    try {
      if (selectedProjectId) {
        const response = await softDeleteProject(selectedProjectId);

        if (response.data?.code === "RCI0000") {
          setProjects((prevProjects) =>
            prevProjects.filter((project) => project.id !== selectedProjectId)
          );
          toast.success(t("RAL0283"));
          handleCloseArchiveDialog();
        } else {
          toast.error(response.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentProjectId(null);
  };

  const location = useLocation();
  const { state } = location || {};

  useEffect(() => {
    if (state?.filter) {
      setFilter(state?.filter);
    }
    if (state?.memberFilter) {
      setMemberFilter(state?.memberFilter);
    }

    if (state?.clientFilter) {
      setClientFilter(state?.clientFilter);
    }
  }, []);

  const handleSwitch = () => {
    navigate("/projects-list", {
      state: {
        filter: filter,
        memberFilter: memberFilter,
        clientFilter: clientFilter,
      },
    });
  };

  // useEffect(() => {
  //   userList().then((r) => {
  //     if (r.data.code === "UASI0000") {
  //       const updatedMembers = r.data.data.users.map((user) => {
  //         return {
  //           id: user.id,
  //           name: user.memberName,
  //           photo: user.profilePhoto
  //         };
  //       });
  //       setMembers(updatedMembers);
  //     }
  //   });
  // }, []);

  const navigateEdit = useCallback(() => {
    if (currentProjectId) {
      navigate(`/projects/${currentProjectId}`);
      handleClose();
    }
  }, [currentProjectId, navigate]);

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-Uk", options);
  };

  const fetchProjects = (status, name) => {
    setIsLoading(true);
    listProject(status, name)
      .then((res) => {
        if (res.data.code !== "RCE0131") setProjects(res.data.data.projects);
        setIsLoading(false);
      })
      .catch((res) => {
        console.error("Error fetching projects:", res);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProjects(filter, search);
  }, [filter, search]);

  const handleReset = () => {
    setClientFilter([t("RAL0272")]);
    setMemberFilter([t("RAL0273")]);
    setSearch("");
    setClientSearch("");
    setMemberSearch("");
    setProjects([]);
    setFilter("");
    setClearSearch(true);
    fetchProjects(filter, search);
  };

  const handleProjectFilter = (event) => {
    setProjectEnchorEl(event.currentTarget);
  };

  const handleStatusChange = (status) => {
    setFilter(status);
  };

  const handleCloseFilter = () => {
    setProjectEnchorEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesResponse = await rolesList();
        if (rolesResponse.data.code === "UASI0000") {
          const clientRole = rolesResponse.data.data.roles.find(
            (role) => role.name === "Client"
          );
          if (clientRole) {
            const clientsResponse = await clientList({ roleId: clientRole.id });
            if (clientsResponse.data.code === "RCI0000") {
              const updatedClients = clientsResponse.data.data.map((user) => ({
                id: user.id,
                name: user.name,
              }));
              setClients(updatedClients);
            }
          }

          // Fetch user list and filter out users with the "Client" role
          const usersResponse = await userList();
          if (usersResponse.data.code === "UASI0000") {
            const updatedMembers = usersResponse.data.data.users
              .filter(
                (user) =>
                  user.roleName !== "Client" && user.status !== "ARCHIVED"
              )
              .map((user) => ({
                id: user.id,
                name: user.memberName,
                photo: user.profilePhoto,
                status: user.status,
              }));
            setMembers(updatedMembers);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredProjects = projects.filter((project) => {
      const computedStatus = computeProjectStatus(project);

      const projectNameIncludes =
        !search || project?.name?.toLowerCase().includes(search.toLowerCase());

      const statusMatches =
        !filter || computedStatus.toLowerCase() === filter.toLowerCase();

      const clientNameIncludes =
        clientFilter.includes(t("RAL0272")) ||
        clientFilter.some((name) =>
          project?.client?.name?.toLowerCase().includes(name.toLowerCase())
        );

      const memberNameIncludes =
        memberFilter.includes(t("RAL0273")) ||
        (project.assignedMembers &&
          project.assignedMembers.some((memberId) =>
            memberFilter.includes(memberId)
          ));

      return (
        projectNameIncludes &&
        statusMatches &&
        clientNameIncludes &&
        memberNameIncludes
      );
    });

    setFilteredProjects(filteredProjects);
  }, [search, clientFilter, filter, memberFilter, projects]);

  const noResultsMessage = () => {
    if (projects.length === 0) {
      return t("RAL0286");
    }

    if (search) {
      return t("RAL0284");
    } else if (memberFilter !== t("RAL0273") && clientFilter !== t("RAL0272")) {
      return t("RAL0541");
    } else if (memberFilter !== t("RAL0273")) {
      return t("RAL0542");
    } else if (clientFilter !== t("RAL0272")) {
      return t("RAL0285");
    }
    return t("RAL0286");
  };

  const getMemberName = (memberId) => {
    const member = members.find((member) => member.id === memberId);
    return member ? member : { name: "Unknown", photo: null };
  };

  // const handleImport = (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   setIsLoading(true);
  //   importProjects(formData).then((res) => {
  //     setIsLoading(false);
  //     if (res.data?.code === "RCI0000") toast.success(t("RAL0373"));
  //     else toast.error(res.data.message);
  //     handleReset();
  //   });
  // };

  const computeProjectStatus = (project) => {
    const currentDate = new Date();
    const { startDate, endDate, status } = project;

    if (status === "ARCHIVED") return "ARCHIVED";
    if (new Date(startDate) > currentDate) {
      return "YET TO START";
    }
    if (new Date(endDate) > currentDate && status !== "COMPLETED") {
      return "IN PROGRESS";
    }
    if (
      new Date(endDate) < currentDate &&
      status !== "COMPLETED" &&
      status !== "ARCHIVED"
    ) {
      return "OVERDUE";
    }
    return "COMPLETED";
  };

  const handleClearSearch = () => {
    setSearch("");
    setClearSearch(true);
    setFilteredProjects(projects);
  };

  const actions = (
    <Box className="projectSelectBox" display="flex" alignItems="center">
      <Box pr={2}>
        <CustomSearchField
          id="btn-search-project"
          name="btn-search-project"
          width="22vw"
          clearSearch={clearSearch}
          // onChange={() => setClearSearch(false)}
          onChange={(newValue) => setSearch(newValue)}
          onClear={handleClearSearch}
          label={t("RAL0270")}
          value={search}
          size={isXlScreen ? "large" : "md"}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              setSearch(event.target.value);
            }
          }}
          onClick={(value) => {
            setClearSearch(false);
            setSearch(value);
          }}
        />
      </Box>

      <Box pr={2}>
        <Box
          sx={{
            "& .filter_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .filter_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            id="btn-filter-project"
            title={t("RAL0224")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              <FilterIcon
                id="btn-filter-project"
                className="filter_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <FilterIcon
                id="btn-filter-project"
                className="filter_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={handleProjectFilter}
          />
        </Box>

        <ProjectFilter
          open={openFilter}
          anchorEl={projectEnchorEl}
          status={filter}
          SelectInput={SelectInput}
          onStatusChange={handleStatusChange}
          onClose={handleCloseFilter}
          role={role}
          isXlScreen={isXlScreen}
          clientFilter={clientFilter}
          clients={clients}
          clientSearch={clientSearch}
          setClientSearch={setClientSearch}
          handleClientFilterChange={handleClientFilterChange}
          memberFilter={memberFilter}
          setClientFilter={setClientFilter}
          setMemberFilter={setMemberFilter}
          members={members}
          memberSearch={memberSearch}
          setMemberSearch={setMemberSearch}
          handleMemberFilterChange={handleMemberFilterChange}
          onClick={() => handleReset()}
        />
      </Box>

      {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
        <>
          {/* <Box item pr={2}>
            <ImportDialog
              title={t("RAL0275")}
              downloadTemplate={() => downloadProjectImportTemplate()}
              onImport={(file) => handleImport(file)}
            />
          </Box> */}
          {/* <Box mr={1.5}>
            <Box
              sx={{
                "& .export_svg": {
                  transition: "color 0.3s ease",
                  color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
                },
                "&:hover": {
                  "& .hover-text, & .export_svg": {
                    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
                  },
                },
              }}
            >
              <CustomIconButton
                id="btn-custom-add-member"
                name="btn-custom-add-member"
                title={t("RAL0276")}
                size={isXlScreen ? "large" : "md"}
                variant="square"
                type="outlined"
                lightMode={<ExportIcon className="export_svg" height={isXlScreen ? "18px" : "14px"} />}
                darkMode={<ExportIcon className="export_svg" height={isXlScreen ? "18px" : "14px"} />}
                onClick={() => {
                  projects?.length > 0 ? exportProjects() : toast.error(t("RAL0277"));
                }}
              />
            </Box>
          </Box> */}
        </>
      )}

      <Box mr={2}>
        <Box
          sx={{
            "& .refresh_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .refresh_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            id="btn-refresh-project"
            title={t("RAL0169")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              <RefreshIcon
                id="btn-refresh-project"
                className="refresh_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <RefreshIcon
                id="btn-refresh-project"
                className="refresh_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={() => handleReset()}
          />
        </Box>
      </Box>

      <Box item pr={2}>
        <Box
          sx={{
            "& .table_view_svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#242424cc",
            },
            "&:hover": {
              "& .hover-text, & .table_view_svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            id="btn-switch-project"
            title={t("RAL0225")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            lightMode={
              <TableViewIcon
                id="btn-switch-project"
                className="table_view_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <TableViewIcon
                id="btn-switch-project"
                className="table_view_svg"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={handleSwitch}
          />
        </Box>
      </Box>

      {role !== "EMPLOYEE" && role !== "CLIENT" && role !== "TEAM LEAD" && (
        <>
          <CustomIconButton
            id="btn-custom-add-project"
            name="btn-custom-add-project"
            title={t("RAL0361")}
            size={isXlScreen ? "large" : "md"}
            variant="square"
            type="contained"
            border={"none"}
            lightMode={
              <AddIcon
                id="btn-custom-add-project"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            darkMode={
              <AddIcon
                id="btn-custom-add-project"
                height={isXlScreen ? "18px" : "14px"}
              />
            }
            onClick={() => navigate("/projects/new")}
          />
          {/* <Button
        id="btn-add-project"
        variant="contained"
        className="addButton"
        onClick={() => navigate("/projects/new")}
      >
        <AddIcon />
      </Button> */}
        </>
      )}
    </Box>
  );

  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog(false);
  };

  const displayNoProjects =
    projects?.length === 0 || filteredProjects?.length === 0;

  const handleConfirmCompleteProject = async () => {
    try {
      let projectIds = [selectedProjectId];
      if (projectIds.length >= 1) {
        const payload = { ids: projectIds };
        const res = await batchProjetsComplete(payload);
        if (res.data?.code === "RCI0000") {
          fetchProjects(filter, search);
          toast.success(t("RAL0544"));
          handleCloseArchiveDialog();
        } else {
          toast.error(res.data?.message);
        }
      } else {
        toast.error(t("RAL0540"));
      }
    } catch (error) {
      console.log(error);
      toast.error(t("RAL0543"));
    }
  };

  const fixedColors = [
    "#FF5733",
    "#2C3E50",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#33FFA1",
    "#A133FF",
    "#FF5733",
    "#FFC300",
    "#DAF7A6",
    "#581845",
    "#900C3F",
    "#C70039",
    "#FF5733",
    "#FFBD33",
    "#33FFBD",
    "#57A1FF",
    "#FF33C4",
    "#33C4FF",
    "#C4FF33",
    "#8E44AD",
    "#3498DB",
    "#2ECC71",
    "#E74C3C",
    "#1ABC9C",
    "#F39C12",
    "#D35400",
    "#27AE60",
    "#2980B9",
    "#8E44AD",
  ];

  const getColorForMember = (identifier) => {
    const index = identifier.charCodeAt(0) % fixedColors.length;
    return fixedColors[index];
  };

  const calculateProgress = (project) => {
    if (project.costBudget === 0 && project.timeBudget === 0) {
      return 0;
    }
    let progress = 0;

    if (
      project.budgetType !== "cost" &&
      project.timeBudget > 0 &&
      (project.timeBudgetUsed > 0 || project.unbilledTimeBudgetUsed > 0)
    ) {
      progress =
        ((project.timeBudgetUsed * 3600 +
          project.unbilledTimeBudgetUsed * 3600) /
          (project.timeBudget * 3600)) *
        100;
    } else if (
      project.costBudget > 0 &&
      (project.costBudgetUsed > 0 || project.unbilledCostBudgetUsed > 0)
    ) {
      progress =
        ((project.costBudgetUsed + project.unbilledCostBudgetUsed) /
          project.costBudget) *
        100;
    }

    // Optional: Ensure progress does not exceed 100
    // progress = Math.min(progress, 100);

    return progress % 1 !== 0 && progress < 1
      ? progress.toFixed(2)
      : Math.ceil(progress);
  };

  return CommonUtil.isOwnerOrAdminOrTeamlead() ? (
    <ContentLayout
      id="header-text-project-name"
      title={t("RAL0269")}
      actions={actions}
      isLoading={isLoading}
      bg={"transparent"}
    >
      <Grid
        className="ProjectDesign"
        container
        alignItems={displayNoProjects ? "center" : "flex-start"}
        justifyContent={displayNoProjects ? "center" : "flex-start"}
      >
        {displayNoProjects ? (
          <Box
            display={isLoading ? "none" : "flex"}
            height={"100%"}
            width={"100%"}
            justifyContent={"center"}
            flexDirection="column"
            alignItems="center"
          >
            {mode == "dark" ? <EmptyProjectListDark /> : <EmptyProjectList />}
            <Typography
              id="text-project-message"
              variant="h6"
              className="noProjectError"
              mt={2}
            >
              {noResultsMessage()}
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            {filteredProjects?.map((project, index) => {
              const progress = calculateProgress(project);
              let billedBudget = "";
              let unbilledBudget = "";
              if (project.budgetType === "time") {
                // let duration = "00h 00m";
                let billedDurationInSec =
                  project.timeBudgetUsed > 0
                    ? project.timeBudgetUsed * 3600
                    : 0;
                let billedDuration =
                  billedDurationInSec > 0
                    ? CommonUtil.getDurationWithoutRoundoff(billedDurationInSec)
                    : {
                        sec: 0,
                        hr: 0,
                        min: 0,
                      };
                billedBudget =
                  billedDuration.hr + "h " + billedDuration.min + "m";
                let unbilledDurationInSec =
                  project.unbilledTimeBudgetUsed > 0
                    ? project.unbilledTimeBudgetUsed * 3600
                    : 0;
                let unbilledDuration =
                  unbilledDurationInSec > 0
                    ? CommonUtil.getDurationWithoutRoundoff(
                        unbilledDurationInSec
                      )
                    : {
                        sec: 0,
                        hr: 0,
                        min: 0,
                      };
                unbilledBudget =
                  unbilledDuration.hr + "h " + unbilledDuration.min + "m";
              } else {
                billedBudget =
                  "$ " + Number(project?.costBudgetUsed?.toFixed(1));
                unbilledBudget =
                  "$ " + Number(project?.unbilledCostBudgetUsed?.toFixed(1));
              }
              return (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <Card className="projectCard">
                    <Box className="projectList">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Project Avatar and Details */}
                        <Box sx={{ display: "flex", width: "83%" }}>
                          <Avatar
                            id="project-image-avatar"
                            className="projectAvatar"
                            sx={{
                              width: "56px",
                              height: "56px",
                              backgroundColor:
                                project.color && project?.color.length > 7
                                  ? project.color
                                  : project.color.concat("4a"),
                            }}
                          >
                            <ProjectIcon
                              id="project-image-icon"
                              fill={
                                project.color && project?.color.length > 7
                                  ? project.color
                                  : project.color.concat("8b")
                              }
                            />
                          </Avatar>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              paddingTop: project?.client?.name ? "" : "17px",
                              flex: 1,
                              maxWidth: "70%",
                              overflow: "hidden",
                            }}
                          >
                            <Tooltip
                              title={
                                project?.name.length >= 13 ? project.name : ""
                              }
                            >
                              <Typography className="projectName">
                                {project.name}
                              </Typography>
                            </Tooltip>
                            <Typography className="projectClientName">
                              {project?.client?.name ? (
                                project.client.name.length > 14 ? (
                                  <Tooltip
                                    title={project.client.name}
                                    placement="top"
                                    arrow
                                  >
                                    {project.client.name}
                                  </Tooltip>
                                ) : (
                                  project.client.name
                                )
                              ) : (
                                "" // Placeholder text for when there is no client name
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        {/* Dropdown for more actions */}
                        <Box sx={{ width: "18%" }}>
                          {role !== "EMPLOYEE" &&
                            role !== "CLIENT" &&
                            role !== "TEAM LEAD" && (
                              <Button
                                id="btn-project-more-icon"
                                className="projectMenu"
                                onClick={(e) => handleClick(e, project.id)}
                                value={project.name}
                                sx={{
                                  background:
                                    open && currentProjectId === project.id
                                      ? "var(--background-color-40)"
                                      : "",
                                  "& .more_svg": {
                                    transition: "color 0.3s ease",
                                    color:
                                      open && currentProjectId === project.id
                                        ? mode === "dark"
                                          ? "#f8f8f8cc"
                                          : "#242424"
                                        : mode === "dark"
                                        ? "var(--color-33-50)"
                                        : "#242424cc",
                                  },
                                  "&:hover": {
                                    "& .hover-text, & .more_svg": {
                                      color:
                                        mode === "dark"
                                          ? "#f8f8f8cc"
                                          : "#242424",
                                    },
                                  },
                                }}
                              >
                                <MoreIcon
                                  id="project-more-icon"
                                  height={"14px"}
                                  width={"14px"}
                                  className="more_svg"
                                />
                              </Button>
                            )}
                          <Menu
                            className="projectActionBtn"
                            open={open && currentProjectId === project.id}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 8],
                                  },
                                },
                                {
                                  name: "flip",
                                  options: {
                                    enabled: false,
                                  },
                                },
                              ],
                            }}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              marginTop: "50px",
                              marginLeft: "50px",
                              border: "none",
                              boxShadow: "none",
                            }}
                          >
                            {filter !== "ARCHIVED" && (
                              <MenuItem onClick={navigateEdit}>
                                <ListItemIcon>
                                  {mode === "dark" ? (
                                    <EditIconDark
                                      id="project-edit-icon"
                                      className="icon"
                                    />
                                  ) : (
                                    <EditIcon
                                      id="project-edit-icon"
                                      className="icon"
                                    />
                                  )}
                                </ListItemIcon>
                                <Box
                                  item
                                  sx={{
                                    color:
                                      mode === "dark"
                                        ? "var(--color-33-80)"
                                        : "var(--text-secondary-80-80)",
                                  }}
                                >
                                  {t("RAL0172")}
                                </Box>
                              </MenuItem>
                            )}
                            {filter !== "ARCHIVED" && (
                              <MenuItem
                                onClick={() => handleArchiveOpen(project.id)}
                              >
                                <ListItemIcon>
                                  {mode == "dark" ? (
                                    <ArchiveIconDark
                                      id="project-archive-icon"
                                      className="archiveIcon"
                                    />
                                  ) : (
                                    <ArchiveIcon
                                      id="project-archive-icon"
                                      className="archiveIcon"
                                    />
                                  )}
                                </ListItemIcon>
                                <Box
                                  item
                                  sx={{
                                    color:
                                      mode === "dark"
                                        ? "var(--color-33-80)"
                                        : "var(--text-secondary-80-80)",
                                  }}
                                >
                                  {t("RAL0173")}
                                </Box>
                              </MenuItem>
                            )}
                            {filter !== "ARCHIVED" &&
                              computeProjectStatus(project) !== "COMPLETED" && (
                                <MenuItem
                                  onClick={() =>
                                    handleCompleteClick(project.id)
                                  }
                                >
                                  <ListItemIcon>
                                    {mode === "light" ? (
                                      <CompleteIcon
                                        id="project-complete-icon"
                                        className="icon"
                                      />
                                    ) : (
                                      <CompleteIconDark
                                        id="project-complete-icon"
                                        className="icon"
                                      />
                                    )}
                                  </ListItemIcon>
                                  <Box
                                    item
                                    sx={{
                                      color:
                                        mode === "dark"
                                          ? "var(--color-33-80)"
                                          : "var(--text-secondary-80-80)",
                                    }}
                                  >
                                    {t("RAL0545")}
                                  </Box>
                                </MenuItem>
                              )}

                            {filter === "ARCHIVED" && (
                              <MenuItem
                                onClick={() => handleUnarchiveClick(project.id)}
                              >
                                <ListItemIcon>
                                  {mode == "dark" ? (
                                    <ArchiveIconDark
                                      id="project-archive-icon"
                                      className="archiveIcon"
                                    />
                                  ) : (
                                    <ArchiveIcon
                                      id="project-archive-icon"
                                      className="archiveIcon"
                                    />
                                  )}
                                </ListItemIcon>
                                <Box
                                  item
                                  sx={{
                                    color:
                                      mode === "dark"
                                        ? "var(--color-33-80)"
                                        : "var(--text-secondary-80-80)",
                                  }}
                                >
                                  {t("RAL0535")}
                                </Box>
                              </MenuItem>
                            )}
                          </Menu>
                        </Box>
                      </Box>
                      {/* Display project dates */}
                      <Typography className="projectDate">
                        {formattedDate(project.startDate)} -{" "}
                        {formattedDate(project.endDate)}
                      </Typography>
                      {/* Display total budget and avatars */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "40px",
                            width: "50%",
                          }}
                        >
                          <Typography className="projectDurationHeader">
                            <Tooltip
                              id="project-text-budget"
                              title={t("RAL0279")}
                              placement="bottom"
                            >
                              <span id="project-text-budget">
                                {t("RAL0279")}
                              </span>
                            </Tooltip>
                          </Typography>
                          {/* <Typography className="projectDuration">
                          {project.budgetCost === "time"
                            ? project.timeBudget + " hrs"
                            : project.costBudget + " $"}
                        </Typography> */}
                          <Typography className="projectDuration">
                            <Tooltip
                              id="project-text-budget"
                              title={
                                (project.budgetType === "time" &&
                                  project.timeBudget > 0 &&
                                  project.timeBudget > 1) ||
                                (project.budgetType === "cost" &&
                                  project.costBudget > 0 &&
                                  project.costBudget.toString().length > 3)
                                  ? project.budgetType === "time"
                                    ? project.timeBudget + t("RAL0673")
                                    : "$" + project.costBudget
                                  : ""
                              }
                              placement="bottom"
                            >
                              {project.budgetType === "time"
                                ? project.timeBudget > 0
                                  ? project.timeBudget + t("RAL0673")
                                  : "0 hrs"
                                : project.budgetType === "cost"
                                ? project.costBudget > 0
                                  ? project.costBudget.toString().length > 3
                                    ? "$" +
                                      project.costBudget
                                        .toString()
                                        .slice(0, 3) +
                                      "..."
                                    : "$" + project.costBudget
                                  : "$0"
                                : "-"}
                            </Tooltip>
                          </Typography>
                        </Box>

                        <AvatarGroup
                          id="project-image-avatargroup"
                          className="MuiAvatar"
                          max={2}
                          sx={{
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              fontSize: 15,
                              backgroundColor: "var(--background-color)",
                              color: "var(--text-primary)",
                            },
                          }}
                        >
                          {project.assignedMembers && (
                            <>
                              {(() => {
                                // Filter valid members only
                                const validMembers = project.assignedMembers
                                  .filter((memberId) =>
                                    members.some((m) => m.id === memberId)
                                  )
                                  .filter(
                                    (memberId) =>
                                      getMemberName(memberId).name !== "Unknown"
                                  );

                                return (
                                  <>
                                    {validMembers.length > 3 && (
                                      <Tooltip
                                        title={`+${validMembers.length - 3} ${t(
                                          "RAL0672"
                                        )}`}
                                        placement="top"
                                        arrow
                                      >
                                        <Avatar id="member-image-avatar">
                                          +{validMembers.length - 3}
                                        </Avatar>
                                      </Tooltip>
                                    )}

                                    {validMembers
                                      .slice(0, 3)
                                      .map((memberId) => {
                                        const member = getMemberName(memberId);
                                        const memberName = member.name;
                                        const profilePhoto = member.photo;
                                        const backgroundColor =
                                          getColorForMember(memberName);

                                        return (
                                          <Tooltip
                                            title={memberName}
                                            key={memberId}
                                            placement="bottom"
                                          >
                                            <Avatar
                                              id="member-image-avatar"
                                              src={profilePhoto}
                                              style={{
                                                backgroundColor: !profilePhoto
                                                  ? backgroundColor
                                                  : undefined,
                                              }}
                                            >
                                              {!profilePhoto &&
                                                memberName[0].toUpperCase()}
                                            </Avatar>
                                          </Tooltip>
                                        );
                                      })}
                                  </>
                                );
                              })()}
                            </>
                          )}
                        </AvatarGroup>
                      </Box>
                      {/* Display project tags */}
                      {/* <div>
                            {project.tags && project.tags.length > 0 ? (
                                <TagsDisplay tags={project.tags} />
                            ) : (
                                ""
                            )}
                          </div> */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "40px",
                          marginBottom: "8px",
                        }}
                      >
                        <Typography className="projectDurationHeader">
                          {
                            project.budgetType === "cost"
                              ? t("RAL0297")
                              : project.budgetType === "time"
                              ? t("RAL0280") // Assuming RAL0280 corresponds to "Time Budget"
                              : t("RAL0082") // Default when neither time nor cost budget is selected
                          }
                        </Typography>

                        <Typography
                          id="project-text-progress"
                          className="projectDurationHeader"
                          sx={{ textAlign: "right" }}
                        >
                          {progress}%
                        </Typography>
                      </Box>
                      <BorderLinearProgress
                        id="project-text-borderline"
                        bgcolor={progress > 100 ? "#FE5050" : "#a1a1a1"}
                        barcolor={
                          progress > 100
                            ? "#FE5050"
                            : progress === 100
                            ? "#3FD981"
                            : "#FFA23A"
                        }
                        variant="determinate"
                        value={
                          progress > 100 ? 100 - (progress % 100) : progress
                        }
                      />
                      <div className="budget-usage">
                        <div className="budget-type">
                          <span
                            id="project-text-billed"
                            className="budget-type-title"
                          >
                            {t("RAL0663")}
                          </span>
                          <span
                            id="project-text-billedBudget"
                            className={
                              CommonUtil.getOS() === "MacOS"
                                ? "mac-budget-type-value"
                                : "budget-type-value"
                            }
                          >
                            {billedBudget}
                          </span>
                        </div>
                        <div className="budget-type">
                          <span
                            id="project-text-unbilledBudget"
                            className="budget-type-title"
                          >
                            {t("RAL0664")}
                          </span>
                          <span
                            id="project-text-unbilledBudget"
                            className={
                              CommonUtil.getOS() === "MacOS"
                                ? "mac-budget-type-value"
                                : "budget-type-value"
                            }
                          >
                            {unbilledBudget}
                          </span>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}

        <ArchiveDialog
          open={openArchiveDialog}
          onClose={handleCloseArchiveDialog}
          onConfirm={
            dialogActionType === "complete"
              ? handleConfirmCompleteProject
              : handleArchiveProject
          }
          name={t("RAL0598")}
          type="Project"
          actionType={dialogActionType}
        />

        <UnarchiveDialog
          open={openUnarchiveDialog}
          onClose={handleCloseUnarchiveDialog}
          onConfirm={handleConfirmUnrchive}
          name={t("RAL0598")}
          type="Project"
        />
      </Grid>
    </ContentLayout>
  ) : (
    <></>
  );
}

export default Projects;
