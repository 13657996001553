import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { departmentList } from "../../services/DepartmentService";
import "./DepartmentFilterRes.scss";
import AppContext from "../../config/AppContext";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { Button } from "@mui/base";
import CustomButton from "../../components/CustomButton";

const DepartmentFilter = ({ departmentFilter, setDepartmentFilter }) => {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [departments, setDepartments] = useState([]);
  const { mode } = useContext(AppContext);
  const { t } = useTranslation();
  const [departmentSearch, setDepartmentSearch] = useState("");
  const [filter, setFilter] = useState(departmentFilter);
  const filterRef = useRef(filter);
  const [open, setOpen] = useState(false);
  const handleDepartmentFilterChange = (departmentId) => {
    let newDepartmentFilter = [...filterRef.current];

    if (departmentId === "All departments") {
      setFilter(["All departments"]);
      filterRef.current = ["All departments"];
      return;
    }

    if (newDepartmentFilter.includes(departmentId)) {
      newDepartmentFilter = newDepartmentFilter.filter(
        (id) => id !== departmentId
      );
    } else {
      newDepartmentFilter.push(departmentId);
    }

    if (newDepartmentFilter.includes("All departments")) {
      newDepartmentFilter = newDepartmentFilter.filter(
        (id) => id !== "All departments"
      );
    }

    if (newDepartmentFilter.length === 0) {
      setFilter(["All departments"]);
      filterRef.current = ["All departments"];
    } else {
      setFilter(newDepartmentFilter);
      filterRef.current = newDepartmentFilter;
    }
  };

  const handleApply = () => {
    setDepartmentFilter(filterRef.current);
    setOpen(false);
  };

  const handleReset = () => {
    setFilter(["All departments"]);
    filterRef.current = ["All departments"];
    setOpen(false);
  };

  useEffect(() => {
    if (
      JSON.stringify(departmentFilter) === JSON.stringify(["All departments"])
    ) {
      setFilter(["All departments"]);
      filterRef.current = ["All departments"];
    }
  }, [departmentFilter]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await departmentList({ pageNumber: 0, pageSize: 1000 });
        if (res?.data?.code === "RCI0000") {
          const updatedDepts = res.data.data.departments;
          setDepartments(updatedDepts);
        }
      } catch (error) {
        console.error("Error fetching department list:", error);
      }
    };

    fetchData();
  }, []);

  const getDepartmentNames = (selectedIds) => {
    if (selectedIds.includes("All departments")) {
      return t("RAL0235");
    }
    return selectedIds
      .map((id) => departments.find((dept) => dept.id === id)?.name)
      .filter(Boolean)
      .join(", ");
  };

  return (
    <Box display="flex" alignItems="center">
      <Box sx={{ ml: 2 }}>
        <FormControl
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--border-color-1) !important",
                borderWidth: "1px !important",
              },
            },
          }}
        >
          <Select
            id="dashboard-dept-select"
            onOpen={() => setOpen(true)}
            open={open}
            onClose={() => handleReset()}
            fullWidth
            value={filterRef.current}
            sx={{
              height: isXlScreen ? "50px" : "40px",
              width: "250px",
              color: "var(--text-primary)",
              "& .MuiSelect-select": {
                display: "block",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              "& .MuiOutlinedInput-root": {
                borderColor: "var(--border-color-1)",
              },
            }}
            multiple
                      renderValue={(selected) => {
    const selectedDepartments = selected
      .map((id) => getDepartmentNames([id])) 
      .join(", ");
            return selectedDepartments.length > 16
               ? selectedDepartments.slice(0, 16) + "..."
               : selectedDepartments; }}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 400,
                  marginTop: 1,
                  maxWidth:220,
                },
              },
                   disableAutoFocusItem: true,
                  autoFocus: false,
             }}
           >
            <MenuItem
              id="dashboard-dept-menuitem"
              onKeyDown={(e) => e.stopPropagation()}
              sx={{
                fontFamily: "Gotham",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: "var(--fw-500)",
                backgroundColor:
                  mode === "dark" ? "#1F1F1F!important" : "#FFFFFF!important",
                color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                "&:hover": {
                  backgroundColor: "#1F1F1F",
                },
                "&.Mui-selected, &.Mui-selected:hover": {
                  backgroundColor:
                    mode === "dark" ? "#362C20!important" : "#FFF9F2!important",
                  color: "#FA9C2B",
                },
                position: "sticky",
                top: "0px",
                zIndex: "1",
                paddingTop: "8px",
              }}
            >
              <TextField
                id="select-search-deptfilter"
                placeholder={t("RAL0654")}
                variant="outlined"
                fullWidth
                size="small"
                value={departmentSearch}
                autoFocus
                autoComplete="off"
                onChange={(e) => setDepartmentSearch(e.target.value)}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  onClick: (e) => {
                    e.stopPropagation();
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid var(--border-color-1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid var(--border-color-1)",
                    },
                    "& input::placeholder": {
                      color:
                        mode === "light"
                          ? "#24242480 !important"
                          : "#f8f8f880 !important",
                      opacity: 1,
                    },
                  },
                }}
              />
            </MenuItem>
            <Box
              sx={{
                maxHeight: 260,
                overflowY: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "var(--background-color-38) transparent",
                "&::-webkit-scrollbar": {
                  width: "8px",
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem
                id="dashboard-dept-menuitem"
                value="All departments"
                onClick={() => handleDepartmentFilterChange("All departments")}
                selected={filterRef?.current?.includes("All departments")}
                sx={{
                  fontFamily: "Gotham",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: "var(--fw-500)",
                  backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF", 
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                  "&:hover": {
                    backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                    color: "#FA9C2B", 
                  },
                  "&.Mui-selected": {
                    backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                    color: "#FA9C2B", 
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                    color: "#FA9C2B", 
                  },
                }}
              >
                <Checkbox
                  id="dashboard-dept-checkbox"
                  checked={filterRef?.current.includes("All departments")}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      border:
                        mode === "dark"
                          ? "1px solid #4D4D4D"
                          : "1px solid #B2B2B2",
                      borderRadius: "4px",
                      width: "20px",
                      height: "20px",
                    },
                    "&.Mui-checked .MuiSvgIcon-root": {
                      border: "none",
                    },
                    "&.Mui-checked": {
                      color: "orange",
                    },
                    "& .MuiSvgIcon-root path": {
                      fill: "transparent",
                    },
                    "&.Mui-checked .MuiSvgIcon-root path": {
                      fill: "orange",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#362C20!important",
                      color: "#FA9C2B",
                      width: "20px",
                      height: "20px",
                    },
                  }}
                />
                {t("RAL0235")}
              </MenuItem>
              {departments &&
                departments
                  .filter((department) =>
                    department.name
                      .toLowerCase()
                      .includes(departmentSearch.toLowerCase())
                  )
                  .map((department) => (
                    <MenuItem
                      id="dashboard-dept-menuitem"
                      key={department.id}
                      selected={filterRef?.current?.includes(department.id)}
                      sx={{
                        fontFamily: "Gotham",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "var(--fw-500)",
                        backgroundColor: mode === "dark" ? "#1F1F1F" : "#FFFFFF", 
                        color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                        "&:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "&.Mui-selected": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: mode === "dark" ? "#362C20" : "#FFF9F2", 
                          color: "#FA9C2B", 
                        },
                      }}
                      value={department.id}
                      onClick={() =>
                        handleDepartmentFilterChange(department.id)
                      }
                    >
                      <Checkbox
                        id="dashboard-dept-checkbox"
                        display={"flex"}
                        alignItems={"center"}
                        checked={filterRef?.current.includes(department.id)}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            border:
                              mode === "dark"
                                ? "1px solid #4D4D4D"
                                : "1px solid #B2B2B2",
                            borderRadius: "4px",
                            width: "20px",
                            height: "20px",
                            boxSizing: "border-box",
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "none",
                          },
                          "&.Mui-checked": {
                            color: "#FA9C2B",
                          },
                          "& .MuiSvgIcon-root path": {
                            fill: "transparent",
                          },
                          "&.Mui-checked .MuiSvgIcon-root path": {
                            fill: "#FA9C2B",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#FA9C2B!important",
                            color: "#FA9C2B",
                            width: "20px!important",
                            height: "20px!important",
                          },
                          "& .MuiSvgIcon-root::before": {
                            display: "none",
                          },
                        }}
                      />
                      {department.name.length > 14 ? (
                        <Tooltip
                          id="dashboard-dept-name"
                          title={department.name}
                          placement="top"
                          arrow
                        >
                          <span id="dashboard-dept-name">{`${department.name
                            .replace("null", "")
                            .substring(0, 14)}...`}</span>
                        </Tooltip>
                      ) : (
                        department.name.replace("null", "")
                      )}
                    </MenuItem>
                  ))}
            </Box>

            <Box display="flex" justifyContent="flex-end" m={1} gap={2}>
              <CustomButton
                id="btn-rst-id"
                name="btn-rst-name"
                variant="outlined"
                color="primary"
                onClick={() => handleReset()}
                sx={{
                  width: "150px",
                  height: "50px",
                  borderRadius: "5px",
                  background:
                    mode === "light"
                      ? "var(--color-37) 0% 0% no-repeat padding-box"
                      : "var(--color-9-00) 0% 0% no-repeat padding-box",
                  border:
                    mode === "light"
                      ? "1px solid var(--color-1)"
                      : "1px solid var(--color-30)",
                  padding: "10px",

                  "&:hover": {
                    background:
                      mode === "light"
                        ? "var(--color-37) 0% 0% no-repeat padding-box"
                        : "var(--color-9-00) 0% 0% no-repeat padding-box",
                    border:
                      mode === "light"
                        ? "1px solid var(--color-1)"
                        : "1px solid var(--color-30)",
                  },
                }}
              >
                <Typography
                  id="dashboard-dept-cancel"
                  variant="h6"
                  style={{
                    font: "normal normal normal 16px/28px Gotham",
                    color:
                      mode === "light" ? "var(--color-1)" : "var(--color-30)",
                  }}
                >
                  {t("RAL0177")}
                </Typography>
              </CustomButton>
              <CustomButton
                id="btn-aply-id"
                name="btn-aply-name"
                variant="contained"
                color="primary"
                onClick={() => handleApply()}
                sx={{
                  width: "150px",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "20px",
                  background:
                    "linear-gradient(99deg, #1D0B77 0%, #6A5FA2 100%)",
                }}
              >
                <Typography
                  id="dashboard-dept-apply"
                  variant="h6"
                  style={{
                    font: "normal normal normal 16px/28px Gotham",
                    color: "#FFFFFF",
                  }}
                >
                  {t("RAL0230")}
                </Typography>
              </CustomButton>
            </Box>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default DepartmentFilter;
