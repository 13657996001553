import axios from "axios";
import CommonUtil from "../util/CommonUtils";

const axiosHelper = (headers) => {
  let req = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: headers,
  });

  req.interceptors.request.use(function (request) {
    localStorage.setItem("isLoading", true);
    window.dispatchEvent(new Event("storage"));
    return request;
  });

  req.interceptors.response.use(
    function (response) {
      if (response.status === 200 && response.data?.code === "ERROR") {
        CommonUtil.resetUserSession();
        window.location = "/";
      }
      localStorage.setItem("isLoading", false);
      window.dispatchEvent(new Event("storage"));
      return response;
    },
    function (error) {
      if (
        401 === error.response?.status &&
        window.location.pathname !== "/error"
      ) {
        // window.location = "/error";
        CommonUtil.resetUserSession();
      }
    }
  );

  return req;
};

const axiosHelperApi = (headers) => {
  let req = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1"),
    headers: headers,
  });

  req.interceptors.request.use(function (request) {
    localStorage.setItem("isLoading", true);
    window.dispatchEvent(new Event("storage"));
    return request;
  });

  req.interceptors.response.use(
    function (response) {
      if (response.status === 200 && response.data?.code === "ERROR") {
        CommonUtil.resetUserSession();
        window.location = "/";
      }
      localStorage.setItem("isLoading", false);
      window.dispatchEvent(new Event("storage"));
      return response;
    },
    function (error) {
      if (
        401 === error.response?.status &&
        window.location.pathname !== "/error"
      ) {
        // window.location = "/error";
        CommonUtil.resetUserSession();
      }
    }
  );

  return req;
};

const securedAxiosApi = () => {
  return axiosHelperApi({
    "Content-type": "application/json",
    Authorization: localStorage.getItem("token"),
    "accept-language": localStorage.getItem("i18nextLng"),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const securedAxios = () => {
  return axiosHelper({
    "Content-type": "application/json",
    Authorization: localStorage.getItem("token"),
    "accept-language": localStorage.getItem("i18nextLng"),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const unsecuredAxios = () => {
  return axiosHelper({
    "Content-type": "application/json",
    "accept-language": localStorage.getItem("i18nextLng"),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const unsecuredAxiosApi = () => {
  return axiosHelperApi({
    "Content-type": "application/json",
    "accept-language": localStorage.getItem("i18nextLng"),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const api = {
  axiosHelper,
  axiosHelperApi,
  securedAxios,
  unsecuredAxios,
  securedAxiosApi,
  unsecuredAxiosApi,
};

export default api;
