import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { ReactComponent as ArrowDown } from "../assets/images/ralvie/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../assets/images/ralvie/ArrowUp.svg";
import { ReactComponent as SearchIcon } from "../assets/images/ralvie/SearchIcon.svg";
import { ReactComponent as ClearIconDark } from "../assets/images/ralvie/clearTextIconDark.svg";
import { ReactComponent as EmptyMemberListDark } from "../assets/images/ralvie/empty-member-list-dark.svg";
import { ReactComponent as EmptyMemberList } from "../assets/images/ralvie/empty-member-list.svg";
import AppContext from "../config/AppContext";
import { userList } from "../services/MemberService";
import CommonUtil from "../util/CommonUtils";
import "./AddMembersComponent.scss";
import "./Components.scss";
import CustomButton from "./CustomButton";
import CustomChecks from "./CustomChecks";
import CustomDeleteButton from "./CustomDeleteButton";

const AddMembersComponent = ({
  selectedEmployee,
  EditData = [],
  billableType,
  billableCostData,
  clients,
}) => {
  // States
  const [cost, setCost] = useState(billableCostData || "");
  const [billableAmount, setBillableAmount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMembers, setSelectedMembers] = useState(EditData || []);
  const selectedMembersRef = useRef(selectedMembers);
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [filter, setFilter] = useState({
    status: "",
    name: "",
    email: "",
    toggleKeyToURL: true,
    includeArchived: true,
  });
  const [memberFilter, setMemberFilter] = useState("");
  const [approver, setApprover] = useState("");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { mode } = useContext(AppContext);

  useEffect(() => {
    if (allMembers?.length > 0 && cost === "Member default") {
      setSelectedMembers((prevSelectedMembers) => {
        let updatedList = prevSelectedMembers.map((member) => {
          const memberData = members.find((m) => m.id === member?.id);
          if (!memberData) {
            console.error(`No member data found for member ID ${member?.id}`);
            return member;
          }
          return {
            ...member,
            cost: memberData.costPerHour,
          };
        });
        selectedMembersRef.current = updatedList;
        return updatedList;
      });
    }
  }, [allMembers]);

  // Effects
  useEffect(() => {
    setCost(billableCostData);
  }, [billableCostData]);

  useEffect(() => {
    if (EditData && EditData?.length > 0) {
      setSelectedMembers(EditData);
      selectedMembersRef.current = EditData;
      setBillableAmount(
        billableCostData === "Common cost" ? EditData[0]?.cost || 0 : 0
      );
    }
  }, [EditData, billableCostData]);

  useEffect(() => {
    const loadMembers = async () => {
      setLoading(true);
      try {
        const res = await userList(filter);
        if (res?.status === 200 && res?.data?.code === "UASI0000") {
          const filteredMembers = res?.data?.data?.users.filter(
            (user) => user.roleName !== "Client"
          );
          setMembers(filteredMembers || []);
          const notClient = res?.data?.data?.users.filter(
            (user) => user.roleName !== "Client"
          );
          setAllMembers(notClient);
        }
      } catch (error) {
        console.error("Error loading members:", error);
      } finally {
        setLoading(false);
      }
    };
    loadMembers();
  }, [filter]);

  // Callbacks
  const handleToggle = useCallback(
    (member) => {
      const storedUser = JSON.parse(localStorage.getItem("USER"));
      if (storedUser && member?.id === storedUser.id) {
        return; // Prevent toggling the stored user
      }
      setSelectedMembers((prevSelectedMembers) => {
        const isSelected = prevSelectedMembers.some(
          (selectedMember) => selectedMember?.id === member?.id
        );
        if (isSelected) {
          let updatedList = prevSelectedMembers.filter(
            (selectedMember) => selectedMember?.id !== member?.id
          );
          selectedMembersRef.current = updatedList;
          return updatedList;
        } else {
          let memberCost =
            cost === "Member default" ? member?.costPerHour : billableAmount;
          let updatedList = [
            ...prevSelectedMembers,
            { ...member, cost: memberCost },
          ];
          selectedMembersRef.current = updatedList;
          return updatedList;
        }
      });
    },
    [billableAmount, cost]
  );

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("USER"));
    if (storedUser && storedUser.id) {
      setSelectedMembers((prevSelectedMembers) => {
        if (
          !prevSelectedMembers.some((member) => member?.id === storedUser.id)
        ) {
          let updatedList = [
            ...prevSelectedMembers,
            {
              id: storedUser.id,
              cost:
                billableCostData === "Member default"
                  ? storedUser.costPerHour
                  : billableAmount,
            },
          ];
          selectedMembersRef.current = updatedList;
          return updatedList;
        }

        selectedMembersRef.current = prevSelectedMembers;
        return prevSelectedMembers;
      });
    }
  }, [billableCostData, billableAmount]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setFilter((prevFilter) => {
      if (CommonUtil.isValidEmail(value)) {
        return { ...prevFilter, email: value, name: "" };
      } else {
        return { ...prevFilter, name: value, email: "" };
      }
    });
  };
  const clearFilterName = () => {
    setFilter({
      ...filter,
      name: "",
      email: "",
    });
  };

  const handleMemberSearch = (event) => {
    setMemberFilter(event.target.value);
  };
  const handleClear = () => {
    setMemberFilter("");
  };

  const handleSelectMembers = () => {
    if (selectedMembers?.length <= 0) {
      toast.error(t("RAL0303"));
    } else {
      selectedEmployee(selectedMembers);
      setOpen((prevState) => !prevState);
      toast.success(t("RAL0304"));
    }
  };

  const handleClick = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const handleClose = () => {
    const storedUser = JSON.parse(localStorage.getItem("USER"));
    if (storedUser && storedUser.id) {
      // Attempt to find the user's cost from the members list
      const userFromMembers = members.find(
        (member) => member?.id === storedUser.id
      );
      const userCost = userFromMembers
        ? userFromMembers.costPerHour
        : billableAmount;

      setSelectedMembers([{ id: storedUser.id, cost: userCost }]);
    } else {
      setSelectedMembers([]);
    }
    setOpen((prevState) => !prevState);
  };

  const handleRemove = (memberId) => {
    const storedUser = JSON.parse(localStorage.getItem("USER"));
    if (storedUser && memberId === storedUser.id) {
      return; // Prevent removing the stored user
    }
    const updatedSelectedMembers = selectedMembers.filter(
      (member) => member?.id !== memberId
    );
    setSelectedMembers(updatedSelectedMembers);
    selectedMembersRef.current = updatedSelectedMembers;
    selectedEmployee(updatedSelectedMembers);
  };

  const shouldDisplayTooltip = (text) => {
    return text && text?.length > 50;
  };

  const getDisplayText = (text) => {
    return text && text?.length > 50 ? `${text.substring(0, 50)}...` : text;
  };

  const handleBillableCost = (e) => {
    const selectedCost = e.target.value;
    setCost(selectedCost);
    if (selectedCost !== "Common cost") {
      setBillableAmount(0);
    } else if (selectedCost === "Common cost") {
      setSelectedMembers((prevSelectedMembers) => {
        let updatedList = prevSelectedMembers.map((member) => ({
          ...member,
          cost: 0,
        }));
        selectedMembersRef.current = updatedList;
        return updatedList;
      });
    } else if (selectedCost === "Individual cost") {
      setSelectedMembers((prevSelectedMembers) => {
        let updatedList = prevSelectedMembers.map((member) => ({
          ...member,
          cost: 0,
        }));
        selectedMembersRef.current = updatedList;
        return updatedList;
      });
    }

    if (selectedCost === "Member default") {
      setSelectedMembers((prevSelectedMembers) => {
        let updatedList = prevSelectedMembers.map((member) => {
          const memberData = members.find((m) => m.id === member?.id);
          if (!memberData) {
            console.error(`No member data found for member ID ${member?.id}`);
            return member;
          }
          return {
            ...member,
            cost: memberData.costPerHour,
          };
        });
        selectedMembersRef.current = updatedList;
        return updatedList;
      });
    }
    billableType(selectedCost);
  };

  const handleBillableAmount = (e) => {
    let input = e.target.value;

    const inputValue = /^\d{0,10}(\.\d{0,2})?$/;
    if (input === "" || inputValue.test(input)) {
      setBillableAmount(input);

      // Update the selected members' cost
      if (cost === "Common cost") {
        setSelectedMembers((prevSelectedMembers) => {
          let updatedList = prevSelectedMembers.map((member) => ({
            ...member,
            cost: input,
          }));
          selectedMembersRef.current = updatedList;
          return updatedList;
        });
      } else if (cost === "Individual cost") {
        setSelectedMembers((prevSelectedMembers) => {
          let updatedList = prevSelectedMembers.map((member) => ({
            ...member,
            cost: member?.cost === "Member default" ? member?.cost : input,
          }));
          selectedMembersRef.current = updatedList;
          return updatedList;
        });
      }
    }
  };

  const handleApprover = (e) => {
    setApprover(e.target.value);
  };

  const handleIndividualCostChange = (memberId, value) => {
    // Remove non-numeric characters except decimal point
    value = value.replace(/[^0-9.]/g, "");
    const inputValue = /^\d{0,10}(\.\d{0,2})?$/;
    if (value === "" || inputValue.test(value)) {
      // Ensure only two decimal places
      const decimalIndex = value.indexOf(".");
      if (decimalIndex >= 0) {
        const decimalPart = value.substring(
          decimalIndex + 1,
          decimalIndex + 2 + 1
        );
        value = value.substring(0, decimalIndex + 1) + decimalPart;
      }

      // Ensure length of value is at most 20 characters
      if (value?.length > 20) {
        value = value.substring(0, 20);
      }

      setSelectedMembers((prevState) => {
        const updatedMembers = prevState.map((member) => {
          if (member?.id === memberId) {
            return { ...member, cost: value };
          }
          return member;
        });

        const newMember = { id: memberId, cost: value };
        const existingMember = updatedMembers.find(
          (member) => member?.id === memberId
        );

        if (!existingMember) {
          return [...updatedMembers, newMember];
        }
        selectedMembersRef.current = updatedMembers;
        return updatedMembers;
      });
    }
  };

  useEffect(() => {
    selectedEmployee(selectedMembers);
  }, [selectedMembers]);

  const renderMembers = () => {
    if (!members || members?.length === 0) {
      return (
        <Box
          id="btn-toggle-exp-member"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {mode == "light" ? <EmptyMemberList /> : <EmptyMemberListDark />}
          <Typography id="project-text-message" variant="body1">
            {t("RAL0321")}
          </Typography>
        </Box>
      );
    } else {
      return (
        <List>
          {members
            .filter((member) => {
              const lowerCaseName = member?.memberName?.toLowerCase();
              const lowerCaseEmail = member?.email?.toLowerCase();
              const lowerCaseFilter = filter.name?.toLowerCase();
              return (
                lowerCaseName?.includes(lowerCaseFilter) ||
                lowerCaseEmail?.includes(lowerCaseFilter)
              );
            })
            .map((member) => (
              <ListItem
                id={`btn-select-${member?.id}-item`}
                name={`btn-select-${member?.id}-item`}
                onClick={() => handleToggle(member)}
                sx={{
                  fontFamily: "Gotham",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: "var(--fw-500)",
                  cursor: "pointer",
                  color: mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor:
                      mode === "dark"
                        ? "#362C20!important"
                        : "#FFF9F2!important",
                    color: "#FA9C2B",
                  },
                }}
                key={member?.id}
                className={
                  selectedMembers.some(
                    (selectedMember) => selectedMember?.id === member?.id
                  )
                    ? "checked"
                    : ""
                }
              >
                <ListItemIcon id="project-list-icon">
                  <CustomChecks
                    id={`btn-select-${member?.id}-item`}
                    name={`btn-select-${member?.id}-item`}
                    checked={selectedMembers.some(
                      (selectedMember) => selectedMember?.id === member?.id
                    )}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleToggle(member);
                    }}
                    member={member}
                  />
                </ListItemIcon>
                <ListItemAvatar id="project-listitem-icon">
                  <Avatar
                    id="project-image-avatar"
                    alt={member?.memberName || "No Name"}
                    src={member?.profilePhoto}
                  />
                </ListItemAvatar>
                <Tooltip
                  title={member?.memberName}
                  placement="top"
                  disableHoverListener={
                    !shouldDisplayTooltip(member?.memberName)
                  }
                >
                  <div>
                    {" "}
                    {/* Wrap in div to ensure Tooltip has a proper target */}
                    <ListItemText
                      primary={getDisplayText(member?.memberName)}
                    />
                  </div>
                </Tooltip>
              </ListItem>
            ))}
        </List>
      );
    }
  };

  const styles = {
    textField: {
      "& .MuiOutlinedInput-root": {
        background:
          "var(--custom-btn-background-1) 0% 0% no-repeat padding-box",
        color: "var(--text-color-6)",
        "& fieldset": {
          borderRadius: "none",
          border: "none",
        },
        "&.Mui-focused fieldset": {
          borderColor: "none",
        },
        "&:hover fieldset": {
          borderColor: "none",
        },
        "&.MuiInputLabel-root ": {
          display: "none",
          border: "none",
        },
        "&.Mui-focused": {
          backgroundColor: "var(-input-focused-background)",
        },
      },
    },
    outlinedInput: {
      border: "none",
      "& fieldset": {
        border: "none",
      },
      color: "var(--text-primary)",
      background: "var(--background-color-9) 0% 0% no-repeat padding-box",
      borderRadius: "5px",
      height: "60px",
    },
    memberListBox: {
      width: "100%",
      height: "55%",
      overflow: "auto",
      overflowY: "auto",
      scrollbarWidth: "thin",
      scrollbarColor: "#a2a4a680 transparent",
      "&::-webkit-scrollbar": {
        width: "8px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
      },
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      height: "60px",
    },
  };

  return (
    <>
      <Accordion
        className="accordion"
        square={false}
        elevation={0}
        sx={{
          backgroundColor:
            mode === "dark" ? "#101010!important" : "#FFFFFF!important",
          "&.MuiAccordion-root": {
            "::before": {
              content: "none", // Correct way to remove ::before pseudo-element
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id="accordinHeader-header-sticky"/>}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordinHeader header-sticky"
        >
          {t("RAL0222")}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="addMember"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box
                      sx={{ marginBottom: 2 }}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        id="project-text-members"
                        className="MemberHeader InviteMember"
                      >
                        <p>{t("RAL0305")}</p>
                        {/* <p>{t("RAL0127")}</p> */}
                      </Typography>
                      {/* <InfoIcon /> */}
                    </Box>
                    <div style={{ maxWidth: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          className="projectSelectMem"
                          fullWidth
                          id="text-search-member-project"
                          name="text-search-member-project"
                          placeholder={t("RAL0306")}
                          value={searchTerm}
                          disabled={open}
                          onChange={handleSearchChange}
                          onClick={handleClick} // Consider handling this differently if it affects adornment
                          sx={{
                            ...styles.textField,
                            cursor: "pointer",
                            "& input": {
                              cursor: "pointer",
                              color: "var(--text-color-6)", // Keeps text but makes it transparent
                              textShadow: "0 0 0 black", // Use text shadow to display the text
                              backgroundColor: "var(--background-color-9)",
                              fontFamily: "'Gotham'",
                            },
                          }}
                          InputProps={{
                            readOnly: true, // This prevents typing but allows for interaction
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents TextField onClick from being triggered
                                    handleClick();
                                  }}
                                  edge="end"
                                >
                                  {open ? (
                                    <ArrowUp
                                      id="project-arrowup-icon"
                                      style={{ width: "10px", height: "8px" }}
                                    />
                                  ) : (
                                    <ArrowDown
                                      id="project-arrowup-icon"
                                      style={{ width: "10px", height: "8px" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      {open && (
                        <Box className="addMembers">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <TextField
                              fullWidth
                              id="text-search-member"
                              name="text-search-member"
                              placeholder={t("RAL0307")}
                              value={filter.name || filter.email}
                              onChange={handleSearchChange}
                              sx={{ ...styles.textField }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      id="project-clear-icon"
                                      aria-label="clear input"
                                      onClick={clearFilterName}
                                      sx={{
                                        borderRadius: "5px",
                                        background: "transparent",
                                        visibility:
                                          filter.name || filter.email
                                            ? "inherit"
                                            : "hidden",
                                        "& .clear_svg": {
                                          transition: "color 0.3s ease",
                                          color:
                                            mode === "dark"
                                              ? "var(--color-33-50)"
                                              : "#242424cc",
                                        },
                                        "&:hover": {
                                          "& .hover-text, & .clear_svg": {
                                            color:
                                              mode === "dark"
                                                ? "#f8f8f8cc"
                                                : "#242424",
                                          },
                                        },
                                      }}
                                    >
                                      {filter.name || filter.email ? (
                                        <Tooltip title={"Clear"}>
                                          <ClearIconDark
                                            id="project-clear-icon"
                                            className="clear_svg"
                                          />
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                    </IconButton>
                                    <IconButton
                                      id="project-search-icon"
                                      sx={{
                                        borderRadius: "5px",
                                        background: "transparent",
                                        "& .search_svg": {
                                          transition: "color 0.3s ease",
                                          color:
                                            mode === "dark"
                                              ? "var(--color-33-50)"
                                              : "#242424cc",
                                        },
                                        "&:hover": {
                                          "& .hover-text, & .search_svg": {
                                            color:
                                              mode === "dark"
                                                ? "#f8f8f8cc"
                                                : "#242424",
                                          },
                                        },
                                      }}
                                      type="button"
                                      aria-label="search"
                                      onClick={handleClick}
                                    >
                                      <Tooltip title={t("RAL0180")}>
                                        <SearchIcon
                                          id="project-search-icon"
                                          className="search_svg"
                                        />
                                      </Tooltip>
                                    </IconButton>
                                    {/* <IconButton
                                      id="btn-search-member"
                                      name="btn-search-member"
                                      aria-label="toggle password visibility"
                                      onClick={handleClick}
                                      edge="end"
                                    >
                                      <SearchIcon />
                                    </IconButton> */}
                                  </InputAdornment>
                                ),
                                inputProps: {
                                  maxLength: 36,
                                },
                              }}
                            />
                          </div>
                          <Typography sx={{ color: "var(--text-primary)" }}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t("RAL0308").replace(
                                  "Selected",
                                  `<span style="color: var(--custom-btn-border-color-1);">Selected </span>`
                                ),
                              }}
                            />
                            <Typography
                              component="span"
                              sx={{
                                color: "var(--custom-btn-border-color-1)",
                              }}
                            >
                              {selectedMembers?.length}
                            </Typography>
                          </Typography>
                          <Box sx={styles.memberListBox}>{renderMembers()}</Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "20px",
                              width: "100%",
                            }}
                          >
                            <CustomButton
                              place="sp-cancel"
                              variant="outlined"
                              class="memberSaveButton"
                              id="btn-cancel-member-list"
                              name="btn-cancel-member-list"
                              onClick={handleClose}
                              disabled={!members || members?.length === 0}
                            >
                              {t("RAL0177")}
                            </CustomButton>
                            <CustomButton
                              variant="contained"
                              class="memberCancelButton"
                              id="btn-save-member-list"
                              name="btn-save-member-list"
                              onClick={handleSelectMembers}
                              disabled={!members || members?.length === 0}
                            >
                              {t("RAL0309")}
                            </CustomButton>
                          </div>
                        </Box>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="Billable"
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box className="MemberInfo">
                        <Typography className="MemberHeader">
                          {t("RAL0310")}
                        </Typography>
                      </Box>
                      <Grid container xs={12} sm={12} md={12} lg={12}>
                        <FormControl
                          sx={{ marginTop: "20px" }}
                          className="BillableCost"
                        >
                          <RadioGroup
                            id="btn-project-radiogroup"
                            row
                            value={cost}
                            onChange={handleBillableCost}
                          >
                            <Grid item xl={4} lg={4} md={4} sm={12}>
                              <FormControlLabel
                                id="btn-radio-default-cost"
                                sx={{
                                  marginRight: "50px",
                                  font: "normal normal normal 14px/16px Gotham",
                                  width: "100%",
                                }}
                                value="Member default"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": { color: "#FA9C2B" },
                                    }}
                                  />
                                }
                                label={t("RAL0311")}
                              />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12}>
                              <FormControlLabel
                                id="btn-radio-common-cost"
                                sx={{
                                  font: "normal normal normal 14px/16px Gotham",
                                }}
                                value="Common cost"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": { color: "#FA9C2B" },
                                    }}
                                  />
                                }
                                label={t("RAL0312")}
                              />
                            </Grid>
                            <Grid item xl={4} lg={4} md={4} sm={12}>
                              <FormControlLabel
                                id="btn-radio-individual-cost"
                                sx={{
                                  font: "normal normal normal 14px/16px Gotham",
                                  width: "100%",
                                }}
                                value="Individual cost"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": { color: "#FA9C2B" },
                                    }}
                                  />
                                }
                                label={t("RAL0313")}
                              />
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className="BillableCostInput"
                    >
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          id="text-project-billableAmount"
                          type="text"
                          inputProps={{ maxLength: 13 }} // maxLength works here
                          value={billableAmount}
                          endAdornment={
                            <InputAdornment position="end">
                              {t("RAL0314")}
                            </InputAdornment>
                          }
                          disabled={cost !== "Common cost"}
                          sx={{ ...styles.outlinedInput }}
                          onChange={handleBillableAmount}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="addMember"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      id="text-project-memberHeader"
                      className="MemberHeader"
                    >
                      {t("RAL0401")}{" "}
                      <span id="text-project-memberHeader" className="optional">
                        {t("RAL0316")}
                      </span>
                    </Typography>
                    <div className="mtTop">
                      <FormControl fullWidth>
                        <Select
                          id="btn-select-approver"
                          displayEmpty
                          value={approver}
                          onChange={handleApprover}
                          input={<OutlinedInput />}
                          renderValue={
                            approver !== ""
                              ? undefined
                              : () => {
                                  t("RAL0317");
                                }
                          }
                          inputProps={{
                            "aria-label": "Without label",
                            placeholder: t("RAL0317"),
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 180,
                                overflowY: "auto",
                                scrollbarWidth: "thin",
                                scrollbarColor:
                                  "var(--background-color-38) transparent",
                                "&::-webkit-scrollbar": {
                                  width: "8px",
                                  backgroundColor: "transparent",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  borderRadius: "10px",
                                  backgroundColor: "var(--background-color-38)",
                                },
                              },
                            },
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          sx={styles.outlinedInput}
                        >
                          {members.map((member) => (
                            <MenuItem
                              id={`btn-select-approver-${member?.id}`}
                              key={member?.id}
                              value={member?.id} // Assuming `id` is a unique identifier for each member
                              sx={{
                                fontFamily: "Gotham",
                                fontStyle: "normal",
                                fontVariant: "normal",
                                fontWeight: "var(--fw-500)",
                                backgroundColor:
                                  mode === "dark"
                                    ? "#1F1F1F!important"
                                    : "#FFFFFF!important",
                                color:
                                  mode === "dark" ? "#f8f8f8cc" : "#242424cc",
                                // "&:hover": {
                                //   backgroundColor: "#1F1F1F",
                                // },
                                "&.Mui-selected, &.Mui-selected:hover": {
                                  backgroundColor:
                                    mode === "dark"
                                      ? "#362C20!important"
                                      : "#FFF9F2!important",
                                  color: "#FA9C2B",
                                },
                              }}
                            >
                              {member?.memberName?.length > 51 ? (
                                <Tooltip
                                  title={member?.memberName}
                                  placement="top"
                                  arrow
                                >
                                  <span>{`${member?.memberName
                                    .replace("null", "")
                                    .substring(0, 51)}...`}</span>
                                </Tooltip>
                              ) : (
                                member?.memberName.replace("null", "")
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Grid container direction="column" style={{ height: "100%" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="membersList"
                >
                  <Box className="MemberListInfo">
                    <Typography
                      id="text-project-memberlist"
                      className="MemberHeader"
                    >
                      {t("RAL0318")}
                    </Typography>
                    <TextField
                      id="project-search-members"
                      placeholder={t("RAL0223")}
                      value={memberFilter}
                      onChange={handleMemberSearch}
                      sx={{ width: "300px", ...styles.textField }}
                      inputProps={{ maxLength: 25 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              id="project-clear-members"
                              aria-label="clear input"
                              onClick={handleClear}
                              sx={{
                                borderRadius: "5px",
                                background: "transparent",
                                visibility: memberFilter ? "inherit" : "hidden",
                                "& .clear_svg": {
                                  transition: "color 0.3s ease",
                                  color:
                                    mode === "dark"
                                      ? "var(--color-33-50)"
                                      : "#242424cc",
                                },
                                "&:hover": {
                                  "& .hover-text, & .clear_svg": {
                                    color:
                                      mode === "dark" ? "#f8f8f8cc" : "#242424",
                                  },
                                },
                              }}
                            >
                              <Tooltip title={"Clear"}>
                                {memberFilter ? (
                                  <ClearIconDark
                                    id="project-clear-members"
                                    className="clear_svg"
                                  />
                                ) : (
                                  <></>
                                )}
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              id="project-search-members"
                              sx={{
                                borderRadius: "5px",
                                background: "transparent",
                                "& .search_svg": {
                                  transition: "color 0.3s ease",
                                  color:
                                    mode === "dark"
                                      ? "var(--color-33-50)"
                                      : "#242424cc",
                                },
                                "&:hover": {
                                  "& .hover-text, & .search_svg": {
                                    color:
                                      mode === "dark" ? "#f8f8f8cc" : "#242424",
                                  },
                                },
                              }}
                              // id={props.id}
                              // name={props.name}
                              type="button"
                              aria-label="search"
                              onClick={handleClick}
                            >
                              <Tooltip title={t("RAL0180")}>
                                <SearchIcon
                                  id="project-search-members"
                                  // height={props.size === "md" ? "14px" : "18px"}
                                  // width={props.size === "md" ? "14px" : "18px"}
                                  className="search_svg"
                                />
                              </Tooltip>
                            </IconButton>
                            {/* <IconButton
                              id="btn-search-member"
                              aria-label="toggle password visibility"
                              onClick={handleClick}
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton> */}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="memberListName"
                    sx={{
                      width: "100%",
                      height: "50px",
                      background:
                        "var(--custom-btn-background-1) 0% 0% no-repeat padding-box",
                      borderRadius: "5px 5px 0px 0px",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Grid xs={5} sm={5} md={5} lg={5}>
                      <Typography
                        id="project-member-name"
                        sx={{ marginLeft: "30px" }}
                      >
                        {t("RAL0319")}
                      </Typography>
                    </Grid>
                    <Grid xs={7} sm={7} md={7} lg={7}>
                      <Typography id="project-member-cost">
                        {t("RAL0320")}
                      </Typography>
                    </Grid>
                  </Grid>
                  {selectedMembers?.length > 0 &&
                  members
                    .filter((member) =>
                      selectedMembers.some(
                        (selectedMember) => selectedMember?.id === member?.id
                      )
                    )
                    .filter((member) =>
                      member?.memberName
                        ?.toLowerCase()
                        ?.includes(memberFilter?.toLowerCase())
                    ).length > 0 ? (
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{
                        ...styles.memberListBox,
                        height: "100%",
                        maxHeight: "290px",
                      }}
                    >
                      <List
                        id="project-member-list"
                        sx={{ overflowY: "auto", height: "90%", width: "100%" }}
                        className="memberListName"
                      >
                        {allMembers
                          .filter((member) =>
                            selectedMembersRef?.current?.some(
                              (selectedMember) =>
                                selectedMember?.id === member?.id
                            )
                          )
                          .filter((member) =>
                            member?.memberName
                              ?.toLowerCase()
                              ?.includes(memberFilter?.toLowerCase())
                          )
                          .map((member) => (
                            <ListItem key={member?.id} sx={styles.listItem}>
                              <Grid
                                item
                                xs={5}
                                sm={5}
                                md={5}
                                lg={5}
                                display="flex"
                                alignItems="center"
                                gap={2}
                                width={"20%"}
                                marginLeft={"20px"}
                              >
                                <Avatar
                                  id="project-member-avatar"
                                  src={member?.profilePhoto}
                                />
                                <Tooltip
                                  title={
                                    member?.memberName?.length > 6
                                      ? member?.memberName
                                      : ""
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <Typography
                                    sx={{
                                      position: "relative",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "120px", // Adjust as needed
                                    }}
                                  >
                                    <span>
                                      {member?.memberName?.length > 6
                                        ? `${member?.memberName.substring(
                                            0,
                                            6
                                          )}...`
                                        : member?.memberName}
                                    </span>
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                display="flex"
                                alignItems="center"
                                gap={4}
                                sx={{ marginLeft: "-20px" }}
                              >
                                {cost === "Individual cost" &&
                                selectedMembersRef?.current ? (
                                  <TextField
                                    id="project-member-add"
                                    value={
                                      selectedMembersRef?.current?.find(
                                        (m) => m.id === member?.id
                                      )?.cost || 0
                                    }
                                    InputProps={{ inputProps: { min: 0 } }}
                                    type="number"
                                    sx={{
                                      height: "40px",
                                      width: "90px",
                                      ...styles.textField,
                                    }}
                                    onChange={(e) =>
                                      handleIndividualCostChange(
                                        member?.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <Typography>
                                    {
                                      selectedMembers.find(
                                        (m) => m.id === member?.id
                                      )?.cost
                                    }
                                  </Typography>
                                )}
                                <Typography>$/hr</Typography>
                              </Grid>
                              <Grid item xs={1} sm={1} md={1} lg={1}>
                                <CustomDeleteButton
                                  id="project-member-delete"
                                  onClick={() => handleRemove(member?.id)}
                                />
                              </Grid>
                            </ListItem>
                          ))}
                      </List>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "290px",
                      }}
                    >
                      {mode == "light" ? (
                        <EmptyMemberList id="project-empty-icon" />
                      ) : (
                        <EmptyMemberListDark id="project-empty-icon" />
                      )}
                      <Typography
                        id="project-empty-text"
                        className="noMemberFound"
                      >
                        {t("RAL0321")}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AddMembersComponent;
