import { useMemo, useState, useEffect } from "react";
import { createTheme } from "@mui/material";
import { ReactComponent as CustomDropdownIcon } from "../assets/images/ralvie/dropdownIcon.svg";
import { ReactComponent as CustomDropdownIconDark } from "../assets/images/ralvie/dropdownIconDark.svg";
const themeSettings = (mode) => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        lg_s: 1366,
        lg_m: 1600,
        xl: 1920,
      },
    },
    palette: {
      mode,

      primary: {
        main: "#1D0B77",
      },
      secondary: {
        main: "#FA9C2B",
      },
    },
    typography: {
      allVariants: {
        color: mode === "dark" ? "#f8f8f8cc" : "#242424",
      },
      page_title: {
        fontFamily: "GothamMedium",
        fontStyle: "normal",
        fontSize: "36px",
        lineHeight: "44px",
        fontVariant: "normal",
        letterSpacing: "0px",
        color: "#242424",
        opacity: "1",
      },
      title: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        font: "normal normal normal 28px/42px Gotham",
        opacity: "1",
      },
      subtitle: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0.36px",
        font: "normal normal normal 18px/27px Gotham",
        opacity: "1",
      },
      subtitle2: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "23px",
        fontFamily: "Gotham",
      },
      subtitle3: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        font: "normal normal normal 16px/25px Gotham",
        opacity: "1",
      },
      subtitle4: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        font: "normal normal medium 14px/21px Gotham",
        opacity: "1",
      },
      paragraph: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        font: "normal normal normal 14px/24px Gotham",
        opacity: "0.8",
      },
      link: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0.4px",
        font: "normal normal normal 16px/25px Gotham",
        opacity: "0.8",
      },
      button: {
        color: mode === "dark" ? "#f8f8f8cc" : "#242424",
        letterSpacing: "0.36px",
        font: "normal normal normal 16px/28px Gotham",
        opacity: "1",

        textTransform: "none",
      },
      text_24_35_0_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "35px",
        fontFamily: "Gotham",
      },
      text_24_35_0_2: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "35px",
        fontFamily: "Gotham",
      },
      text_16_25_0_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        fontStyle: "normal",
        fontVariant: "normal",
        fontSize: "16px",
        lineHeight: "25px",
        fontFamily: "Gotham",
        opacity: "1",
      },
      text_20_30_0_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        font: "normal normal 600 20px/30px Gotham",
        opacity: "1",
      },
      text_28_42_0_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "600",
        fontSize: "28px",
        lineHeight: "42px",
        fontFamily: "Gotham",
      },
      text_16_25_04_08: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0.4px",
        opacity: "0.8",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "25px",
        fontFamily: "Gotham",
      },
      text_16_25_0_08: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        opacity: "0.8",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "25px",
        fontFamily: "Gotham",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      text_16_23_0_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "23px",
        fontFamily: "Gotham",
      },
      text_18_27_0_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "550",
        fontSize: "18px",
        lineHeight: "27px",
        fontFamily: "Gotham",
      },
      text_18_27_036_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0.36px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "27px",
        fontFamily: "Gotham",
      },
      text_16_28_032_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "23px",
        fontFamily: "Gotham",
      },
      text_16_28_032_1_white: {
        color: "#FFFFFF",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "23px",
        fontFamily: "Gotham",
      },
      text_14_17_0_1: {
        color: "#242424",
        letterSpacing: "0px",
        font: "normal normal normal 14px/17px Gotham",
        opacity: "0.8",
      },
      text_14_21_0_1: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        font: "normal normal normal 14px/21px Gotham",
        opacity: "0.5",
      },
      text_12_17_1_1_m: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        font: "normal normal bold 12px/17px Gotham",
        opacity: "1",
      },
      text_10_10_1_1_m: {
        color: mode === "dark" ? "#F8F8F8" : "#242424",
        letterSpacing: "0px",
        font: "normal normal bold 10px/10px Gotham",
        opacity: "1",
      },
      text_28_42_0_3: {
        color: "#1D0B77",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "23px",
        fontFamily: "Gotham",
      },
      text_28_42_0_8: {
        color: "#FE5050",
        letterSpacing: "0px",
        opacity: "1",
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "23px",
        fontFamily: "Gotham",
      },
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          popupIndicator: {
            position: "absolute",
            right: "5px",
            top: "calc(50% - 0.15em)",
            width: "14px",
            height: "8px",
            "& svg": {
              width: "inherit",
              height: "inherit",
            },
          },
          option: {
            '&[aria-selected="true"]': {
              backgroundColor:
                mode === "dark" ? "#362C20!important" : "#FFF9F2!important",
              color: "#FA9C2B",
            },
            // "&:hover": {
            //   backgroundColor:
            //     mode === "dark" ? "#362C20!important" : "#FFF9F2!important",
            //   color: "#FA9C2B",
            // },
          },
          paper: {
            "& .MuiAutocomplete-listbox": {
              maxHeight: 180,
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "var(--background-color-38) transparent",
              "&::-webkit-scrollbar": {
                width: "8px",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "var(--background-color-38)",
              },
            },
          },
        },
        defaultProps: {
          popupIcon:
            mode === "dark" ? (
              <CustomDropdownIconDark />
            ) : (
              <CustomDropdownIcon />
            ),
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            position: "absolute",
            right: "14px",
            top: "calc(50% - 0.15em)",
            width: "10px",
            height: "8px",
            "& svg": {
              width: "inherit",
              height: "inherit",
            },
          },
        },
        defaultProps: {
          IconComponent:
            mode === "dark" ? CustomDropdownIconDark : CustomDropdownIcon,
        },
      },
      // MuiBackdrop: {
      //   styleOverrides: {
      //     root: {
      //       backdropFilter: "blur(3px)",
      //       webkitBackdropFilter: "blur(3px)",
      //     },
      //   },
      // },
      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiBackdrop-root": {
              backdropFilter: "blur(5px)",
              webkitBackdropFilter: "blur(5px)",
            },
          },
        },
      },
    },
  };
};

export const useMode = () => {
  const [mode, setMode] = useState(getSystemTheme());

  // Function to get the system's theme preference
  function getSystemTheme() {
    let mode = localStorage.getItem("mode");
    if (!mode) {
      mode = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
      localStorage.setItem("mode", mode);
    }
    return mode;
  }
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // Handler for the media query change event
    const handleThemeChange = (e) => {
      setMode(e.matches ? "dark" : "light");
      localStorage.setItem("mode", e.matches ? "dark" : "light");
    };

    // Add event listener to detect theme changes
    mediaQuery.addEventListener("change", handleThemeChange);

    // Cleanup the event listener on component unmount
    return () => mediaQuery.removeEventListener("change", handleThemeChange);
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem(
          "mode",
          localStorage.getItem("mode") === "light" ? "dark" : "light"
        );
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [mode]
  );
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  theme.typography.header1 = {
    font: "normal normal normal 28px/34px GothamMedium",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 34px/41px GothamMedium",
    },
  };

  theme.typography.header2 = {
    font: "normal normal normal 25px/30px GothamMedium",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 30px/36px GothamMedium",
    },
  };

  theme.typography.header3 = {
    font: "normal normal bold 14px/15px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal bold 16px/18px Gotham",
    },
  };

  theme.typography.header4 = {
    font: "normal normal normal 12px/15px GothamMedium",
    letterSpacing: "0px",
    opacity: 1,
    color: mode === "dark" ? "#f8f8f8cc" : "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 14px/16px GothamMedium",
    },
  };

  theme.typography.header5 = {
    font: "normal normal normal 10px/12px GothamMedium",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 12px/15px GothamMedium",
    },
  };

  theme.typography.header6 = {
    font: "normal normal normal 20px/22px GothamMedium",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 22px/25px GothamMedium",
    },
  };

  theme.typography.menu = {
    font: "normal normal normal 13px/15px GothamMedium",
    letterSpacing: "0px",
    opacity: 1,
    color: "#4F4F4F",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 15px/18px GothamMedium",
    },
  };

  theme.typography.label = {
    font: "normal normal normal 10px/12px Gotham",
    letterSpacing: "0px",
    opacity: 0.8,
    color: "var(--text-color-8)",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 12px/14px Gotham",
    },
  };

  theme.typography.placeholder = {
    font: "normal normal normal 12px/14px Gotham",
    letterSpacing: "0px",
    opacity: 0.5,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 13px/15px Gotham",
    },
  };

  theme.typography.link1 = {
    font: "normal normal normal 12px/14px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 14px/17px Gotham",
    },
  };

  theme.typography.colorlink = {
    font: "normal normal normal 12px/15px GothamMedium",
    letterSpacing: "0px",
    opacity: 1,
    color: "#1D0B77",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 14px/16px GothamMedium",
    },
  };

  theme.typography.checkbox_label = {
    font: "normal normal normal 10px/20px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 12px/22px Gotham",
    },
  };

  theme.typography.devider = {
    font: "normal normal normal 10px/15px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#999999",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 12px/18px Gotham",
    },
  };

  theme.typography.button_filled = {
    font: "normal normal normal 12px/19px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#FFFFFF",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 14px/23px Gotham",
    },
  };

  theme.typography.button_outlined = {
    font: "normal normal normal 12px/16px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#1D0B77",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 14px/19px Gotham",
    },
  };

  theme.typography.caption = {
    font: "normal normal normal 12px/19px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 17px/19px Gotham",
    },
  };

  theme.typography.input = {
    font: "normal normal normal 12px/14px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 14px/17px Gotham",
    },
  };

  theme.typography.helpertext = {
    font: "normal normal normal 10px/19px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#FE5050",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 12px/23px Gotham",
    },
  };

  theme.typography.datepicker = {
    font: "normal normal normal 16px/19px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    cursor: "pointer",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 18px/21px Gotham",
    },
  };

  theme.typography.activity_view = {
    font: "normal normal normal 14px/20px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 16px/20px Gotham",
    },
  };

  theme.typography.activity_header = {
    font: "normal normal normal 10px/14px GothamMedium",
    letterSpacing: "0px",
    opacity: 1,
    color: mode === "dark" ? "#F8F8F8" : "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 12px/17px GothamMedium",
    },
  };

  theme.typography.activity_duration = {
    font: "normal normal normal 8px/14px Gotham",
    letterSpacing: "0px",
    opacity: 1,
    color: mode === "dark" ? "#F8F8F8" : "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 10px/17px Gotham",
    },
  };

  theme.typography.activity_log_count = {
    font: "normal normal normal 8px/12px Gotham",
    letterSpacing: "0.5px",
    opacity: 0.5,
    color: "#242424",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 10px/14px Gotham",
    },
  };

  theme.typography.worklog_project_title = {
    font: "normal normal normal 14px/16px GothamMedium",
    letterSpacing: 0,
    opacity: 1,
    color: "#FFFFFF",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 16px/19px GothamMedium",
    },
  };

  theme.typography.worklog_project_details = {
    font: "normal normal normal 12px/14px Gotham",
    letterSpacing: 0,
    opacity: 1,
    color: "#FFFFFF",
    [theme.breakpoints.up("xl")]: {
      font: "normal normal normal 14px/17px Gotham",
    },
  };

  return [theme, colorMode, mode];
};
