import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import CustomIconButton from "../CustomIconButton";
import { ReactComponent as BackArrowIcon } from "../../assets/images/ralvie/back-arrow-icon.svg";
import "./LayoutComponents.scss";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppContext from "../../config/AppContext";
export default function TitleBar(props) {
  const theme = useTheme();
  const { mode } = useContext(AppContext);
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (props.onBackClick) {
      props.onBackClick();
    } else {
      window.history.go(-1);
    }
  };

  return (
    <Box
      display={"flex"}
      alignItems={"baseline"}
      justifyContent={"start"}
      pb={3}
    >
      {props.navBack && (
        <Box
          item
          pr={2}
          sx={{
            "& .back-arrow-svg": {
              transition: "color 0.3s ease",
              color: mode === "dark" ? "var(--color-33-50)" : "#24242480",
            },
            "&:hover": {
              "& .hover-text, & .back-arrow-svg": {
                color: mode === "dark" ? "#f8f8f8cc" : "#242424",
              },
            },
          }}
        >
          <CustomIconButton
            size={isXlScreen ? "large" : "md"}
            darkMode={<BackArrowIcon className="back-arrow-svg" />}
            lightMode={<BackArrowIcon className="back-arrow-svg" />}
            variant="square"
            onClick={handleBackClick}
          />
        </Box>
      )}
      <Box item flexGrow={1} maxWidth={"100%"} overflow={"hidden"}>
        <Typography className="title-text text-truncate">
          {props.title}
        </Typography>
        {props.subtitle && (
          <Typography className="subtitle-text" sx={{ position: "absolute" }}>
            {props.subtitle}
          </Typography>
        )}
        <Typography>{props.rowtitle}</Typography>
      </Box>
      <Box item>{props.children}</Box>
    </Box>
  );
}
