import axios from "axios";
import api from "../config/http-common";

export const predictProjectAndTag = async (payload) => {
  return api.securedAxios().post("/event/predict", payload);
};

export const createTimesheet = async (payload) => {
  return api.securedAxios().post("/timesheet", payload);
};

export const createTimesheetManual = async (payload) => {
  return api.securedAxios().post("/timesheet/manual", payload);
};

export const listTimesheet = async ({ startDate, endDate, userId }) => {
  let url = "/timesheet/list?startTime=" + startDate + "&endTime=" + endDate;
  if (userId) {
    url += "&userId=" + userId;
  } else {
    url += "&userId=";
  }
  return api.securedAxios().get(url);
};

export const listWeeklyTimesheet = async ({ startDate, endDate, userId }) => {
  let url = "/timesheet/week?startDate=" + startDate + "&endDate=" + endDate;
  if (userId) {
    url += "&userId=" + userId;
  } else {
    url += "&userId=";
  }
  return api.securedAxios().get(url);
};

export const textSummaraize = async (payload) => {
  return api.securedAxios().post("/timesheet/summarize", payload);
};

export const generateTitle = async (payload) => {
  return api.securedAxiosApi().post("/ai/generate_title", payload);
};

export const checkGrammer = async (payload) => {
  return api.securedAxiosApi().post("/ai/correct_grammar", payload);
};

export const exportTimesheet = async (startDate, endDate, userId) => {
  try {
    let api =
      "/timesheets/export?startTime=" + startDate + "&endTime=" + endDate;
    if (userId) {
      api += "&userId=" + userId;
    } else {
      api += "&userId=";
    }

    const response = await axios.get(
      process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") + api,
      {
        responseType: "blob", // Important to specify the response type as blob
        headers: {
          Authorization: localStorage.getItem("token"),
          timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );

    // Create a link element
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "project.xlsx"); // Set the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export const updateTimesheet = async (id, payload) => {
  return api.securedAxios().put(process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +"/timesheet/" + id, payload);
};

export const getTimesheetById = async (id) => {
  return api.securedAxios().get(process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +"/timesheet/" + id);
};

export const deleteTimesheetById = async (id) => {
  return api.securedAxios().delete(process.env.REACT_APP_SERVER_URL?.replace("/web", "/api/v1") +"/timesheet/" + id);
};