import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Avatar,
  Stack,
  Paper,
} from "@mui/material";
import { css } from "@emotion/css";
import { getMostActiveAndIdleUsers } from "../../services/DashboardService";
import { ReactComponent as EmptyMemberList } from "../../assets/images/ralvie/empty-member-list.svg";
import { ReactComponent as EmptyMemberListDark } from "../../assets/images/ralvie/empty-member-list-dark.svg";
import AppContext from "../../config/AppContext";
import CommonUtil from "../../util/CommonUtils";
import { useTranslation } from "react-i18next";
import "./PieChartRes.scss";

const UserActiveHours = (props) => {
  const { mode } = useContext(AppContext);
  const [mostActiveUsers, setMostActiveUsers] = useState([]);
  const [mostIdleUsers, setMostIdleUsers] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (props.startDate && props.endDate) fetchData();
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    if (
      props.startDate &&
      props.endDate &&
      props.departmentFilter &&
      props.departmentFilter !== "All departments"
    )
      fetchData();
  }, [props.departmentFilter]);

  const fetchData = async () => {
    try {
      let startDate = CommonUtil.formatDateToUTC(props.startDate);
      let endDate = CommonUtil.formatDateToUTCDate(props.endDate);
      const res = await getMostActiveAndIdleUsers({
        departmentId: "All departments".includes(props.departmentFilter)
          ? ""
          : props.departmentFilter,
        startTime: startDate,
        endTime: endDate,
      });

      if (res?.data?.code === "RCI0000") {
        setMostActiveUsers(res.data.data.mostActiveUsers);
        setMostIdleUsers(res.data.data.mostIdleUsers);
      } else {
        setMostActiveUsers([]);
        setMostIdleUsers([]);
      }
    } catch (error) {
      console.error("Error fetching getMostActiveAndIdleUsers:", error);
    }
  };

  return (
    <>
      <div
        className={css`
          min-height: 100%;
          max-height: 100%;
          width: 50%;
          background: var(--card-background-color) 0% 0% no-repeat padding-box;
          border-radius: 5px;
          opacity: 1;
          flex: 0 0 calc(50% - 12px);
        `}
      >
        <Box
          sx={{
            height: "100%",
            mx: "auto",
            padding: "20px",
            position: "relative",
          }}
        >
          <Typography
            id="user-active-highest"
            className="chartPresentWeek"
            style={{
              marginBottom: "30px",
            }}
          >
            {t("RAL0593")}
          </Typography>
          {mostActiveUsers && mostActiveUsers.length > 0 ? (
            <>
              {mostActiveUsers.slice(0, 5).map((ele, index) => (
                <Paper
                  key={index}
                  sx={{
                    padding: "20px",
                    marginBottom: "10px",
                    boxShadow: "none",
                    background:
                      "var(--background-color-9) 0% 0% no-repeat padding-box",
                    borderRadius: "5px",
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {ele?.user?.profilePhoto ? (
                      <Avatar
                        id="user-active-avatar"
                        sx={{ height: 65, width: 65 }}
                        src={ele?.user?.profilePhoto}
                      ></Avatar>
                    ) : (
                      <Avatar id="user-active-avatar" sx={{ height: 65, width: 65 }}>
                        {ele?.user?.name?.charAt(0)}
                      </Avatar>
                    )}

                    <Box sx={{
                      display: "flex",
                      flexGrow: 1,
                      justifyContent: "space-between",
                    }} >
                      <Box sx={{
                        display: "block",
                        alignSelf: "center",
                      }}>
                        <Typography
                          id="user-active-name"
                          variant="body1"
                          className="text-truncate"
                          sx={{
                            // width: "65%",
                            color: "var(--text-secondary-80)",
                            wordBreak: "break-all",
                            textWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {ele?.user?.name}
                        </Typography>
                        <Typography
                          id="user-active-designationname"
                          variant="body2"
                          sx={{
                            font: "normal normal normal 14px/17px Gotham",
                            letterSpacing: "0px",
                            color: "var(--text-secondary-80-80)",
                            textWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {ele?.user?.designation?.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "block",
                          alignSelf: "center",
                        }}
                      >
                        <Typography
                          id="user-active-average"
                          variant="caption"
                          display="block"
                          gutterBottom
                          sx={{
                            font: "normal normal normal 14px/17px Gotham",
                            letterSpacing: "0px",
                            color: "var(--text-secondary-80-80)",
                          }}
                        >
                          {t("RAL0596")}
                        </Typography>
                        <Typography
                          id="user-active-avgperday"
                          variant="caption"
                          display="block"
                          gutterBottom
                          sx={{
                            font: "14px/16px GothamMedium",
                            letterSpacing: "0px",
                            color: "var(--text-color-4)",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {ele?.weeklyAverage}/day
                        </Typography>
                      </Box>
                      {/* <LinearProgress
                        variant="determinate"
                        value={ele?.completion * 100}
                        sx={{
                          height: 8,
                          borderRadius: 2,
                          my: 1,
                          backgroundColor: "#EBFBF2",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#3FD981",
                          },
                        }}
                      /> */}
                    </Box>
                  </Stack>
                </Paper>
              ))}
            </>
          ) : (
            <Box
              id="btn-toggle-exp-member"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {mode == "light" ? <EmptyMemberList /> : <EmptyMemberListDark />}
              <Box>
                <p id="member-empty-icon" className="EmptyDashboardicon">{t("RAL0277")}</p>
              </Box>
            </Box>
          )}
        </Box>
      </div>
      <div
        className={css`
          min-height: 100%;
          max-height: 100%;
          width: 50%;
          background: var(--card-background-color) 0% 0% no-repeat padding-box;
          border-radius: 5px;
          opacity: 1;
          flex: 0 0 calc(50% - 12px);
        `}
      >
        <div
          className={css`
            height: 100%;
          `}
        >
          <Box
            sx={{
              height: "100%",
              mx: "auto",
              padding: "20px",
              position: "relative",
            }}
          >
            <Typography
              id="user-idle-highest"
              className="chartPresentWeek"
              style={{
                marginBottom: "30px",
              }}
            >
              {t("RAL0594")}
            </Typography>
            {mostIdleUsers && mostIdleUsers.length > 0 ? (
              <>
                {mostIdleUsers.slice(0, 5).map((ele, index) => (
                  <Paper
                    key={index}
                    sx={{
                      padding: "20px",
                      marginBottom: "10px",
                      boxShadow: "none",
                      background:
                        "var(--background-color-9) 0% 0% no-repeat padding-box",
                      borderRadius: "5px",
                    }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center">
                      {ele?.user?.profilePhoto ? (
                        <Avatar
                          id="user-idle-avatar"
                          sx={{ height: 65, width: 65 }}
                          src={ele?.user?.profilePhoto}
                        ></Avatar>
                      ) : (
                        <Avatar id="user-idle-avatar" sx={{ height: 65, width: 65 }}>
                          {ele?.user?.name?.charAt(0)}
                        </Avatar>
                      )}
                      <Box sx={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "space-between",
                      }}>
                        <Box
                          sx={{
                            display: "block",
                            alignSelf: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            id="user-idle-name"
                            className="text-truncate"
                            sx={{
                              color: "var(--text-secondary-80)",
                              // width: "65%",
                              wordBreak: "break-all",
                              textWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {ele?.user?.name}
                          </Typography>
                          <Typography
                            id="user-idle-designationName"
                            variant="body2"
                            sx={{
                              font: "normal normal normal 14px/17px Gotham",
                              letterSpacing: "0px",
                              color: "var(--text-secondary-80-80)",
                              textWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {ele?.user?.designation?.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "block",
                            alignSelf: "center",
                          }}
                        >

                          <Typography
                            id="user-idle-avg"
                            variant="caption"
                            display="block"
                            gutterBottom
                            sx={{
                              font: "normal normal normal 14px/17px Gotham",
                              letterSpacing: "0px",
                              color: "var(--text-secondary-80-80)",
                            }}
                          >
                            {t("RAL0596")}
                          </Typography>
                          <Typography
                            id="user-idle-avgperday"
                            variant="caption"
                            display="block"
                            gutterBottom
                            sx={{
                              font: "14px/16px GothamMedium",
                              letterSpacing: "0px",
                              color: "var(--text-color-4)",
                              whiteSpace: "nowrap"
                            }}
                          >
                            {ele?.weeklyAverage}/day
                          </Typography>
                        </Box>
                        {/* <LinearProgress
                          variant="determinate"
                          value={ele?.completion * 100}
                          sx={{
                            backgroundColor: "#FFEDED",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FE5050",
                            },
                            height: 8,
                            borderRadius: 2,
                            my: 1,
                          }}
                        /> */}
                      </Box>
                    </Stack>
                  </Paper>
                ))}
              </>
            ) : (
              <Box
                id="btn-toggle-exp-member"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  flexDirection: "column",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {mode == "light" ? (
                  <EmptyMemberList />
                ) : (
                  <EmptyMemberListDark />
                )}
                <Box>
                  <p id="member-empty-icon" className="EmptyDashboardicon">{t("RAL0277")}</p>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default UserActiveHours;
